import classNames from 'classnames';
import { rangeArray } from 'common/utils/helpers';
import { OrderDestination } from 'generated';
import { OrderOverviewWidget } from 'order/productGroups/common/components/molecule/OrderOverview';
import React, { VFC } from 'react';
import classes from './destination-detail-section.module.css';
import { Regions } from 'order/productGroups/common/components/atom/AMDHLDestinationInput/CalculateRegions';
import { useTranslation } from 'i18n';

export type DestinationDetailSectionProps = {
  destinations: OrderDestination[];
};

export const DestinationDetailSection: VFC<DestinationDetailSectionProps> = ({ destinations }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'destinationDetailSection' });
  const destination = new Map<string, number>();
  destinations?.forEach((dest) => {
    const zipKey = dest.zipCode?.substr(0, 2) || '';
    if (zipKey.length > 0) {
      destination.set(zipKey, (destination.get(zipKey) || 0) + (dest.quantity || 0));
    }
  });
  const destinationsWithSLN = destinations.filter((region) => Regions.SLN_REGIONS?.some((destZip) => `SLN${destZip}` === region.zipCode));

  return (
    <>
      <OrderOverviewWidget width={2}>
        <table className={'dhlTable ' + classes.amTable}>
          <thead className={classNames('dhlTable-sort-header ' + classes.productInfoTableHead)}>
            <tr>
              <th>{t('palettesQuantityPerZone')}</th>
              <th colSpan={10}>{t('palettesQuantityPerRegion')}</th>
            </tr>
          </thead>
          <tbody className={classes.productInfoTable}>
            {rangeArray(11)
              .filter((row) => destinations?.some((dest) => (dest.zipCode === 'DE' && row === 10) || dest.zipCode?.startsWith(row.toString())))
              .map((row, i) => {
                return (
                  <tr key={`row${i}`} id={`tableRowId${i}`} className={classNames('tableRow')}>
                    <td key={i} className={classNames('tableTdValue', 'lastTd')} colSpan={row === 10 ? 11 : 0}>
                      <p>{row === 10 ? `0-9` : row}</p>
                      <p className={classNames('tableParaValue')}>{destination?.get(row === 10 ? 'DE' : row.toString())}</p>
                    </td>
                    {row < 10 &&
                      rangeArray(10).map((col, i) => {
                        return (
                          <td key={i} className={classNames('tableTdValue')}>
                            <p>{`${row}${col}`}</p>
                            <p className={classNames('tableParaValue')}>{destination?.get(`${row}${col}`)}</p>
                          </td>
                        );
                      })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </OrderOverviewWidget>
      {destinationsWithSLN.length > 0 && (
        <OrderOverviewWidget width={2}>
          <table data-testid={`SLN-table`} className={`${classes.amTable} ${classes.slnTable}`}>
            <caption className={classes.slnSection}>{t('palettesQuantityPerSLNDepot')}</caption>
            <tbody className={classes.productInfoTable}>
              <tr className={classNames('tableRow')}>
                {Regions.SLN_REGIONS.map((region, i) => {
                  return (
                    <td key={i}>
                      <p>{region.toString()}</p>
                      {destinationsWithSLN.map((d, k) => {
                        return (
                          <p data-testid={`${k}-${i}-quantity`} key={k}>
                            {d.zipCode === `SLN${region}` ? d.quantity : ''}
                          </p>
                        );
                      })}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </OrderOverviewWidget>
      )}
    </>
  );
};
