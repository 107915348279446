import { DHLHeadline } from '@gkuis/gkp-base-widgets/dist/lib';
import iconPayer from 'assets/icon-payer.svg';
import classNames from 'classnames';
import { Button } from 'common/components/Button';
import { FormRow } from 'common/components/FormRow';
import orderClasses from 'common/styles/order.module.css';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { useOrderStep } from 'order/common/services/OrderStepProvider';
import { OrderStepHeadline } from 'order/productGroups/common/components/atom/OrderStepHeadline';
import { MarginalColumn } from 'order/productGroups/common/components/molecule/MarginalColumn';
import React, { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BackToSearchButton } from '../../../../common/components/BackToSearch/BackToSearchButton';
import { InternationalClusterOrderCreate, validateInternationalClusterOrderCreate } from '../../schema/internationalClusterSchema';
import { PostageTypeDropdown } from '../../../../common/components/PostageTypeDropdown';
import { TakeOriginatorAsPayer } from '../../../../common/components/TakeOriginatorAsPayer/TakeOriginatorAsPayer';
import { BillingOrPostcardNumberInput } from '../../../../orderChange/common/components/BillingOrPostcardNumberInput/BillingOrPostcardNumberInput';
import { PostageType } from 'order/common/dtos/PostageType';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { PostageInfoSection } from 'order/productGroups/dialogMarketing/components/PostageInfoSection';

export const InternationalClusterStep3: FC = () => {
  const { previousStep, nextStep } = useOrderStep();
  const { t } = useTranslation('orderCreate');
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step3Form' });
  const { control, getValues, trigger, setError } = useFormContext<InternationalClusterOrderCreate>();
  const { isDirty, orderMode, order } = useOrderContext();

  const [productGroup, postageType] = useWatch({
    control,
    name: ['productGroup', 'postageType']
  });

  return (
    <>
      <div className={orderClasses.formContent}>
        <div className={orderClasses.rowWithSidebar}>
          <main>
            <OrderStepHeadline icon={iconPayer} alt="Payer Icon">
              {oc('paymentInformation')}
            </OrderStepHeadline>
            <section>
              <DHLHeadline type="h6">{oc('payer')}</DHLHeadline>
              <TakeOriginatorAsPayer hasTooltip={true} />
              <BillingOrPostcardNumberInput name={'payer'} />
            </section>
            <section>
              <DHLHeadline type="h6">{oc('franking')}</DHLHeadline>
              <FormRow mode="two-col">
                <PostageTypeDropdown<InternationalClusterOrderCreate>
                  name="postageType"
                  required
                  label={oc('typeOfFrankingInt')}
                  data-testid={'frankingId'}
                  baseProduct={getValues('transportServices.0.baseProduct.productNumber')}
                  productGroup={productGroup}
                  disabled={orderMode === OrderMode.CHANGE && order?.postage?.type === PostageType.DV}
                />
              </FormRow>
              {postageType === PostageType.AFM && <PostageInfoSection type={postageType} />}
            </section>
          </main>
          <MarginalColumn />
        </div>
      </div>
      <footer className={classNames('d-flex', 'justify-content-between')}>
        <div>
          <Button style={{ marginRight: '10px' }} variant="default" onClick={() => previousStep()} type="button" label={t('buttons.back')} />
          <BackToSearchButton isDirty={isDirty} />
        </div>
        <Button
          dataTestId="btnSubmit"
          variant="primary"
          onClick={async () => {
            const isPaymentValid = await trigger(['payer']);
            const areDependentFieldsValid = validateInternationalClusterOrderCreate(getValues(), setError, 3);
            if (isPaymentValid && areDependentFieldsValid) {
              nextStep();
            }
          }}
          type="button"
          label={t('buttons.continue')}
        />
      </footer>
    </>
  );
};
