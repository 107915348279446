import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';
import axios from 'axios';
import { FormRow } from 'common/components/FormRow';
import { DeleteMethod, InputSelectSearchable } from 'common/components/InputSelectSearchable';
import { FetchMethod } from 'common/components/InputSelectSearchable/useFetcher';
import { getJournals, JournalItem } from 'common/services/JournalService';
import { isBlank, PathToArrayType } from 'common/utils/formHelpers';
import { JournalRep } from 'generated';
import { PressDistributionSupplementProduct } from 'order/common/dtos/PressDistributionSupplementProduct';
import { TextInput } from 'order/productGroups/common/components/atom/TextInput';
import { PressBaseProduct, PressOrderCreate } from 'order/productGroups/press/schema/pressSchema';
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classes from '../PressVariants/PressVariants.module.css';
import { JournalError } from '../JournalInput';
import { useRecommendations } from '../JournalInput/useRecommendations';
import { Assignments, Supplements } from './SupplementSchema';
import { AssignmentInput } from './AssignmentInput';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { ActiveMultiList } from '../../../../../common/components/ActiveMultiList';
import { useOrderTransferForm } from 'order/productGroups/common/utils/OrderTransferForm';

export type SupplementInputProps = {
  maxAmount?: number;
  name: PathToArrayType<PressOrderCreate, Supplements>;
  disabled?: boolean;
  assignmentBaseProduct: PressBaseProduct[];
  supplements?: Supplements[];
  supplementProductsNational: PressDistributionSupplementProduct[];
  onDelete?: (index: number, supplement: Supplements, isLast: boolean) => void;
};

export const SupplementInput = ({
  maxAmount = 99,
  name,
  disabled,
  assignmentBaseProduct,
  supplements,
  supplementProductsNational,
  ...props
}: SupplementInputProps): ReactElement => {
  const { control, trigger, setValue, resetField } = useOrderTransferForm<PressOrderCreate>();
  const [totalAssignmentCount, setTotalAssignmentCount] = useState(0);
  const { orderMode } = useOrderContext();
  const [isLoaded, setIsLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [supplementInc, setSupplementInc] = useState<number>(0);
  const { t } = useTranslation('orderCreate');
  const getErrorMessage = useCallback((key?: string) => (!key ? undefined : t(`error.${key}`)), [t]);

  const { fields: supplementFields, remove: supplementRemove, append: supplementAppend, update: supplementUpdate } = useFieldArray({ control, name });
  const values = useWatch({
    control,
    name: name
  });
  type WatchedProduct = { id: string } & Supplements;
  let watchedFields = useRef<WatchedProduct[]>([]).current;

  if (values) {
    watchedFields = supplementFields.map(({ id }, index) => ({
      id,
      ...(values as any)[index]
    }));
  }
  useEffect(() => {
    const supplementCount = watchedFields
      ?.map((s) => Number(s.description?.replace(`${t('supplement')} - `, '')))
      .filter((value) => {
        return value;
      });
    if (supplementCount.length) {
      const max = (count: number[]) => {
        let max = -Infinity;
        for (let i = 0; i < count.length; i++) {
          if (!+count[i]) {
            continue;
          }
          max = Math.max(max, +count[i]);
        }
        return Math.round(max);
      };
      setSupplementInc(max(supplementCount));
    }
    if (watchedFields.length && !loaded) {
      setLoaded(true);
    }
  }, [watchedFields, loaded]);

  const deleteSupplementActive = useMemo(() => watchedFields.length > 0, [watchedFields]);

  const { recommendations, load: getRecommendations, add: addRecommendation, delete: removeRecommendation } = useRecommendations();

  const zkzInfo = useRef<JournalRep[]>([]);

  useEffect(() => {
    watchedFields.map(async (s) => {
      if (orderMode === OrderMode.CHANGE || orderMode === OrderMode.COPY) {
        if (s.otherAttribute?.value) {
          const journals = await getJournals(s.otherAttribute.value);
          const journal: JournalRep = {
            registrationNumber: journals?.data?.[0]?.registrationNumber,
            title: journals?.data?.[0]?.title
          };
          if (zkzInfo.current.every((z) => z.registrationNumber !== s.otherAttribute.value)) {
            zkzInfo.current.push(journal);
            setIsLoaded(true);
          }
        }
      }
    });
  }, [watchedFields]);

  const isDuplicateDescription = useCallback(
    (value: string) => {
      return watchedFields?.map((p) => p.description).filter((desc) => desc === value).length > 1;
    },
    [watchedFields]
  );

  useEffect(() => {
    const count = supplements?.map((s) => s.assignments?.map((a) => a.supplementId)).flat();
    if (count?.length) {
      const max = Math.max(...(count as number[]));
      setLoading(false);
      setTotalAssignmentCount(max);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplements, loading]);

  const onSearch: FetchMethod<JournalItem[]> = async (searchText: string) => {
    const trimmedQuery = searchText.trim().replace(/\s+/g, ' ');
    if (trimmedQuery.length < 3) {
      throw new Error(JournalError.QueryTooShort);
    }
    try {
      const journals = await getJournals(trimmedQuery);
      if (journals.status === 200 && journals.data) {
        return journals.data;
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 413) {
        throw new Error(JournalError.TooMany);
      }
    }
    throw new Error(JournalError.NoResults);
  };

  const onDelete: DeleteMethod<JournalItem> = (_, item) => {
    removeRecommendation(item);
  };

  const handleAssignmentChange = (assignment: Assignments) => {
    setTotalAssignmentCount(assignment?.supplementId || totalAssignmentCount);
  };
  const validateAllDescriptions = useCallback(
    (fieldName: string) => {
      watchedFields?.forEach((_, index) => {
        trigger(`${fieldName}.${index}.description` as any);
      });
    },
    [trigger, watchedFields]
  );
  useEffect(() => {
    if (watchedFields.length < 1) {
      setSupplementInc(0);
    }
  }, [watchedFields.length]);

  return (
    <>
      {/* Beilage */}
      {supplementFields.map((supplementField, index) => (
        <div key={supplementField.id} className={`${classes.parentDiv} pt-4`}>
          <div key={supplementField.id} data-testid={`${name}.${index}`}>
            <div className={classes.heading}>
              <p className={classes.productName}>{`Beilage${deleteSupplementActive ? ` ${index + 1}` : ''}`}</p>
              {deleteSupplementActive && (
                <DHLButton
                  icon="delete"
                  iconPosition={'icon'}
                  size="xs"
                  onClick={
                    watchedFields.length === 1
                      ? () => {
                          resetField(name, { defaultValue: [{}] as any });
                          supplementRemove(index);
                        }
                      : () => deleteSupplementActive && supplementRemove(index)
                  }
                  name={`products-${index}-delete`}
                />
              )}
            </div>
            <FormRow mode="two-col">
              <Controller
                name={`${name}.${index}.productNumber`}
                rules={{
                  required: {
                    value: true,
                    message: 'beilageInput.beilage.empty'
                  },
                  minLength: {
                    value: 1,
                    message: 'beilageInput.beilage.empty'
                  }
                }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <div>
                    <ActiveMultiList
                      name={`${name}.${index}.productNumber`}
                      onChange={(item) => {
                        // field.onChange(item?.productNumber);
                        const supp = {
                          productNumber: item?.productNumber,
                          description: watchedFields[index].description,
                          type: item?.supplementType,
                          assignments: watchedFields[index].assignments,
                          otherAttribute: watchedFields[index].otherAttribute,
                          weight: watchedFields[index].weight,
                          weightUnit: watchedFields[index].weightUnit
                        };
                        supplementUpdate(index, supp as Supplements);
                        // setValue(`${name}.${index}.type`, item?.supplementType);
                        trigger(`${name}.${index}.productNumber`);
                        resetField(`supplements.${index}.otherAttribute.value`);
                        field.onBlur();
                      }}
                      onBlur={field.onBlur}
                      valueToString={({ descriptionShort }) => descriptionShort}
                      value={supplementProductsNational.find(({ productNumber }) => productNumber === field.value)}
                      items={supplementProductsNational}
                      placeholder="BEILAGENPRODUKT*"
                      data-testid={`${name}.${index}.productNumber`}
                      disabled={disabled}
                      error={getErrorMessage(fieldState.error?.message)}
                    />
                  </div>
                )}
              />

              {supplementProductsNational.find(({ productNumber: p }) => p === supplements?.[index]?.productNumber)?.supplementType === 'MV' && (
                <Controller
                  name={`${name}.${index}.otherAttribute`}
                  rules={{
                    required: {
                      value:
                        supplementProductsNational.find(({ productNumber: p }) => p === supplements?.[index]?.productNumber)?.supplementType === 'MV',
                      message: 'beilageInput.zkz.empty'
                    }
                  }}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <InputSelectSearchable<JournalItem>
                      fetcher={onSearch}
                      value={field.value}
                      headline={isLoaded ? undefined : t('journalInput.lastSearched')}
                      onSearched={(r) => setIsLoaded(r.state === 'finished')}
                      valueToString={(i) => (i.registrationNumber && i.title ? `${i.registrationNumber} - ${i.title}` : '')}
                      placeholder={t('journalInput.dropdownPlaceholder') + '*'}
                      journalInfo={
                        orderMode === OrderMode.CHANGE || orderMode === OrderMode.COPY
                          ? {
                              registrationNumber: supplementField?.otherAttribute?.value ?? '',
                              title: zkzInfo.current.find((z) => z.registrationNumber === supplementField?.otherAttribute?.value)?.title
                            }
                          : undefined
                      }
                      searchPlaceholder={t('journalInput.searchPlaceholder')}
                      name={`${name}.${index}.otherAttribute`}
                      data-testid={`${name}.${index}.otherAttribute.value`}
                      onDelete={onDelete}
                      error={getErrorMessage(fieldState.error?.message)}
                      onChange={(item) => {
                        addRecommendation(item!);
                        // field.onChange(item);
                        // setValue(`${name}.${index}.otherAttribute.value`, item?.registrationNumber);
                        const zkzItem = { ...item, value: item?.registrationNumber };
                        const supp = {
                          productNumber: watchedFields[index].productNumber,
                          description: watchedFields[index].description,
                          type: watchedFields[index].type,
                          assignments: watchedFields[index].assignments,
                          otherAttribute: zkzItem,
                          weight: watchedFields[index].weight,
                          weightUnit: watchedFields[index].weightUnit
                        };
                        supplementUpdate(index, supp as Supplements);
                        trigger(`${name}.${index}.otherAttribute`);
                      }}
                      cached={recommendations}
                    >
                      {(result, query) => {
                        if (result.state !== 'error') {
                          return undefined;
                        }
                        if (result.error.message === JournalError.TooMany || result.error.message === JournalError.QueryTooShort) {
                          const errorJSX = (
                            <>
                              {t('error.beilageInput.zkz.prefix')} <strong>{query} </strong>
                              {t('error.beilageInput.zkz.suffixForToManyResults')}
                            </>
                          );
                          return (
                            <div className={classes.errorMessage} data-testid="journal-error">
                              {errorJSX}
                            </div>
                          );
                        }
                        if (result.error.message === JournalError.NoResults) {
                          const errorJSX = (
                            <>
                              {t('error.beilageInput.zkz.prefix')} <strong>{query} </strong>
                              {t('error.beilageInput.zkz.suffixForNoResults')}
                            </>
                          );
                          return (
                            <div className={classes.errorMessage} data-testid="journal-error">
                              {errorJSX}
                            </div>
                          );
                        }
                      }}
                    </InputSelectSearchable>
                  )}
                />
              )}
            </FormRow>
            <FormRow mode="two-col">
              <TextInput
                name={`${name}.${index}.description`}
                defaultValue={`${t('supplement')} - ${supplementInc}`}
                rules={{
                  maxLength: {
                    value: 120,
                    message: 'beilageInput.description.tooLong'
                  },
                  validate: {
                    valid: (value) => {
                      if (isBlank(value)) {
                        return 'required';
                      } else if (isDuplicateDescription(value)) {
                        return 'baseProductInput.description.duplicateValue';
                      }
                      return true;
                    }
                  }
                }}
                label="Bezeichnung*"
                error={({ fieldState }) => getErrorMessage(fieldState.error?.message)}
                disabled={disabled}
                onBlur={() => validateAllDescriptions(name)}
              />
              <TextInput
                name={`${name}.${index}.weight`}
                rules={{
                  required: {
                    value: true,
                    message: 'beilageInput.weight.empty'
                  },
                  pattern: {
                    value: /^\d+$/,
                    message: 'beilageInput.weight.empty'
                  },
                  min: {
                    value: 1,
                    message: 'min'
                  },
                  max: {
                    value: 1e4 - 1,
                    message: 'beilageInput.weight.tooBig'
                  }
                }}
                type="number"
                label="Einzelgewicht in g*"
                onChange={() => trigger(`${name}.${index}.weight` as any)}
                error={({ fieldState }) => getErrorMessage(fieldState.error?.message)}
                disabled={disabled}
              />
            </FormRow>
          </div>
          <AssignmentInput
            nestIndex={index}
            totalAssignmentCount={totalAssignmentCount}
            onChange={handleAssignmentChange}
            assignmentBaseProduct={assignmentBaseProduct}
            name={`supplements.${index}.assignments`}
            disabled={disabled}
          />
        </div>
      ))}
      {(maxAmount < 1 || watchedFields.length < maxAmount) && (
        <div className={`${classes.addBtnCustomSpacing} ${classes.borderLine} ${classes.borderLineSpaceEven}`}>
          <DHLButton
            name="products-add"
            size="xs"
            type="ghost"
            icon="plus"
            iconPosition={'icon-first'}
            label={watchedFields.length > 0 ? t('pressDistribution.addMoreSupplementButton') : t('pressDistribution.supplementButton')}
            onClick={() => {
              setSupplementInc(supplementInc + 1);
              // supplementAppend({ description: `${t('supplement')} - ${supplementInc}` } as Supplements);
              supplementAppend({} as Supplements);
            }}
            className={classes.addButton}
            disabled={disabled}
          />
        </div>
      )}
    </>
  );
};
