/* tslint:disable */
/* eslint-disable */
/**
 * AM PORTAL
 * Documentation APIs v1.0
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setBearerAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from './common';
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI } from './base';
// @ts-ignore
import { Supplements } from 'order/productGroups/press/components/SupplementInput/SupplementSchema';
// @ts-ignore
import { MergeOrderRep } from 'order/orderView/components/OrderMerge/schema/OrderMergeSchema';
// @ts-ignore
import { ProductGroup } from 'order/common/dtos/ProductGroup';
// @ts-ignore
import { PRESS_VARIANTS } from 'order/productGroups/press/components/PressBaseProduct/PressBaseProducts';

/**
 *
 * @export
 * @interface AddressTO
 */
export interface AddressTO {
  /**
   *
   * @type {string}
   * @memberof AddressTO
   */
  postCode?: string;
  /**
   *
   * @type {string}
   * @memberof AddressTO
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof AddressTO
   */
  street?: string;
  /**
   *
   * @type {string}
   * @memberof AddressTO
   */
  streetNumber?: string;
  /**
   *
   * @type {string}
   * @memberof AddressTO
   */
  country?: string;
}
/**
 *
 * @export
 * @interface AllowedMethodsOfDenotingPrepaymentTO
 */
export interface AllowedMethodsOfDenotingPrepaymentTO {
  /**
   *
   * @type {string}
   * @memberof AllowedMethodsOfDenotingPrepaymentTO
   */
  methodOfDenotingPrepayment?: string;
  /**
   *
   * @type {string}
   * @memberof AllowedMethodsOfDenotingPrepaymentTO
   */
  methodOfDenotingPrepaymentCatalog?: string;
  /**
   *
   * @type {string}
   * @memberof AllowedMethodsOfDenotingPrepaymentTO
   */
  validFrom?: string;
  /**
   *
   * @type {string}
   * @memberof AllowedMethodsOfDenotingPrepaymentTO
   */
  validTo?: string;
}
/**
 *
 * @export
 * @interface AllowedProductClusterCombinationTO
 */
export interface AllowedProductClusterCombinationTO {
  /**
   *
   * @type {string}
   * @memberof AllowedProductClusterCombinationTO
   */
  productGroupNumber?: string;
  /**
   *
   * @type {string}
   * @memberof AllowedProductClusterCombinationTO
   */
  productNumber?: string;
  /**
   *
   * @type {string}
   * @memberof AllowedProductClusterCombinationTO
   */
  validFrom?: string;
  /**
   *
   * @type {string}
   * @memberof AllowedProductClusterCombinationTO
   */
  validTo?: string;
}
/**
 *
 * @export
 * @interface AmErrorCodeRep
 */
export interface AmErrorCodeRep {
  /**
   *
   * @type {string}
   * @memberof AmErrorCodeRep
   */
  errorCategory?: string;
  /**
   *
   * @type {string}
   * @memberof AmErrorCodeRep
   */
  errorDescription?: string;
  /**
   *
   * @type {string}
   * @memberof AmErrorCodeRep
   */
  code?: string;
}
/**
 *
 * @export
 * @interface CancelOrderResultRep
 */
export interface CancelOrderResultRep {
  /**
   *
   * @type {string}
   * @memberof CancelOrderResultRep
   */
  resultStatus?: string;
  /**
   *
   * @type {number}
   * @memberof CancelOrderResultRep
   */
  durationInMillis?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof CancelOrderResultRep
   */
  timings?: { [key: string]: number };
  /**
   *
   * @type {Array<AmErrorCodeRep>}
   * @memberof CancelOrderResultRep
   */
  amErrors?: Array<AmErrorCodeRep>;
  /**
   * Warning that if true means AM hasn\'t persisted shipment plan yet
   * @type {boolean}
   * @memberof CancelOrderResultRep
   */
  pendingIds?: boolean;
  /**
   *
   * @type {string}
   * @memberof CancelOrderResultRep
   */
  orderId?: string;
}
/**
 *
 * @export
 * @interface ContractInfoTO
 */
export interface ContractInfoTO {
  /**
   *
   * @type {Array<OrderCustomer>}
   * @memberof ContractInfoTO
   */
  customers?: Array<OrderCustomer>;
  /**
   *
   * @type {string}
   * @memberof ContractInfoTO
   */
  settlementNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ContractInfoTO
   */
  iban?: string;
  /**
   *
   * @type {string}
   * @memberof ContractInfoTO
   */
  bank?: string;
}
/**
 *
 * @export
 * @interface CreateCollectionOrder
 */
export interface CreateCollectionOrder {
  /**
   *
   * @type {string}
   * @memberof CreateCollectionOrder
   */
  productGroup?: string;
  /**
   * The id (aka EKP) sourcing system \"AM.PORTAL\". ->  OrderHeader.CustOrderID.CustID  format = Numeric, exactly 10 digits
   * @type {string}
   * @memberof CreateCollectionOrder
   */
  customerOrderId?: string;
  /**
   * The label of that order (aka Aktionsbezeichnung) in the sourcing system \"AM.PORTAL\". -> OrderHeader.OrderLabel.
   * @type {string}
   * @memberof CreateCollectionOrder
   */
  orderLabel?: string;
  /**
   * In this field the order type is specifiedsourcing system \"AM.PORTAL\". -> OrderHeader.OrderType   format =  Currently the following values are possible: \"EA\" - for normal posting orders. \"ZA\" - for additional orders e.g. for partial services or collection of consignments of several orders  \"TE\" - posting orders in a partial posting group
   * @type {string}
   * @memberof CreateCollectionOrder
   */
  orderType?: string;
  /**
   *
   * @type {OrderPackaging}
   * @memberof CreateCollectionOrder
   */
  packaging?: OrderPackaging;
  /**
   * This element specifies the shipment destinations of the shipments or the shipping schedule..(AKA Leitzone)
   * @type {Array<OrderDestination>}
   * @memberof CreateCollectionOrder
   */
  destinations?: Array<OrderDestination>;
  /**
   * The list of customers involved in this order.  For each job, the roles involved in it are specified exactly once in this section. The recurring roles are provided for each order can be found in CustomerRole Enum. -> Parties
   * @type {Array<OrderCustomer>}
   * @memberof CreateCollectionOrder
   */
  parties?: Array<OrderCustomer>;
  /**
   *  The element identifies the product variants to be considered for the service. Several can be specified if require -> ShipmentItem.Item.LineItem.Variant
   * @type {Array<string>}
   * @memberof CreateCollectionOrder
   */
  variants?: Array<string>;
  /**
   *
   * @type {OrderDetail}
   * @memberof CreateCollectionOrder
   */
  orderDetail?: OrderDetail;
  /**
   *
   * @type {PickupAddress}
   * @memberof CreateCollectionOrder
   */
  pickupAddress?: PickupAddress;
  /**
   * In this field a list dependents products is defined ex. Track & Match
   * @type {Array<DependentProduct>}
   * @memberof CreateCollectionOrder
   */
  dependentProducts?: Array<DependentProduct>;
  /**
   *
   * @type {OrderPostage}
   * @memberof CreateCollectionOrder
   */
  postage?: OrderPostage;
  /**
   *  This is the order id. -> OrderHeader.OrderID.
   * @type {string}
   * @memberof CreateCollectionOrder
   */
  orderId?: string;
  /**
   *  This is the order catecory.
   * @type {string}
   * @memberof CreateCollectionOrder
   */
  orderCategory?: string;
  /**
   * Preserved AM.exchange order content. Must be included as is from getOrder request.
   * @type {string}
   * @memberof CreateCollectionOrder
   */
  amexOrder?: string;
  /**
   *  This is the production state.
   * @type {string}
   * @memberof CreateCollectionOrder
   */
  productionState?: string;
  /**
   * The order references.
   * @type {Array<OrderReference>}
   * @memberof CreateCollectionOrder
   */
  orderReferences?: Array<OrderReference>;
}
/**
 *
 * @export
 * @interface CreateDialogPostOrder
 */
export interface CreateDialogPostOrder {
  /**
   *
   * @type {string}
   * @memberof CreateDialogPostOrder
   */
  productGroup?: string;
  /**
   * The id (aka EKP) sourcing system \"AM.PORTAL\". ->  OrderHeader.CustOrderID.CustID  format = Numeric, exactly 10 digits
   * @type {string}
   * @memberof CreateDialogPostOrder
   */
  customerOrderId?: string;
  /**
   * The label of that order (aka Aktionsbezeichnung) in the sourcing system \"AM.PORTAL\". -> OrderHeader.OrderLabel.
   * @type {string}
   * @memberof CreateDialogPostOrder
   */
  orderLabel?: string;
  /**
   * In this field the order type is specifiedsourcing system \"AM.PORTAL\". -> OrderHeader.OrderType   format =  Currently the following values are possible: \"EA\" - for normal posting orders. \"ZA\" - for additional orders e.g. for partial services or collection of consignments of several orders  \"TE\" - posting orders in a partial posting group
   * @type {string}
   * @memberof CreateDialogPostOrder
   */
  orderType?: string;
  /**
   *
   * @type {OrderPackaging}
   * @memberof CreateDialogPostOrder
   */
  packaging?: OrderPackaging;
  /**
   * This element specifies the shipment destinations of the shipments or the shipping schedule..(AKA Leitzone)
   * @type {Array<OrderDestination>}
   * @memberof CreateDialogPostOrder
   */
  destinations?: Array<OrderDestination>;
  /**
   * The list of customers involved in this order.  For each job, the roles involved in it are specified exactly once in this section. The recurring roles are provided for each order can be found in CustomerRole Enum. -> Parties
   * @type {Array<OrderCustomer>}
   * @memberof CreateDialogPostOrder
   */
  parties?: Array<OrderCustomer>;
  /**
   *  The element identifies the product variants to be considered for the service. Several can be specified if require -> ShipmentItem.Item.LineItem.Variant
   * @type {Array<string>}
   * @memberof CreateDialogPostOrder
   */
  variants?: Array<string>;
  /**
   *
   * @type {OrderDetail}
   * @memberof CreateDialogPostOrder
   */
  orderDetail?: OrderDetail;
  /**
   *
   * @type {PickupAddress}
   * @memberof CreateDialogPostOrder
   */
  pickupAddress?: PickupAddress;
  /**
   * In this field a list dependents products is defined ex. Track & Match
   * @type {Array<DependentProduct>}
   * @memberof CreateDialogPostOrder
   */
  dependentProducts?: Array<DependentProduct>;
  /**
   *
   * @type {OrderPostage}
   * @memberof CreateDialogPostOrder
   */
  postage?: OrderPostage;
  /**
   *  This is the order id. -> OrderHeader.OrderID.
   * @type {string}
   * @memberof CreateDialogPostOrder
   */
  orderId?: string;
  /**
   *  This is the order catecory.
   * @type {string}
   * @memberof CreateDialogPostOrder
   */
  orderCategory?: string;
  /**
   * Preserved AM.exchange order content. Must be included as is from getOrder request.
   * @type {string}
   * @memberof CreateDialogPostOrder
   */
  amexOrder?: string;
  /**
   *  This is the production state.
   * @type {string}
   * @memberof CreateDialogPostOrder
   */
  productionState?: string;
  /**
   *
   * @type {Product}
   * @memberof CreateDialogPostOrder
   */
  baseProduct: Product;
  /**
   *
   * @type {number}
   * @memberof CreateDialogPostOrder
   */
  totalQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof CreateDialogPostOrder
   */
  additionalQuantity?: number;
  /**
   *
   * @type {Array<ShipmentData>}
   * @memberof CreateDialogPostOrder
   */
  shipmentData?: Array<ShipmentData>;
}
/**
 *
 * @export
 * @interface CreateDialogPostOrder
 */
export interface CreatePostaktuellOrder {
  productGroup?: string;

  customerOrderId?: string;

  orderLabel?: string;

  orderType?: string;

  packaging?: OrderPackaging;

  destinations?: Array<OrderDestination>;

  parties?: Array<OrderCustomer>;

  variants?: Array<string>;

  orderDetail?: OrderDetail;

  pickupAddress?: PickupAddress;

  dependentProducts?: Array<DependentProduct>;

  postage?: OrderPostage;

  orderId?: string;

  orderCategory?: string;

  amexOrder?: string;

  productionState?: string;

  totalQuantity?: number;

  additionalQuantity?: number;

  shipmentData?: Array<ShipmentData>;

  letterBaseProducts?: Array<LetterBaseProduct>;
}
/**
 *
 * @export
 * @interface CreateLetterOrder
 */
export interface CreateLetterOrder {
  /**
   *
   * @type {string}
   * @memberof CreateLetterOrder
   */
  productGroup?: string;
  /**
   * The id (aka EKP) sourcing system \"AM.PORTAL\". ->  OrderHeader.CustOrderID.CustID  format = Numeric, exactly 10 digits
   * @type {string}
   * @memberof CreateLetterOrder
   */
  customerOrderId?: string;
  /**
   * The label of that order (aka Aktionsbezeichnung) in the sourcing system \"AM.PORTAL\". -> OrderHeader.OrderLabel.
   * @type {string}
   * @memberof CreateLetterOrder
   */
  orderLabel?: string;
  /**
   * In this field the order type is specifiedsourcing system \"AM.PORTAL\". -> OrderHeader.OrderType   format =  Currently the following values are possible: \"EA\" - for normal posting orders. \"ZA\" - for additional orders e.g. for partial services or collection of consignments of several orders  \"TE\" - posting orders in a partial posting group
   * @type {string}
   * @memberof CreateLetterOrder
   */
  orderType?: string;
  /**
   *
   * @type {OrderPackaging}
   * @memberof CreateLetterOrder
   */
  packaging?: OrderPackaging;
  /**
   * This element specifies the shipment destinations of the shipments or the shipping schedule..(AKA Leitzone)
   * @type {Array<OrderDestination>}
   * @memberof CreateLetterOrder
   */
  destinations?: Array<OrderDestination>;
  /**
   * The list of customers involved in this order.  For each job, the roles involved in it are specified exactly once in this section. The recurring roles are provided for each order can be found in CustomerRole Enum. -> Parties
   * @type {Array<OrderCustomer>}
   * @memberof CreateLetterOrder
   */
  parties?: Array<OrderCustomer>;
  /**
   *  The element identifies the product variants to be considered for the service. Several can be specified if require -> ShipmentItem.Item.LineItem.Variant
   * @type {Array<string>}
   * @memberof CreateLetterOrder
   */
  variants?: Array<string>;
  /**
   *
   * @type {OrderDetail}
   * @memberof CreateLetterOrder
   */
  orderDetail?: OrderDetail;
  /**
   *
   * @type {PickupAddress}
   * @memberof CreateLetterOrder
   */
  pickupAddress?: PickupAddress;
  /**
   * In this field a list dependents products is defined ex. Track & Match
   * @type {Array<DependentProduct>}
   * @memberof CreateLetterOrder
   */
  dependentProducts?: Array<DependentProduct>;
  /**
   *
   * @type {OrderPostage}
   * @memberof CreateLetterOrder
   */
  postage?: OrderPostage;
  /**
   *  This is the order id. -> OrderHeader.OrderID.
   * @type {string}
   * @memberof CreateLetterOrder
   */
  orderId?: string;
  /**
   *  This is the order catecory.
   * @type {string}
   * @memberof CreateLetterOrder
   */
  orderCategory?: string;
  /**
   * Preserved AM.exchange order content. Must be included as is from getOrder request.
   * @type {string}
   * @memberof CreateLetterOrder
   */
  amexOrder?: string;
  /**
   *  This is the production state.
   * @type {string}
   * @memberof CreateLetterOrder
   */
  productionState?: string;
  /**
   * Brief base products. At least one Brief base product is required.
   * @type {Array<LetterBaseProduct>}
   * @memberof CreateLetterOrder
   */
  letterBaseProducts: Array<LetterBaseProduct>;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CreateLetterOrder
   */
  otherAttributes?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface CreatePartialOrder
 */
export interface CreatePartialOrder {
  /**
   *
   * @type {string}
   * @memberof CreatePartialOrder
   */
  productGroup?: string;
  /**
   * The id (aka EKP) sourcing system \"AM.PORTAL\". ->  OrderHeader.CustOrderID.CustID  format = Numeric, exactly 10 digits
   * @type {string}
   * @memberof CreatePartialOrder
   */
  customerOrderId?: string;
  /**
   * The label of that order (aka Aktionsbezeichnung) in the sourcing system \"AM.PORTAL\". -> OrderHeader.OrderLabel.
   * @type {string}
   * @memberof CreatePartialOrder
   */
  orderLabel?: string;
  /**
   * In this field the order type is specifiedsourcing system \"AM.PORTAL\". -> OrderHeader.OrderType   format =  Currently the following values are possible: \"EA\" - for normal posting orders. \"ZA\" - for additional orders e.g. for partial services or collection of consignments of several orders  \"TE\" - posting orders in a partial posting group
   * @type {string}
   * @memberof CreatePartialOrder
   */
  orderType?: string;
  /**
   *
   * @type {OrderPackaging}
   * @memberof CreatePartialOrder
   */
  packaging?: OrderPackaging;
  /**
   * This element specifies the shipment destinations of the shipments or the shipping schedule..(AKA Leitzone)
   * @type {Array<OrderDestination>}
   * @memberof CreatePartialOrder
   */
  destinations?: Array<OrderDestination>;
  /**
   * The list of customers involved in this order.  For each job, the roles involved in it are specified exactly once in this section. The recurring roles are provided for each order can be found in CustomerRole Enum. -> Parties
   * @type {Array<OrderCustomer>}
   * @memberof CreatePartialOrder
   */
  parties?: Array<OrderCustomer>;
  /**
   *  The element identifies the product variants to be considered for the service. Several can be specified if require -> ShipmentItem.Item.LineItem.Variant
   * @type {Array<string>}
   * @memberof CreatePartialOrder
   */
  variants?: Array<string>;
  /**
   *
   * @type {OrderDetail}
   * @memberof CreatePartialOrder
   */
  orderDetail?: OrderDetail;
  /**
   *
   * @type {PickupAddress}
   * @memberof CreatePartialOrder
   */
  pickupAddress?: PickupAddress;
  /**
   * In this field a list dependents products is defined ex. Track & Match
   * @type {Array<DependentProduct>}
   * @memberof CreatePartialOrder
   */
  dependentProducts?: Array<DependentProduct>;
  /**
   *
   * @type {OrderPostage}
   * @memberof CreatePartialOrder
   */
  postage?: OrderPostage;
  /**
   *  This is the order id. -> OrderHeader.OrderID.
   * @type {string}
   * @memberof CreatePartialOrder
   */
  orderId?: string;
  /**
   *  This is the order catecory.
   * @type {string}
   * @memberof CreatePartialOrder
   */
  orderCategory?: string;
  /**
   * Preserved AM.exchange order content. Must be included as is from getOrder request.
   * @type {string}
   * @memberof CreatePartialOrder
   */
  amexOrder?: string;
  /**
   *  This is the production state.
   * @type {string}
   * @memberof CreatePartialOrder
   */
  productionState?: string;
  /**
   * Type definition of partial service
   * @type {string}
   * @memberof CreatePartialOrder
   */
  partialServiceType?: string;
  /**
   * The continual 4 digit submission id of a partial service order
   * @type {number}
   * @memberof CreatePartialOrder
   */
  submissionId?: number;
  /**
   * The order references.
   * @type {Array<OrderReference>}
   * @memberof CreatePartialOrder
   */
  orderReferences?: Array<OrderReference>;
  /**
   * The order references to be changed.
   * @type {Array<OrderReference>}
   * @memberof CreatePartialOrder
   */
  changedOrderReferences?: Array<OrderReference>;
  /**
   *
   * @type {Array<AmErrorCodeRep>}
   * @memberof CreatePartialOrder
   */
  amErrors?: Array<AmErrorCodeRep>;

  maxiBriefDiscountAvailable?: boolean;
}
/**
 *
 * @export
 * @interface CreatePressDistributionOrder
 */
export interface CreatePressDistributionOrder {
  /**
   *
   * @type {string}
   * @memberof CreatePressDistributionOrder
   */
  productGroup?: string;
  /**
   * The id (aka EKP) sourcing system \"AM.PORTAL\". ->  OrderHeader.CustOrderID.CustID  format = Numeric, exactly 10 digits
   * @type {string}
   * @memberof CreatePressDistributionOrder
   */
  customerOrderId?: string;
  /**
   * The label of that order (aka Aktionsbezeichnung) in the sourcing system \"AM.PORTAL\". -> OrderHeader.OrderLabel.
   * @type {string}
   * @memberof CreatePressDistributionOrder
   */
  orderLabel?: string;
  /**
   * In this field the order type is specifiedsourcing system \"AM.PORTAL\". -> OrderHeader.OrderType   format =  Currently the following values are possible: \"EA\" - for normal posting orders. \"ZA\" - for additional orders e.g. for partial services or collection of consignments of several orders  \"TE\" - posting orders in a partial posting group
   * @type {string}
   * @memberof CreatePressDistributionOrder
   */
  orderType?: string;
  /**
   *
   * @type {OrderPackaging}
   * @memberof CreatePressDistributionOrder
   */
  packaging?: OrderPackaging;
  /**
   *
   * @type {OrderPackaging}
   * @memberof CreatePressDistributionOrder
   */
  internationalPackaging?: OrderPackaging;
  /**
   * This element specifies the shipment destinations of the shipments or the shipping schedule..(AKA Leitzone)
   * @type {Array<OrderDestination>}
   * @memberof CreatePressDistributionOrder
   */
  destinations?: Array<OrderDestination>;
  /**
   * This element specifies the shipment destinations of the shipments or the shipping schedule..(AKA Leitzone)
   * @type {Array<OrderDestination>}
   * @memberof CreatePressDistributionOrder
   */
  internationalDestinations?: Array<OrderDestination>;
  /**
   * The list of customers involved in this order.  For each job, the roles involved in it are specified exactly once in this section. The recurring roles are provided for each order can be found in CustomerRole Enum. -> Parties
   * @type {Array<OrderCustomer>}
   * @memberof CreatePressDistributionOrder
   */
  parties?: Array<OrderCustomer>;
  /**
   *  The element identifies the product variants to be considered for the service. Several can be specified if require -> ShipmentItem.Item.LineItem.Variant
   * @type {Array<string>}
   * @memberof CreatePressDistributionOrder
   */
  variants?: Array<string>;
  /**
   *
   * @type {OrderDetail}
   * @memberof CreatePressDistributionOrder
   */
  orderDetail?: OrderDetail;
  /**
   *
   * @type {PickupAddress}
   * @memberof CreatePressDistributionOrder
   */
  pickupAddress?: PickupAddress;
  /**
   * In this field a list dependents products is defined ex. Track & Match
   * @type {Array<DependentProduct>}
   * @memberof CreatePressDistributionOrder
   */
  dependentProducts?: Array<DependentProduct>;
  /**
   *
   * @type {OrderPostage}
   * @memberof CreatePressDistributionOrder
   */
  postage?: OrderPostage;
  /**
   *  This is the order id. -> OrderHeader.OrderID.
   * @type {string}
   * @memberof CreatePressDistributionOrder
   */
  orderId?: string;
  /**
   *  This is the order catecory.
   * @type {string}
   * @memberof CreatePressDistributionOrder
   */
  orderCategory?: string;
  /**
   * Preserved AM.exchange order content. Must be included as is from getOrder request.
   * @type {string}
   * @memberof CreatePressDistributionOrder
   */
  amexOrder?: string;
  /**
   *  This is the production state.
   * @type {string}
   * @memberof CreatePressDistributionOrder
   */
  productionState?: string;
  /**
   *
   * @type {Journal}
   * @memberof CreatePressDistributionOrder
   */
  journal?: Journal;
  /**
   * In this field a list of base products is specified
   * @type {Array<Product>}
   * @memberof CreatePressDistributionOrder
   */
  nationalProducts: Array<Product>;
  /**
   * In this field a list of base international products is specified
   * @type {Array<Product>}
   * @memberof CreatePressDistributionOrder
   */
  internationalProducts?: Array<Product>;
  supplements?: Supplements[];
}

/**
 *
 * @export
 * @interface CreateInternationalOrder
 */
export interface CreateInternationalOrder {
  /**
   *
   * @type {string}
   * @memberof CreateInternationalOrder
   */
  productGroup?: string;
  /**
   * The id (aka EKP) sourcing system \"AM.PORTAL\". ->  OrderHeader.CustOrderID.CustID  format = Numeric, exactly 10 digits
   * @type {string}
   * @memberof CreateInternationalOrder
   */
  customerOrderId?: string;
  /**
   * The label of that order (aka Aktionsbezeichnung) in the sourcing system \"AM.PORTAL\". -> OrderHeader.OrderLabel.
   * @type {string}
   * @memberof CreateInternationalOrder
   */
  orderLabel?: string;
  /**
   * In this field the order type is specifiedsourcing system \"AM.PORTAL\". -> OrderHeader.OrderType   format =  Currently the following values are possible: \"EA\" - for normal posting orders. \"ZA\" - for additional orders e.g. for partial services or collection of consignments of several orders  \"TE\" - posting orders in a partial posting group
   * @type {string}
   * @memberof CreateInternationalOrder
   */
  orderType?: string;
  /**
   *
   * @type {OrderPackaging}
   * @memberof CreateInternationalOrder
   */
  packaging?: OrderPackaging;
  /**
   * This element specifies the shipment destinations of the shipments or the shipping schedule..(AKA Leitzone)
   * @type {Array<OrderDestination>}
   * @memberof CreateInternationalOrder
   */
  destinations?: Array<OrderDestination>;
  /**
   * The list of customers involved in this order.  For each job, the roles involved in it are specified exactly once in this section. The recurring roles are provided for each order can be found in CustomerRole Enum. -> Parties
   * @type {Array<OrderCustomer>}
   * @memberof CreateInternationalOrder
   */
  parties?: Array<OrderCustomer>;
  /**
   *  The element identifies the product variants to be considered for the service. Several can be specified if require -> ShipmentItem.Item.LineItem.Variant
   * @type {Array<string>}
   * @memberof CreateInternationalOrder
   */
  variants?: Array<string>;
  /**
   *
   * @type {OrderDetail}
   * @memberof CreateInternationalOrder
   */
  orderDetail?: OrderDetail;
  /**
   *
   * @type {PickupAddress}
   * @memberof CreateInternationalOrder
   */
  pickupAddress?: PickupAddress;
  /**
   * In this field a list dependents products is defined ex. Track & Match
   * @type {Array<DependentProduct>}
   * @memberof CreateInternationalOrder
   */
  dependentProducts?: Array<DependentProduct>;
  /**
   *
   * @type {OrderPostage}
   * @memberof CreateInternationalOrder
   */
  postage?: OrderPostage;
  /**
   *  This is the order id. -> OrderHeader.OrderID.
   * @type {string}
   * @memberof CreateInternationalOrder
   */
  orderId?: string;
  /**
   *  This is the order catecory.
   * @type {string}
   * @memberof CreateInternationalOrder
   */
  orderCategory?: string;
  /**
   * Preserved AM.exchange order content. Must be included as is from getOrder request.
   * @type {string}
   * @memberof CreateInternationalOrder
   */
  amexOrder?: string;
  /**
   *  This is the production state.
   * @type {string}
   * @memberof CreateInternationalOrder
   */
  productionState?: string;
  /**
   *
   * @type {LetterBaseProduct}
   * @memberof CreateInternationalOrder
   */
  letterBaseProducts: LetterBaseProduct[];
  /**
   *
   * @type {number}
   * @memberof CreateInternationalOrder
   */
  additionalQuantity?: number;
  /**
   *
   * @type {Array<ShipmentData>}
   * @memberof CreateInternationalOrder
   */
  shipmentData?: Array<ShipmentData>;
}

/**
 *
 * @export
 * @interface SplitOrderProps
 */
export interface SplitOrderProps {
  orderLastChangedOn: string;
  consignmentInformation: {
    separationType?: string;
    aggregationLevel?: string;
    destinations?: Array<string>;
    shipmentNumbers?: Array<string>;
  };
  postingDate: string;
  mailerUcp: string;
  collectionInformation?: {
    name: string;
    phone: string;
    fax: string;
    address: {
      street: string;
      streetNumber: string;
      city: string;
      postcode: string;
      countryCode: string;
    };
  };
  storageInformation?: {
    dateFrom: Date;
    dateTo: Date;
  };
  orderID: string;
  bulkMailAcceptanceOffice?: string;
}

/**
 *
 * @export
 * @interface MovePalletProps
 */
export interface MovePalletProps {
  separationType: string;
  aggregationLevel?: string;
  assignments: Array<PalletAssignments>;
}

export interface PalletAssignments {
  targetOrder: {
    orderNumber: string;
    lastChangedOn: string;
  };
  consignmentInformation: {
    destinations?: Array<PalletDestination>;
    shipmentNumbers?: Array<PalletDestination>;
  };
}

export interface PalletDestination {
  movedItem: string;
  sourceOrder: {
    orderNumber: string;
    lastChangedOn: string;
  };
}

/**
 *
 * @export
 * @interface CustomerInfoWithAddressTO
 */
export interface CustomerInfoWithAddressTO {
  /**
   *
   * @type {string}
   * @memberof CustomerInfoWithAddressTO
   */
  customerId?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerInfoWithAddressTO
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerInfoWithAddressTO
   */
  companyName?: string;
  /**
   *
   * @type {AddressTO}
   * @memberof CustomerInfoWithAddressTO
   */
  address?: AddressTO;
}
/**
 *
 * @export
 * @interface CustomerNameTO
 */
export interface CustomerNameTO {
  /**
   *
   * @type {string}
   * @memberof CustomerNameTO
   */
  customerId?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerNameTO
   */
  name?: string;
}
/**
 * In this field a list dependents products is defined ex. Track & Match
 * @export
 * @interface DependentProduct
 */
export interface DependentProduct {
  /**
   * In this field the base product is specified  -> ShipmentItem.Item.LineItem.ProductNumber
   * @type {string}
   * @memberof DependentProduct
   */
  productNumber?: string;
  /**
   * In the case of scheduled services (e.g. scheduled delivery, collection), the desired start of the service provision is transmitted here   ->  ShipmentItem.Item.LineItem.EarliestDateTime
   * @type {Date}
   * @memberof DependentProduct
   */
  earliestDateTime?: Date;
  /**
   * This can be used to create a reference to shipments of a shipment cluster. -> ShipmentItem.Item.RefShipment
   * @type {string}
   * @memberof DependentProduct
   */
  referenceShipmentId?: string;
  /**
   * The number of shipments in this shipment cluster. . -> ShipmentItem.Shipment.Qty
   * @type {number}
   * @memberof DependentProduct
   */
  shipmentQuantity?: number;
  /**
   * Item id in existing order. Take over from getOrder request.
   * @type {number}
   * @memberof DependentProduct
   */
  itemId?: number;
  /**
   * national in existing order. Take over from getOrder request.
   * @type {boolean}
   * @memberof DependentProduct
   */
  national?: boolean;
}
/**
 *
 * @export
 * @interface GetCollectionOrderRep
 */
export interface GetCollectionOrderRep {
  /**
   *
   * @type {string}
   * @memberof GetCollectionOrderRep
   */
  resultStatus?: string;
  /**
   *
   * @type {number}
   * @memberof GetCollectionOrderRep
   */
  durationInMillis?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof GetCollectionOrderRep
   */
  timings?: { [key: string]: number };
  /**
   *
   * @type {Array<AmErrorCodeRep>}
   * @memberof GetCollectionOrderRep
   */
  amErrors?: Array<AmErrorCodeRep>;
  /**
   * Warning that if true means AM hasn\'t persisted shipment plan yet
   * @type {boolean}
   * @memberof GetCollectionOrderRep
   */
  pendingIds?: boolean;
  /**
   * This is the order id. -> OrderHeader.OrderID.
   * @type {string}
   * @memberof GetCollectionOrderRep
   */
  orderId?: string;
  /**
   * Order version / order state. Necessary for price management -> OrderHeader.State.
   * @type {string}
   * @memberof GetCollectionOrderRep
   */
  orderVersion?: string;
  /**
   * Product group discriminator.
   * @type {string}
   * @memberof GetCollectionOrderRep
   */
  productGroup: string;
  /**
   * The id (aka EKP) sourcing system \"AM.PORTAL\". ->  OrderHeader.CustOrderID.CustID  format = Numeric, exactly 10 digits
   * @type {string}
   * @memberof GetCollectionOrderRep
   */
  customerOrderId?: string;
  /**
   * The label of that order (aka Aktionsbezeichnung) in the sourcing system \"AM.PORTAL\". -> OrderHeader.OrderLabel.
   * @type {string}
   * @memberof GetCollectionOrderRep
   */
  orderLabel?: string;
  /**
   * In this field the order type is specifiedsourcing system \"AM.PORTAL\". -> OrderHeader.OrderType   format =  Currently the following values are possible: \"EA\" - for normal posting orders. \"ZA\" - for additional orders e.g. for partial services or collection of consignments of several orders  \"TE\" - posting orders in a partial posting group
   * @type {string}
   * @memberof GetCollectionOrderRep
   */
  orderType?: string;
  /**
   * This field specifies if an order contains destinations
   * @type {boolean}
   * @memberof GetCollectionOrderRep
   */
  includesDestinations?: boolean;
  /**
   *
   * @type {OrderPackaging}
   * @memberof GetCollectionOrderRep
   */
  packaging: OrderPackaging;
  /**
   * This element specifies the shipment destinations of the shipments or the shipping schedule..(AKA Leitzone)
   * @type {Array<OrderDestination>}
   * @memberof GetCollectionOrderRep
   */
  destinations?: Array<OrderDestination>;
  /**
   * The list of customers involved in this order.  For each job, the roles involved in it are specified exactly once in this section. The recurring roles are provided for each order can be found in CustomerRole Enum. -> Parties
   * @type {Array<OrderCustomer>}
   * @memberof GetCollectionOrderRep
   */
  parties: Array<OrderCustomer>;
  /**
   *  The element identifies the product variants to be considered for the service. Several can be specified if require -> ShipmentItem.Item.LineItem.Variant
   * @type {Set<string>}
   * @memberof GetCollectionOrderRep
   */
  variants?: string[];
  /**
   *
   * @type {OrderDetail}
   * @memberof GetCollectionOrderRep
   */
  orderDetail?: OrderDetail;
  /**
   *
   * @type {PickupAddress}
   * @memberof GetCollectionOrderRep
   */
  pickupAddress?: PickupAddress;
  /**
   * In this field a list dependents products is defined ex. Track & Match
   * @type {Array<DependentProduct>}
   * @memberof GetCollectionOrderRep
   */
  dependentProducts?: Array<DependentProduct>;
  /**
   *
   * @type {OrderPostage}
   * @memberof GetCollectionOrderRep
   */
  postage?: OrderPostage;
  /**
   *
   * @type {OrderPriceRep}
   * @memberof GetCollectionOrderRep
   */
  orderPrice?: OrderPriceRep;
  /**
   * the category of an order
   * @type {string}
   * @memberof GetCollectionOrderRep
   */
  orderCategory?: string;
  /**
   * When creating and changing posting orders with the operations createOrder and changeOrder you can specify here the data status you want to achieve with this message.When creating and changing posting orders (EA) the values \"PM\", \"DM\" and \"AU\" are allowed. When creating and changing additional orders (ZA), this element must not be used.
   * @type {string}
   * @memberof GetCollectionOrderRep
   */
  state?: string;
  /**
   *  The production status of the order. This provides information about the current status of the order in DPAG\'s production process.
   * @type {string}
   * @memberof GetCollectionOrderRep
   */
  productionState?: string;
  /**
   * Preserved AM.exchange order content. Must be included as is in changeOrder request.
   * @type {string}
   * @memberof GetCollectionOrderRep
   */
  amexOrder?: string;
  /**
   * Other attributes as key value map.
   * @type {{ [key: string]: string; }}
   * @memberof GetCollectionOrderRep
   */
  otherAttributes?: { [key: string]: string };
  /**
   * A combination of OrderCategory and Product Group used in FE for whitelisting
   * @type {string}
   * @memberof GetCollectionOrderRep
   */
  orderCategoryProductKey?: string;
  /**
   * Order references. Only references to external orders (EA).
   * @type {Array<OrderReference>}
   * @memberof GetCollectionOrderRep
   */
  orderReferences?: Array<OrderReference>;
}
/**
 *
 * @export
 * @interface GetDialogPostOrderRep
 */
export interface GetDialogPostOrderRep {
  /**
   *
   * @type {string}
   * @memberof GetDialogPostOrderRep
   */
  resultStatus?: string;
  /**
   *
   * @type {number}
   * @memberof GetDialogPostOrderRep
   */
  durationInMillis?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof GetDialogPostOrderRep
   */
  timings?: { [key: string]: number };
  /**
   *
   * @type {Array<AmErrorCodeRep>}
   * @memberof GetDialogPostOrderRep
   */
  amErrors?: Array<AmErrorCodeRep>;
  /**
   * Warning that if true means AM hasn\'t persisted shipment plan yet
   * @type {boolean}
   * @memberof GetDialogPostOrderRep
   */
  pendingIds?: boolean;
  /**
   * This is the order id. -> OrderHeader.OrderID.
   * @type {string}
   * @memberof GetDialogPostOrderRep
   */
  orderId?: string;
  /**
   * Order version / order state. Necessary for price management -> OrderHeader.State.
   * @type {string}
   * @memberof GetDialogPostOrderRep
   */
  orderVersion?: string;
  /**
   * Product group discriminator.
   * @type {string}
   * @memberof GetDialogPostOrderRep
   */
  productGroup: string;
  /**
   * The id (aka EKP) sourcing system \"AM.PORTAL\". ->  OrderHeader.CustOrderID.CustID  format = Numeric, exactly 10 digits
   * @type {string}
   * @memberof GetDialogPostOrderRep
   */
  customerOrderId?: string;
  /**
   * The label of that order (aka Aktionsbezeichnung) in the sourcing system \"AM.PORTAL\". -> OrderHeader.OrderLabel.
   * @type {string}
   * @memberof GetDialogPostOrderRep
   */
  orderLabel?: string;
  /**
   * In this field the order type is specifiedsourcing system \"AM.PORTAL\". -> OrderHeader.OrderType   format =  Currently the following values are possible: \"EA\" - for normal posting orders. \"ZA\" - for additional orders e.g. for partial services or collection of consignments of several orders  \"TE\" - posting orders in a partial posting group
   * @type {string}
   * @memberof GetDialogPostOrderRep
   */
  orderType?: string;
  /**
   * This field specifies if an order contains destinations
   * @type {boolean}
   * @memberof GetDialogPostOrderRep
   */
  includesDestinations?: boolean;
  /**
   *
   * @type {OrderPackaging}
   * @memberof GetDialogPostOrderRep
   */
  packaging: OrderPackaging;
  /**
   * This element specifies the shipment destinations of the shipments or the shipping schedule..(AKA Leitzone)
   * @type {Array<OrderDestination>}
   * @memberof GetDialogPostOrderRep
   */
  destinations?: Array<OrderDestination>;
  /**
   * The list of customers involved in this order.  For each job, the roles involved in it are specified exactly once in this section. The recurring roles are provided for each order can be found in CustomerRole Enum. -> Parties
   * @type {Array<OrderCustomer>}
   * @memberof GetDialogPostOrderRep
   */
  parties: Array<OrderCustomer>;
  /**
   *  The element identifies the product variants to be considered for the service. Several can be specified if require -> ShipmentItem.Item.LineItem.Variant
   * @type {Set<string>}
   * @memberof GetDialogPostOrderRep
   */
  variants?: string[];
  /**
   *
   * @type {OrderDetail}
   * @memberof GetDialogPostOrderRep
   */
  orderDetail?: OrderDetail;
  /**
   *
   * @type {PickupAddress}
   * @memberof GetDialogPostOrderRep
   */
  pickupAddress?: PickupAddress;
  /**
   * In this field a list dependents products is defined ex. Track & Match
   * @type {Array<DependentProduct>}
   * @memberof GetDialogPostOrderRep
   */
  dependentProducts?: Array<DependentProduct>;
  /**
   *
   * @type {OrderPostage}
   * @memberof GetDialogPostOrderRep
   */
  postage?: OrderPostage;
  /**
   *
   * @type {OrderPriceRep}
   * @memberof GetDialogPostOrderRep
   */
  orderPrice?: OrderPriceRep;
  /**
   * the category of an order
   * @type {string}
   * @memberof GetDialogPostOrderRep
   */
  orderCategory?: string;
  /**
   * When creating and changing posting orders with the operations createOrder and changeOrder you can specify here the data status you want to achieve with this message.When creating and changing posting orders (EA) the values \"PM\", \"DM\" and \"AU\" are allowed. When creating and changing additional orders (ZA), this element must not be used.
   * @type {string}
   * @memberof GetDialogPostOrderRep
   */
  state?: string;
  /**
   *  The production status of the order. This provides information about the current status of the order in DPAG\'s production process.
   * @type {string}
   * @memberof GetDialogPostOrderRep
   */
  productionState?: string;
  /**
   * Preserved AM.exchange order content. Must be included as is in changeOrder request.
   * @type {string}
   * @memberof GetDialogPostOrderRep
   */
  amexOrder?: string;
  /**
   * Other attributes as key value map.
   * @type {{ [key: string]: string; }}
   * @memberof GetDialogPostOrderRep
   */
  otherAttributes?: { [key: string]: string };
  /**
   * A combination of OrderCategory and Product Group used in FE for whitelisting
   * @type {string}
   * @memberof GetDialogPostOrderRep
   */
  orderCategoryProductKey?: string;
  /**
   *
   * @type {Product}
   * @memberof GetDialogPostOrderRep
   */
  baseProduct: Product;
  /**
   * Quantity of shipments payed for reaching a discount.
   * @type {number}
   * @memberof GetDialogPostOrderRep
   */
  additionalQuantity?: number;
  /**
   * Products of DiP_VARIO or DiP_SAMMEL only.
   * @type {Array<ShipmentData>}
   * @memberof GetDialogPostOrderRep
   */
  shipmentData?: Array<ShipmentData>;
  /**
   * Quantity allocations for the base products
   * @type {Array<QtyAllocation>}
   * @memberof GetDialogPostOrderRep
   */
  qtyAllocation?: Array<QtyAllocation>;
  /**
   * Sum of total weights in the qtyAllocation list.
   * @type {number}
   * @memberof GetDialogPostOrderRep
   */
  totalNetWeight?: number;
}
/**
 *
 * @export
 * @interface GetDialogPostWurfSpecialOrderRep
 */
export interface GetDialogPostWurfSpecialOrderRep {
  /**
   *
   * @type {string}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  resultStatus?: string;
  /**
   *
   * @type {number}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  durationInMillis?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  timings?: { [key: string]: number };
  /**
   *
   * @type {Array<AmErrorCodeRep>}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  amErrors?: Array<AmErrorCodeRep>;
  /**
   * Warning that if true means AM hasn\'t persisted shipment plan yet
   * @type {boolean}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  pendingIds?: boolean;
  /**
   * This is the order id. -> OrderHeader.OrderID.
   * @type {string}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  orderId?: string;
  /**
   * Order version / order state. Necessary for price management -> OrderHeader.State.
   * @type {string}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  orderVersion?: string;
  /**
   * Product group discriminator.
   * @type {string}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  productGroup: string;
  /**
   * The id (aka EKP) sourcing system \"AM.PORTAL\". ->  OrderHeader.CustOrderID.CustID  format = Numeric, exactly 10 digits
   * @type {string}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  customerOrderId?: string;
  /**
   * The label of that order (aka Aktionsbezeichnung) in the sourcing system \"AM.PORTAL\". -> OrderHeader.OrderLabel.
   * @type {string}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  orderLabel?: string;
  /**
   * In this field the order type is specifiedsourcing system \"AM.PORTAL\". -> OrderHeader.OrderType   format =  Currently the following values are possible: \"EA\" - for normal posting orders. \"ZA\" - for additional orders e.g. for partial services or collection of consignments of several orders  \"TE\" - posting orders in a partial posting group
   * @type {string}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  orderType?: string;
  /**
   * This field specifies if an order contains destinations
   * @type {boolean}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  includesDestinations?: boolean;
  /**
   *
   * @type {OrderPackaging}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  packaging: OrderPackaging;
  /**
   * This element specifies the shipment destinations of the shipments or the shipping schedule..(AKA Leitzone)
   * @type {Array<OrderDestination>}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  destinations?: Array<OrderDestination>;
  /**
   * The list of customers involved in this order.  For each job, the roles involved in it are specified exactly once in this section. The recurring roles are provided for each order can be found in CustomerRole Enum. -> Parties
   * @type {Array<OrderCustomer>}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  parties: Array<OrderCustomer>;
  /**
   *  The element identifies the product variants to be considered for the service. Several can be specified if require -> ShipmentItem.Item.LineItem.Variant
   * @type {Set<string>}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  variants?: string[];
  /**
   *
   * @type {OrderDetail}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  orderDetail?: OrderDetail;
  /**
   *
   * @type {PickupAddress}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  pickupAddress?: PickupAddress;
  /**
   * In this field a list dependents products is defined ex. Track & Match
   * @type {Array<DependentProduct>}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  dependentProducts?: Array<DependentProduct>;
  /**
   *
   * @type {OrderPostage}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  postage?: OrderPostage;
  /**
   *
   * @type {OrderPriceRep}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  orderPrice?: OrderPriceRep;
  /**
   * the category of an order
   * @type {string}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  orderCategory?: string;
  /**
   * When creating and changing posting orders with the operations createOrder and changeOrder you can specify here the data status you want to achieve with this message.When creating and changing posting orders (EA) the values \"PM\", \"DM\" and \"AU\" are allowed. When creating and changing additional orders (ZA), this element must not be used.
   * @type {string}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  state?: string;
  /**
   *  The production status of the order. This provides information about the current status of the order in DPAG\'s production process.
   * @type {string}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  productionState?: string;
  /**
   * Preserved AM.exchange order content. Must be included as is in changeOrder request.
   * @type {string}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  amexOrder?: string;
  /**
   * Other attributes as key value map.
   * @type {{ [key: string]: string; }}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  otherAttributes?: { [key: string]: string };
  /**
   * A combination of OrderCategory and Product Group used in FE for whitelisting
   * @type {string}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  orderCategoryProductKey?: string;
  /**
   *
   * @type {Product}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  baseProduct: Product;
  /**
   * Quantity of shipments payed for reaching a discount.
   * @type {number}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  additionalQuantity?: number;
  /**
   * Products of DiP_VARIO or DiP_SAMMEL only.
   * @type {Array<ShipmentData>}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  shipmentData?: Array<ShipmentData>;
  /**
   * Quantity allocations for the base products
   * @type {Array<QtyAllocation>}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  qtyAllocation?: Array<QtyAllocation>;
  /**
   * Sum of total weights in the qtyAllocation list.
   * @type {number}
   * @memberof GetDialogPostWurfSpecialOrderRep
   */
  totalNetWeight?: number;
}
/**
 *
 * @export
 * @interface GetInternationalOrderRep
 */
export interface GetInternationalOrderRep {
  constraints?: Constraints;

  additionalQuantity?: number;
  /**
   *
   * @type {string}
   * @memberof GetInternationalOrderRep
   */
  resultStatus?: string;
  /**
   *
   * @type {number}
   * @memberof GetInternationalOrderRep
   */
  durationInMillis?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof GetInternationalOrderRep
   */
  timings?: { [key: string]: number };
  /**
   *
   * @type {Array<AmErrorCodeRep>}
   * @memberof GetInternationalOrderRep
   */
  amErrors?: Array<AmErrorCodeRep>;
  /**
   * Warning that if true means AM hasn\'t persisted shipment plan yet
   * @type {boolean}
   * @memberof GetInternationalOrderRep
   */
  pendingIds?: boolean;
  /**
   * This is the order id. -> OrderHeader.OrderID.
   * @type {string}
   * @memberof GetInternationalOrderRep
   */
  orderId?: string;
  /**
   * Order version / order state. Necessary for price management -> OrderHeader.State.
   * @type {string}
   * @memberof GetInternationalOrderRep
   */
  orderVersion?: string;
  /**
   * Product group discriminator.
   * @type {string}
   * @memberof GetInternationalOrderRep
   */
  productGroup: string;
  /**
   * The id (aka EKP) sourcing system \"AM.PORTAL\". ->  OrderHeader.CustOrderID.CustID  format = Numeric, exactly 10 digits
   * @type {string}
   * @memberof GetInternationalOrderRep
   */
  customerOrderId?: string;
  /**
   * The label of that order (aka Aktionsbezeichnung) in the sourcing system \"AM.PORTAL\". -> OrderHeader.OrderLabel.
   * @type {string}
   * @memberof GetInternationalOrderRep
   */
  orderLabel?: string;
  /**
   * In this field the order type is specifiedsourcing system \"AM.PORTAL\". -> OrderHeader.OrderType   format =  Currently the following values are possible: \"EA\" - for normal posting orders. \"ZA\" - for additional orders e.g. for partial services or collection of consignments of several orders  \"TE\" - posting orders in a partial posting group
   * @type {string}
   * @memberof GetInternationalOrderRep
   */
  orderType?: string;
  /**
   * This field specifies if an order contains destinations
   * @type {boolean}
   * @memberof GetInternationalOrderRep
   */
  includesDestinations?: boolean;
  /**
   *
   * @type {OrderPackaging}
   * @memberof GetInternationalOrderRep
   */
  packaging: OrderPackaging;
  /**
   * This element specifies the shipment destinations of the shipments or the shipping schedule..(AKA Leitzone)
   * @type {Array<OrderDestination>}
   * @memberof GetInternationalOrderRep
   */
  destinations?: Array<OrderDestination>;
  /**
   * The list of customers involved in this order.  For each job, the roles involved in it are specified exactly once in this section. The recurring roles are provided for each order can be found in CustomerRole Enum. -> Parties
   * @type {Array<OrderCustomer>}
   * @memberof GetInternationalOrderRep
   */
  parties: Array<OrderCustomer>;
  /**
   *  The element identifies the product variants to be considered for the service. Several can be specified if require -> ShipmentItem.Item.LineItem.Variant
   * @type {Set<string>}
   * @memberof GetInternationalOrderRep
   */
  variants?: string[];
  /**
   *
   * @type {OrderDetail}
   * @memberof GetInternationalOrderRep
   */
  orderDetail?: OrderDetail;
  /**
   *
   * @type {PickupAddress}
   * @memberof GetInternationalOrderRep
   */
  pickupAddress?: PickupAddress;
  /**
   * In this field a list dependents products is defined ex. Track & Match
   * @type {Array<DependentProduct>}
   * @memberof GetInternationalOrderRep
   */
  dependentProducts?: Array<DependentProduct>;
  /**
   *
   * @type {OrderPostage}
   * @memberof GetInternationalOrderRep
   */
  postage?: OrderPostage;
  /**
   *
   * @type {OrderPriceRep}
   * @memberof GetInternationalOrderRep
   */
  orderPrice?: OrderPriceRep;
  /**
   * the category of an order
   * @type {string}
   * @memberof GetInternationalOrderRep
   */
  orderCategory?: string;
  /**
   * When creating and changing posting orders with the operations createOrder and changeOrder you can specify here the data status you want to achieve with this message.When creating and changing posting orders (EA) the values \"PM\", \"DM\" and \"AU\" are allowed. When creating and changing additional orders (ZA), this element must not be used.
   * @type {string}
   * @memberof GetInternationalOrderRep
   */
  state?: string;
  /**
   *  The production status of the order. This provides information about the current status of the order in DPAG\'s production process.
   * @type {string}
   * @memberof GetInternationalOrderRep
   */
  productionState?: string;
  /**
   * Preserved AM.exchange order content. Must be included as is in changeOrder request.
   * @type {string}
   * @memberof GetInternationalOrderRep
   */
  amexOrder?: string;
  /**
   * Other attributes as key value map.
   * @type {{ [key: string]: string; }}
   * @memberof GetInternationalOrderRep
   */
  otherAttributes?: { [key: string]: string };
  /**
   * A combination of OrderCategory and Product Group used in FE for whitelisting
   * @type {string}
   * @memberof GetInternationalOrderRep
   */
  orderCategoryProductKey?: string;
  /**
   *
   * @type {Array<LetterQtyAllocation>}
   * @memberof GetInternationalOrderRep
   */
  qtyAllocation?: Array<LetterQtyAllocation>;
  /**
   *
   * @type {Array<LetterBaseProduct>}
   * @memberof GetInternationalOrderRep
   */
  letterBaseProducts?: Array<LetterBaseProduct>;
  /**
   *
   * @type {number}
   * @memberof GetInternationalOrderRep
   */
  totalNetWeight?: number;
  internationalBaseProduct?: string;
}
/**
 *
 * @export
 * @interface GetLetterOrderRep
 */
export interface GetLetterOrderRep {
  /**
   *
   * @type {string}
   * @memberof GetLetterOrderRep
   */
  resultStatus?: string;
  /**
   *
   * @type {number}
   * @memberof GetLetterOrderRep
   */
  durationInMillis?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof GetLetterOrderRep
   */
  timings?: { [key: string]: number };
  /**
   *
   * @type {Array<AmErrorCodeRep>}
   * @memberof GetLetterOrderRep
   */
  amErrors?: Array<AmErrorCodeRep>;
  /**
   * Warning that if true means AM hasn\'t persisted shipment plan yet
   * @type {boolean}
   * @memberof GetLetterOrderRep
   */
  pendingIds?: boolean;
  /**
   * This is the order id. -> OrderHeader.OrderID.
   * @type {string}
   * @memberof GetLetterOrderRep
   */
  orderId?: string;
  /**
   * Order version / order state. Necessary for price management -> OrderHeader.State.
   * @type {string}
   * @memberof GetLetterOrderRep
   */
  orderVersion?: string;
  /**
   * Product group discriminator.
   * @type {string}
   * @memberof GetLetterOrderRep
   */
  productGroup: string;
  /**
   * The id (aka EKP) sourcing system \"AM.PORTAL\". ->  OrderHeader.CustOrderID.CustID  format = Numeric, exactly 10 digits
   * @type {string}
   * @memberof GetLetterOrderRep
   */
  customerOrderId?: string;
  /**
   * The label of that order (aka Aktionsbezeichnung) in the sourcing system \"AM.PORTAL\". -> OrderHeader.OrderLabel.
   * @type {string}
   * @memberof GetLetterOrderRep
   */
  orderLabel?: string;
  /**
   * In this field the order type is specifiedsourcing system \"AM.PORTAL\". -> OrderHeader.OrderType   format =  Currently the following values are possible: \"EA\" - for normal posting orders. \"ZA\" - for additional orders e.g. for partial services or collection of consignments of several orders  \"TE\" - posting orders in a partial posting group
   * @type {string}
   * @memberof GetLetterOrderRep
   */
  orderType?: string;
  /**
   * This field specifies if an order contains destinations
   * @type {boolean}
   * @memberof GetLetterOrderRep
   */
  includesDestinations?: boolean;
  /**
   *
   * @type {OrderPackaging}
   * @memberof GetLetterOrderRep
   */
  packaging: OrderPackaging;
  /**
   * This element specifies the shipment destinations of the shipments or the shipping schedule..(AKA Leitzone)
   * @type {Array<OrderDestination>}
   * @memberof GetLetterOrderRep
   */
  destinations?: Array<OrderDestination>;
  /**
   * The list of customers involved in this order.  For each job, the roles involved in it are specified exactly once in this section. The recurring roles are provided for each order can be found in CustomerRole Enum. -> Parties
   * @type {Array<OrderCustomer>}
   * @memberof GetLetterOrderRep
   */
  parties: Array<OrderCustomer>;
  /**
   *  The element identifies the product variants to be considered for the service. Several can be specified if require -> ShipmentItem.Item.LineItem.Variant
   * @type {Set<string>}
   * @memberof GetLetterOrderRep
   */
  variants?: string[];
  /**
   *
   * @type {OrderDetail}
   * @memberof GetLetterOrderRep
   */
  orderDetail?: OrderDetail;
  /**
   *
   * @type {PickupAddress}
   * @memberof GetLetterOrderRep
   */
  pickupAddress?: PickupAddress;
  /**
   * In this field a list dependents products is defined ex. Track & Match
   * @type {Array<DependentProduct>}
   * @memberof GetLetterOrderRep
   */
  dependentProducts?: Array<DependentProduct>;
  /**
   *
   * @type {OrderPostage}
   * @memberof GetLetterOrderRep
   */
  postage?: OrderPostage;
  /**
   *
   * @type {OrderPriceRep}
   * @memberof GetLetterOrderRep
   */
  orderPrice?: OrderPriceRep;
  /**
   * the category of an order
   * @type {string}
   * @memberof GetLetterOrderRep
   */
  orderCategory?: string;
  /**
   * When creating and changing posting orders with the operations createOrder and changeOrder you can specify here the data status you want to achieve with this message.When creating and changing posting orders (EA) the values \"PM\", \"DM\" and \"AU\" are allowed. When creating and changing additional orders (ZA), this element must not be used.
   * @type {string}
   * @memberof GetLetterOrderRep
   */
  state?: string;
  /**
   *  The production status of the order. This provides information about the current status of the order in DPAG\'s production process.
   * @type {string}
   * @memberof GetLetterOrderRep
   */
  productionState?: string;
  /**
   * Preserved AM.exchange order content. Must be included as is in changeOrder request.
   * @type {string}
   * @memberof GetLetterOrderRep
   */
  amexOrder?: string;
  /**
   * Other attributes as key value map.
   * @type {{ [key: string]: string; }}
   * @memberof GetLetterOrderRep
   */
  otherAttributes?: { [key: string]: string };
  /**
   * A combination of OrderCategory and Product Group used in FE for whitelisting
   * @type {string}
   * @memberof GetLetterOrderRep
   */
  orderCategoryProductKey?: string;
  /**
   *
   * @type {Array<LetterQtyAllocation>}
   * @memberof GetLetterOrderRep
   */
  qtyAllocation?: Array<LetterQtyAllocation>;
  /**
   *
   * @type {Array<LetterBaseProduct>}
   * @memberof GetLetterOrderRep
   */
  letterBaseProducts?: Array<LetterBaseProduct>;
  /**
   *
   * @type {number}
   * @memberof GetLetterOrderRep
   */
  totalNetWeight?: number;
}
/**
 *
 * @export
 * @interface GetOrderPartialContentRep
 */
export interface GetOrderPartialContentRep {
  /**
   *
   * @type {string}
   * @memberof GetOrderPartialContentRep
   */
  resultStatus?: string;
  /**
   *
   * @type {number}
   * @memberof GetOrderPartialContentRep
   */
  durationInMillis?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof GetOrderPartialContentRep
   */
  timings?: { [key: string]: number };
  /**
   *
   * @type {Array<AmErrorCodeRep>}
   * @memberof GetOrderPartialContentRep
   */
  amErrors?: Array<AmErrorCodeRep>;
  /**
   * Warning that if true means AM hasn\'t persisted shipment plan yet
   * @type {boolean}
   * @memberof GetOrderPartialContentRep
   */
  pendingIds?: boolean;
  /**
   * This is the order id. -> OrderHeader.OrderID.
   * @type {string}
   * @memberof GetOrderPartialContentRep
   */
  orderId?: string;
}

/**
 *
 * @export
 * @interface GetPostaktuellOrderRep
 */
export interface GetPostaktuellOrderRep {
  /**
   *
   * @type {string}
   * @memberof GetPostaktuellOrderRep
   */
  resultStatus?: string;
  /**
   *
   * @type {number}
   * @memberof GetPostaktuellOrderRep
   */
  durationInMillis?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof GetPostaktuellOrderRep
   */
  timings?: { [key: string]: number };
  /**
   *
   * @type {Array<AmErrorCodeRep>}
   * @memberof GetPostaktuellOrderRep
   */
  amErrors?: Array<AmErrorCodeRep>;
  /**
   * Warning that if true means AM hasn\'t persisted shipment plan yet
   * @type {boolean}
   * @memberof GetPostaktuellOrderRep
   */
  pendingIds?: boolean;
  /**
   * This is the order id. -> OrderHeader.OrderID.
   * @type {string}
   * @memberof GetPostaktuellOrderRep
   */
  orderId?: string;
  /**
   * Order version / order state. Necessary for price management -> OrderHeader.State.
   * @type {string}
   * @memberof GetPostaktuellOrderRep
   */
  orderVersion?: string;
  /**
   * Product group discriminator.
   * @type {string}
   * @memberof GetPostaktuellOrderRep
   */
  productGroup: string;
  /**
   * The id (aka EKP) sourcing system \"AM.PORTAL\". ->  OrderHeader.CustOrderID.CustID  format = Numeric, exactly 10 digits
   * @type {string}
   * @memberof GetPostaktuellOrderRep
   */
  customerOrderId?: string;
  /**
   * The label of that order (aka Aktionsbezeichnung) in the sourcing system \"AM.PORTAL\". -> OrderHeader.OrderLabel.
   * @type {string}
   * @memberof GetPostaktuellOrderRep
   */
  orderLabel?: string;
  /**
   * In this field the order type is specifiedsourcing system \"AM.PORTAL\". -> OrderHeader.OrderType   format =  Currently the following values are possible: \"EA\" - for normal posting orders. \"ZA\" - for additional orders e.g. for partial services or collection of consignments of several orders  \"TE\" - posting orders in a partial posting group
   * @type {string}
   * @memberof GetPostaktuellOrderRep
   */
  orderType?: string;
  /**
   * This field specifies if an order contains destinations
   * @type {boolean}
   * @memberof GetPostaktuellOrderRep
   */
  includesDestinations?: boolean;
  /**
   *
   * @type {OrderPackaging}
   * @memberof GetPostaktuellOrderRep
   */
  packaging: OrderPackaging;
  /**
   * This element specifies the shipment destinations of the shipments or the shipping schedule..(AKA Leitzone)
   * @type {Array<OrderDestination>}
   * @memberof GetPostaktuellOrderRep
   */
  destinations?: Array<OrderDestination>;
  /**
   * The list of customers involved in this order.  For each job, the roles involved in it are specified exactly once in this section. The recurring roles are provided for each order can be found in CustomerRole Enum. -> Parties
   * @type {Array<OrderCustomer>}
   * @memberof GetPostaktuellOrderRep
   */
  parties: Array<OrderCustomer>;
  /**
   *  The element identifies the product variants to be considered for the service. Several can be specified if require -> ShipmentItem.Item.LineItem.Variant
   * @type {Set<string>}
   * @memberof GetPostaktuellOrderRep
   */
  variants?: string[];
  /**
   *
   * @type {OrderDetail}
   * @memberof GetPostaktuellOrderRep
   */
  orderDetail?: OrderDetail;
  /**
   *
   * @type {PickupAddress}
   * @memberof GetPostaktuellOrderRep
   */
  pickupAddress?: PickupAddress;
  /**
   * In this field a list dependents products is defined ex. Track & Match
   * @type {Array<DependentProduct>}
   * @memberof GetPostaktuellOrderRep
   */
  dependentProducts?: Array<DependentProduct>;
  /**
   *
   * @type {OrderPostage}
   * @memberof GetPostaktuellOrderRep
   */
  postage?: OrderPostage;
  /**
   *
   * @type {OrderPriceRep}
   * @memberof GetPostaktuellOrderRep
   */
  orderPrice?: OrderPriceRep;
  /**
   * the category of an order
   * @type {string}
   * @memberof GetPostaktuellOrderRep
   */
  orderCategory?: string;
  /**
   * When creating and changing posting orders with the operations createOrder and changeOrder you can specify here the data status you want to achieve with this message.When creating and changing posting orders (EA) the values \"PM\", \"DM\" and \"AU\" are allowed. When creating and changing additional orders (ZA), this element must not be used.
   * @type {string}
   * @memberof GetPostaktuellOrderRep
   */
  state?: string;
  /**
   *  The production status of the order. This provides information about the current status of the order in DPAG\'s production process.
   * @type {string}
   * @memberof GetPostaktuellOrderRep
   */
  productionState?: string;
  /**
   * Preserved AM.exchange order content. Must be included as is in changeOrder request.
   * @type {string}
   * @memberof GetPostaktuellOrderRep
   */
  amexOrder?: string;
  /**
   * Other attributes as key value map.
   * @type {{ [key: string]: string; }}
   * @memberof GetPostaktuellOrderRep
   */
  otherAttributes?: { [key: string]: string };
  /**
   * A combination of OrderCategory and Product Group used in FE for whitelisting
   * @type {string}
   * @memberof GetPostaktuellOrderRep
   */
  orderCategoryProductKey?: string;
  /**
   *
   * @type {Array<LetterBaseProduct>}
   * @memberof GetLetterOrderRep
   */
  letterBaseProducts: Array<LetterBaseProduct>;
  /**
   * Quantity of shipments payed for reaching a discount.
   * @type {number}
   * @memberof GetPostaktuellOrderRep
   */
  additionalQuantity?: number;
  /**
   * Products of DiP_VARIO or DiP_SAMMEL only.
   * @type {Array<ShipmentData>}
   * @memberof GetPostaktuellOrderRep
   */
  shipmentData?: Array<ShipmentData>;
  /**
   * Quantity allocations for the base products
   * @type {Array<QtyAllocation>}
   * @memberof GetPostaktuellOrderRep
   */
  qtyAllocation?: Array<QtyAllocation>;
  /**
   * Sum of total weights in the qtyAllocation list.
   * @type {number}
   * @memberof GetPostaktuellOrderRep
   */
  totalNetWeight?: number;
}

/**
 * @type GetOrderRep
 * @export
 */
export type GetOrderRep =
  | ({ productGroup: 'BRIEF' } & GetLetterOrderRep)
  | ({ productGroup: 'COLLECTION' } & GetCollectionOrderRep)
  | ({ productGroup: 'DIALOGPOST' } & GetDialogPostOrderRep)
  | ({ productGroup: 'DIALOGPOSTWURFSPEZIAL' } & GetDialogPostWurfSpecialOrderRep)
  | ({ productGroup: 'INFOPOST_DHL' } & GetDialogPostOrderRep)
  | ({ productGroup: 'PARTIAL' } & GetPartialOrderRep)
  | ({ productGroup: 'PRESS_DISTRIBUTION' } & GetPressDistributionOrderRep)
  | ({ productGroup: 'POSTAKTUELL' } & GetPostaktuellOrderRep)
  | ({ productGroup: ProductGroup.DIALOGPOST_INTERNATIONAL } & GetInternationalOrderRep)
  | ({ productGroup: ProductGroup.BRIEF_INTERNATIONAL } & GetInternationalOrderRep)
  | ({ productGroup: ProductGroup.PRESS_INTERNATIONAL } & GetInternationalOrderRep)
  | ({ productGroup: ProductGroup.BRIEF_DMC_INTERNATIONAL } & GetInternationalOrderRep);

/**
 *
 * @export
 * @interface GetPartialOrderRep
 */
export interface GetPartialOrderRep {
  /**
   *
   * @type {string}
   * @memberof GetPartialOrderRep
   */
  resultStatus?: string;
  /**
   *
   * @type {number}
   * @memberof GetPartialOrderRep
   */
  durationInMillis?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof GetPartialOrderRep
   */
  timings?: { [key: string]: number };
  /**
   *
   * @type {Array<AmErrorCodeRep>}
   * @memberof GetPartialOrderRep
   */
  amErrors?: Array<AmErrorCodeRep>;
  /**
   * Warning that if true means AM hasn\'t persisted shipment plan yet
   * @type {boolean}
   * @memberof GetPartialOrderRep
   */
  pendingIds?: boolean;
  /**
   * This is the order id. -> OrderHeader.OrderID.
   * @type {string}
   * @memberof GetPartialOrderRep
   */
  orderId?: string;
  /**
   * Order version / order state. Necessary for price management -> OrderHeader.State.
   * @type {string}
   * @memberof GetPartialOrderRep
   */
  orderVersion?: string;
  /**
   * Product group discriminator.
   * @type {string}
   * @memberof GetPartialOrderRep
   */
  productGroup: string;
  /**
   * The id (aka EKP) sourcing system \"AM.PORTAL\". ->  OrderHeader.CustOrderID.CustID  format = Numeric, exactly 10 digits
   * @type {string}
   * @memberof GetPartialOrderRep
   */
  customerOrderId?: string;
  /**
   * The label of that order (aka Aktionsbezeichnung) in the sourcing system \"AM.PORTAL\". -> OrderHeader.OrderLabel.
   * @type {string}
   * @memberof GetPartialOrderRep
   */
  orderLabel?: string;
  /**
   * In this field the order type is specifiedsourcing system \"AM.PORTAL\". -> OrderHeader.OrderType   format =  Currently the following values are possible: \"EA\" - for normal posting orders. \"ZA\" - for additional orders e.g. for partial services or collection of consignments of several orders  \"TE\" - posting orders in a partial posting group
   * @type {string}
   * @memberof GetPartialOrderRep
   */
  orderType?: string;
  /**
   * This field specifies if an order contains destinations
   * @type {boolean}
   * @memberof GetPartialOrderRep
   */
  includesDestinations?: boolean;
  /**
   *
   * @type {OrderPackaging}
   * @memberof GetPartialOrderRep
   */
  packaging: OrderPackaging;
  /**
   * This element specifies the shipment destinations of the shipments or the shipping schedule..(AKA Leitzone)
   * @type {Array<OrderDestination>}
   * @memberof GetPartialOrderRep
   */
  destinations?: Array<OrderDestination>;
  /**
   * The list of customers involved in this order.  For each job, the roles involved in it are specified exactly once in this section. The recurring roles are provided for each order can be found in CustomerRole Enum. -> Parties
   * @type {Array<OrderCustomer>}
   * @memberof GetPartialOrderRep
   */
  parties: Array<OrderCustomer>;
  /**
   *  The element identifies the product variants to be considered for the service. Several can be specified if require -> ShipmentItem.Item.LineItem.Variant
   * @type {Set<string>}
   * @memberof GetPartialOrderRep
   */
  variants?: string[];
  /**
   *
   * @type {OrderDetail}
   * @memberof GetPartialOrderRep
   */
  orderDetail?: OrderDetail;
  /**
   *
   * @type {PickupAddress}
   * @memberof GetPartialOrderRep
   */
  pickupAddress?: PickupAddress;
  /**
   * In this field a list dependents products is defined ex. Track & Match
   * @type {Array<DependentProduct>}
   * @memberof GetPartialOrderRep
   */
  dependentProducts?: Array<DependentProduct>;
  /**
   *
   * @type {OrderPostage}
   * @memberof GetPartialOrderRep
   */
  postage?: OrderPostage;
  /**
   *
   * @type {OrderPriceRep}
   * @memberof GetPartialOrderRep
   */
  orderPrice?: OrderPriceRep;
  /**
   * the category of an order
   * @type {string}
   * @memberof GetPartialOrderRep
   */
  orderCategory?: string;
  /**
   * When creating and changing posting orders with the operations createOrder and changeOrder you can specify here the data status you want to achieve with this message.When creating and changing posting orders (EA) the values \"PM\", \"DM\" and \"AU\" are allowed. When creating and changing additional orders (ZA), this element must not be used.
   * @type {string}
   * @memberof GetPartialOrderRep
   */
  state?: string;
  /**
   *  The production status of the order. This provides information about the current status of the order in DPAG\'s production process.
   * @type {string}
   * @memberof GetPartialOrderRep
   */
  productionState?: string;
  /**
   * Preserved AM.exchange order content. Must be included as is in changeOrder request.
   * @type {string}
   * @memberof GetPartialOrderRep
   */
  amexOrder?: string;
  /**
   * Other attributes as key value map.
   * @type {{ [key: string]: string; }}
   * @memberof GetPartialOrderRep
   */
  otherAttributes?: { [key: string]: string };
  /**
   * A combination of OrderCategory and Product Group used in FE for whitelisting
   * @type {string}
   * @memberof GetPartialOrderRep
   */
  orderCategoryProductKey?: string;
  /**
   *
   * @type {number}
   * @memberof GetPartialOrderRep
   */
  submissionId?: number;
  /**
   *
   * @type {Array<OrderReference>}
   * @memberof GetPartialOrderRep
   */
  orderReferences?: Array<OrderReference>;
  /**
   *
   * @type {string}
   * @memberof GetPartialOrderRep
   */
  partialServiceType?: string;
  lastChangedOn?: string;
}
/**
 *
 * @export
 * @interface GetPressDistributionOrderRep
 */
export interface GetPressDistributionOrderRep {
  /**
   *
   * @type {string}
   * @memberof GetPressDistributionOrderRep
   */
  resultStatus?: string;
  /**
   *
   * @type {number}
   * @memberof GetPressDistributionOrderRep
   */
  durationInMillis?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof GetPressDistributionOrderRep
   */
  timings?: { [key: string]: number };
  /**
   *
   * @type {Array<AmErrorCodeRep>}
   * @memberof GetPressDistributionOrderRep
   */
  amErrors?: Array<AmErrorCodeRep>;
  /**
   * Warning that if true means AM hasn\'t persisted shipment plan yet
   * @type {boolean}
   * @memberof GetPressDistributionOrderRep
   */
  pendingIds?: boolean;
  /**
   * This is the order id. -> OrderHeader.OrderID.
   * @type {string}
   * @memberof GetPressDistributionOrderRep
   */
  orderId?: string;
  /**
   * Order version / order state. Necessary for price management -> OrderHeader.State.
   * @type {string}
   * @memberof GetPressDistributionOrderRep
   */
  orderVersion?: string;
  /**
   * Product group discriminator.
   * @type {string}
   * @memberof GetPressDistributionOrderRep
   */
  productGroup: string;
  /**
   * The id (aka EKP) sourcing system \"AM.PORTAL\". ->  OrderHeader.CustOrderID.CustID  format = Numeric, exactly 10 digits
   * @type {string}
   * @memberof GetPressDistributionOrderRep
   */
  customerOrderId?: string;
  /**
   * The label of that order (aka Aktionsbezeichnung) in the sourcing system \"AM.PORTAL\". -> OrderHeader.OrderLabel.
   * @type {string}
   * @memberof GetPressDistributionOrderRep
   */
  orderLabel?: string;
  /**
   * In this field the order type is specifiedsourcing system \"AM.PORTAL\". -> OrderHeader.OrderType   format =  Currently the following values are possible: \"EA\" - for normal posting orders. \"ZA\" - for additional orders e.g. for partial services or collection of consignments of several orders  \"TE\" - posting orders in a partial posting group
   * @type {string}
   * @memberof GetPressDistributionOrderRep
   */
  orderType?: string;
  /**
   * This field specifies if an order contains destinations
   * @type {boolean}
   * @memberof GetPressDistributionOrderRep
   */
  includesDestinations?: boolean;
  /**
   *
   * @type {Constraints}
   * @memberof GetPressDistributionOrderRep
   */
  constraints?: Constraints;
  /**
   *
   * @type {OrderPackaging}
   * @memberof GetPressDistributionOrderRep
   */
  packaging: OrderPackaging;
  /**
   *
   * @type {OrderPackaging}
   * @memberof GetPressDistributionOrderRep
   */
  internationalPackaging?: OrderPackaging;
  /**
   * This element specifies the shipment destinations of the shipments or the shipping schedule..(AKA Leitzone)
   * @type {Array<OrderDestination>}
   * @memberof GetPressDistributionOrderRep
   */
  destinations?: Array<OrderDestination>;
  /**
   * This element specifies the shipment destinations of the shipments or the shipping schedule..(AKA Leitzone)
   * @type {Array<OrderDestination>}
   * @memberof GetPressDistributionOrderRep
   */
  internationalDestinations?: Array<OrderDestination>;
  /**
   * The list of customers involved in this order.  For each job, the roles involved in it are specified exactly once in this section. The recurring roles are provided for each order can be found in CustomerRole Enum. -> Parties
   * @type {Array<OrderCustomer>}
   * @memberof GetPressDistributionOrderRep
   */
  parties: Array<OrderCustomer>;
  /**
   *  The element identifies the product variants to be considered for the service. Several can be specified if require -> ShipmentItem.Item.LineItem.Variant
   * @type {Set<string>}
   * @memberof GetPressDistributionOrderRep
   */
  variants?: string[];
  /**
   *
   * @type {OrderDetail}
   * @memberof GetPressDistributionOrderRep
   */
  orderDetail?: OrderDetail;
  /**
   *
   * @type {PickupAddress}
   * @memberof GetPressDistributionOrderRep
   */
  pickupAddress?: PickupAddress;
  /**
   * In this field a list dependents products is defined ex. Track & Match
   * @type {Array<DependentProduct>}
   * @memberof GetPressDistributionOrderRep
   */
  dependentProducts?: Array<DependentProduct>;
  /**
   *
   * @type {OrderPostage}
   * @memberof GetPressDistributionOrderRep
   */
  postage?: OrderPostage;
  /**
   *
   * @type {OrderPriceRep}
   * @memberof GetPressDistributionOrderRep
   */
  orderPrice?: OrderPriceRep;
  /**
   * the category of an order
   * @type {string}
   * @memberof GetPressDistributionOrderRep
   */
  orderCategory?: string;
  /**
   * When creating and changing posting orders with the operations createOrder and changeOrder you can specify here the data status you want to achieve with this message.When creating and changing posting orders (EA) the values \"PM\", \"DM\" and \"AU\" are allowed. When creating and changing additional orders (ZA), this element must not be used.
   * @type {string}
   * @memberof GetPressDistributionOrderRep
   */
  state?: string;
  /**
   *  The production status of the order. This provides information about the current status of the order in DPAG\'s production process.
   * @type {string}
   * @memberof GetPressDistributionOrderRep
   */
  productionState?: string;
  /**
   * Preserved AM.exchange order content. Must be included as is in changeOrder request.
   * @type {string}
   * @memberof GetPressDistributionOrderRep
   */
  amexOrder?: string;
  /**
   * Other attributes as key value map.
   * @type {{ [key: string]: string; }}
   * @memberof GetPressDistributionOrderRep
   */
  otherAttributes?: { [key: string]: string };
  /**
   * A combination of OrderCategory and Product Group used in FE for whitelisting
   * @type {string}
   * @memberof GetPressDistributionOrderRep
   */
  orderCategoryProductKey?: string;
  /**
   *
   * @type {Journal}
   * @memberof GetPressDistributionOrderRep
   */
  journal?: Journal;
  /**
   * In this field a list of base products is specified
   * @type {Array<Product>}
   * @memberof GetPressDistributionOrderRep
   */
  nationalProducts?: Array<Product>;
  /**
   * In this field a list of base international products is specified
   * @type {Array<Product>}
   * @memberof GetPressDistributionOrderRep
   */
  internationalProducts?: Array<Product>;
  /**
   * In this field a list national supplements is specified
   * @type {Array<Supplement>}
   * @memberof GetPressDistributionOrderRep
   */
  supplements?: Array<Supplement>;
  /**
   * Quantity allocations for the base products
   * @type {Array<QtyAllocation>}
   * @memberof GetPressDistributionOrderRep
   */
  qtyAllocations?: Array<QtyAllocation>;
  /**
   * Sum of total weights in the qtyAllocation list.
   * @type {number}
   * @memberof GetPressDistributionOrderRep
   */
  totalNetWeight?: number;
}
/**
 *
 * @export
 * @interface IssueNumber
 */
export interface IssueNumber {
  /**
   * The issue number\'s year
   * @type {number}
   * @memberof IssueNumber
   */
  year?: number;
  /**
   * The issue number
   * @type {number}
   * @memberof IssueNumber
   */
  issueNumber?: number;
}
/**
 * The journals identifier (Zeitungskennzahl).
 * @export
 * @interface IssueNumberRange
 */
export interface IssueNumberRange {
  /**
   *
   * @type {IssueNumber}
   * @memberof IssueNumberRange
   */
  from?: IssueNumber;
  /**
   *
   * @type {IssueNumber}
   * @memberof IssueNumberRange
   */
  to?: IssueNumber;
}
/**
 * The transfer object of the next free issue sequence number.
 * @export
 * @interface IssueTO
 */
export interface IssueTO {
  /**
   * Next available issue number.
   * @type {number}
   * @memberof IssueTO
   */
  magazineSequenceNumber?: number;
  /**
   * The associated year to clearly identify the issue sequence.
   * @type {number}
   * @memberof IssueTO
   */
  magazineSequenceYear?: number;
  /**
   * Posting identifier for press distribution orders.
   * @type {string}
   * @memberof IssueTO
   */
  postingIdentifier?: string;
  /**
   * Return message to query the next free issue sequence number
   * @type {Array<string>}
   * @memberof IssueTO
   */
  messages?: Array<string>;
}
/**
 *
 * @export
 * @interface Journal
 */
export interface Journal {
  /**
   * The issue number of the journals order.
   * @type {string}
   * @memberof Journal
   */
  journalNumber?: string;
  /**
   * The journals posting identifier.
   * @type {string}
   * @memberof Journal
   */
  postingIdentifier?: JournalPostingIdentifierEnum;
  /**
   * The journals identifier (Zeitungskennzahl).
   * @type {string}
   * @memberof Journal
   */
  zkz?: string;
  /**
   *
   * @type {IssueNumberRange}
   * @memberof Journal
   */
  issueNumberRange?: IssueNumberRange;
}

export const JournalPostingIdentifierEnum = {
  S: 'S',
  W: 'W',
  R: 'R'
} as const;

export type JournalPostingIdentifierEnum = typeof JournalPostingIdentifierEnum[keyof typeof JournalPostingIdentifierEnum];

/**
 * The transfer object for a journal with customers and payment info.
 * @export
 * @interface JournalPaymentInfoRep
 */
export interface JournalPaymentInfoRep {
  /**
   * The journal registration number.
   * @type {string}
   * @memberof JournalPaymentInfoRep
   */
  registrationNumber?: string;
  /**
   * The journal title from concatenating the two title parts
   * @type {string}
   * @memberof JournalPaymentInfoRep
   */
  title?: string;
  /**
   * Identification for tariff zone billing
   * @type {boolean}
   * @memberof JournalPaymentInfoRep
   */
  hasTariffCode?: boolean;
  /**
   *
   * @type {PayerInfoWithAddressTO}
   * @memberof JournalPaymentInfoRep
   */
  payer?: PayerInfoWithAddressTO;
  /**
   *
   * @type {CustomerInfoWithAddressTO}
   * @memberof JournalPaymentInfoRep
   */
  submitter?: CustomerInfoWithAddressTO;
  /**
   *
   * @type {CustomerInfoWithAddressTO}
   * @memberof JournalPaymentInfoRep
   */
  originator?: CustomerInfoWithAddressTO;
}
/**
 * The transfer object for a journal.
 * @export
 * @interface JournalRep
 */
export interface JournalRep {
  /**
   * The journal registration number.
   * @type {string}
   * @memberof JournalRep
   */
  registrationNumber?: string;
  /**
   * The journal title from concatenating the two title parts
   * @type {string}
   * @memberof JournalRep
   */
  title?: string;
  /**
   * Identification for tariff zone billing
   * @type {boolean}
   * @memberof JournalRep
   */
  hasTariffCode?: boolean;
}
/**
 * Brief base products. At least one Brief base product is required.
 * @export
 * @interface LetterBaseProduct
 */
export interface LetterBaseProduct {
  /**
   *
   * @type {Product}
   * @memberof LetterBaseProduct
   */
  baseProduct: Product;
  /**
   * Destinations.
   * @type {Array<Range>}
   * @memberof LetterBaseProduct
   */
  destinations?: Array<Range>;
  /**
   * Discounts, e.g. Infrastrukturrabatt
   * @type {Array<DependentProduct>}
   * @memberof LetterBaseProduct
   */
  discounts?: Array<DependentProduct>;
}
/**
 *
 * @export
 * @interface LetterQtyAllocation
 */
export interface LetterQtyAllocation {
  /**
   *
   * @type {string}
   * @memberof LetterQtyAllocation
   */
  productNumber?: string;
  /**
   *
   * @type {string}
   * @memberof LetterQtyAllocation
   */
  format?: string;
  /**
   *
   * @type {number}
   * @memberof LetterQtyAllocation
   */
  quantity?: number;
  /**
   *
   * @type {string}
   * @memberof LetterQtyAllocation
   */
  refShipmentId?: string;
  /**
   *
   * @type {number}
   * @memberof LetterQtyAllocation
   */
  totalWeight?: number;
}
/**
 * amount of one unit with value and currency
 * @export
 * @interface MoneyDT
 */
export interface MoneyDT {
  /**
   *
   * @type {number}
   * @memberof MoneyDT
   */
  value?: number;
  /**
   *
   * @type {string}
   * @memberof MoneyDT
   */
  currency?: string;
}
/**
 *
 * @export
 * @interface OrderAddress
 */
export interface OrderAddress {
  /**
   *
   * @type {string}
   * @memberof OrderAddress
   */
  street?: string;
  /**
   *
   * @type {string}
   * @memberof OrderAddress
   */
  streetNumber?: string;
  /**
   *
   * @type {string}
   * @memberof OrderAddress
   */
  postCode?: string;
  /**
   *
   * @type {string}
   * @memberof OrderAddress
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof OrderAddress
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof OrderAddress
   */
  further?: string;
}
/**
 * Contact person of the customer. -> ex for originator Parties.Originator.Contact
 * @export
 * @interface OrderContact
 */
export interface OrderContact {
  /**
   * First name of contact person. -> ex Parties.Originator.Contact.FirstName
   * @type {string}
   * @memberof OrderContact
   */
  firstName?: string;
  /**
   * First last name of contact person. -> ex Parties.Originator.Contact.LastName
   * @type {string}
   * @memberof OrderContact
   */
  lastName?: string;
  /**
   * Email address of the contact person . -> ex Parties.Originator.Contact.Email
   * @type {string}
   * @memberof OrderContact
   */
  email?: string;
  /**
   * Phone number of contact person. -> ex Parties.Originator.Contact.Phone
   * @type {string}
   * @memberof OrderContact
   */
  telephone?: string;
}
/**
 * In this object a customer is defined.
 * @export
 * @interface OrderCustomer
 */
export interface OrderCustomer {
  /**
   * The customer role.more info can found in CustomerRole Enum. -> Parties
   * @type {string}
   * @memberof OrderCustomer
   */
  role?: OrderCustomerRoleEnum;
  /**
   * Unique customer number (EKP).more info can found in CustomerRole Enum. -> ex for originator Parties.Originator.CustID
   * @type {string}
   * @memberof OrderCustomer
   */
  customerId?: string;
  /**
   * The name of the customer. -> ex for originator Parties.Originator.Name
   * @type {string}
   * @memberof OrderCustomer
   */
  name?: string;
  /**
   *
   * @type {OrderContact}
   * @memberof OrderCustomer
   */
  contact?: OrderContact;
  /**
   *
   * @type {OrderAddress}
   * @memberof PickupAddress
   */
  orderAddress?: OrderAddress;
}

export const OrderCustomerRoleEnum = {
  Payer: 'PAYER',
  Submitter: 'SUBMITTER',
  Producer: 'PRODUCER',
  Originator: 'ORIGINATOR',
  Agency: 'AGENCY',
  Beneficiary: 'BENEFICIARY',
  Owner: 'OWNER',
  Consolidator: 'CONSOLIDATOR',
  DiscountRecipient: 'DISCOUNT_RECIPIENT',
  InvoiceRecipient: 'INVOICE_RECIPIENT',
  DpOptimiser: 'DP_OPTIMISER',
  Contractor: 'CONTRACTOR',
  ContractorPremium: 'CONTRACTOR_PREMIUM',
  GoodsRecipient: 'GOODS_RECIPIENT',
  ShipmentRecipient: 'SHIPMENT_RECIPIENT',
  CreditRecipient: 'CREDIT_RECIPIENT',
  AvisRecipient: 'AVIS_RECIPIENT',
  ReminderRecipient: 'REMINDER_RECIPIENT'
} as const;

export type OrderCustomerRoleEnum = typeof OrderCustomerRoleEnum[keyof typeof OrderCustomerRoleEnum];

/**
 * This element specifies the shipment destinations of the shipments or the shipping schedule..(AKA Leitzone)
 * @export
 * @interface OrderDestination
 */
export interface OrderDestination {
  /**
   * The destination area of the shipments as specified in the Destination.prec attribute. Please note the required accuracy of this specification depending on the product. (See description of the Destination.prec attribute).. -> ShipmentItem.Shipment.Destination.Dst.zip
   * @type {string}
   * @memberof OrderDestination
   */
  zipCode?: string;
  /**
   * Shipment quantity going to the destination area. -> ShipmentItem.Shipment.Destination.Dst.qty.
   * @type {number}
   * @memberof OrderDestination
   */
  quantity?: number;
  /**
   *
   * @type {Array<OrderPackaging>}
   * @memberof OrderDestination
   */
  packaging?: Array<OrderPackaging>;
}
/**
 *
 * @export
 * @interface OrderDetail
 */
export interface OrderDetail {
  /**
   *
   * @type {Date}
   * @memberof OrderDetail
   */
  date?: Date;
  /**
   *
   * @type {string}
   * @memberof OrderDetail
   */
  productionPlantId?: string;
  /**
   * Submission type.
   * @type {string}
   * @memberof OrderDetail
   */
  submissionType?: string;
  /**
   *
   * @type {boolean}
   * @memberof OrderDetail
   */
  pickup?: boolean;
}
/**
 * List of order fees from all relevant allocation documents (used with orderType ZA)
 * @export
 * @interface OrderFeeRep
 */
export interface OrderFeeRep {
  /**
   * type of the order fee
   * @type {string}
   * @memberof OrderFeeRep
   */
  type?: string;
  /**
   *
   * @type {MoneyDT}
   * @memberof OrderFeeRep
   */
  amount?: MoneyDT;
}
/**
 * This object holds information concernig the packaging. Information on the container structure of the order is transferred.This contains, for example, the number of bundles, their weights and shipment destinations, the respective bundle type, the number of storage locations required for transport, etc.Hierarchical bundle structures, such as bundles on pallets, can also be transmitted. In addition, it is possible to specify how many shipments of a shipment cluster belong to the bundle and even more detailed, which shipment numbers belong to the bundle.  ->  Packaging
 * @export
 * @interface OrderPackaging
 */
export interface OrderPackaging {
  /**
   *  The type of container.\'BDL\', \'PAL\', \'TRT\', \'TRA\'. ->  Packaging.Type
   * @type {string}
   * @memberof OrderPackaging
   */
  type?: string;
  /**
   *  The number of \"outer\" containers, which are transmitted in the packaging section as \"Package\". Even if, in addition to the information on the outer packages, information on the packages contained therein is supplied, only the number of outer packages must be specified here. This is important because the number specified here is automatically passed on to the transport system during collection. If, for example, a pallet with 20 containers is announced, then the number 1 and not 21 is to be indicated here.. ->  Packaging.Qty
   * @type {number}
   * @memberof OrderPackaging
   */
  quantity?: number;
  /**
   *  Indicates whether the container is stackable.->  Packaging.Package.stackable
   * @type {boolean}
   * @memberof OrderPackaging
   */
  stackable?: boolean;
  /**
   *
   * @type {number}
   * @memberof OrderPackaging
   */
  totalWeight?: number;
  /**
   *
   * @type {string}
   * @memberof OrderPackaging
   */
  packageId?: string;
  /**
   *
   * @type {number}
   * @memberof OrderPackaging
   */
  serialNumber?: number;
  /**
   *
   * @type {string}
   * @memberof OrderPackaging
   */
  unit?: string;
  /**
   * Transport-Netz / Versandservice. Only for press distribution.
   * @type {string}
   * @memberof OrderPackaging
   */
  transport?: string;
  /**
   * ZSP. Only for press distribution.
   * @type {string}
   * @memberof OrderPackaging
   */
  deliveryBase?: string;
  /**
   *
   * @type {string}
   * @memberof OrderPackaging
   */
  sortProc?: string;
  /**
   *
   * @type {boolean}
   * @memberof OrderPackaging
   */
  packagingEditable?: boolean;

  shipmentRefs?: Array<number>;
}
export interface Constraints {
  /**
   *  Indicates whether the container is Editable.->  Packaging.Package.packageSectionEditable
   * @type {boolean}
   * @memberof Constraints
   */
  packageSectionEditable?: boolean;
}
/**
 * Franking information.
 * @export
 * @interface OrderPostage
 */
export interface OrderPostage {
  /**
   * Art der Frankierung [ShipmentItem.Shipment.Postage.Type]
   * @type {string}
   * @memberof OrderPostage
   */
  type?: string;
  /**
   * Bezahlart Aufzahlung und Zuschläge [ShipmentItem.Shipment.Postage.AdditionalCharge]
   * @type {string}
   * @memberof OrderPostage
   */
  additionalCharge?: string;
  /**
   * Einlieferungsbelegnummer [ShipmentItem.Shipment.Postage.PaymentClearingNumber]
   * @type {string}
   * @memberof OrderPostage
   */
  paymentClearingNumber?: string;
  /**
   * Freimachungswert [ShipmentItem.Shipment.Postage.Amount]
   * @type {number}
   * @memberof OrderPostage
   */
  amount?: number;
  /**
   * Maschinenkennung [ShipmentItem.Shipment.Postage.Code]
   * @type {string}
   * @memberof OrderPostage
   */
  machineCode?: string;
  /**
   * Prefix of the maximum 20-digit franking ID [ShipmentItem.Shipment.Postage.FrankingIdPrefix]
   * @type {string}
   * @memberof OrderPostage
   */
  frankingIdPrefix?: string;
  /**
   * The encoding used to specify the shipment number in the franking ID. [ShipmentItem.Shipment.Postage.FrankingIdEncoding]
   * @type {string}
   * @memberof OrderPostage
   */
  frankingIdEncoding?: OrderPostageFrankingIdEncodingEnum;
}

export const OrderPostageFrankingIdEncodingEnum = {
  Dechexc40: 'DEC,HEX,C40'
} as const;

export type OrderPostageFrankingIdEncodingEnum = typeof OrderPostageFrankingIdEncodingEnum[keyof typeof OrderPostageFrankingIdEncodingEnum];

/**
 * Order pricing.
 * @export
 * @interface OrderPriceRep
 */
export interface OrderPriceRep {
  /**
   * List of service clusters (base products)
   * @type {Array<ServiceClusterRep>}
   * @memberof OrderPriceRep
   */
  serviceClusters?: Array<ServiceClusterRep>;
  /**
   * List of order fees
   * @type {Array<OrderFeeRep>}
   * @memberof OrderPriceRep
   */
  orderFees?: Array<OrderFeeRep>;
  /**
   * List of service clusters from all relevant allocation documents (used with orderType ZA)
   * @type {Array<ServiceClusterRep>}
   * @memberof OrderPriceRep
   */
  allocationDocumentsServiceClusters?: Array<ServiceClusterRep>;
  /**
   * List of order fees from all relevant allocation documents (used with orderType ZA)
   * @type {Array<OrderFeeRep>}
   * @memberof OrderPriceRep
   */
  allocationDocumentsOrderFees?: Array<OrderFeeRep>;
  /**
   * Date of last state change of order
   * @type {Date}
   * @memberof OrderPriceRep
   */
  lastChangedAt?: Date;
}

export interface OrderPriceResponse {
  /**
   *
   * @type {string}
   * @memberof OrderResultRep
   */
  resultStatus?: string;
  /**
   *
   * @type {number}
   * @memberof OrderResultRep
   */
  durationInMillis?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof OrderResultRep
   */
  timings?: { [key: string]: number };
  /**
   *
   * @type {Array<AmErrorCodeRep>}
   * @memberof OrderResultRep
   */
  amErrors?: Array<AmErrorCodeRep>;
  /**
   * Warning that if true means AM hasn\'t persisted shipment plan yet
   * @type {boolean}
   * @memberof OrderResultRep
   */
  pendingIds?: boolean;
  /**
   * Order pricing.
   * @export
   * @interface OrderPriceRep
   */
  orderPriceRep?: OrderPriceRep;
}

/**
 * The order references to be changed.
 * @export
 * @interface OrderReference
 */
export interface OrderReference {
  /**
   * an order id.
   * @type {string}
   * @memberof OrderReference
   */
  orderId?: string;
  /**
   *
   * @type {number}
   * @memberof OrderReference
   */
  initialTotalQty?: number;
  /**
   * The franking prefix.
   * @type {string}
   * @memberof OrderReference
   */
  frankingIdPrefix?: string;
  /**
   * if contain ids the Destinations have to be present in Shipment.Destination
   * @type {string}
   * @memberof OrderReference
   */
  containsIds: OrderReferenceContainsIdsEnum;
  /**
   * If If \"includeTotalQty\" is set to \"true\", there is no need to enter \"qtyAllocation\" because the entire shipping schedule of the referenced shipments is to be adopted.The backend must execute a getOrder for the referenced order and transfer the product quantities to the ZA
   * @type {string}
   * @memberof OrderReference
   */
  includeTotalQty?: OrderReferenceIncludeTotalQtyEnum;
  /**
   * The quantity allocation
   * @type {Array<QtyAllocation>}
   * @memberof OrderReference
   */
  qtyAllocation?: Array<QtyAllocation>;
  /**
   * if flex option true and containsIds true then flex product and rabatt flex must be included as line Items. If containIds true and flex false then rabatt product must me present as a line item
   * @type {string}
   * @memberof OrderReference
   */
  flexOption: OrderReferenceFlexOptionEnum;
}

export const OrderReferenceContainsIdsEnum = {
  All: 'ALL',
  None: 'NONE',
  Some: 'SOME'
} as const;

export type OrderReferenceContainsIdsEnum = typeof OrderReferenceContainsIdsEnum[keyof typeof OrderReferenceContainsIdsEnum];
export const OrderReferenceIncludeTotalQtyEnum = {
  All: 'ALL',
  None: 'NONE',
  Some: 'SOME'
} as const;

export type OrderReferenceIncludeTotalQtyEnum = typeof OrderReferenceIncludeTotalQtyEnum[keyof typeof OrderReferenceIncludeTotalQtyEnum];
export const OrderReferenceFlexOptionEnum = {
  All: 'ALL',
  None: 'NONE',
  Some: 'SOME'
} as const;

export type OrderReferenceFlexOptionEnum = typeof OrderReferenceFlexOptionEnum[keyof typeof OrderReferenceFlexOptionEnum];

/**
 *
 * @export
 * @interface OrderResultRep
 */
export interface OrderResultRep {
  /**
   *
   * @type {string}
   * @memberof OrderResultRep
   */
  resultStatus?: string;
  /**
   *
   * @type {number}
   * @memberof OrderResultRep
   */
  durationInMillis?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof OrderResultRep
   */
  timings?: { [key: string]: number };
  /**
   *
   * @type {Array<AmErrorCodeRep>}
   * @memberof OrderResultRep
   */
  amErrors?: Array<AmErrorCodeRep>;
  /**
   * Warning that if true means AM hasn\'t persisted shipment plan yet
   * @type {boolean}
   * @memberof OrderResultRep
   */
  pendingIds?: boolean;
  /**
   *
   * @type {string}
   * @memberof OrderResultRep
   */
  orderId?: string;
}

/**
 *
 * @export
 * @interface SplitOrderResultRep
 */
export interface SplitOrderResultRepResponse {
  response: SplitOrderResultRep;
}

export interface MergeOrderResultRepResponse {
  response: MergeOrderResultRep;
}
/**
 *
 * @export
 * @interface SplitOrderResultRep
 */
export interface SplitOrderResultRep {
  /**
   *
   * @type {string}
   * @memberof SplitOrderResultRep
   */
  resultStatus?: string;
  /**
   *
   * @type {number}
   * @memberof SplitOrderResultRep
   */
  durationInMillis?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof SplitOrderResultRep
   */
  timings?: { [key: string]: number };
  /**
   *
   * @type {Array<AmErrorCodeRep>}
   * @memberof SplitOrderResultRep
   */
  amErrors?: Array<AmErrorCodeRep>;
  /**
   * Warning that if true means AM hasn\'t persisted shipment plan yet
   * @type {boolean}
   * @memberof SplitOrderResultRep
   */
  pendingIds?: boolean;
  /**
   *
   * @type {string}
   * @memberof SplitOrderResultRep
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof SplitOrderResultRep
   */
  newOrderNumber: string;
  /**
   *
   * @type {string}
   * @memberof SplitOrderResultRep
   */
  remainingOrderNumber: string;
}

/**
 *
 * @export
 * @interface SplitOrderResultRep
 */
export interface MergeOrderResultRep {
  /**
   *
   * @type {string}
   * @memberof SplitOrderResultRep
   */
  resultStatus?: string;
  /**
   *
   * @type {number}
   * @memberof SplitOrderResultRep
   */
  durationInMillis?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof SplitOrderResultRep
   */
  timings?: { [key: string]: number };
  /**
   *
   * @type {Array<AmErrorCodeRep>}
   * @memberof SplitOrderResultRep
   */
  amErrors?: Array<AmErrorCodeRep>;
  /**
   * Warning that if true means AM hasn\'t persisted shipment plan yet
   * @type {boolean}
   * @memberof SplitOrderResultRep
   */
  pendingIds?: boolean;
  /**
   *
   * @type {string}
   * @memberof SplitOrderResultRep
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof SplitOrderResultRep
   */
  newOrderNumber: string;
  /**
   *
   * @type {string}
   * @memberof SplitOrderResultRep
   */
  remainingOrderNumber: string;
}

/**
 *
 * @export
 * @interface OrderSearchResultRep
 */
export interface OrderSearchResultRep {
  /**
   *
   * @type {string}
   * @memberof OrderSearchResultRep
   */
  resultStatus?: string;
  /**
   *
   * @type {number}
   * @memberof OrderSearchResultRep
   */
  durationInMillis?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof OrderSearchResultRep
   */
  timings?: { [key: string]: number };
  /**
   *
   * @type {Array<AmErrorCodeRep>}
   * @memberof OrderSearchResultRep
   */
  amErrors?: Array<AmErrorCodeRep>;
  /**
   * Warning that if true means AM hasn\'t persisted shipment plan yet
   * @type {boolean}
   * @memberof OrderSearchResultRep
   */
  pendingIds?: boolean;
  /**
   *
   * @type {Array<OrderTreeItem>}
   * @memberof OrderSearchResultRep
   */
  orders?: Array<OrderTreeItem>;
}
/**
 *
 * @export
 * @interface OrderTreeItem
 */
export interface OrderTreeItem {
  /**
   *
   * @type {SearchOrderRep}
   * @memberof OrderTreeItem
   */
  data?: SearchOrderRep;
  /**
   *
   * @type {number}
   * @memberof OrderTreeItem
   */
  id?: number;
}
/**
 * They offer the possibility of transmitting further characteristics of the enclosures to the shipments
 * @export
 * @interface OtherAttribute
 */
export interface OtherAttribute {
  /**
   * Specifies the type of the other value specified in the Value field.
   * @type {string}
   * @memberof OtherAttribute
   */
  type?: string;
  /**
   * Specifies the value of the other value specified in the Value field.
   * @type {string}
   * @memberof OtherAttribute
   */
  value?: string;
}
/**
 * The payer of a journal AKA Zahlungspflichtiger.
 * @export
 * @interface PayerInfoWithAddressTO
 */
export interface PayerInfoWithAddressTO {
  /**
   *
   * @type {string}
   * @memberof PayerInfoWithAddressTO
   */
  customerId?: string;
  /**
   *
   * @type {string}
   * @memberof PayerInfoWithAddressTO
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PayerInfoWithAddressTO
   */
  companyName?: string;
  /**
   *
   * @type {AddressTO}
   * @memberof PayerInfoWithAddressTO
   */
  address?: AddressTO;
  /**
   * Name of the bank for the above bank code.
   * @type {string}
   * @memberof PayerInfoWithAddressTO
   */
  bankName?: string;
  /**
   * International Bank Account Number.
   * @type {string}
   * @memberof PayerInfoWithAddressTO
   */
  iban?: string;
}
/**
 *
 * @export
 * @interface PaymentRep
 */
export interface PaymentRep {
  /**
   *
   * @type {string}
   * @memberof PaymentRep
   */
  settlementNumber?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentRep
   */
  companyName?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentRep
   */
  iban?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentRep
   */
  bank?: string;
  /**
   *
   * @type {string}
   * @memberof OrderAddress
   */
  address?: OrderAddress;
}
/**
 * Address for the pickup of the order when the customer wants to deliver the items himself to a GrASt or a Deutsche Post depot. There must be no collection service on the order. The items are picked up from the customer by Deutsche Post. The pickup is not yet a binding order, i.e., in addition to the electronic announcement, the fax advice is required. If the address is set, the flag Induction.TransitDirectionCode will be set to \"A\" for Collection or \"E\" for Deposit (default).
 * @export
 * @interface PickupAddress
 */
export interface PickupAddress {
  /**
   *
   * @type {string}
   * @memberof PickupAddress
   */
  addressBook?: string;
  /**
   *
   * @type {string}
   * @memberof PickupAddress
   */
  companyName?: string;
  /**
   *
   * @type {OrderAddress}
   * @memberof PickupAddress
   */
  orderAddress?: OrderAddress;
}
/**
 *
 * @export
 * @interface PrDBaseProductTO
 */
export interface PrDBaseProductTO {
  /**
   * Region where the products are valid
   * @type {string}
   * @memberof PrDBaseProductTO
   */
  region?: PrDBaseProductTORegionEnum;
  /**
   *
   * @type {string}
   * @memberof PrDBaseProductTO
   */
  productNumber?: string;
  /**
   *
   * @type {string}
   * @memberof PrDBaseProductTO
   */
  validFrom?: string;
  /**
   *
   * @type {string}
   * @memberof PrDBaseProductTO
   */
  validTo?: string;
  /**
   *
   * @type {string}
   * @memberof PrDBaseProductTO
   */
  descriptionShort?: string;
}

export const PrDBaseProductTORegionEnum = {
  National: 'National',
  International: 'International'
} as const;

export type PrDBaseProductTORegionEnum = typeof PrDBaseProductTORegionEnum[keyof typeof PrDBaseProductTORegionEnum];

/**
 *
 * @export
 * @interface PrDSupplementProductTO
 */
export interface PrDSupplementProductTO {
  /**
   * Region where the products are valid
   * @type {string}
   * @memberof PrDSupplementProductTO
   */
  region?: PrDSupplementProductTORegionEnum;
  /**
   *
   * @type {string}
   * @memberof PrDSupplementProductTO
   */
  productNumber?: string;
  /**
   *
   * @type {string}
   * @memberof PrDSupplementProductTO
   */
  validFrom?: string;
  /**
   *
   * @type {string}
   * @memberof PrDSupplementProductTO
   */
  validTo?: string;
  /**
   *
   * @type {string}
   * @memberof PrDSupplementProductTO
   */
  descriptionShort?: string;
  /**
   * Type of the supplement
   * @type {string}
   * @memberof PrDSupplementProductTO
   */
  supplementType?: PrDSupplementProductTOSupplementTypeEnum;
}

export const PrDSupplementProductTORegionEnum = {
  National: 'National',
  International: 'International'
} as const;

export type PrDSupplementProductTORegionEnum = typeof PrDSupplementProductTORegionEnum[keyof typeof PrDSupplementProductTORegionEnum];
export const PrDSupplementProductTOSupplementTypeEnum = {
  Re: 'RE',
  It: 'IT',
  Eb: 'EB',
  Mv: 'MV'
} as const;

export type PrDSupplementProductTOSupplementTypeEnum =
  typeof PrDSupplementProductTOSupplementTypeEnum[keyof typeof PrDSupplementProductTOSupplementTypeEnum];

/**
 *
 * @export
 * @interface PressTitleTO
 */
export interface PressTitleTO {
  /**
   *
   * @type {string}
   * @memberof PressTitleTO
   */
  newspaperNumber?: string;
  /**
   *
   * @type {string}
   * @memberof PressTitleTO
   */
  name?: string;
}
/**
 * List of price positions
 * @export
 * @interface PricePositionRep
 */
export interface PricePositionRep {
  /**
   * product description
   * @type {string}
   * @memberof PricePositionRep
   */
  productDescription?: string;
  /**
   * format (used with orderType ZA)
   * @type {string}
   * @memberof PricePositionRep
   */
  format?: PricePositionRepFormatEnum;
  /**
   * quantity
   * @type {number}
   * @memberof PricePositionRep
   */
  qty?: number;
  /**
   *
   * @type {MoneyDT}
   * @memberof PricePositionRep
   */
  unitAmount?: MoneyDT;
  /**
   *
   * @type {ValueDT}
   * @memberof PricePositionRep
   */
  unitWeight?: ValueDT;
  /**
   * tax rate (in percent)
   * @type {number}
   * @memberof PricePositionRep
   */
  taxRate?: number;
  /**
   *
   * @type {MoneyDT}
   * @memberof PricePositionRep
   */
  amount?: MoneyDT;
  /**
   * reduction (used with orderType ZA)
   * @type {number}
   * @memberof PricePositionRep
   */
  reduction?: number;
}

export const PricePositionRepFormatEnum = {
  S: 'S',
  K: 'K',
  G: 'G',
  M: 'M',
  P: 'P',
  Fsr: 'FSR'
} as const;

export type PricePositionRepFormatEnum = typeof PricePositionRepFormatEnum[keyof typeof PricePositionRepFormatEnum];

/**
 *
 * @export
 * @interface ProcedureTO
 */
export interface ProcedureTO {
  /**
   *
   * @type {string}
   * @memberof ProcedureTO
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ProcedureTO
   */
  name?: string;
}
/**
 * a base product is specified
 * @export
 * @interface Product
 */
export interface Product {
  /**
   *
   * @type {string}
   * @memberof Product
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  productNumber?: string;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  quantity?: number;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  weight?: number;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  grossWeight?: number;
  /**
   * This can be used to create a reference to shipments of a shipment cluster. -> ShipmentItem.Item.RefShipment
   * @type {string}
   * @memberof Product
   */
  referenceShipmentId?: string;
  /**
   * Item id in existing order. Take over from getOrder request.
   * @type {number}
   * @memberof Product
   */
  itemId?: number;

  /**
   * Discounts, e.g. Infrastrukturrabatt
   * @type {Array<DependentProduct>}
   * @memberof LetterBaseProduct
   */
  discounts?: Array<DependentProduct>;

  preExisting?: boolean;

  pressVariant?: PRESS_VARIANTS;
}
/**
 *
 * @export
 * @interface ProductTO
 */
export interface ProductTO {
  /**
   *
   * @type {string}
   * @memberof ProductTO
   */
  productNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ProductTO
   */
  validFrom?: string;
  /**
   *
   * @type {string}
   * @memberof ProductTO
   */
  validTo?: string;
  /**
   *
   * @type {string}
   * @memberof ProductTO
   */
  descriptionShort?: string;
  /**
   *
   * @type {string}
   * @memberof ProductTO
   */
  serviceType?: string;
  /**
   *
   * @type {string}
   * @memberof ProductTO
   */
  group?: string;
  /**
   *
   * @type {string}
   * @memberof ProductTO
   */
  basicFormat?: string;
  /**
   *
   * @type {string}
   * @memberof ProductTO
   */
  hierarchyProductGroup?: string;
  /**
   *
   * @type {string}
   * @memberof ProductTO
   */
  hierarchyProductCharacteristic?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProductTO
   */
  isAdditionalCharge?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductTO
   */
  partialServiceCapable?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductTO
   */
  premiumAddress?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductTO
   */
  premiumAddressType?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProductTO
   */
  sortedPalletZone?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductTO
   */
  sortedPalletRegion?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductTO
   */
  sortedTrayRegion?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductTO
   */
  sortedTrayPostcode?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductTO
   */
  internationalProduct?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductTO
   */
  partialServiceType?: string;
  /**
   *
   * @type {string}
   * @memberof ProductTO
   */
  variant?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProductTO
   */
  baseProduct?: boolean;
}
/**
 *
 * @export
 * @interface ProductionFacilityTO
 */
export interface ProductionFacilityTO {
  /**
   *
   * @type {string}
   * @memberof ProductionFacilityTO
   */
  validFrom?: string;
  /**
   *
   * @type {string}
   * @memberof ProductionFacilityTO
   */
  validTo?: string;
  /**
   *
   * @type {string}
   * @memberof ProductionFacilityTO
   */
  catalogId?: string;
  /**
   *
   * @type {AddressTO}
   * @memberof ProductionFacilityTO
   */
  address?: AddressTO;
  /**
   *
   * @type {string}
   * @memberof ProductionFacilityTO
   */
  comment?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ProductionFacilityTO
   */
  regions?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ProductionFacilityTO
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof ProductionFacilityTO
   */
  fax?: string;
  /**
   *
   * @type {string}
   * @memberof ProductionFacilityTO
   */
  email?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ProductionFacilityTO
   */
  internationalPickUp?: Array<string>;
}
/**
 * The quantity allocation
 * @export
 * @interface QtyAllocation
 */
export interface QtyAllocation {
  postageType?: string;

  /**
   * the product number to be mapped to BZE|BZA product
   * @type {string}
   * @memberof QtyAllocation
   */
  productNumber?: string;
  /**
   * the product format
   * @type {string}
   * @memberof QtyAllocation
   */
  format?: QtyAllocationFormatEnum;
  /**
   * The order quantity
   * @type {number}
   * @memberof QtyAllocation
   */
  quantity?: number;
  /**
   * The Ranges to map in the DestType Object
   * @type {Array<Range>}
   * @memberof QtyAllocation
   */
  ranges?: Array<Range>;
  /**
   * The shipment to be referenced
   * @type {number}
   * @memberof QtyAllocation
   */
  refShipmentId?: number;
  /**
   * The shipment to be referenced in the partial service order
   * @type {number}
   * @memberof QtyAllocation
   */
  refShipmentIdZA?: number;
  /**
   * Flag to signify whether there is an id discount or not
   * @type {boolean}
   * @memberof QtyAllocation
   */
  containsIds?: boolean;
  /**
   * Flag to signify whether there is an flex discount or not
   * @type {boolean}
   * @memberof QtyAllocation
   */
  flexOption?: boolean;
  /**
   * Flag to signify whether the qtyAllocation is fully assigned or not
   * @type {boolean}
   * @memberof QtyAllocation
   */
  includeTotalQty?: boolean;
  /**
   *
   * @type {number}
   * @memberof QtyAllocation
   */
  partialServiceItem?: number;
  /**
   *
   * @type {number}
   * @memberof QtyAllocation
   */
  idItem?: number;
  /**
   *
   * @type {number}
   * @memberof QtyAllocation
   */
  flexItem?: number;
  /**
   *
   * @type {number}
   * @memberof QtyAllocation
   */
  initialQty?: number;
  /**
   * Quantity of the refShipmentId times individual weight of the referenced shipments.
   * @type {number}
   * @memberof QtyAllocation
   */
  totalWeight?: number;
}

export const QtyAllocationFormatEnum = {
  S: 'S',
  K: 'K',
  G: 'G',
  M: 'M',
  P: 'P',
  Fsr: 'FSR'
} as const;

export type QtyAllocationFormatEnum = typeof QtyAllocationFormatEnum[keyof typeof QtyAllocationFormatEnum];

/**
 * The Ranges to map in the DestType Object
 * @export
 * @interface Range
 */
export interface Range {
  /**
   * The first consignment number of the consignment area
   * @type {string}
   * @memberof Range
   */
  from?: string;
  /**
   * The last consignment ID of a consignment range, if the consignments are numbered consecutively
   * @type {string}
   * @memberof Range
   */
  to?: string;
  /**
   * Prefix of the maximum 20-digit franking ID. The franking ID of a mailing is made up as follows: - from this prefix - the consecutive item number of the item in the specified coding for the item numbers (decimal, hexadecimal or C40) - the check digit
   * @type {string}
   * @memberof Range
   */
  frankingIdPrefix?: string;
  /**
   * The destination area of the shipments as specified in the Destination.prec attribute. Please note the required accuracy of this specification depending on the product. (See description of the Destination.prec attribute).
   * @type {string}
   * @memberof Range
   */
  zip?: string;
  /**
   *  This is the PrecT Indicates the sorting accuracy of the bundles or containers: (routing zone bundles/containers have no influence on pricing or discounting).
   * @type {number}
   * @memberof Range
   */
  precision?: number;
}
/**
 *
 * @export
 * @interface SavedSetting
 */
export interface SavedSetting {
  /**
   * Base64 encoded search history
   * @type {string}
   * @memberof SavedSetting
   */
  payload: string;
}
/**
 *
 * @export
 * @interface SavedSettingsRep
 */
export interface SavedSettingsRep {
  /**
   *
   * @type {string}
   * @memberof SavedSettingsRep
   */
  payload?: string;
  /**
   *
   * @type {string}
   * @memberof SavedSettingsRep
   */
  type?: string;
}
/**
 *
 * @export
 * @interface SearchOrder
 */
export interface SearchOrder {
  /**
   *
   * @type {string}
   * @memberof SearchOrder
   */
  firstDate?: string;
  /**
   *
   * @type {string}
   * @memberof SearchOrder
   */
  lastDate?: string;
  /**
   *
   * @type {{ [key: string]: Array<string>; }}
   * @memberof SearchOrder
   */
  searchCriteria: { [key: string]: Array<string> };
  /**
   *
   * @type {boolean}
   * @memberof SearchOrder
   */
  limitSearchResult?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SearchOrder
   */
  prdSearch?: boolean;
  /**
   *
   * @type {string}
   * @memberof SearchOrder
   */
  firstDateAsLocalDate?: string;
  /**
   *
   * @type {string}
   * @memberof SearchOrder
   */
  lastDateAsLocalDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof SearchOrder
   */
  searchModeAbholauftrag?: boolean;
}
/**
 *
 * @export
 * @interface SearchOrderRep
 */
export interface SearchOrderRep {
  /**
   *
   * @type {string}
   * @memberof SearchOrderRep
   */
  orderCategory?: string;
  /**
   *
   * @type {string}
   * @memberof SearchOrderRep
   */
  orderNumber?: string;
  /**
   *
   * @type {string}
   * @memberof SearchOrderRep
   */
  orderType?: string;
  /**
   *
   * @type {string}
   * @memberof SearchOrderRep
   */
  orderLabel?: string;
  /**
   *
   * @type {string}
   * @memberof SearchOrderRep
   */
  product?: string;
  /**
   *
   * @type {number}
   * @memberof SearchOrderRep
   */
  totalQty?: number;
  /**
   *
   * @type {string}
   * @memberof SearchOrderRep
   */
  frankierung?: string;
  /**
   *
   * @type {number}
   * @memberof SearchOrderRep
   */
  totalAmountValue?: number;
  /**
   *
   * @type {string}
   * @memberof SearchOrderRep
   */
  totalAmountCurrency?: string;
  /**
   *
   * @type {string}
   * @memberof SearchOrderRep
   */
  creationDate?: string;
  /**
   *
   * @type {string}
   * @memberof SearchOrderRep
   */
  firstInductionDate?: string;
  /**
   *
   * @type {string}
   * @memberof SearchOrderRep
   */
  state?: string;
  /**
   *
   * @type {OrderCustomer}
   * @memberof SearchOrderRep
   */
  originator?: OrderCustomer;
  /**
   *
   * @type {OrderCustomer}
   * @memberof SearchOrderRep
   */
  submitter?: OrderCustomer;
  /**
   *
   * @type {OrderCustomer}
   * @memberof SearchOrderRep
   */
  producer?: OrderCustomer;
  /**
   *
   * @type {OrderCustomer}
   * @memberof SearchOrderRep
   */
  beneficiary?: OrderCustomer;
  /**
   *
   * @type {string}
   * @memberof SearchOrderRep
   */
  zkz?: string;
  /**
   *
   * @type {string}
   * @memberof SearchOrderRep
   */
  newspaperNumber?: string;
  /**
   *
   * @type {string}
   * @memberof SearchOrderRep
   */
  newspaperTitle?: string;
  /**
   *
   * @type {string}
   * @memberof SearchOrderRep
   */
  customerOrderId?: string;
  /**
   *
   * @type {string}
   * @memberof SearchOrderRep
   */
  settlementNumber?: string;
  /**
   *
   * @type {OrderCustomer}
   * @memberof SearchOrderRep
   */
  payer?: OrderCustomer;
  /**
   *
   * @type {string}
   * @memberof SearchOrderRep
   */
  procedure?: string;
  /**
   *
   * @type {string}
   * @memberof SearchOrderRep
   */
  participation?: string;
  /**
   *
   * @type {string}
   * @memberof SearchOrderRep
   */
  paymentClearingNumber?: string;
  /**
   *
   * @type {string}
   * @memberof SearchOrderRep
   */
  orderReference?: string;
  /**
   *
   * @type {string}
   * @memberof SearchOrderRep
   */
  packagingType?: string;
  /**
   *
   * @type {number}
   * @memberof SearchOrderRep
   */
  packagingQty?: number;
  /**
   *
   * @type {string}
   * @memberof SearchOrderRep
   */
  orderCategoryProductKey?: string;
}
/**
 * List of service clusters from all relevant allocation documents (used with orderType ZA)
 * @export
 * @interface ServiceClusterRep
 */
export interface ServiceClusterRep {
  /**
   * base product description
   * @type {string}
   * @memberof ServiceClusterRep
   */
  baseProductDescription?: string;
  /**
   * base product format (used with orderType ZA)
   * @type {string}
   * @memberof ServiceClusterRep
   */
  baseProductFormat?: ServiceClusterRepBaseProductFormatEnum;
  /**
   * quantity
   * @type {number}
   * @memberof ServiceClusterRep
   */
  qty?: number;
  /**
   *
   * @type {MoneyDT}
   * @memberof ServiceClusterRep
   */
  amount?: MoneyDT;
  /**
   * List of price positions
   * @type {Array<PricePositionRep>}
   * @memberof ServiceClusterRep
   */
  pricePositions?: Array<PricePositionRep>;
  /**
   * singleOrderId
   * @type {string}
   * @memberof ServiceClusterRep
   */
  singleOrderId?: string;
}

export const ServiceClusterRepBaseProductFormatEnum = {
  S: 'S',
  K: 'K',
  G: 'G',
  M: 'M',
  P: 'P',
  Fsr: 'FSR'
} as const;

export type ServiceClusterRepBaseProductFormatEnum =
  typeof ServiceClusterRepBaseProductFormatEnum[keyof typeof ServiceClusterRepBaseProductFormatEnum];

/**
 * a an item product of collective delivery is specified
 * @export
 * @interface ShipmentData
 */
export interface ShipmentData {
  /**
   *
   * @type {string}
   * @memberof ShipmentData
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof ShipmentData
   */
  totalQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof ShipmentData
   */
  additionalQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof ShipmentData
   */
  individualWeight?: number;
  /**
   *
   * @type {number}
   * @memberof ShipmentData
   */
  referenceShipmentId?: number;
}
/**
 * For specifying information on inserts. These are used in particular for the Presse and Postaktuell products (response elements).
 * @export
 * @interface Supplement
 */
export interface Supplement {
  /**
   *
   * @type {string}
   * @memberof Supplement
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof Supplement
   */
  productNumber?: string;
  /**
   *
   * @type {number}
   * @memberof Supplement
   */
  quantity?: number;
  /**
   *
   * @type {number}
   * @memberof Supplement
   */
  weight?: number;
  /**
   * This can be used to create a reference to shipments of a shipment cluster. -> ShipmentItem.Item.RefShipment
   * @type {string}
   * @memberof Supplement
   */
  referenceShipmentId?: string;
  /**
   * Item id in existing order. Take over from getOrder request.
   * @type {number}
   * @memberof Supplement
   */
  itemId?: number;
  /**
   *  The supplement id used in The supplement reference.
   * @type {number}
   * @memberof Supplement
   */
  supplementId?: number;
  /**
   *  Specifies the type of the other value specified in the Value field.
   * @type {string}
   * @memberof Supplement
   */
  weightUnit?: string;
  /**
   * Designation of the type of insert.
   * @type {string}
   * @memberof Supplement
   */
  type?: string;
  /**
   *
   * @type {OtherAttribute}
   * @memberof Supplement
   */
  otherAttribute?: OtherAttribute;
  /**
   *
   * @type {assignments}
   * @memberof Supplement
   */
  assignments?: Assignments[];
}

export interface Assignments {
  quantity?: number;
  referenceShipmentId?: string;
  itemId?: string;
  supplementId?: number;
}

/**
 * weight of one unit with value and measure unit
 * @export
 * @interface ValueDT
 */
export interface ValueDT {
  /**
   *
   * @type {number}
   * @memberof ValueDT
   */
  value?: number;
  /**
   *
   * @type {string}
   * @memberof ValueDT
   */
  unit?: string;
}
/**
 *
 * @export
 * @interface VersionTO
 */
export interface VersionTO {
  /**
   *
   * @type {string}
   * @memberof VersionTO
   */
  version?: string;
  /**
   *
   * @type {string}
   * @memberof VersionTO
   */
  hash?: string;
  /**
   *
   * @type {string}
   * @memberof VersionTO
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof VersionTO
   */
  branch?: string;
}
/**
 *
 * @export
 * @interface WhitelistAccessListRep
 */
export interface WhitelistAccessListRep {
  /**
   *
   * @type {Array<string>}
   * @memberof WhitelistAccessListRep
   */
  categories?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof WhitelistAccessListRep
   */
  status?: Array<string>;
}
/**
 *
 * @export
 * @interface WhitelistRep
 */
export interface WhitelistRep {
  /**
   *
   * @type {WhitelistAccessListRep}
   * @memberof WhitelistRep
   */
  create?: WhitelistAccessListRep;
  /**
   *
   * @type {WhitelistAccessListRep}
   * @memberof WhitelistRep
   */
  change?: WhitelistAccessListRep;
  /**
   *
   * @type {WhitelistAccessListRep}
   * @memberof WhitelistRep
   */
  get?: WhitelistAccessListRep;
  /**
   *
   * @type {WhitelistAccessListRep}
   * @memberof WhitelistRep
   */
  copy?: WhitelistAccessListRep;
  /**
   *
   * @type {WhitelistAccessListRep}
   * @memberof WhitelistRep
   */
  delete?: WhitelistAccessListRep;
  /**
   *
   * @type {WhitelistAccessListRep}
   * @memberof WhitelistRep
   */
  print?: WhitelistAccessListRep;
}

/**
 * InternationalProductionFacilityApi - axios parameter creator
 * @export
 */
export const InternationalProductionFacilityApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *  This service retrieves the responsible international production facility
     * @summary retrieve production facility by country or region
     * @param {string} [region]
     * @param {string} [country]
     * @param {string} [inductionDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductionFacility: async (
      region?: string,
      country?: string,
      inductionDate?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/masterdata/production-facility`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      console.log(region, country, inductionDate);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (region !== undefined) {
        localVarQueryParameter['region'] = region;
      }
      if (inductionDate !== undefined) {
        localVarQueryParameter['validityPoint'] = inductionDate;
      }
      if (country !== undefined) {
        localVarQueryParameter['country'] = country;
      }

      console.log(localVarQueryParameter);
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * InternationalProductionFacilityApi - functional programming interface
 * @export
 */
export const InternationalProductionFacilityApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InternationalProductionFacilityApiAxiosParamCreator(configuration);
  return {
    /**
     *  This service retrieves the responsible international production facility
     * @summary retrieve production facility by country or region
     * @param {string} [region]
     * @param {string} [country]
     * @param {string} [inductionDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductionFacility(
      region?: string,
      country?: string,
      inductionDate?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductionFacilityTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductionFacility(region, country, inductionDate, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * InternationalProductionFacilityApi - factory interface
 * @export
 */
export const InternationalProductionFacilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = InternationalProductionFacilityApiFp(configuration);
  return {
    /**
     *  This service retrieves the responsible international production facility
     * @summary retrieve production facility by country or region
     * @param {string} [region]
     * @param {string} [country]
     * @param {string} [inductionDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductionFacility(region?: string, country?: string, inductionDate?: string, options?: any): AxiosPromise<Array<ProductionFacilityTO>> {
      return localVarFp.getProductionFacility(region, country, inductionDate, options).then((request) => request(axios, basePath));
    }
  };
};

/**
 * InternationalProductionFacilityApi - object-oriented interface
 * @export
 * @class InternationalProductionFacilityApi
 * @extends {BaseAPI}
 */
export class InternationalProductionFacilityApi extends BaseAPI {
  /**
   *  This service retrieves the responsible international production facility
   * @summary retrieve production facility by country or region
   * @param {string} [region]
   * @param {string} [country]
   * @param {string} [inductionDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternationalProductionFacilityApi
   */
  public getProductionFacility(region?: string, country?: string, inductionDate?: string, options?: AxiosRequestConfig) {
    return InternationalProductionFacilityApiFp(this.configuration)
      .getProductionFacility(region, country, inductionDate, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * JournalApi - axios parameter creator
 * @export
 */
export const JournalApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *  This service retrieves journal info concerning payment and customersgiven a validregistration number.
     * @summary fetch for journal customer related data
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJournalPaymentData: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getJournalPaymentData', 'id', id);
      const localVarPath = `/journals/search/registration/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *  This service retrieves journal registration number and title.
     * @summary check if journal exists
     * @param {string} query zkz or title.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJournals: async (query: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'query' is not null or undefined
      assertParamExists('getJournals', 'query', query);
      const localVarPath = `/journals/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * JournalApi - functional programming interface
 * @export
 */
export const JournalApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = JournalApiAxiosParamCreator(configuration);
  return {
    /**
     *  This service retrieves journal info concerning payment and customersgiven a validregistration number.
     * @summary fetch for journal customer related data
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getJournalPaymentData(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JournalPaymentInfoRep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getJournalPaymentData(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *  This service retrieves journal registration number and title.
     * @summary check if journal exists
     * @param {string} query zkz or title.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getJournals(
      query: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JournalRep>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getJournals(query, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * JournalApi - factory interface
 * @export
 */
export const JournalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = JournalApiFp(configuration);
  return {
    /**
     *  This service retrieves journal info concerning payment and customersgiven a validregistration number.
     * @summary fetch for journal customer related data
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJournalPaymentData(id: string, options?: any): AxiosPromise<JournalPaymentInfoRep> {
      return localVarFp.getJournalPaymentData(id, options).then((request) => request(axios, basePath));
    },
    /**
     *  This service retrieves journal registration number and title.
     * @summary check if journal exists
     * @param {string} query zkz or title.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJournals(query: string, options?: any): AxiosPromise<Array<JournalRep>> {
      return localVarFp.getJournals(query, options).then((request) => request(axios, basePath));
    }
  };
};

/**
 * JournalApi - object-oriented interface
 * @export
 * @class JournalApi
 * @extends {BaseAPI}
 */
export class JournalApi extends BaseAPI {
  /**
   *  This service retrieves journal info concerning payment and customersgiven a validregistration number.
   * @summary fetch for journal customer related data
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JournalApi
   */
  public getJournalPaymentData(id: string, options?: AxiosRequestConfig) {
    return JournalApiFp(this.configuration)
      .getJournalPaymentData(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *  This service retrieves journal registration number and title.
   * @summary check if journal exists
   * @param {string} query zkz or title.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JournalApi
   */
  public getJournals(query: string, options?: AxiosRequestConfig) {
    return JournalApiFp(this.configuration)
      .getJournals(query, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * MasterdataResourceApi - axios parameter creator
 * @export
 */
export const MasterdataResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} productNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClusterCombinations: async (productNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'productNumber' is not null or undefined
      assertParamExists('getClusterCombinations', 'productNumber', productNumber);
      const localVarPath = `/masterdata/products/{productNumber}/clusterCombinations`.replace(
        `{${'productNumber'}}`,
        encodeURIComponent(String(productNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} customerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomer: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists('getCustomer', 'customerId', customerId);
      const localVarPath = `/masterdata/customers/{customerId}`.replace(`{${'customerId'}}`, encodeURIComponent(String(customerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} customerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerName: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists('getCustomerName', 'customerId', customerId);
      const localVarPath = `/masterdata/customers/{customerId}/name`.replace(`{${'customerId'}}`, encodeURIComponent(String(customerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} productNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDenotingPrepaymentsForProduct: async (productNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'productNumber' is not null or undefined
      assertParamExists('getDenotingPrepaymentsForProduct', 'productNumber', productNumber);
      const localVarPath = `/masterdata/products/{productNumber}/denotingPrepayments`.replace(
        `{${'productNumber'}}`,
        encodeURIComponent(String(productNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *  This service retrieves dependent products for a product group.
     * @summary retrieve dependent products
     * @param {string} productGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDependentProducts: async (productGroup: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'productGroup' is not null or undefined
      assertParamExists('getDependentProducts', 'productGroup', productGroup);
      const localVarPath = `/masterdata/productGroup/{productGroup}/dependentProducts`.replace(
        `{${'productGroup'}}`,
        encodeURIComponent(String(productGroup))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} newspaperNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNewspaperTitle: async (newspaperNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'newspaperNumber' is not null or undefined
      assertParamExists('getNewspaperTitle', 'newspaperNumber', newspaperNumber);
      const localVarPath = `/masterdata/newspapers/{newspaperNumber}`.replace(`{${'newspaperNumber'}}`, encodeURIComponent(String(newspaperNumber)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} zkz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPressdistributionBaseProducts: async (zkz: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'zkz' is not null or undefined
      assertParamExists('getPressdistributionBaseProducts', 'zkz', zkz);
      const localVarPath = `/masterdata/pressdistribution/baseProducts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (zkz !== undefined) {
        localVarQueryParameter['zkz'] = zkz;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} zkz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPressdistributionSupplementProducts: async (zkz: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'zkz' is not null or undefined
      assertParamExists('getPressdistributionSupplementProducts', 'zkz', zkz);
      const localVarPath = `/masterdata/pressdistribution/supplementProducts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (zkz !== undefined) {
        localVarQueryParameter['zkz'] = zkz;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProcesses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/masterdata/procedures`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductionFacilities: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/masterdata/production-facilities`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} [validityPoint]
     * @param {string} [group]
     * @param {string} [serviceType]
     * @param {boolean} [premiumAddress]
     * @param {boolean} [isPartialServiceCapable]
     * @param {string} [hierarchyDestination]
     * @param {string} [hierarchyProductGroup]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProducts: async (
      validityPoint?: string,
      group?: string,
      serviceType?: string,
      premiumAddress?: boolean,
      isPartialServiceCapable?: boolean,
      hierarchyDestination?: string,
      hierarchyProductGroup?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/masterdata/products`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (validityPoint !== undefined) {
        localVarQueryParameter['validityPoint'] = validityPoint;
      }

      if (group !== undefined) {
        localVarQueryParameter['group'] = group;
      }

      if (serviceType !== undefined) {
        localVarQueryParameter['serviceType'] = serviceType;
      }

      if (premiumAddress !== undefined) {
        localVarQueryParameter['premiumAddress'] = premiumAddress;
      }

      if (isPartialServiceCapable !== undefined) {
        localVarQueryParameter['isPartialServiceCapable'] = isPartialServiceCapable;
      }

      if (hierarchyDestination !== undefined) {
        localVarQueryParameter['hierarchyDestination'] = hierarchyDestination;
      }

      if (hierarchyProductGroup !== undefined) {
        localVarQueryParameter['hierarchyProductGroup'] = hierarchyProductGroup;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserRights: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/masterdata/user-rights`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * MasterdataResourceApi - functional programming interface
 * @export
 */
export const MasterdataResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MasterdataResourceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} productNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClusterCombinations(
      productNumber: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AllowedProductClusterCombinationTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClusterCombinations(productNumber, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} customerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCustomer(
      customerId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerInfoWithAddressTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomer(customerId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} customerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCustomerName(
      customerId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerNameTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerName(customerId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} productNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDenotingPrepaymentsForProduct(
      productNumber: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AllowedMethodsOfDenotingPrepaymentTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDenotingPrepaymentsForProduct(productNumber, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *  This service retrieves dependent products for a product group.
     * @summary retrieve dependent products
     * @param {string} productGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDependentProducts(
      productGroup: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDependentProducts(productGroup, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} newspaperNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNewspaperTitle(
      newspaperNumber: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PressTitleTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNewspaperTitle(newspaperNumber, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} zkz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPressdistributionBaseProducts(
      zkz: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrDBaseProductTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPressdistributionBaseProducts(zkz, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} zkz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPressdistributionSupplementProducts(
      zkz: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrDSupplementProductTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPressdistributionSupplementProducts(zkz, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProcesses(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProcedureTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProcesses(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductionFacilities(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductionFacilityTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductionFacilities(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} [validityPoint]
     * @param {string} [group]
     * @param {string} [serviceType]
     * @param {boolean} [premiumAddress]
     * @param {boolean} [isPartialServiceCapable]
     * @param {string} [hierarchyDestination]
     * @param {string} [hierarchyProductGroup]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProducts(
      validityPoint?: string,
      group?: string,
      serviceType?: string,
      premiumAddress?: boolean,
      isPartialServiceCapable?: boolean,
      hierarchyDestination?: string,
      hierarchyProductGroup?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProducts(
        validityPoint,
        group,
        serviceType,
        premiumAddress,
        isPartialServiceCapable,
        hierarchyDestination,
        hierarchyProductGroup,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserRights(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRights(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * MasterdataResourceApi - factory interface
 * @export
 */
export const MasterdataResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = MasterdataResourceApiFp(configuration);
  return {
    /**
     *
     * @param {string} productNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClusterCombinations(productNumber: string, options?: any): AxiosPromise<Array<AllowedProductClusterCombinationTO>> {
      return localVarFp.getClusterCombinations(productNumber, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} customerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomer(customerId: string, options?: any): AxiosPromise<CustomerInfoWithAddressTO> {
      return localVarFp.getCustomer(customerId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} customerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomerName(customerId: string, options?: any): AxiosPromise<CustomerNameTO> {
      return localVarFp.getCustomerName(customerId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} productNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDenotingPrepaymentsForProduct(productNumber: string, options?: any): AxiosPromise<Array<AllowedMethodsOfDenotingPrepaymentTO>> {
      return localVarFp.getDenotingPrepaymentsForProduct(productNumber, options).then((request) => request(axios, basePath));
    },
    /**
     *  This service retrieves dependent products for a product group.
     * @summary retrieve dependent products
     * @param {string} productGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDependentProducts(productGroup: string, options?: any): AxiosPromise<Array<ProductTO>> {
      return localVarFp.getDependentProducts(productGroup, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} newspaperNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNewspaperTitle(newspaperNumber: string, options?: any): AxiosPromise<PressTitleTO> {
      return localVarFp.getNewspaperTitle(newspaperNumber, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} zkz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPressdistributionBaseProducts(zkz: string, options?: any): AxiosPromise<Array<PrDBaseProductTO>> {
      return localVarFp.getPressdistributionBaseProducts(zkz, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} zkz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPressdistributionSupplementProducts(zkz: string, options?: any): AxiosPromise<Array<PrDSupplementProductTO>> {
      return localVarFp.getPressdistributionSupplementProducts(zkz, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProcesses(options?: any): AxiosPromise<Array<ProcedureTO>> {
      return localVarFp.getProcesses(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductionFacilities(options?: any): AxiosPromise<Array<ProductionFacilityTO>> {
      return localVarFp.getProductionFacilities(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [validityPoint]
     * @param {string} [group]
     * @param {string} [serviceType]
     * @param {boolean} [premiumAddress]
     * @param {boolean} [isPartialServiceCapable]
     * @param {string} [hierarchyDestination]
     * @param {string} [hierarchyProductGroup]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProducts(
      validityPoint?: string,
      group?: string,
      serviceType?: string,
      premiumAddress?: boolean,
      isPartialServiceCapable?: boolean,
      hierarchyDestination?: string,
      hierarchyProductGroup?: string,
      options?: any
    ): AxiosPromise<Array<ProductTO>> {
      return localVarFp
        .getProducts(validityPoint, group, serviceType, premiumAddress, isPartialServiceCapable, hierarchyDestination, hierarchyProductGroup, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserRights(options?: any): AxiosPromise<Array<string>> {
      return localVarFp.getUserRights(options).then((request) => request(axios, basePath));
    }
  };
};

/**
 * MasterdataResourceApi - object-oriented interface
 * @export
 * @class MasterdataResourceApi
 * @extends {BaseAPI}
 */
export class MasterdataResourceApi extends BaseAPI {
  /**
   *
   * @param {string} productNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MasterdataResourceApi
   */
  public getClusterCombinations(productNumber: string, options?: AxiosRequestConfig) {
    return MasterdataResourceApiFp(this.configuration)
      .getClusterCombinations(productNumber, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} customerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MasterdataResourceApi
   */
  public getCustomer(customerId: string, options?: AxiosRequestConfig) {
    return MasterdataResourceApiFp(this.configuration)
      .getCustomer(customerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} customerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MasterdataResourceApi
   */
  public getCustomerName(customerId: string, options?: AxiosRequestConfig) {
    return MasterdataResourceApiFp(this.configuration)
      .getCustomerName(customerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} productNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MasterdataResourceApi
   */
  public getDenotingPrepaymentsForProduct(productNumber: string, options?: AxiosRequestConfig) {
    return MasterdataResourceApiFp(this.configuration)
      .getDenotingPrepaymentsForProduct(productNumber, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *  This service retrieves dependent products for a product group.
   * @summary retrieve dependent products
   * @param {string} productGroup
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MasterdataResourceApi
   */
  public getDependentProducts(productGroup: string, options?: AxiosRequestConfig) {
    return MasterdataResourceApiFp(this.configuration)
      .getDependentProducts(productGroup, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} newspaperNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MasterdataResourceApi
   */
  public getNewspaperTitle(newspaperNumber: string, options?: AxiosRequestConfig) {
    return MasterdataResourceApiFp(this.configuration)
      .getNewspaperTitle(newspaperNumber, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} zkz
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MasterdataResourceApi
   */
  public getPressdistributionBaseProducts(zkz: string, options?: AxiosRequestConfig) {
    return MasterdataResourceApiFp(this.configuration)
      .getPressdistributionBaseProducts(zkz, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} zkz
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MasterdataResourceApi
   */
  public getPressdistributionSupplementProducts(zkz: string, options?: AxiosRequestConfig) {
    return MasterdataResourceApiFp(this.configuration)
      .getPressdistributionSupplementProducts(zkz, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MasterdataResourceApi
   */
  public getProcesses(options?: AxiosRequestConfig) {
    return MasterdataResourceApiFp(this.configuration)
      .getProcesses(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MasterdataResourceApi
   */
  public getProductionFacilities(options?: AxiosRequestConfig) {
    return MasterdataResourceApiFp(this.configuration)
      .getProductionFacilities(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [validityPoint]
   * @param {string} [group]
   * @param {string} [serviceType]
   * @param {boolean} [premiumAddress]
   * @param {boolean} [isPartialServiceCapable]
   * @param {string} [hierarchyDestination]
   * @param {string} [hierarchyProductGroup]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MasterdataResourceApi
   */
  public getProducts(
    validityPoint?: string,
    group?: string,
    serviceType?: string,
    premiumAddress?: boolean,
    isPartialServiceCapable?: boolean,
    hierarchyDestination?: string,
    hierarchyProductGroup?: string,
    options?: AxiosRequestConfig
  ) {
    return MasterdataResourceApiFp(this.configuration)
      .getProducts(validityPoint, group, serviceType, premiumAddress, isPartialServiceCapable, hierarchyDestination, hierarchyProductGroup, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MasterdataResourceApi
   */
  public getUserRights(options?: AxiosRequestConfig) {
    return MasterdataResourceApiFp(this.configuration)
      .getUserRights(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * NextMagazineSequenceNumberApi - axios parameter creator
 * @export
 */
export const NextMagazineSequenceNumberApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * This service provides the query of the sequence number for an issue.
     * @summary Get next issue sequence number
     * @param {string} newspaperNumber
     * @param {string} postingIdentifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIssueSequenceNumber: async (newspaperNumber: string, postingIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'newspaperNumber' is not null or undefined
      assertParamExists('getIssueSequenceNumber', 'newspaperNumber', newspaperNumber);
      // verify required parameter 'postingIdentifier' is not null or undefined
      assertParamExists('getIssueSequenceNumber', 'postingIdentifier', postingIdentifier);
      const localVarPath = `/masterdata/issue-sequence-number/{newspaperNumber}/{postingIdentifier}`
        .replace(`{${'newspaperNumber'}}`, encodeURIComponent(String(newspaperNumber)))
        .replace(`{${'postingIdentifier'}}`, encodeURIComponent(String(postingIdentifier)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * NextMagazineSequenceNumberApi - functional programming interface
 * @export
 */
export const NextMagazineSequenceNumberApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NextMagazineSequenceNumberApiAxiosParamCreator(configuration);
  return {
    /**
     * This service provides the query of the sequence number for an issue.
     * @summary Get next issue sequence number
     * @param {string} newspaperNumber
     * @param {string} postingIdentifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIssueSequenceNumber(
      newspaperNumber: string,
      postingIdentifier: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssueTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIssueSequenceNumber(newspaperNumber, postingIdentifier, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * NextMagazineSequenceNumberApi - factory interface
 * @export
 */
export const NextMagazineSequenceNumberApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = NextMagazineSequenceNumberApiFp(configuration);
  return {
    /**
     * This service provides the query of the sequence number for an issue.
     * @summary Get next issue sequence number
     * @param {string} newspaperNumber
     * @param {string} postingIdentifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIssueSequenceNumber(newspaperNumber: string, postingIdentifier: string, options?: any): AxiosPromise<IssueTO> {
      return localVarFp.getIssueSequenceNumber(newspaperNumber, postingIdentifier, options).then((request) => request(axios, basePath));
    }
  };
};

/**
 * NextMagazineSequenceNumberApi - object-oriented interface
 * @export
 * @class NextMagazineSequenceNumberApi
 * @extends {BaseAPI}
 */
export class NextMagazineSequenceNumberApi extends BaseAPI {
  /**
   * This service provides the query of the sequence number for an issue.
   * @summary Get next issue sequence number
   * @param {string} newspaperNumber
   * @param {string} postingIdentifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NextMagazineSequenceNumberApi
   */
  public getIssueSequenceNumber(newspaperNumber: string, postingIdentifier: string, options?: AxiosRequestConfig) {
    return NextMagazineSequenceNumberApiFp(this.configuration)
      .getIssueSequenceNumber(newspaperNumber, postingIdentifier, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OpsApi - axios parameter creator
 * @export
 */
export const OpsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * retrieving latest commit from am portal backend application
     * @summary Git commit Version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVersion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/version`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Check if instance is up
     * @summary Healthcheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthcheck: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/healthcheck`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * OpsApi - functional programming interface
 * @export
 */
export const OpsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OpsApiAxiosParamCreator(configuration);
  return {
    /**
     * retrieving latest commit from am portal backend application
     * @summary Git commit Version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVersion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVersion(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Check if instance is up
     * @summary Healthcheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async healthcheck(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.healthcheck(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * OpsApi - factory interface
 * @export
 */
export const OpsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = OpsApiFp(configuration);
  return {
    /**
     * retrieving latest commit from am portal backend application
     * @summary Git commit Version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVersion(options?: any): AxiosPromise<VersionTO> {
      return localVarFp.getVersion(options).then((request) => request(axios, basePath));
    },
    /**
     * Check if instance is up
     * @summary Healthcheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthcheck(options?: any): AxiosPromise<void> {
      return localVarFp.healthcheck(options).then((request) => request(axios, basePath));
    }
  };
};

/**
 * OpsApi - object-oriented interface
 * @export
 * @class OpsApi
 * @extends {BaseAPI}
 */
export class OpsApi extends BaseAPI {
  /**
   * retrieving latest commit from am portal backend application
   * @summary Git commit Version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpsApi
   */
  public getVersion(options?: AxiosRequestConfig) {
    return OpsApiFp(this.configuration)
      .getVersion(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Check if instance is up
   * @summary Healthcheck
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpsApi
   */
  public healthcheck(options?: AxiosRequestConfig) {
    return OpsApiFp(this.configuration)
      .healthcheck(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OrderOperationsApi - axios parameter creator
 * @export
 */
export const OrderOperationsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * cancel order based on its ID
     * @summary cancel an order
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelOrder: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('cancelOrder', 'id', id);
      const localVarPath = `/orders/cancel/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Changing a collection order
     * @summary ChangeCollectionOrder
     * @param {CreateCollectionOrder} createCollectionOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeCollectionOrder: async (createCollectionOrder: CreateCollectionOrder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'createCollectionOrder' is not null or undefined
      assertParamExists('changeCollectionOrder', 'createCollectionOrder', createCollectionOrder);
      const localVarPath = `/orders/change/collection`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createCollectionOrder, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Changing a DialogPost order
     * @summary ChangeDialogPostOrder
     * @param {CreateDialogPostOrder} createDialogPostOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeDialogPostOrder: async (createDialogPostOrder: CreateDialogPostOrder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'createDialogPostOrder' is not null or undefined
      assertParamExists('changeDialogPostOrder', 'createDialogPostOrder', createDialogPostOrder);
      const localVarPath = `/orders/change/dialogpost`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createDialogPostOrder, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Changing a Postaktuell order
     * @summary ChangePostaktuellOrder
     * @param {CreatePostaktuellOrder} createPostaktuellOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePostaktuellOrder: async (createPostaktuellOrder: CreatePostaktuellOrder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'createDialogPostOrder' is not null or undefined
      assertParamExists('changePostaktuellOrder', 'createPostaktuellOrder', createPostaktuellOrder);
      const localVarPath = `/orders/change/postaktuell`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createPostaktuellOrder, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Changing a Letter order
     * @summary changeLetterOrder
     * @param {CreateLetterOrder} createLetterOrder
     * @param letterType the letter type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeLetterOrder: async (createLetterOrder: CreateLetterOrder, letterType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'createLetterOrder' is not null or undefined
      assertParamExists('changeLetterOrder', 'createLetterOrder', createLetterOrder);
      const localVarPath = [`/orders/change/`, letterType].join('');
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createLetterOrder, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Changing a International order
     * @summary changeInternationalOrder
     * @param {CreateInternationalOrder} changeOrder
     * @param letterType the letter type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeInternationalOrder: async (
      changeOrder: CreateInternationalOrder,
      letterType: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createLetterOrder' is not null or undefined
      assertParamExists('changeDialogpostInternationalOrder', 'CreateInternationalOrder', changeOrder);
      const localVarPath = [`/orders/change/`, letterType].join('');
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(changeOrder, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Changing a partial order
     * @summary ChangePartialOrder
     * @param {CreatePartialOrder} createPartialOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePartialOrder: async (createPartialOrder: CreatePartialOrder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'createPartialOrder' is not null or undefined
      assertParamExists('changePartialOrder', 'createPartialOrder', createPartialOrder);
      const localVarPath = `/orders/change/partial`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createPartialOrder, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Changing a PressDistribution order
     * @summary ChangePressOrder
     * @param {CreatePressDistributionOrder} createPressDistributionOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePressOrder: async (createPressDistributionOrder: CreatePressDistributionOrder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'createPressDistributionOrder' is not null or undefined
      assertParamExists('changePressOrder', 'createPressDistributionOrder', createPressDistributionOrder);
      const localVarPath = `/orders/change/pressdistribution`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createPressDistributionOrder, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Creating a Collection order
     * @summary CreateCollectionOrder
     * @param {CreateCollectionOrder} createCollectionOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCollectionOrder: async (createCollectionOrder: CreateCollectionOrder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'createCollectionOrder' is not null or undefined
      assertParamExists('createCollectionOrder', 'createCollectionOrder', createCollectionOrder);
      const localVarPath = `/orders/create/collection`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createCollectionOrder, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Creating a DialogPost  order
     * @summary createDialogPostOrder
     * @param {CreateDialogPostOrder} createDialogPostOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDialogPostOrder: async (createDialogPostOrder: CreateDialogPostOrder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'createDialogPostOrder' is not null or undefined
      assertParamExists('createDialogPostOrder', 'createDialogPostOrder', createDialogPostOrder);
      const localVarPath = `/orders/create/dialogpost`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createDialogPostOrder, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Creating a Letter order
     * @summary CreateLetterOrder
     * @param {CreateLetterOrder} createLetterOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLetterOrder: async (createLetterOrder: CreateLetterOrder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'createLetterOrder' is not null or undefined
      assertParamExists('createLetterOrder', 'createLetterOrder', createLetterOrder);
      const localVarPath = `/orders/create/frankit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createLetterOrder, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Creating a Partial order
     * @summary CreatePartialOrder
     * @param {CreatePartialOrder} createPartialOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPartialOrder: async (createPartialOrder: CreatePartialOrder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'createPartialOrder' is not null or undefined
      assertParamExists('createPartialOrder', 'createPartialOrder', createPartialOrder);
      const localVarPath = `/orders/create/partial`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createPartialOrder, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Creating a PressDistribution order
     * @summary CreatePressOrder
     * @param {CreatePressDistributionOrder} createPressDistributionOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPressOrder: async (createPressDistributionOrder: CreatePressDistributionOrder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'createPressDistributionOrder' is not null or undefined
      assertParamExists('createPressOrder', 'createPressDistributionOrder', createPressDistributionOrder);
      const localVarPath = `/orders/create/pressdistribution`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createPressDistributionOrder, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     * Creating a Dialogpost International order
     * @summary CreateInternationalOrder
     * @param {CreateInternationalOrder} CreateInternationalOrder
     * @param letterTypeURL the letter type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    CreateInternationalOrder: async (
      CreateInternationalOrder: CreateInternationalOrder,
      letterTypeURL: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'CreateInternationalOrder' is not null or undefined
      assertParamExists('CreateInternationalOrder', 'CreateInternationalOrder', CreateInternationalOrder);
      const localVarPath = [`/orders/create/`, letterTypeURL].join('');
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(CreateInternationalOrder, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     * Splitting order
     * @summary splitOrder
     * @param {SplitOrderProps} splitOrderProps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    splitOrder: async (splitOrderProps: SplitOrderProps, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'createPressDistributionOrder' is not null or undefined
      assertParamExists('splitOrder', 'splitOrderProps', splitOrderProps);
      const localVarPath = (splitOrderProps.bulkMailAcceptanceOffice ? `/orders/split/{orderID}` : `/orders/part/{orderID}`).replace(
        `{${'orderID'}}`,
        encodeURIComponent(String(splitOrderProps.orderID))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(splitOrderProps, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Moving an Pallet
     * @summary movePallet
     * @param {MovePalletProps} movePalletProps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    movePallet: async (movePalletProps: MovePalletProps, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'createPressDistributionOrder' is not null or undefined
      assertParamExists('movePallet', 'movePalletProps', movePalletProps);
      const localVarPath = `/orders/palletassignment`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(movePalletProps, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Merging of order
     * @summary MergeOrder
     * @param {MergeOrderRep} MergeOrderRep
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mergeOrder: async (mergeOrderProps: MergeOrderRep, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      assertParamExists('mergeOrder', 'mergeOrderProps', mergeOrderProps);
      const localVarPath = `orders/merge/{orderId}`.replace(`{${'orderId'}}`, encodeURIComponent(String(mergeOrderProps.orderId)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const mergeOrderPropsWithoutOrderNumber = { ...mergeOrderProps, orderId: undefined };

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(mergeOrderPropsWithoutOrderNumber, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     * Order Versions
     * @summary Order Version
     * @param {orderId} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderVersion: async (orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // assertParamExists('mergeOrder', 'mergeOrderProps', mergeOrderProps);
      const localVarPath = `orders/versions/{orderId}`.replace(`{${'orderId'}}`, encodeURIComponent(String(orderId)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      // const mergeOrderPropsWithoutOrderNumber = { ...mergeOrderProps, orderId: undefined };

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },

    /**
     * International Products
     * @summary Order Version
     * @param {productGroup} productGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInternationalProducts: async (pG: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // assertParamExists('mergeOrder', 'mergeOrderProps', mergeOrderProps);
      const localVarPath = `masterdata/international-products?productGroup={pG}`.replace(`{${'pG'}}`, encodeURIComponent(String(pG)));
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      // const mergeOrderPropsWithoutOrderNumber = { ...mergeOrderProps, orderId: undefined };

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get an order
     * @summary retrieve order based on its ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrder: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/whitelist`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get an order
     * @summary retrieve order based on its ID
     * @param {string} orderId
     * @param {boolean} [includeDst]
     * @param {boolean} [includePrice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrder1: async (orderId: string, includeDst?: boolean, includePrice?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists('getOrder1', 'orderId', orderId);
      const localVarPath = `/orders/{orderId}`.replace(`{${'orderId'}}`, encodeURIComponent(String(orderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (includeDst !== undefined) {
        localVarQueryParameter['includeDst'] = includeDst;
      }

      if (includePrice !== undefined) {
        localVarQueryParameter['includePrice'] = includePrice;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Preparing an order for collection
     * @summary PrepareForCollection
     * @param {string} orderId
     * @param {string} inductionDate
     * @param {string} productionPlantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    prepareForCollection: async (
      orderId: string,
      inductionDate: string,
      productionPlantId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists('prepareForCollection', 'orderId', orderId);
      // verify required parameter 'inductionDate' is not null or undefined
      assertParamExists('prepareForCollection', 'inductionDate', inductionDate);
      // verify required parameter 'productionPlantId' is not null or undefined
      assertParamExists('prepareForCollection', 'productionPlantId', productionPlantId);
      const localVarPath = `/orders/prepare-for-collection/{orderId}`.replace(`{${'orderId'}}`, encodeURIComponent(String(orderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (inductionDate !== undefined) {
        localVarQueryParameter['inductionDate'] = inductionDate;
      }

      if (productionPlantId !== undefined) {
        localVarQueryParameter['productionPlantId'] = productionPlantId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Seeking for an order
     * @summary SeekOrder
     * @param {SearchOrder} searchOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchOrders: async (searchOrder: SearchOrder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'searchOrder' is not null or undefined
      assertParamExists('searchOrders', 'searchOrder', searchOrder);
      const localVarPath = `/orders/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(searchOrder, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * OrderOperationsApi - functional programming interface
 * @export
 */
export const OrderOperationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OrderOperationsApiAxiosParamCreator(configuration);
  return {
    /**
     * cancel order based on its ID
     * @summary cancel an order
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelOrder(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CancelOrderResultRep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cancelOrder(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Changing a collection order
     * @summary ChangeCollectionOrder
     * @param {CreateCollectionOrder} createCollectionOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changeCollectionOrder(
      createCollectionOrder: CreateCollectionOrder,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResultRep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changeCollectionOrder(createCollectionOrder, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Changing a DialogPost order
     * @summary ChangeDialogPostOrder
     * @param {CreateDialogPostOrder} createDialogPostOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changeDialogPostOrder(
      createDialogPostOrder: CreateDialogPostOrder,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResultRep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changeDialogPostOrder(createDialogPostOrder, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Changing a Postaktuell order
     * @summary ChangePostaktuellOrder
     * @param {CreatePostaktuellOrder} createPostaktuellOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changePostaktuellOrder(
      createPostaktuellOrder: CreatePostaktuellOrder,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResultRep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changePostaktuellOrder(createPostaktuellOrder, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Changing a Letter order
     * @summary changeLetterOrder
     * @param {CreateLetterOrder} createLetterOrder
     * @param letterType the letter type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changeLetterOrder(
      createLetterOrder: CreateLetterOrder,
      letterType: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResultRep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changeLetterOrder(createLetterOrder, letterType, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Changing a International order
     * @summary changeInternationalOrder
     * @param {CreateInternationalOrder} changeOrder
     * @param letterType the letter type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changeInternationalOrder(
      changeOrder: CreateLetterOrder,
      letterType: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResultRep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changeInternationalOrder(changeOrder, letterType, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Changing a partial order
     * @summary ChangePartialOrder
     * @param {CreatePartialOrder} createPartialOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changePartialOrder(
      createPartialOrder: CreatePartialOrder,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResultRep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changePartialOrder(createPartialOrder, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Changing a PressDistribution order
     * @summary ChangePressOrder
     * @param {CreatePressDistributionOrder} createPressDistributionOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changePressOrder(
      createPressDistributionOrder: CreatePressDistributionOrder,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResultRep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changePressOrder(createPressDistributionOrder, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Creating a Collection order
     * @summary CreateCollectionOrder
     * @param {CreateCollectionOrder} createCollectionOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCollectionOrder(
      createCollectionOrder: CreateCollectionOrder,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResultRep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCollectionOrder(createCollectionOrder, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Creating a DialogPost  order
     * @summary createDialogPostOrder
     * @param {CreateDialogPostOrder} createDialogPostOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDialogPostOrder(
      createDialogPostOrder: CreateDialogPostOrder,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResultRep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createDialogPostOrder(createDialogPostOrder, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Creating a Letter order
     * @summary CreateLetterOrder
     * @param {CreateLetterOrder} createLetterOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createLetterOrder(
      createLetterOrder: CreateLetterOrder,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResultRep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createLetterOrder(createLetterOrder, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Creating a Partial order
     * @summary CreatePartialOrder
     * @param {CreatePartialOrder} createPartialOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPartialOrder(
      createPartialOrder: CreatePartialOrder,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResultRep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createPartialOrder(createPartialOrder, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Creating a PressDistribution order
     * @summary CreatePressOrder
     * @param {CreatePressDistributionOrder} createPressDistributionOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPressOrder(
      createPressDistributionOrder: CreatePressDistributionOrder,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResultRep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createPressOrder(createPressDistributionOrder, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Creating a Dialogpost International order
     * @summary CreateInternationalOrder
     * @param {CreateInternationalOrder} CreateInternationalOrder
     * @param letterTypeURL the letter type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async CreateInternationalOrder(
      CreateInternationalOrder: CreateInternationalOrder,
      letterTypeURL: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResultRep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.CreateInternationalOrder(CreateInternationalOrder, letterTypeURL, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Splitting an order
     * @summary splitOrder
     * @param {SplitOrderProps} splitOrderProps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async splitOrder(
      splitOrderProps: SplitOrderProps,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SplitOrderResultRepResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.splitOrder(splitOrderProps, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Moving an Pallet
     * @summary movePallet
     * @param {MovePalletProps} movePalletProps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async movePallet(
      movePalletProps: MovePalletProps,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SplitOrderResultRepResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.movePallet(movePalletProps, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Merging of orders
     * @summary mergeOrder
     * @param {MergeOrderRep} MergeOrderRep
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mergeOrder(
      mergeOrderProps: MergeOrderRep,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MergeOrderResultRepResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mergeOrder(mergeOrderProps, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Order versions
     * @summary mergeOrder
     * @param {orderId} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrderVersion(orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderVersion(orderId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * International Products
     * @summary mergeOrder
     * @param {productGroup} productGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInternationalProducts(
      productGroup: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInternationalProducts(productGroup, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get an order
     * @summary retrieve order based on its ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrder(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WhitelistRep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrder(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get an order
     * @summary retrieve order based on its ID
     * @param {string} orderId
     * @param {boolean} [includeDst]
     * @param {boolean} [includePrice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrder1(
      orderId: string,
      includeDst?: boolean,
      includePrice?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrderRep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrder1(orderId, includeDst, includePrice, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Preparing an order for collection
     * @summary PrepareForCollection
     * @param {string} orderId
     * @param {string} inductionDate
     * @param {string} productionPlantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async prepareForCollection(
      orderId: string,
      inductionDate: string,
      productionPlantId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResultRep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.prepareForCollection(orderId, inductionDate, productionPlantId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Seeking for an order
     * @summary SeekOrder
     * @param {SearchOrder} searchOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchOrders(
      searchOrder: SearchOrder,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderSearchResultRep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchOrders(searchOrder, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * OrderOperationsApi - factory interface
 * @export
 */
export const OrderOperationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = OrderOperationsApiFp(configuration);
  return {
    /**
     * cancel order based on its ID
     * @summary cancel an order
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelOrder(id: string, options?: any): AxiosPromise<CancelOrderResultRep> {
      return localVarFp.cancelOrder(id, options).then((request) => request(axios, basePath));
    },
    /**
     * Changing a collection order
     * @summary ChangeCollectionOrder
     * @param {CreateCollectionOrder} createCollectionOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeCollectionOrder(createCollectionOrder: CreateCollectionOrder, options?: any): AxiosPromise<OrderResultRep> {
      return localVarFp.changeCollectionOrder(createCollectionOrder, options).then((request) => request(axios, basePath));
    },
    /**
     * Changing a DialogPost order
     * @summary ChangeDialogPostOrder
     * @param {CreateDialogPostOrder} createDialogPostOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeDialogPostOrder(createDialogPostOrder: CreateDialogPostOrder, options?: any): AxiosPromise<OrderResultRep> {
      return localVarFp.changeDialogPostOrder(createDialogPostOrder, options).then((request) => request(axios, basePath));
    },
    /**
     * Changing a Postaktuell order
     * @summary ChangeDialogPostOrder
     * @param {CreatePostaktuellOrder} createPostaktuellOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePostaktuellOrder(createPostaktuellOrder: CreatePostaktuellOrder, options?: any): AxiosPromise<OrderResultRep> {
      return localVarFp.changePostaktuellOrder(createPostaktuellOrder, options).then((request) => request(axios, basePath));
    },
    /**
     * Changing a Letter order
     * @summary changeLetterOrder
     * @param {CreateLetterOrder} createLetterOrder
     * @param letterType the letter type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeLetterOrder(createLetterOrder: CreateLetterOrder, letterType: string, options?: any): AxiosPromise<OrderResultRep> {
      return localVarFp.changeLetterOrder(createLetterOrder, letterType, options).then((request) => request(axios, basePath));
    },
    /**
     * Changing a International order
     * @summary changeInternationalOrder
     * @param {CreateInternationalOrder} changeOrder
     * @param letterType the letter type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeInternationalOrder(changeOrder: CreateInternationalOrder, letterType: string, options?: any): AxiosPromise<OrderResultRep> {
      return localVarFp.changeInternationalOrder(changeOrder, letterType, options).then((request) => request(axios, basePath));
    },
    /**
     * Changing a partial order
     * @summary ChangePartialOrder
     * @param {CreatePartialOrder} createPartialOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePartialOrder(createPartialOrder: CreatePartialOrder, options?: any): AxiosPromise<OrderResultRep> {
      return localVarFp.changePartialOrder(createPartialOrder, options).then((request) => request(axios, basePath));
    },
    /**
     * Changing a PressDistribution order
     * @summary ChangePressOrder
     * @param {CreatePressDistributionOrder} createPressDistributionOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePressOrder(createPressDistributionOrder: CreatePressDistributionOrder, options?: any): AxiosPromise<OrderResultRep> {
      return localVarFp.changePressOrder(createPressDistributionOrder, options).then((request) => request(axios, basePath));
    },
    /**
     * Creating a Collection order
     * @summary CreateCollectionOrder
     * @param {CreateCollectionOrder} createCollectionOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCollectionOrder(createCollectionOrder: CreateCollectionOrder, options?: any): AxiosPromise<OrderResultRep> {
      return localVarFp.createCollectionOrder(createCollectionOrder, options).then((request) => request(axios, basePath));
    },
    /**
     * Creating a DialogPost  order
     * @summary createDialogPostOrder
     * @param {CreateDialogPostOrder} createDialogPostOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDialogPostOrder(createDialogPostOrder: CreateDialogPostOrder, options?: any): AxiosPromise<OrderResultRep> {
      return localVarFp.createDialogPostOrder(createDialogPostOrder, options).then((request) => request(axios, basePath));
    },
    /**
     * Creating a Letter order
     * @summary CreateLetterOrder
     * @param {CreateLetterOrder} createLetterOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createLetterOrder(createLetterOrder: CreateLetterOrder, options?: any): AxiosPromise<OrderResultRep> {
      return localVarFp.createLetterOrder(createLetterOrder, options).then((request) => request(axios, basePath));
    },
    /**
     * Creating a Partial order
     * @summary CreatePartialOrder
     * @param {CreatePartialOrder} createPartialOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPartialOrder(createPartialOrder: CreatePartialOrder, options?: any): AxiosPromise<OrderResultRep> {
      return localVarFp.createPartialOrder(createPartialOrder, options).then((request) => request(axios, basePath));
    },
    /**
     * Creating a PressDistribution order
     * @summary CreatePressOrder
     * @param {CreatePressDistributionOrder} createPressDistributionOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPressOrder(createPressDistributionOrder: CreatePressDistributionOrder, options?: any): AxiosPromise<OrderResultRep> {
      return localVarFp.createPressOrder(createPressDistributionOrder, options).then((request) => request(axios, basePath));
    },
    /**
     * Creating a Dialogpost International order
     * @summary CreateInternationalOrder
     * @param {CreateInternationalOrder} CreateInternationalOrder
     * @param letterTypeURL the letter type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInternationalOrder(CreateInternationalOrder: CreateInternationalOrder, letterTypeURL: string, options?: any): AxiosPromise<OrderResultRep> {
      return localVarFp.CreateInternationalOrder(CreateInternationalOrder, letterTypeURL, options).then((request) => request(axios, basePath));
    },
    /**
     * Splitting an order
     * @summary splitOrder
     * @param {SplitOrderProps} splitOrderProps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    splitOrder(splitOrderProps: SplitOrderProps, options?: any): AxiosPromise<SplitOrderResultRepResponse> {
      return localVarFp.splitOrder(splitOrderProps, options).then((request) => request(axios, basePath));
    },
    /**
     * Moving an pallet
     * @summary movePallet
     * @param {MovePalletProps} movePalletProps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    movePallet(movePalletProps: MovePalletProps, options?: any): AxiosPromise<SplitOrderResultRepResponse> {
      return localVarFp.movePallet(movePalletProps, options).then((request) => request(axios, basePath));
    },
    /**
     * Merging of orders
     * @summary MergeOrder
     * @param {MergeOrderRep} MergeOrderRep
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mergeOrder(mergeOrderProps: MergeOrderRep, options?: any): AxiosPromise<MergeOrderResultRepResponse> {
      return localVarFp.mergeOrder(mergeOrderProps, options).then((request) => request(axios, basePath));
    },
    /**
     * Order versions
     * @summary Order versions
     * @param {orderId} orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrderVersion(orderId: string, options?: AxiosRequestConfig): AxiosPromise {
      return localVarFp.getOrderVersion(orderId).then((request) => request(axios, basePath));
    },
    /**
     * International products
     * @summary Order versions
     * @param {productGroup} productGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInternationalProducts(productGroup: string, options?: AxiosRequestConfig): AxiosPromise {
      return localVarFp.getInternationalProducts(productGroup).then((request) => request(axios, basePath));
    },
    /**
     * Get an order
     * @summary retrieve order based on its ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrder(options?: any): AxiosPromise<WhitelistRep> {
      return localVarFp.getOrder(options).then((request) => request(axios, basePath));
    },
    /**
     * Get an order
     * @summary retrieve order based on its ID
     * @param {string} orderId
     * @param {boolean} [includeDst]
     * @param {boolean} [includePrice]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrder1(orderId: string, includeDst?: boolean, includePrice?: boolean, options?: any): AxiosPromise<GetOrderRep> {
      return localVarFp.getOrder1(orderId, includeDst, includePrice, options).then((request) => request(axios, basePath));
    },
    /**
     * Preparing an order for collection
     * @summary PrepareForCollection
     * @param {string} orderId
     * @param {string} inductionDate
     * @param {string} productionPlantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    prepareForCollection(orderId: string, inductionDate: string, productionPlantId: string, options?: any): AxiosPromise<OrderResultRep> {
      return localVarFp.prepareForCollection(orderId, inductionDate, productionPlantId, options).then((request) => request(axios, basePath));
    },
    /**
     * Seeking for an order
     * @summary SeekOrder
     * @param {SearchOrder} searchOrder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchOrders(searchOrder: SearchOrder, options?: any): AxiosPromise<OrderSearchResultRep> {
      return localVarFp.searchOrders(searchOrder, options).then((request) => request(axios, basePath));
    }
  };
};

/**
 * OrderOperationsApi - object-oriented interface
 * @export
 * @class OrderOperationsApi
 * @extends {BaseAPI}
 */
export class OrderOperationsApi extends BaseAPI {
  /**
   * cancel order based on its ID
   * @summary cancel an order
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderOperationsApi
   */
  public cancelOrder(id: string, options?: AxiosRequestConfig) {
    return OrderOperationsApiFp(this.configuration)
      .cancelOrder(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Changing a collection order
   * @summary ChangeCollectionOrder
   * @param {CreateCollectionOrder} createCollectionOrder
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderOperationsApi
   */
  public changeCollectionOrder(createCollectionOrder: CreateCollectionOrder, options?: AxiosRequestConfig) {
    return OrderOperationsApiFp(this.configuration)
      .changeCollectionOrder(createCollectionOrder, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Changing a DialogPost order
   * @summary ChangeDialogPostOrder
   * @param {CreateDialogPostOrder} createDialogPostOrder
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderOperationsApi
   */
  public changeDialogPostOrder(createDialogPostOrder: CreateDialogPostOrder, options?: AxiosRequestConfig) {
    return OrderOperationsApiFp(this.configuration)
      .changeDialogPostOrder(createDialogPostOrder, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Changing a Letter order
   * @summary changeLetterOrder
   * @param {CreateLetterOrder} createLetterOrder
   * @param letterType the type of letter.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderOperationsApi
   */
  public changeLetterOrder(createLetterOrder: CreateLetterOrder, letterType: string, options?: AxiosRequestConfig) {
    return OrderOperationsApiFp(this.configuration)
      .changeLetterOrder(createLetterOrder, letterType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Changing a partial order
   * @summary ChangePartialOrder
   * @param {CreatePartialOrder} createPartialOrder
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderOperationsApi
   */
  public changePartialOrder(createPartialOrder: CreatePartialOrder, options?: AxiosRequestConfig) {
    return OrderOperationsApiFp(this.configuration)
      .changePartialOrder(createPartialOrder, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Changing a PressDistribution order
   * @summary ChangePressOrder
   * @param {CreatePressDistributionOrder} createPressDistributionOrder
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderOperationsApi
   */
  public changePressOrder(createPressDistributionOrder: CreatePressDistributionOrder, options?: AxiosRequestConfig) {
    return OrderOperationsApiFp(this.configuration)
      .changePressOrder(createPressDistributionOrder, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Creating a Collection order
   * @summary CreateCollectionOrder
   * @param {CreateCollectionOrder} createCollectionOrder
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderOperationsApi
   */
  public createCollectionOrder(createCollectionOrder: CreateCollectionOrder, options?: AxiosRequestConfig) {
    return OrderOperationsApiFp(this.configuration)
      .createCollectionOrder(createCollectionOrder, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Creating a DialogPost  order
   * @summary createDialogPostOrder
   * @param {CreateDialogPostOrder} createDialogPostOrder
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderOperationsApi
   */
  public createDialogPostOrder(createDialogPostOrder: CreateDialogPostOrder, options?: AxiosRequestConfig) {
    return OrderOperationsApiFp(this.configuration)
      .createDialogPostOrder(createDialogPostOrder, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Creating a Letter order
   * @summary CreateLetterOrder
   * @param {CreateLetterOrder} createLetterOrder
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderOperationsApi
   */
  public createLetterOrder(createLetterOrder: CreateLetterOrder, options?: AxiosRequestConfig) {
    return OrderOperationsApiFp(this.configuration)
      .createLetterOrder(createLetterOrder, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Creating a Partial order
   * @summary CreatePartialOrder
   * @param {CreatePartialOrder} createPartialOrder
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderOperationsApi
   */
  public createPartialOrder(createPartialOrder: CreatePartialOrder, options?: AxiosRequestConfig) {
    return OrderOperationsApiFp(this.configuration)
      .createPartialOrder(createPartialOrder, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Creating a PressDistribution order
   * @summary CreatePressOrder
   * @param {CreatePressDistributionOrder} createPressDistributionOrder
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderOperationsApi
   */
  public createPressOrder(createPressDistributionOrder: CreatePressDistributionOrder, options?: AxiosRequestConfig) {
    return OrderOperationsApiFp(this.configuration)
      .createPressOrder(createPressDistributionOrder, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get an order
   * @summary retrieve order based on its ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderOperationsApi
   */
  public getOrder(options?: AxiosRequestConfig) {
    return OrderOperationsApiFp(this.configuration)
      .getOrder(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get an order
   * @summary retrieve order based on its ID
   * @param {string} orderId
   * @param {boolean} [includeDst]
   * @param {boolean} [includePrice]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderOperationsApi
   */
  public getOrder1(orderId: string, includeDst?: boolean, includePrice?: boolean, options?: AxiosRequestConfig) {
    return OrderOperationsApiFp(this.configuration)
      .getOrder1(orderId, includeDst, includePrice, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Preparing an order for collection
   * @summary PrepareForCollection
   * @param {string} orderId
   * @param {string} inductionDate
   * @param {string} productionPlantId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderOperationsApi
   */
  public prepareForCollection(orderId: string, inductionDate: string, productionPlantId: string, options?: AxiosRequestConfig) {
    return OrderOperationsApiFp(this.configuration)
      .prepareForCollection(orderId, inductionDate, productionPlantId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Seeking for an order
   * @summary SeekOrder
   * @param {SearchOrder} searchOrder
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrderOperationsApi
   */
  public searchOrders(searchOrder: SearchOrder, options?: AxiosRequestConfig) {
    return OrderOperationsApiFp(this.configuration)
      .searchOrders(searchOrder, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *  This service retrieves contract details for the settlement number with customers.
     * @summary retrieves contract for settlement number
     * @param {string} settlementNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContractInformationForSettlementNumber: async (settlementNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'settlementNumber' is not null or undefined
      assertParamExists('getContractInformationForSettlementNumber', 'settlementNumber', settlementNumber);
      const localVarPath = `/payment/contract/{settlementNumber}`.replace(`{${'settlementNumber'}}`, encodeURIComponent(String(settlementNumber)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *  This service retrieves payment detail for the payer using as input parameter the postcard number or the settlement number.
     * @summary retrieves payer\'s payment details
     * @param {string} id
     * @param validityDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentDetailsForPayer: async (id: string, validityDateStr?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getPaymentDetailsForPayer', 'id', id);
      let localVarPath = `/payment/detail/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      if (validityDateStr) {
        localVarPath = localVarPath.concat('/', encodeURIComponent(String(validityDateStr)));
      }
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PaymentApiAxiosParamCreator(configuration);
  return {
    /**
     *  This service retrieves contract details for the settlement number with customers.
     * @summary retrieves contract for settlement number
     * @param {string} settlementNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContractInformationForSettlementNumber(
      settlementNumber: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractInfoTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getContractInformationForSettlementNumber(settlementNumber, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *  This service retrieves payment detail for the payer using as input parameter the postcard number or the settlement number.
     * @summary retrieves payer\'s payment details
     * @param {string} id
     * @param validityDateStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPaymentDetailsForPayer(
      id: string,
      validityDateStr?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentRep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentDetailsForPayer(id, validityDateStr, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PaymentApiFp(configuration);
  return {
    /**
     *  This service retrieves contract details for the settlement number with customers.
     * @summary retrieves contract for settlement number
     * @param {string} settlementNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContractInformationForSettlementNumber(settlementNumber: string, options?: any): AxiosPromise<ContractInfoTO> {
      return localVarFp.getContractInformationForSettlementNumber(settlementNumber, options).then((request) => request(axios, basePath));
    },
    /**
     *  This service retrieves payment detail for the payer using as input parameter the postcard number or the settlement number.
     * @summary retrieves payer\'s payment details
     * @param {string} id
     * @param validityDateStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPaymentDetailsForPayer(id: string, validityDateStr?: string, options?: any): AxiosPromise<PaymentRep> {
      return localVarFp.getPaymentDetailsForPayer(id, validityDateStr, options).then((request) => request(axios, basePath));
    }
  };
};

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
  /**
   *  This service retrieves contract details for the settlement number with customers.
   * @summary retrieves contract for settlement number
   * @param {string} settlementNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApi
   */
  public getContractInformationForSettlementNumber(settlementNumber: string, options?: AxiosRequestConfig) {
    return PaymentApiFp(this.configuration)
      .getContractInformationForSettlementNumber(settlementNumber, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *  This service retrieves payment detail for the payer using as input parameter the postcard number or the settlement number.
   * @summary retrieves payer\'s payment details
   * @param {string} id
   * @param validityDateStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApi
   */
  public getPaymentDetailsForPayer(id: string, validityDateStr: string, options?: AxiosRequestConfig) {
    return PaymentApiFp(this.configuration)
      .getPaymentDetailsForPayer(id, validityDateStr, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SettingsRepoApi - axios parameter creator
 * @export
 */
export const SettingsRepoApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * This service retrieves user\'s settings based on userid type of search.
     * @summary retrieves user\'s settings
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSettings: async (type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'type' is not null or undefined
      assertParamExists('getSettings', 'type', type);
      const localVarPath = `/settings/{type}`.replace(`{${'type'}}`, encodeURIComponent(String(type)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Saving user settings per type
     * @summary save user settings
     * @param {string} type
     * @param {SavedSetting} savedSetting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveUserSettings: async (type: string, savedSetting: SavedSetting, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'type' is not null or undefined
      assertParamExists('saveUserSettings', 'type', type);
      // verify required parameter 'savedSetting' is not null or undefined
      assertParamExists('saveUserSettings', 'savedSetting', savedSetting);
      const localVarPath = `/settings/{type}`.replace(`{${'type'}}`, encodeURIComponent(String(type)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(savedSetting, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * SettingsRepoApi - functional programming interface
 * @export
 */
export const SettingsRepoApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SettingsRepoApiAxiosParamCreator(configuration);
  return {
    /**
     * This service retrieves user\'s settings based on userid type of search.
     * @summary retrieves user\'s settings
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSettings(
      type: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SavedSettingsRep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSettings(type, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Saving user settings per type
     * @summary save user settings
     * @param {string} type
     * @param {SavedSetting} savedSetting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveUserSettings(
      type: string,
      savedSetting: SavedSetting,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SavedSettingsRep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveUserSettings(type, savedSetting, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * SettingsRepoApi - factory interface
 * @export
 */
export const SettingsRepoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SettingsRepoApiFp(configuration);
  return {
    /**
     * This service retrieves user\'s settings based on userid type of search.
     * @summary retrieves user\'s settings
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSettings(type: string, options?: any): AxiosPromise<SavedSettingsRep> {
      return localVarFp.getSettings(type, options).then((request) => request(axios, basePath));
    },
    /**
     * Saving user settings per type
     * @summary save user settings
     * @param {string} type
     * @param {SavedSetting} savedSetting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveUserSettings(type: string, savedSetting: SavedSetting, options?: any): AxiosPromise<SavedSettingsRep> {
      return localVarFp.saveUserSettings(type, savedSetting, options).then((request) => request(axios, basePath));
    }
  };
};

/**
 * SettingsRepoApi - object-oriented interface
 * @export
 * @class SettingsRepoApi
 * @extends {BaseAPI}
 */
export class SettingsRepoApi extends BaseAPI {
  /**
   * This service retrieves user\'s settings based on userid type of search.
   * @summary retrieves user\'s settings
   * @param {string} type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingsRepoApi
   */
  public getSettings(type: string, options?: AxiosRequestConfig) {
    return SettingsRepoApiFp(this.configuration)
      .getSettings(type, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Saving user settings per type
   * @summary save user settings
   * @param {string} type
   * @param {SavedSetting} savedSetting
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingsRepoApi
   */
  public saveUserSettings(type: string, savedSetting: SavedSetting, options?: AxiosRequestConfig) {
    return SettingsRepoApiFp(this.configuration)
      .saveUserSettings(type, savedSetting, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
