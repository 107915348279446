import React, { useMemo, VFC } from 'react';
import { observer } from 'mobx-react-lite';
import { DHLFormGroup } from '@gkuis/gkp-base-widgets/dist/lib';
import { toFormattedDate } from 'common/formatting';

import { useTranslation } from 'i18n';
import { useProductionFacilities } from 'order/common/hooks/useProductionFacilities';
import { CustomerDetailSection } from 'order/common/components/Overview/CustomerDetailSection';
import { DialogPostContainer } from 'order/productGroups/common/components/atom/DialogPostContainer';
import { OrderStepHeadline } from 'order/productGroups/common/components/atom/OrderStepHeadline';
import { OrderOverviewRow, OrderOverviewWidget } from 'order/productGroups/common/components/molecule/OrderOverview';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { OrderCustomerRoleEnum } from 'generated';
import { LetterBaseProduct } from '../../../../common/context/order/dtos/GetOrder';
import { useDependentProducts } from 'order/common/hooks/useDependentProducts';
import { DeliveryOption, mapOtherAttributeToDeliverOption } from '../../../../../common/dtos/DeliveryOption';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { deepClone } from 'common/utils/deepClone';
import { sortLetterTypeList } from 'order/common/context/order/dtos/Order';
import iconDataEdit from 'assets/icon-data-edit.svg';
import { LetterOrderCreate } from '../../schemea/LetterSchema';

export type LetterOverviewProps = {
  value: LetterOrderCreate;
  orderPhase: OrderMode;
};

export const FrankItOverview: VFC<LetterOverviewProps> = observer(({ value, orderPhase }) => {
  const { getFacility } = useProductionFacilities(value.details?.date ?? new Date());
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step4Overview' });
  const { t: oc2 } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });
  const language = useAuthContext().user.language;
  const { order, orderMode, orderCategory, meta } = useOrderContext();
  const facility = getFacility(value.details?.productionPlantId || '');
  const { premium } = useDependentProducts(value.productGroup, value.details?.date, value.letterBaseProducts[0].baseProduct?.productNumber);
  const premiumName = premium.find(({ productNumber: p }) => p === value.subProducts.premium)?.descriptionShort;
  const isDVBrief = order?.orderCategory === OrderCategory.DV_BRIEF;
  const { t: tCatalogValues } = useTranslation('catalogValues');
  const letterBaseProducts = deepClone(value.letterBaseProducts || []);
  const sortedLetterBaseProducts = letterBaseProducts?.sort(
    (a, b) =>
      sortLetterTypeList.indexOf(a.baseProduct?.description?.toLowerCase() || '') -
      sortLetterTypeList.indexOf(b.baseProduct?.description?.toLowerCase() || '')
  );
  const parties = useMemo(() => {
    return [
      {
        role: OrderCustomerRoleEnum.Originator,
        customerId: value.originator
      },
      {
        role: OrderCustomerRoleEnum.Submitter,
        customerId: value.submitter
      },
      {
        role: OrderCustomerRoleEnum.Payer,
        customerId: value.payer
      },
      ...(value.otherPartners?.map((partner) => ({
        role: partner.role,
        customerId: partner.customerId
      })) || [])
    ];
  }, [value]);

  const { t: translateCategory } = useTranslation('translation', { keyPrefix: 'orderCategoryByKey' });

  const deliveryOption =
    mapOtherAttributeToDeliverOption(order?.otherAttributes?.ZOP) === DeliveryOption.STANDARD
      ? oc('standard')
      : mapOtherAttributeToDeliverOption(order?.otherAttributes?.ZOP) === DeliveryOption.FLEX
      ? oc('flex')
      : '';

  return (
    <DialogPostContainer>
      <DHLFormGroup>
        <OrderStepHeadline icon={iconDataEdit} alt={'data Edit Icon'}>
          {oc('orderOverview')}
        </OrderStepHeadline>
        <h6 style={{ marginBottom: '-10px' }}>{oc('orderInfo')}</h6>
        <OrderOverviewRow>
          <OrderOverviewWidget width={1}>
            <table>
              <tr>
                <td>
                  <p>{`${oc('orderCategory')}:`}</p>
                </td>
                <td>
                  <p>{order?.orderCategoryProductKey ? translateCategory(order?.orderCategoryProductKey as string) : orderCategory}</p>
                </td>
              </tr>
            </table>
          </OrderOverviewWidget>
          <OrderOverviewWidget width={1}>
            <table>
              <tr>
                <td>
                  <p>{`${oc('orderName')}:`}</p>
                </td>
                <td>
                  <p>{value?.orderLabel}</p>
                </td>
              </tr>
            </table>
          </OrderOverviewWidget>
          <OrderOverviewWidget width={1}></OrderOverviewWidget>
          {order?.orderId && orderMode !== OrderMode.COPY && (
            <OrderOverviewWidget width={1}>
              <table>
                <tr>
                  <td>
                    <p>{`${oc('orderNumber')}:`}</p>
                  </td>
                  <td>
                    <p>{order?.orderId}</p>
                  </td>
                </tr>
              </table>
            </OrderOverviewWidget>
          )}
          {order?.customerOrderId && orderMode !== OrderMode.COPY && (
            <OrderOverviewWidget width={1}>
              <table>
                <tr>
                  <td>
                    <p>{`${oc('customerOrderNumber')}:`}</p>
                  </td>
                  <td>
                    <p>{order?.customerOrderId}</p>
                  </td>
                </tr>
              </table>
            </OrderOverviewWidget>
          )}
        </OrderOverviewRow>
        {!isDVBrief && (
          <OrderOverviewRow>
            <OrderOverviewWidget width={1} title={oc('machineIdentifier')}>
              <table>
                <tr>
                  <td>
                    <p>{value.postageMachineCode}</p>
                  </td>
                </tr>
              </table>
            </OrderOverviewWidget>
          </OrderOverviewRow>
        )}
        <OrderOverviewRow>
          <OrderOverviewWidget width={1} title={oc2('product')}>
            {sortedLetterBaseProducts.map((product: LetterBaseProduct, index: number) => {
              return (
                <table style={{ marginTop: '10px' }} key={'overview-baseproduct-' + index}>
                  <tr>
                    <td>
                      <p>{oc('basicProduct') + ' ' + (index + 1) + ':'}</p>
                    </td>
                    <td>{<p>{product.baseProduct?.description}</p>}</td>
                  </tr>
                  <tr>
                    <td>
                      <p>{oc('shipmentVolume')}</p>
                    </td>
                    <td>{<p>{product.baseProduct?.quantity?.toLocaleString(language)}</p>}</td>
                  </tr>
                  {product.digitalCopy && (
                    <tr>
                      <td>
                        <p>{oc('furtherProductCharacteristics') + ':'}</p>
                      </td>
                      <td>{<p>{product.digitalCopy && oc2('digitalCopy')}</p>}</td>
                    </tr>
                  )}
                </table>
              );
            })}
          </OrderOverviewWidget>
        </OrderOverviewRow>
        {(meta?.isAdditionalServiceAvailable || meta?.isDeliveryOptionAvailable) && (
          <OrderOverviewRow>
            {meta?.isDeliveryOptionAvailable && (
              <OrderOverviewWidget width={1} title={oc('deliveryOption')}>
                <table>
                  <tr>
                    <td>
                      <p>{deliveryOption}</p>
                    </td>
                  </tr>
                </table>
              </OrderOverviewWidget>
            )}
            {meta?.isAdditionalServiceAvailable && (
              <OrderOverviewWidget width={1} title={oc('additionalServices')}>
                <table>
                  <tr>
                    <td>
                      <p>{premiumName}</p>
                    </td>
                  </tr>
                </table>
              </OrderOverviewWidget>
            )}
          </OrderOverviewRow>
        )}
        <OrderOverviewRow>
          <OrderOverviewWidget width={1} title={oc('postingInformation')}>
            <table>
              <tr>
                <td>
                  <p>{oc('consignmentDate')}</p>
                </td>
                <td>
                  <p>{toFormattedDate(value.details.date)}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{oc('consignmentCity')}</p>
                </td>
                <td>
                  <p>
                    {facility?.address?.postCode} {facility?.comment}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{oc('contactPersonForQueries')}</p>
                </td>
                <td>
                  <p>
                    {value.contactForQuestions?.firstName} {value.contactForQuestions?.lastName}
                  </p>
                  <p>{value.contactForQuestions?.telephone}</p>
                  <p>{value.contactForQuestions?.email}</p>
                </td>
              </tr>
            </table>
          </OrderOverviewWidget>
          {/* {isDVBrief && (
            <OrderOverviewWidget title={oc('bundles')}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>{oc('typeOfBundle')}</p>
                    </td>
                    <td>
                      <p>{value.packaging.type && tCatalogValues('mailEntityType.display.' + value.packaging?.type)}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>{oc('numberOfBundles')}</p>
                    </td>
                    <td>
                      <p>{value.packaging?.quantity?.toLocaleString(language)}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </OrderOverviewWidget>
          )} */}
        </OrderOverviewRow>
        <CustomerDetailSection parties={parties} withAddress={!isDVBrief} step={'Overview'} validityDate={order?.orderDetail?.date} />
      </DHLFormGroup>
    </DialogPostContainer>
  );
});
