export interface AuthUser {
  isAuthenticated: boolean;
  userEkp: string | undefined;
  userName: string | undefined;
  language: string;
  _accessToken: string | undefined;
  extUserId?: string | undefined;
}

export const defaultAuthUserData: AuthUser = {
  isAuthenticated: false,
  userEkp: undefined,
  userName: 'test',
  language: 'de',
  _accessToken: undefined
};
