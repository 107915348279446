import React, { ReactElement, useRef } from 'react';
import { Canvas, Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';
import { greyColor, styles } from './EinlieferungslisteStyles';
import moment from 'moment/moment';
import { GetPartialOrderRep, OrderCustomerRoleEnum, ProductionFacilityTO, QtyAllocation } from '../../../../generated';
import { PostLogo } from '../pdf/PostLogo';
import { useTranslation } from 'react-i18next';
import { toFormattedDate, toFormattedInt, toFormattedTime } from '../../../../common/formatting';
import { getOrderIdBarcodeImageUrl } from './EinlieferungslisteBarcodes';
import { getDeliveryPoint } from '../utils/productionFacilityUtils';
import { now } from '../../../../common/utils/timeAndDate';
import { generateAndDownloadPdf } from '../utils/pdfUtils';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import deliveryBold from '../../fonts/Delivery.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import deliveryRegular from '../../fonts/Delivery_Rg.ttf';
import { determineLetterTypeByFormat } from '../../../common/components/QtyAllocation/helper/determineLetterTypeByFormat';
import { OrderCategory } from '../../../common/context/order/dtos/OrderCategory';
import { OrderCustomerRole } from '../../../common/context/order/dtos/OrderCustomerRole';
import { AllocatedOrderData } from '../../../common/context/order/dtos/AllocatedOrder';
import { LetterTypes } from 'order/common/components/QtyAllocation/dtos/LetterTypes';
import { maxiBriefDiscountAvailable } from 'order/productGroups/additionalOrders/utils/maxiBriefDeliveryDiscountDiscontinuedDate';
import { isAvailableFromJan25 } from 'order/common/utils/availabilityByDate';
import { uid } from 'react-uid';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { calLetterDiscountsWithType } from '../utils/calLetterDiscountsWithType';
import customWordBreakForPDF from '../utils/customWordBreakForPDF';

Font.register({
  family: 'delivery',
  fonts: [
    {
      src: deliveryRegular
    },
    {
      src: deliveryBold,
      fontWeight: 'bold'
    }
  ]
});

export const downloadEinlieferungslistePdfTL = async (
  printVariantenliste: boolean,
  order: { productGroup: 'PARTIAL' } & GetPartialOrderRep,
  orders: (AllocatedOrderData | undefined)[] | undefined,
  validProductionFacilities: ProductionFacilityTO[],
  version: string | undefined,
  finished: () => void
): Promise<void> => {
  try {
    const doc = (
      <EinlieferungslisteTL
        printVariantenliste={printVariantenliste}
        order={order}
        orders={orders}
        validProductionFacilities={validProductionFacilities}
        version={version}
      />
    );
    await generateAndDownloadPdf(doc, order, 'Einlieferungsliste');
  } catch (e) {
    console.error(`${now()} - error printing Einlieferungsliste: ${e}`);
  } finally {
    finished();
  }
};

interface EinlieferungslisteTLProps {
  printVariantenliste: boolean;
  order: GetPartialOrderRep;
  orders?: (AllocatedOrderData | undefined)[];
  validProductionFacilities?: ProductionFacilityTO[];
  version?: string;
}

export const EinlieferungslisteTL = ({
  printVariantenliste,
  order,
  orders,
  validProductionFacilities,
  version
}: EinlieferungslisteTLProps): JSX.Element => {
  const { t: tCatalogValues } = useTranslation('catalogValues');
  const { t: tProduct } = useTranslation('orderCreate', { keyPrefix: 'step4Overview' }); // same as used in order view (PartialServiceLetterOverview)
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step4ShipmentFeeReduction' });
  const lang = useAuthContext().user.language;

  const submitter = order?.parties?.find((party) => party.role === OrderCustomerRoleEnum.Submitter);
  const payer = order?.parties?.find((party) => party.role === OrderCustomerRoleEnum.Payer);
  const deliveryPoint = getDeliveryPoint(order?.orderDetail?.productionPlantId, validProductionFacilities);
  const lineBreak = '\n';
  const wordBreak = `-${lineBreak}`;

  // Qty Allocation processing...
  const qtyAllocations = order?.orderReferences?.flatMap((o) => {
    const orderPostageType = orders?.find((ea) => ea?.orderNumber === o?.orderId)?.postage?.type;
    return o?.qtyAllocation?.map((qa) => {
      return { ...qa, postageType: orderPostageType } as QtyAllocation;
    });
  });

  const discounts = calLetterDiscountsWithType(qtyAllocations);
  const discountsToArray = Object.entries(discounts);

  return order ? (
    <Document pdfVersion={'1.3'}>
      <Page style={styles.page} orientation={'portrait'} size="A4" wrap={true}>
        <View style={[styles.pageHeader, { minHeight: '12mm' }]} fixed>
          <PostLogo style={[{ width: '50mm', height: '10mm' }, styles.logo]} viewBox="0 0 1000 200" />
        </View>
        {/* page content */}
        <View>
          <View style={[{ marginTop: '7mm' }]}>
            <Text style={[styles.pageHeading, styles.lineContainer]}>Einlieferungsliste</Text>
            <Text style={[styles.pageHeading, styles.lineContainer]}>Zusatzauftrag Teilleistungen Brief</Text>
          </View>
          <View style={[{ marginTop: '5mm' }, styles.lineContainerStreched]}>
            <View>
              <View style={[styles.lineContainerStreched, { marginTop: '-2.5mm' }]}>
                <Text style={[{ minWidth: '60mm' }, styles.paddingTop5]}>Produkt:</Text>
                <Text style={[{ width: '70%', lineHeight: '1' }, styles.paddingTop5]}>{tProduct(order.partialServiceType || '') || ''}</Text>
              </View>
              <View style={[styles.lineContainerStreched, { marginTop: '-2.5mm' }]}>
                <Text style={[{ minWidth: '60mm' }, styles.paddingTop5]}>Teilleistungsnummer:</Text>
                <Text style={[{ width: '70%', lineHeight: '1' }, styles.paddingTop5]}>{order?.submissionId}</Text>
              </View>
              <View style={[styles.lineContainerStreched, { marginTop: '-2.5mm' }]}>
                <Text style={[{ minWidth: '60mm' }, styles.paddingTop5]}>Auftragsbezeichnung:</Text>
                <Text style={[{ width: '70%', lineHeight: '1' }, styles.paddingTop5]}>{customWordBreakForPDF(order?.orderLabel, 35) || '--'}</Text>
              </View>
            </View>
            <View style={[styles.internationalBarcodeConatiner, { marginTop: '-40mm' }]}>
              <Text style={[{ marginTop: '-16mm', marginLeft: '21.5mm' }]}>{`Auftragsnummer: ${order.orderId}`}</Text>
              {(() => {
                const barcodeUrl = getOrderIdBarcodeImageUrl(order.orderId || '');
                return barcodeUrl ? <Image style={{ minWidth: '42mm', height: '10mm' }} src={barcodeUrl} /> : <></>;
              })()}
            </View>
          </View>
          {/* Kundendaten */}
          <View style={[styles.groupContainer, { marginTop: '4mm', marginBottom: '0mm' }]}>
            <Text style={[styles.textHeader, { marginTop: '3mm', marginBottom: '1.5mm' }]}>Kundendaten</Text>
            <View style={styles.lineContainer}>
              <View style={[{ width: '100%' }, styles.linesContainer]}>
                <View style={[{ marginBottom: '2mm' }]}>
                  <View style={[styles.lineContainer, { alignItems: 'flex-end' }]}>
                    <Text style={{ minWidth: '60mm', lineHeight: '1' }}>Vertragspartner:</Text>
                    <Text style={{ width: '100%', maxWidth: '70mm', lineHeight: '1' }}>{`Abrechnungsnummer: ${payer?.customerId}`}</Text>
                  </View>
                  <View style={[styles.lineContainer, { marginTop: 0 }]}>
                    <Text style={{ minWidth: '60mm' }} />
                    <Text style={{ width: '100%', maxWidth: '70mm', lineHeight: '1' }}>{payer?.name}</Text>
                  </View>
                </View>
                <View style={[{ marginBottom: '2mm' }]}>
                  <View style={[styles.lineContainer, { alignItems: 'flex-end' }]}>
                    <Text style={{ minWidth: '60mm', lineHeight: '1' }}>Einlieferer:</Text>
                    <Text style={{ width: '100%', maxWidth: '70mm', lineHeight: '1' }}>{`Kundennummer: ${submitter?.customerId}`}</Text>
                  </View>
                  <View style={[styles.lineContainer, { marginTop: 0 }]}>
                    <Text style={{ minWidth: '60mm' }} />
                    <Text style={{ width: '100%', maxWidth: '70mm', lineHeight: '1' }}>{submitter?.name}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          {/* Einlieferungsinformationen */}
          <View style={[styles.groupContainer, { marginTop: '5mm', marginBottom: '0mm' }]}>
            <Text style={[styles.textHeader, { marginTop: '3mm', marginBottom: '2mm' }]}>{'Einlieferungsinformationen'}</Text>
            <View style={styles.lineContainer}>
              <Text style={{ minWidth: '60mm' }}>{'Einlieferungsdatum:'}</Text>
              <Text style={{ width: '100%' }}>{toFormattedDate(order.orderDetail?.date)}</Text>
              <Text style={{ minWidth: '60mm' }}>{'Einlieferungsstelle:'}</Text>
              <Text style={{ width: '100%' }}>{`${deliveryPoint?.address?.postCode || ''} ${deliveryPoint?.comment || ''}`}</Text>
            </View>
          </View>
          {/* Produktdaten */}
          <View style={[styles.groupContainer, { marginTop: '5mm', marginBottom: '0mm' }]}>
            <Text style={[styles.textHeader, { marginTop: '3mm', marginBottom: '2mm' }]}>{'Produktdaten'}</Text>
            {isAvailableFromJan25(order?.orderDetail?.date) ? (
              <View>
                <View style={styles.gridContainer}>
                  <View style={[styles.gridHeaderContainer, { backgroundColor: '#E6E6E6' }]}>
                    <View style={[styles.gridContainer, styles.borderBottomBlack, styles.borderLeftBlack, { marginTop: 0 }]}>
                      <Text
                        style={[
                          styles.gridHeaderItem,
                          styles.borderBottomBlack,
                          styles.borderTopBlack,
                          { paddingLeft: '2mm', width: '100%', fontWeight: 'bold', fontSize: '9pt', paddingTop: '2mm', paddingBottom: '2mm' }
                        ]}
                      >
                        {lineBreak}Produkt
                      </Text>
                      <View style={styles.gridRowContainer}>
                        <Text style={[styles.gridHeaderItem]}>{lineBreak}</Text>
                      </View>
                    </View>

                    <View style={[styles.gridContainer, styles.borderBottomBlack, { marginTop: 0 }]}>
                      <Text
                        style={[
                          styles.gridHeaderItem,
                          styles.borderLeftBlack,
                          styles.borderTopBlack,
                          styles.borderBottomBlack,
                          styles.textCenter,
                          { width: '100%', fontWeight: 'bold', fontSize: '9pt', paddingTop: '2mm', paddingBottom: '2mm' }
                        ]}
                      >
                        Sendungsmenge {lineBreak}Teilleistungen Basis
                      </Text>

                      <View style={styles.gridRowContainer}>
                        <Text style={[styles.gridHeaderItem, styles.borderLeftBlack, styles.textLeft, { fontSize: '8pt', paddingLeft: '2mm' }]}>
                          DV- {lineBreak}Freimachung
                        </Text>
                        <Text style={[styles.gridHeaderItem, styles.borderLeftBlack, styles.textLeft, { fontSize: '8pt', paddingLeft: '2mm' }]}>
                          Frankier- {lineBreak}maschinen
                        </Text>
                      </View>
                    </View>

                    <View style={[styles.gridContainer, styles.borderBottomBlack, { marginTop: 0 }]}>
                      <Text
                        style={[
                          styles.gridHeaderItem,
                          styles.borderLeftBlack,
                          styles.borderTopBlack,
                          styles.borderBottomBlack,
                          styles.textCenter,
                          { width: '100%', fontWeight: 'bold', fontSize: '9pt', paddingTop: '2mm', paddingBottom: '2mm' }
                        ]}
                      >
                        Sendungsmenge {lineBreak}Teilleistungen ID
                      </Text>

                      <View style={styles.gridRowContainer}>
                        <Text style={[styles.gridHeaderItem, styles.borderLeftBlack, styles.textLeft, { fontSize: '8pt', paddingLeft: '2mm' }]}>
                          DV- {lineBreak}Freimachung
                        </Text>
                        <Text style={[styles.gridHeaderItem, styles.borderLeftBlack, styles.textLeft, { fontSize: '8pt', paddingLeft: '2mm' }]}>
                          Frankier- {lineBreak}maschinen
                        </Text>
                      </View>
                    </View>

                    <View style={[styles.gridContainer, styles.borderBottomBlack, { marginTop: 0 }]}>
                      <Text
                        style={[
                          styles.gridHeaderItem,
                          styles.borderLeftBlack,
                          styles.borderTopBlack,
                          styles.borderBottomBlack,
                          styles.borderRightBlack,
                          styles.textCenter,
                          { width: '100%', fontWeight: 'bold', fontSize: '9pt', paddingTop: '2mm', paddingBottom: '2mm' }
                        ]}
                      >
                        Sendungsmenge {lineBreak}Teilleistungen Schnell
                      </Text>

                      <View style={styles.gridRowContainer}>
                        <Text style={[styles.gridHeaderItem, styles.borderLeftBlack, styles.textLeft, { fontSize: '8pt', paddingLeft: '2mm' }]}>
                          DV- {lineBreak}Freimachung
                        </Text>
                        <Text
                          style={[
                            styles.gridHeaderItem,
                            styles.borderLeftBlack,
                            styles.borderRightBlack,
                            styles.textLeft,
                            { fontSize: '8pt', paddingLeft: '2mm' }
                          ]}
                        >
                          Frankier- {lineBreak}maschinen
                        </Text>
                      </View>
                    </View>

                    <View style={[styles.gridContainer, styles.borderBottomBlack, { marginTop: 0 }]}>
                      <Text
                        style={[
                          styles.gridHeaderItem,
                          styles.borderTopBlack,
                          styles.borderBottomBlack,
                          styles.borderRightBlack,
                          { width: '100%', paddingTop: '9.9mm', paddingBottom: '2mm' }
                        ]}
                      ></Text>
                      <Text
                        style={[
                          styles.gridHeaderItem,
                          styles.textCenter,
                          styles.borderRightBlack,
                          { textAlign: 'right', fontSize: '8pt', width: '100%', paddingRight: '2mm' }
                        ]}
                      >
                        {lineBreak}Gesamtsumme
                      </Text>
                    </View>
                  </View>
                </View>
                {discountsToArray?.map(([key, value], idx) => {
                  const generatedID = uid(`${idx}-${'shipmentFeeReductionChildRows'}`);
                  const isSumRow = key === 'totals';
                  return value?.total ? (
                    <View key={`${generatedID}`} style={styles.gridRowContainer}>
                      <View style={[styles.gridContainer, styles.borderBottomBlack, styles.borderLeftBlack, { marginTop: 0 }]}>
                        <View style={styles.gridRowContainer}>
                          <Text
                            style={[
                              styles.gridHeaderItem,
                              styles.pslTableCell,
                              styles.textLeft,
                              {
                                fontWeight: isSumRow ? 'bold' : 'normal'
                              }
                            ]}
                          >
                            {isSumRow ? 'Summe' : oc(key)}
                          </Text>
                        </View>
                      </View>

                      <View style={[styles.gridContainer, styles.borderBottomBlack, { marginTop: 0 }]}>
                        <View style={styles.gridRowContainer}>
                          <Text
                            style={[styles.gridHeaderItem, styles.borderLeftBlack, styles.pslTableCell, { fontWeight: isSumRow ? 'bold' : 'normal' }]}
                          >
                            {value?.dvFFQty?.toLocaleString(lang)}
                          </Text>
                          <Text
                            style={[styles.gridHeaderItem, styles.borderLeftBlack, styles.pslTableCell, { fontWeight: isSumRow ? 'bold' : 'normal' }]}
                          >
                            {value?.afmFFQty?.toLocaleString(lang)}
                          </Text>
                        </View>
                      </View>

                      <View style={[styles.gridContainer, styles.borderBottomBlack, { marginTop: 0 }]}>
                        <View style={styles.gridRowContainer}>
                          <Text
                            style={[styles.gridHeaderItem, styles.borderLeftBlack, styles.pslTableCell, { fontWeight: isSumRow ? 'bold' : 'normal' }]}
                          >
                            {value?.dvTTQty?.toLocaleString(lang)}
                          </Text>
                          <Text
                            style={[styles.gridHeaderItem, styles.borderLeftBlack, styles.pslTableCell, { fontWeight: isSumRow ? 'bold' : 'normal' }]}
                          >
                            {value?.afmTTQty?.toLocaleString(lang)}
                          </Text>
                        </View>
                      </View>

                      <View style={[styles.gridContainer, styles.borderBottomBlack, styles.borderRightBlack, { marginTop: 0 }]}>
                        <View style={styles.gridRowContainer}>
                          <Text
                            style={[styles.gridHeaderItem, styles.borderLeftBlack, styles.pslTableCell, { fontWeight: isSumRow ? 'bold' : 'normal' }]}
                          >
                            {value?.dvTFQty?.toLocaleString(lang)}
                          </Text>
                          <Text
                            style={[styles.gridHeaderItem, styles.borderLeftBlack, styles.pslTableCell, { fontWeight: isSumRow ? 'bold' : 'normal' }]}
                          >
                            {value?.afmTFQty?.toLocaleString(lang)}
                          </Text>
                        </View>
                      </View>

                      <View style={[styles.gridContainer, styles.borderBottomBlack, { marginTop: 0 }]}>
                        <Text
                          style={[
                            styles.gridHeaderItem,
                            styles.borderRightBlack,
                            styles.pslTableCell,
                            {
                              fontWeight: isSumRow ? 'bold' : 'normal'
                            }
                          ]}
                        >
                          {value?.total?.toLocaleString(lang)}
                        </Text>
                      </View>
                    </View>
                  ) : (
                    <></>
                  );
                })}
              </View>
            ) : (
              <View>
                <View style={styles.gridContainer}>
                  <View style={styles.gridHeaderContainer}>
                    <Text style={styles.gridHeaderItem}>Produkt</Text>
                    <Text style={styles.gridHeaderItemRight}>Sendungsmenge für{lineBreak}Teilleistungen</Text>
                    <Text style={styles.gridHeaderItemRight}>Sendungsmenge für{lineBreak}ID-Rabatt</Text>
                    <Text style={styles.gridHeaderItemRight}>Sendungsmenge für{lineBreak}Laufzeitrabatt</Text>
                  </View>
                  {(() => {
                    // calculate discount quantities by product incl. sum
                    interface DiscountQtyType {
                      productFormat: string;
                      tl: number;
                      idRabatt: number;
                      laufzeitRabatt: number;
                    }

                    const qtys = order.orderReferences
                      ?.flatMap((orderReference) => orderReference.qtyAllocation)
                      .reduceRight<Map<string, DiscountQtyType>>((result, current) => {
                        if (current) {
                          const discountQty =
                            result?.get(current.format || '') ||
                            ({ productFormat: current.format, tl: 0, idRabatt: 0, laufzeitRabatt: 0 } as DiscountQtyType);
                          if (!result?.has(current.format || '')) {
                            result.set(current.format || '', discountQty);
                          }
                          const sumQty =
                            result?.get('Summe') ||
                            ({
                              productFormat: 'Summe',
                              tl: 0,
                              idRabatt: 0,
                              laufzeitRabatt: 0
                            } as DiscountQtyType);
                          if (!result?.has('Summe')) {
                            result.set('Summe', sumQty);
                          }
                          discountQty.tl += current.quantity || 0;
                          sumQty.tl += current.quantity || 0;
                          if (current.containsIds) {
                            discountQty.idRabatt += current.quantity || 0;
                            sumQty.idRabatt += current.quantity || 0;
                          }
                          if (current.flexOption) {
                            const isMaxiBriefDiscountAvailable = current?.format
                              ? determineLetterTypeByFormat(current?.format) === LetterTypes.Maxi &&
                                !maxiBriefDiscountAvailable(order?.orderDetail?.date)
                              : false;
                            if (isMaxiBriefDiscountAvailable) {
                              discountQty.laufzeitRabatt += 0;
                              sumQty.laufzeitRabatt += 0;
                            } else {
                              discountQty.laufzeitRabatt += current.quantity || 0;
                              sumQty.laufzeitRabatt += current.quantity || 0;
                            }
                          }
                        }
                        return result;
                      }, new Map<string, DiscountQtyType>());

                    // show all lines containing at least one qty
                    if (qtys) {
                      const qtysToShow = Array.from(qtys.values()).filter((qty) => qty.tl > 0 || qty.idRabatt > 0 || qty.laufzeitRabatt > 0);
                      const sortRanking = new Map<string, number>([
                        ['S', 1],
                        ['K', 2],
                        ['G', 3],
                        ['M', 4],
                        ['P', 5],
                        ['Summe', 99]
                      ]);
                      const qtysToShowSorted = qtysToShow.sort(
                        (a, b) => (sortRanking.get(a.productFormat) || 999) - (sortRanking.get(b.productFormat) || 999)
                      );
                      return qtysToShowSorted.map((qty, idx) => {
                        return (
                          <View
                            style={[styles.gridRowContainer, qty.productFormat === 'Summe' ? { marginTop: '10px' } : {}]}
                            key={`productdata_${idx}`}
                          >
                            <Text style={styles.gridRowItem}>
                              {determineLetterTypeByFormat(qty.productFormat) === LetterTypes.Maxi &&
                              !maxiBriefDiscountAvailable(order?.orderDetail?.date)
                                ? 'Maxibrief (Laufzeit E+1)'
                                : determineLetterTypeByFormat(qty.productFormat)}
                            </Text>
                            <Text style={[styles.gridRowItemRight, qty.productFormat === 'Summe' ? { fontWeight: 'bold' } : {}]}>
                              {toFormattedInt(qty.tl) || '0'}
                            </Text>
                            <Text style={[styles.gridRowItemRight, qty.productFormat === 'Summe' ? { fontWeight: 'bold' } : {}]}>
                              {toFormattedInt(qty.idRabatt) || '0'}
                            </Text>
                            <Text style={[styles.gridRowItemRight, qty.productFormat === 'Summe' ? { fontWeight: 'bold' } : {}]}>
                              {toFormattedInt(qty.laufzeitRabatt) || '0'}
                            </Text>
                          </View>
                        );
                      });
                    }
                    return <></>;
                  })()}
                </View>
              </View>
            )}
          </View>
          {/* Unterschrift */}
          <View style={[styles.groupContainer, { marginTop: '5mm', marginBottom: '0mm' }]}>
            <View style={[styles.lineContainer, { marginTop: '2mm', marginBottom: '4mm' }]}>
              <Text style={[{ minWidth: '44mm' }, styles.textHeader]}>Anzahl Behälterwagen:</Text>
              <View style={{ width: '25mm', height: '6mm', marginTop: '4mm', border: '1px solid #d3d3d3' }} />
            </View>
            <Text style={[styles.textHeader, { marginTop: '6mm', marginBottom: '4mm' }]}>Einlieferungsbestätigung</Text>
            {/* measurement unit for canvas is 1pt = 1 inch / 72 */}
            <Canvas paint={(painter) => painter.circle(57, 30, 29).stroke(greyColor)} />
            <View style={{ left: '75mm', width: '85mm', marginTop: '3.5mm', borderTop: `1px solid ${greyColor}` }} />
            <View style={styles.lineContainer}>
              <Text style={{ minWidth: '80mm' }}>Stempel Deutsche Post AG</Text>
              <Text style={{ width: '100%' }}>Datum, Unterschrift Mitarbeiter Deutsche Post AG</Text>
            </View>
          </View>
        </View>
        {/* page footer */}
        <View style={styles.footer} fixed>
          <View style={styles.linesContainer}>
            <Text>{`AM.GK-P ${version || ''}`}</Text>
            <View style={styles.lineContainerStreched}>
              <Text>{`Druckdatum: ${moment().format('DD.MM.YYYY HH:mm')}`}</Text>
              <Text render={({ pageNumber, totalPages }) => `Seite ${pageNumber} von ${totalPages}`} />
            </View>
          </View>
        </View>
        {/* Liste der zugeordneten Aufträge on next page(s) */}
        {printVariantenliste && (
          <>
            <View style={[{ marginTop: '6mm' }, styles.lineContainer]} break>
              <Text style={styles.pageHeading}>Liste zugeordnete Aufträge</Text>
            </View>
            <View style={styles.groupContainer}>
              <View style={styles.gridContainer}>
                <View style={[styles.gridHeaderContainer]} fixed>
                  <Text style={styles.gridHeaderItem}>Art der Frankierung</Text>
                  <Text style={styles.gridHeaderItemRight}>Abrechnungs{wordBreak}nummer</Text>
                  <Text style={styles.gridHeaderItemRight}>Einlieferungs{wordBreak}belegnummer</Text>
                  <Text style={styles.gridHeaderItemRight}>Maschinen{wordBreak}kennung</Text>
                  <Text style={styles.gridHeaderItemRight}>Auftragsnummer</Text>
                  <Text style={styles.gridHeaderItemRight}>Sendungsmenge{lineBreak}für Teilleistungen</Text>
                </View>
                {order?.orderReferences
                  ?.sort((ref1, ref2) => {
                    const RESULT_REF1_BEFORE_REF2 = -1;
                    const RESULT_REF1_AFTER_REF2 = 1;
                    let result = RESULT_REF1_BEFORE_REF2;

                    // order category: DV-BRIEF first
                    const ea1 = orders?.find((ea) => ea?.orderNumber === ref1.orderId);
                    const ea2 = orders?.find((ea) => ea?.orderNumber === ref2.orderId);
                    if (ea1?.orderCategory === undefined) {
                      return RESULT_REF1_AFTER_REF2;
                    }
                    if (ea2?.orderCategory === undefined) {
                      return RESULT_REF1_BEFORE_REF2;
                    }
                    if (ea1.orderCategory !== ea2.orderCategory) {
                      if (ea1.orderCategory === OrderCategory.DV_BRIEF) {
                        return RESULT_REF1_BEFORE_REF2;
                      }
                      return RESULT_REF1_AFTER_REF2;
                    }

                    // DV-BRIEF
                    if (ea1.orderCategory === OrderCategory.DV_BRIEF) {
                      // Sortierung nach Abrechnungsnummer (aufsteigend)
                      // Bei gleicher Abrechnungsnummer, Sortierung nach Einlieferungsbelegnummer (aufsteigend)
                      const ea1SettlementNumber = ea1.parties?.find((party) => party.role === OrderCustomerRole.Payer)?.customerId;
                      if (!ea1SettlementNumber) {
                        return RESULT_REF1_AFTER_REF2;
                      }
                      const ea2SettlementNumber = ea2.parties?.find((party) => party.role === OrderCustomerRole.Payer)?.customerId;
                      if (!ea2SettlementNumber) {
                        return RESULT_REF1_BEFORE_REF2;
                      }
                      result = ea1SettlementNumber.localeCompare(ea2SettlementNumber);
                      if (result === 0) {
                        const ea1PaymentClearingNumber = Number.parseInt(ea1.postage?.paymentClearingNumber || '');
                        if (!ea1PaymentClearingNumber || Number.isNaN(ea1PaymentClearingNumber)) {
                          return RESULT_REF1_AFTER_REF2;
                        }
                        const ea2PaymentClearingNumber = Number.parseInt(ea2.postage?.paymentClearingNumber || '');
                        if (!ea2PaymentClearingNumber || Number.isNaN(ea2PaymentClearingNumber)) {
                          return RESULT_REF1_BEFORE_REF2;
                        }
                        result = ea1PaymentClearingNumber - ea2PaymentClearingNumber;
                      }
                    }

                    // FRANKIT
                    if (ea1.orderCategory === OrderCategory.AFM_BRIEF) {
                      // Sortierung nach Kundennummer des Zahlungspflichtigen (aufsteigend)
                      // Bei gleicher Kundennummer des Zahlungspflichtigen, Sortierung nach Maschinenkennung (aufsteigend)
                      const ea1CustomerId = ea1.parties?.find((party) => party.role === OrderCustomerRole.Payer)?.customerId;
                      if (!ea1CustomerId) {
                        return RESULT_REF1_AFTER_REF2;
                      }
                      const ea2CustomerId = ea2.parties?.find((party) => party.role === OrderCustomerRole.Payer)?.customerId;
                      if (!ea2CustomerId) {
                        return RESULT_REF1_BEFORE_REF2;
                      }
                      result = ea1CustomerId.localeCompare(ea2CustomerId);
                      if (result === 0) {
                        const ea1MachineCode = ea1.postage?.machineCode;
                        if (!ea1MachineCode) {
                          return RESULT_REF1_AFTER_REF2;
                        }
                        const ea2MachineCode = ea2.postage?.machineCode;
                        if (!ea2MachineCode) {
                          return RESULT_REF1_BEFORE_REF2;
                        }
                        result = ea1MachineCode.localeCompare(ea2MachineCode);
                      }
                    }

                    return result;
                  })
                  .flatMap((orderRef, idx) => {
                    const ea = orders?.find((ea) => ea?.orderNumber === orderRef.orderId);
                    return (
                      <View style={styles.gridRowContainer} key={`shipment_${idx}`}>
                        <Text style={styles.gridRowItem}>{ea?.postage?.type ? tCatalogValues(`postageType.display.${ea?.postage?.type}`) : ''}</Text>
                        <Text style={styles.gridRowItemRight}>
                          {ea?.parties?.find((customer) => customer.role === OrderCustomerRoleEnum.Payer)?.customerId || ''}
                        </Text>
                        <Text style={styles.gridRowItemRight}>{ea?.postage?.paymentClearingNumber || ''}</Text>
                        <Text style={styles.gridRowItemRight}>{ea?.postage?.machineCode || ''}</Text>
                        <Text style={styles.gridRowItemRight}>{orderRef?.orderId || ''}</Text>
                        <Text style={styles.gridRowItemRight}>
                          {toFormattedInt(orderRef?.qtyAllocation?.reduce((result, qtyAllocation) => result + (qtyAllocation.quantity || 0), 0)) ||
                            ''}
                        </Text>
                      </View>
                    );
                  })}
              </View>
            </View>
          </>
        )}
      </Page>
    </Document>
  ) : (
    <></>
  );
};
