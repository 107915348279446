import { useHistory } from 'react-router-dom';
import React, { VFC } from 'react';
import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';
import classNames from 'classnames';

import { useAlerts } from 'common/AlertContext';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { UserRights } from 'common/dtos/userRights';
import { useBoolean } from 'common/hooks/useBoolean';
import i18n, { useTranslation } from 'i18n';
import { AppPaths } from 'Main';
import { useSearchContext } from 'order/common/context/search/SearchContext';
import { PostageType } from 'order/common/dtos/PostageType';
import { PrintDialog } from 'order/orderView/components/PrintDialog/PrintDialog';
import { CancelOrder } from 'order/productGroups/common/components/molecule/CancelOrder/CancelOrder';
import { OrderTypes } from 'order/orderSearch/components/OrderTypes';
import { Order } from 'order/common/context/order/dtos/Order';
import { OrderMode, WhitelistMethod } from 'order/common/dtos/OrderMode';
import { ProductionState } from 'order/common/context/order/dtos/ProductionState';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import ErrorBoundary from 'common/components/ErrorBoundary/ErrorBoundary';
import { GetOrderRep } from 'generated';
import { SplitOrderModal } from '../SplitOrder/SplitOrderModal/SplitOrderModal';

import styles from './FooterSection.module.css';
import { ProductGroup } from '../../../common/dtos/ProductGroup';
import { useOrderContext } from '../../../common/context/order/OrderContext';
import { Button } from 'common/components/Button';
import { FormProvider, useForm } from 'react-hook-form';
import { OrderCreate } from '../../../productGroups/common/utils/orderCreateSchema';
import { OrderMergeModal } from '../OrderMerge/components/OrderMergeModal/OrderMergeModal';
import { MovePalletModal } from 'order/orderView/components/MovePallet/MovePalletModal/MovePalletModal';

export type FooterSectionProps = {
  order: Order;
  productionState?: ProductionState;
};

export const FooterSection: VFC<FooterSectionProps> = ({ order, productionState }) => {
  const [isPrintDialogOpen, setPrintDialogOpen] = useBoolean(false);
  const [isCancelDialogOpen, setCancelDialogOpen] = useBoolean(false);
  const [isSplitOrderDialogOpen, setSplitOrderDialogOpen] = useBoolean(false);
  const [isMovePalletDialogOpen, setMovePalletDialogOpen] = useBoolean(false);
  const [isMergeOrderDialogOpen, setMergeOrderDialogOpen] = useBoolean(false);
  const history = useHistory();
  const { hasPermission, checkWhitelistingKey, checkWhitelistingWithProductionState } = useAuthContext();
  const { clear } = useAlerts();
  const { setCancelOrderStatus } = useSearchContext();
  const { allocationsLoading, reset, order: rawOrder, orderPrice, allocationsOrders, resetAllocations, resetOrderReferences } = useOrderContext();
  const { t } = useTranslation('orderSearch');
  const isPartial = order.orderType === OrderTypes.Zusatzauftrag;
  const { triggerLastSearch } = useSearchContext();

  // Checks for Pallets move, split & merge buttons
  const isPalletMoveAllowed =
    hasPermission(UserRights.PalletsMove) &&
    order?.orderCategoryProductKey &&
    order?.productionState &&
    checkWhitelistingWithProductionState(order.orderCategoryProductKey, order.productionState, WhitelistMethod.PALLETSMOVE);
  const isSplitAllowed =
    hasPermission(UserRights.OrderSplit) &&
    order?.orderCategoryProductKey &&
    order?.productionState &&
    checkWhitelistingWithProductionState(order.orderCategoryProductKey, order.productionState, WhitelistMethod.SPLIT);

  const isMergeAllowed =
    hasPermission(UserRights.OrderMerge) &&
    order?.orderCategoryProductKey &&
    order?.productionState &&
    checkWhitelistingWithProductionState(order.orderCategoryProductKey, order.productionState, WhitelistMethod.MERGE);

  const methods = useForm<OrderCreate>({
    mode: 'onBlur'
  });

  const isPrintReady =
    rawOrder?.productGroup === ProductGroup.PARTIAL_SERVICE_LETTER ||
    rawOrder?.productGroup === ProductGroup.TEILLEISTUNG ||
    rawOrder?.productGroup === ProductGroup.COLLECTION
      ? true
      : !!orderPrice?.lastChangedAt;

  return (
    <div className={classNames(styles.grid, styles.mt_26)}>
      <div className={classNames(styles.col_6, styles.text_left)}>
        <DHLButton
          name={'auftrag-suchen'}
          label={i18n.t('viewOrderFooter.jobSearchButton')}
          type={'secondary'}
          onClick={() => {
            reset();
            triggerLastSearch();
            history.push(AppPaths.orderSearchPath);
          }}
          disabled={!hasPermission(UserRights.OrderSearch)}
        />
      </div>
      <CancelOrder
        orderId={order.orderId || ''}
        orderLabel={order.orderLabel || ''}
        show={isCancelDialogOpen}
        onCancel={() => setCancelDialogOpen.off()}
        orderMode={OrderMode.VIEW}
      />
      <FormProvider {...methods}>
        <SplitOrderModal
          order={order}
          show={isSplitOrderDialogOpen}
          onCancel={() => {
            setSplitOrderDialogOpen.off();
          }}
        />
        <MovePalletModal order={order} show={isMovePalletDialogOpen} onCancel={() => setMovePalletDialogOpen.off()} />
      </FormProvider>
      <FormProvider {...methods}>
        <OrderMergeModal order={order} show={isMergeOrderDialogOpen} onCancel={() => setMergeOrderDialogOpen.off()} />
      </FormProvider>
      <ErrorBoundary context={'OderView-Footer-PrintDialog'}>
        <PrintDialog
          show={isPrintDialogOpen}
          orderId={order.orderId || ''}
          allocatedOrders={allocationsOrders || undefined}
          onCancel={() => setPrintDialogOpen.off()}
          rawOrder={{ ...order, orderPrice: orderPrice } as GetOrderRep}
        />
      </ErrorBoundary>
      <div className={classNames(styles.col_6, styles.text_right)}>
        <ul className={classNames(styles.footerList)}>
          <li>
            <DHLButton
              name={'auftrag-duplizieren'}
              label={i18n.t('viewOrderFooter.menu.copy')}
              icon={'copy'}
              iconPosition={'icon-first'}
              type={'default'}
              size="sm"
              onClick={() => {
                clear();
                history.push(`${AppPaths.orderCopyPath}/${order.orderId}`);
              }}
              disabled={
                rawOrder?.pendingIds ||
                rawOrder?.disableEditing ||
                (order.orderCategoryProductKey && !checkWhitelistingKey(order.orderCategoryProductKey, WhitelistMethod.COPY)) ||
                !hasPermission(UserRights.OrderEdit) ||
                isPartial ||
                order.postage?.type === PostageType.DV ||
                order.orderCategory === OrderCategory.DIP_SAMMEL ||
                order.orderCategory === OrderCategory.DIP_VARIO ||
                order.orderCategory === OrderCategory.DV_BRIEF
              }
            />
          </li>
          <li>
            <DHLButton
              name={'auftrag-drucken'}
              label={i18n.t('viewOrderFooter.menu.print')}
              icon={'print'}
              iconPosition={'icon-first'}
              type={'default'}
              size="sm"
              onClick={() => setPrintDialogOpen.on()}
              disabled={
                rawOrder?.pendingIds ||
                (order.orderCategoryProductKey && !checkWhitelistingKey(order.orderCategoryProductKey, WhitelistMethod.PRINT)) ||
                !hasPermission(UserRights.OrderPrint) ||
                ((order.productGroup === ProductGroup.PARTIAL_SERVICE_LETTER || order.productGroup === ProductGroup.TEILLEISTUNG) &&
                  allocationsLoading) ||
                !isPrintReady
              }
            />
          </li>
          <li>
            <DHLButton
              name={'auftrag-stornieren'}
              label={i18n.t('viewOrderFooter.menu.delete')}
              icon={'delete'}
              iconPosition={'icon-first'}
              type={'default'}
              size="sm"
              onClick={async () => {
                setCancelDialogOpen.on();
                setCancelOrderStatus(undefined, '');
              }}
              disabled={
                rawOrder?.pendingIds ||
                (order.orderCategoryProductKey && !checkWhitelistingKey(order.orderCategoryProductKey, WhitelistMethod.DELETE)) ||
                !hasPermission(UserRights.OrderEdit) ||
                productionState === ProductionState.CANCELED ||
                productionState === ProductionState.CANCELED_AND_MERGED ||
                productionState === ProductionState.BILLED ||
                productionState === ProductionState.BEING_PROCESSED
              }
            />
          </li>
          <li>
            <DHLButton
              name={'auftrag-bearbeiten'}
              label={i18n.t('viewOrderFooter.menu.edit')}
              icon={'edit'}
              iconPosition={'icon-first'}
              type={'default'}
              size="sm"
              onClick={() => {
                clear();
                history.push(`${AppPaths.orderChangePath}/${order.orderId}${isPartial ? '?orderType=ZA' : ''}`);
              }}
              disabled={
                rawOrder?.pendingIds ||
                rawOrder?.disableEditing ||
                (order.orderCategoryProductKey && !checkWhitelistingKey(order.orderCategoryProductKey, WhitelistMethod.CHANGE)) ||
                !hasPermission(UserRights.OrderEdit) ||
                productionState === ProductionState.CANCELED ||
                productionState === ProductionState.CANCELED_AND_MERGED ||
                productionState === ProductionState.BILLED ||
                productionState === ProductionState.BEING_PROCESSED
              }
            />
          </li>

          {isPalletMoveAllowed && (
            <li className={styles.flexButton}>
              <Button
                name={'auftrag-paletten-verschieben'}
                label={i18n.t('viewOrderFooter.menu.movePallets')}
                JSXIcon={<span className="icon icon-move"></span>}
                type={'button'}
                sizing="sm"
                dataTestId="movePalletsButton"
                onClick={() => {
                  setMovePalletDialogOpen.on();
                  clear();
                }}
              />
            </li>
          )}
          {isSplitAllowed && (
            <li className={styles.flexButton}>
              <Button
                name={'auftrag-teilen'}
                label={i18n.t('viewOrderFooter.menu.share')}
                JSXIcon={<span className="icon icon-split"></span>}
                type={'button'}
                sizing="sm"
                dataTestId="splitOrderButton"
                onClick={() => {
                  setSplitOrderDialogOpen.on();
                  clear();
                }}
              />
            </li>
          )}
          {isMergeAllowed && (
            <li className={styles.flexButton}>
              <Button
                name={'auftrag-zusammenfuhren'}
                label={i18n.t('viewOrderFooter.menu.merge')}
                JSXIcon={<span className="icon icon-merge"></span>}
                type={'button'}
                sizing="sm"
                dataTestId="mergeOrderButton"
                onClick={() => {
                  resetAllocations();
                  resetOrderReferences();
                  clear();
                  setMergeOrderDialogOpen.on();
                }}
              />
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};
