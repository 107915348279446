import { Age, AllocatedOrderData } from '../dtos/AllocatedOrder';
import { OrderResponse } from '../dtos/GetOrder';
import { determineFlexOption } from './determineFlexOption';
import { getPayerProcedureOrParticipationValue } from './getPayerProcedureOrParticipationValue';
import { Occurrence } from 'common/dtos/Occurrence';
import { OrderCustomerRole } from '../dtos/OrderCustomerRole';

export function createAllocationOrderData(order: OrderResponse): Partial<AllocatedOrderData> {
  return {
    ...order,
    orderNumber: order?.orderId,
    containsIds: order?.includesDestinations ? Occurrence.ALL : Occurrence.NONE,
    flexOption: determineFlexOption(order?.otherAttributes || {}) ? Occurrence.ALL : Occurrence.NONE,
    firstInductionDate: order?.orderDetail.date || '',
    payerParticipation: getPayerProcedureOrParticipationValue(order?.parties || []),
    payerProcedure: getPayerProcedureOrParticipationValue(order?.parties || [], true),
    frankierung: order?.postage?.type || '',
    frankingIdPrefix: order?.postage?.machineCode || order?.postage?.frankingIdPrefix || undefined,
    machineCode: order?.postage?.machineCode,
    includeTotalQty: Occurrence.ALL,
    originator: order?.parties.find((p) => p.role === OrderCustomerRole.Originator),
    paymentClearingNumber: order?.postage?.paymentClearingNumber || undefined,
    age: Age.NEW
  };
}
