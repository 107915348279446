import React, { useCallback, useState, VFC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { Button } from 'common/components/Button';
import { useOrderStep } from 'order/common/services/OrderStepProvider';
import { PartialServiceLetterOverview } from '../../components/molecule/additionalOrdersOverview/additionalOrdersOverview';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { UserRights } from 'common/dtos/userRights';
import { OrderMode } from 'order/common/dtos/OrderMode';
import i18n, { useTranslation } from 'i18n';
import { AppPaths } from 'Main';
import { useAlerts } from 'common/AlertContext';
import { useSearchContext } from 'order/common/context/search/SearchContext';
import { AlertTypes } from 'order/common/components/Alerts/dtos/AlertTypes';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { BackToSearchButton } from '../../../../common/components/BackToSearch/BackToSearchButton';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { publishToTopic } from 'common/dtos/PubSubEvents';
import { extractOrdersToPrepare } from './utils/extractOrdersToPrepare';
import { persistCollectionOrder, persistPartialOrder } from './utils/persistOrder';
import { CreatePartialOrder } from '../../../../../generated';
import { ProductionState } from '../../../../common/context/order/dtos/ProductionState';
import { ResponsibleProductionFacilitySelect } from '../../../../common/components/ProductionFacilitySelect';

import orderClasses from 'common/styles/order.module.css';
import ErrorBoundary from 'common/components/ErrorBoundary/ErrorBoundary';
import { prepareForCollection } from './utils/prepareForCollection';
import { AdditionalOrdersOrderCreate, translatePartialServiceLetter } from '../../schema/additionalOrdersSchema';
import { MyOrder } from 'order/orderSearch/components/MyOrders/MyOrders';

export const AdditionalOrdersStep4: VFC = observer(() => {
  const { t } = useTranslation('orderCreate');
  const { watch, handleSubmit } = useFormContext<AdditionalOrdersOrderCreate>();
  const { previousStep, nextStep } = useOrderStep();
  const { addAlert, clear: deleteAlerts } = useAlerts();
  const { hasPermission } = useAuthContext();
  const watchedValues = watch();
  const { reset: resetSearchContext, triggerAlert, updateMyOrders, myOrders } = useSearchContext();
  const history = useHistory();
  const orderCtxt = useOrderContext();
  const changeMode = orderCtxt.orderMode === OrderMode.CHANGE;
  const [activeSpinner, setActiveSpinner] = useState(false);

  const handleResponse = useCallback((response: any) => {
    setActiveSpinner(false);
    if (response && response?.resultStatus !== 'ERROR') {
      resetSearchContext();

      // For persisting my orders
      const myOrderData: MyOrder = {
        id: response?.orderId,
        myOrderNumbers: [response?.orderId],
        orderCategoryProductKey: orderCtxt?.orderCategoryProductKey || orderCtxt?.order?.orderCategoryProductKey,
        orderCategory: orderCtxt?.orderCategory,
        orderLabel: watchedValues?.orderLabel,
        creationDate: new Date()?.getTime(),
        sortingDate: new Date()?.getTime()
      };
      const found = myOrders
        ?.map((myOrder) => myOrder?.myOrderNumbers)
        ?.filter((myOrderArr) => myOrderArr?.length === 1)
        ?.find((myOrderNums) => (response?.orderId ? myOrderNums?.includes(response.orderId) : undefined));
      if (!found || !myOrders?.length) {
        updateMyOrders(myOrderData, 'ADD');
      } else {
        updateMyOrders(myOrderData, 'UPDATE');
      }

      orderCtxt.reset();
      orderCtxt.setDirty(false);
      triggerAlert(response);
      history?.push(AppPaths.orderSearchPath);
    } else {
      response?.amErrors?.forEach((error: { code: string; errorCategory: string; errorDescription: string }) => {
        addAlert({
          type: AlertTypes.Error,
          title: `${error.errorCategory} ${error.code}!`,
          description: error.errorDescription
        });
      });
    }
  }, []);
  const handlePrepareCollection = useCallback(
    async (
      orderNumbers: (string | undefined)[],
      additionalOrder: CreatePartialOrder & { productionState?: ProductionState },
      meta: any,
      orderMode: OrderMode | undefined,
      pickupAddress: any,
      productionPlantId: string
    ) => {
      showModalDialogCollectionOrder(orderNumbers, additionalOrder, meta, orderMode, pickupAddress, true);
      await prepareForCollection(orderNumbers, meta?.pickUpDate || '', productionPlantId || '');
      publishToTopic('am.modal.close', null);
      setActiveSpinner(true);
      const response = await persistCollectionOrder(additionalOrder, orderMode);
      handleResponse(response);
    },
    []
  );

  const showModalDialogCollectionOrder = useCallback(
    (
      orderNumbers: (string | undefined)[],
      additionalOrder: CreatePartialOrder & { productionState?: ProductionState },
      meta: any,
      orderMode: OrderMode | undefined,
      pickupAddress: any,
      activeSpinner: boolean = false
    ) => {
      let productionPlantId = meta.productionPlantId;
      publishToTopic('am.modal.open', {
        content: (
          <ErrorBoundary context={'Step4-Modal'}>
            <div data-testid={`order-mismatch-modal-content`}>
              {t('errors.mismatchedCollectionAllocatedOrdersInductionDate')}
              <div className={orderClasses.counterHeadline}>{t('headline.orderNumbers')}</div>
              <p className={orderClasses.misMatchedInductionDateOrders}>
                {orderNumbers.map((orderNumber, i) => (
                  <div className={orderClasses.misMatchedInductionDateOrderNumber} key={`order-mismatch-${i}`}>
                    {orderNumber}
                  </div>
                ))}
              </p>
              <ErrorBoundary context={'Step4-Modal-ResponsibleProductionFacilitySelect'}>
                <div className={orderClasses.modalResponsibleProductionFacility}>
                  <ResponsibleProductionFacilitySelect
                    name={`pickupAddress`}
                    pickupAddress={pickupAddress}
                    disabled={orderCtxt?.order?.pendingIds}
                    onChange={(item: any) => {
                      orderCtxt.upsertMetaData('productionPlant', item);
                      productionPlantId = item?.catalogId;
                    }}
                  />
                </div>
              </ErrorBoundary>
            </div>
          </ErrorBoundary>
        ),
        headline: t('notifications.notice'),
        cancelConfig: {
          title: t('buttons.cancel'),
          onCancel: () => {
            orderCtxt.upsertMetaData('productionPlantError', false);
          }
        },
        submitConfig: {
          title: t(`buttons.approve`),
          onSubmit: async () => {
            if (!orderCtxt?.meta?.productionPlant) {
              orderCtxt.upsertMetaData('productionPlantError', true);
              return;
            }
            handlePrepareCollection(orderNumbers, additionalOrder, meta, orderMode, pickupAddress, productionPlantId);
          }
        },
        activeSpinner: activeSpinner
      });
    },
    []
  );

  return (
    <>
      <main className={orderClasses.formContent}>
        <PartialServiceLetterOverview value={watchedValues} orderMode={OrderMode.CREATE} orderReferences={orderCtxt.orderReferences} />
      </main>
      <footer className={classNames('d-flex', 'justify-content-between')}>
        <div>
          <Button
            style={{ marginRight: '10px' }}
            variant="default"
            onClick={() => previousStep()}
            type="button"
            label={t('buttons.back')}
            dataTestId="btnBack"
          />
          <BackToSearchButton isDirty={orderCtxt.isDirty} />
        </div>
        <Button
          variant={activeSpinner ? 'default' : 'primary'}
          dataTestId="btnSubmit"
          disabled={!hasPermission(UserRights.OrderEdit) || activeSpinner}
          onClick={async () => {
            await handleSubmit(async (values) => {
              try {
                deleteAlerts();
                const additionalOrder = await translatePartialServiceLetter(
                  values,
                  orderCtxt.orderReferences,
                  orderCtxt.orderId,
                  orderCtxt.orderMode,
                  orderCtxt.orderCategory
                );
                if (orderCtxt.orderCategory !== OrderCategory.ABHOLAUFTRAG) {
                  setActiveSpinner(true);
                  const response = await persistPartialOrder(additionalOrder, orderCtxt.orderMode);
                  handleResponse(response);
                } else {
                  // handle collection order
                  const meta = orderCtxt.meta;
                  const orderNumbers = extractOrdersToPrepare(orderCtxt.allocationsOrders, meta).map((order) => order.data.orderNumber);
                  if (orderNumbers.length) {
                    showModalDialogCollectionOrder(orderNumbers, additionalOrder, meta, orderCtxt.orderMode, watchedValues.pickupAddress);
                  } else {
                    setActiveSpinner(true);
                    const response = await persistCollectionOrder(additionalOrder, orderCtxt.orderMode);
                    handleResponse(response);
                  }
                }
              } catch (e: any) {
                console.error(e);
                setActiveSpinner(false);
                addAlert({
                  type: AlertTypes.Error,
                  title: changeMode ? i18n.t('responseErrors.change.title') : i18n.t('responseErrors.create.title'),
                  description:
                    (changeMode ? i18n.t('responseErrors.change.description') : i18n.t('responseErrors.create.description')) +
                    (e?.response?.data?.status ? ` (${e?.response?.data?.status})` : '')
                });
              } finally {
                nextStep();
              }
            }, console.error)();
          }}
          type="button"
          label={changeMode ? t('buttons.createOrderConfirm') : t('buttons.submitOrder')}
          activeSpinner={activeSpinner}
          style={{ minWidth: '275px' }}
        />
      </footer>
    </>
  );
});
