import { reference } from 'common/utils/helpers';
import React, { AllHTMLAttributes } from 'react';
import classes from './form-row.module.css';

export type FormRowProps = AllHTMLAttributes<HTMLDivElement> & {
  mode?: 'radiobuttons' | 'checkboxes' | 'two-col' | 'one-col' | 'n-col' | 'three-col' | 'two-col-min';
};

export const FormRow = reference<HTMLDivElement, FormRowProps>(
  'FormRow',
  'div',
  ({ mode = 'one-col' }) => [
    classes.formGroup,
    {
      [classes.radiobuttons]: mode === 'radiobuttons',
      [classes.checkboxes]: mode === 'checkboxes',
      [classes.oneCol]: mode === 'one-col',
      [classes.twoCol]: mode === 'two-col',
      [classes.twoColMinWidth]: mode === 'two-col-min',
      [classes.threeCol]: mode === 'three-col',
      [classes.nCol]: mode === 'n-col'
    }
  ],
  ({ mode, ...props }) => props
);
