import { toFormattedTime } from 'common/formatting';
import {
  GetDialogPostOrderRep,
  GetInternationalOrderRep,
  GetLetterOrderRep,
  GetOrderRep,
  GetPartialOrderRep,
  GetPostaktuellOrderRep,
  GetPressDistributionOrderRep,
  Product,
  ShipmentData
} from 'generated';
import { LineItemVariant, LineItemVariant as LineItemVariantEnum } from 'order/common/dtos/LineItemVariant';
import { PostageType } from 'order/common/dtos/PostageType';
import { UseDependentProductsReturn } from 'order/common/hooks/useDependentProducts';
import { CopyOrderSchema } from 'order/orderCopy/schema/copyOrderSchema';
import { DestinationSchema } from 'order/productGroups/common/components/atom/AMDHLDestinationInput';
import { OtherPartnerSchema } from 'order/productGroups/common/components/atom/AMDHLOtherPartners/otherPartnerSchema';
import { AddressSectionSchema } from 'order/productGroups/common/components/molecule/AddressSection/addressSectionSchema';
import { OrderCreate } from 'order/productGroups/common/utils/orderCreateSchema';
import { DeepPartial } from 'react-hook-form';
import { ChangeOrderSchema } from 'order/orderChange/schema/changeOrderSchema';
import { ProductGroup } from '../dtos/ProductGroup';
import { LetterBaseProduct } from '../context/order/dtos/GetOrder';
import { ProductionState } from '../context/order/dtos/ProductionState';
import { OrderCustomerRole } from '../context/order/dtos/OrderCustomerRole';
import { OrderCategoryProductKey } from '../context/order/dtos/OrderCategoryProductKey';
import { Fertigung, fertigungTypes } from 'order/productGroups/dialogMarketing/components/FertigungSection/FertigungSection';
import { MailEntityType } from 'order/productGroups/common/dtos/CatalogValues';
import { InternationalDestinationZipCodes } from 'order/productGroups/international/schema/internationalClusterSchema';
import { dependentProductsAndVariants } from 'order/productGroups/press/schema/pressSchema';
import { AdditionalLetterService, transformPressBaseProducts } from '../utils/transformPressBaseProducts';
import { PressDistributionBaseProduct } from '../dtos/PressDistributionBaseProduct';
import { PressDistributionDependentProduct } from '../dtos/PressDistributionDependentProduct';

export const translateChangeOrders = (
  translateOrder: GetOrderRep | null | undefined,
  dependentProducts: UseDependentProductsReturn,
  baseProductsNational: PressDistributionBaseProduct[],
  fetchDependentProductsByZkz?: PressDistributionDependentProduct[],
  additionalLetterServiceForPressInt?: AdditionalLetterService[]
): DeepPartial<ChangeOrderSchema> | undefined => {
  if (!translateOrder || dependentProducts.isLoading) {
    return;
  }

  const pressInvoiceRecipient =
    translateOrder.productGroup === ProductGroup.PRESS_DISTRIBUTION &&
    translateOrder?.parties?.find((party) => party.role === OrderCustomerRole.InvoiceRecipient)?.customerId === '0020000000';
  const findAllNotInRoles = (...roles: OrderCustomerRole[]) =>
    translateOrder?.parties.filter((p) => !!p.role && !roles.includes(p.role as OrderCustomerRole));
  const getDependentProduct = (...productNumbers: (string | undefined)[]) =>
    productNumbers ? translateOrder?.dependentProducts?.find((p) => productNumbers.includes(p.productNumber)) : undefined;

  const getPressDependentProduct = (varName?: dependentProductsAndVariants) =>
    varName
      ? translateOrder.dependentProducts?.filter(
          (usedep) =>
            fetchDependentProductsByZkz?.find((p) => usedep.productNumber && p.productNumber.includes(usedep.productNumber))
              ?.hierarchyProductCharacteristic === varName
        )?.[0]?.productNumber
      : undefined;
  const nums = fetchDependentProductsByZkz?.map((p) => p.productNumber);

  const findByRole = (role: OrderCustomerRole) => translateOrder?.parties.find((p) => p.role === role);
  const payerId = findByRole(OrderCustomerRole.Payer)?.customerId;
  const originatorId = findByRole(OrderCustomerRole.Originator)?.customerId;
  const submitterId = findByRole(OrderCustomerRole.Submitter)?.customerId;
  const contactForQuestions = findByRole(OrderCustomerRole.Submitter)?.contact;
  const date = translateOrder?.orderDetail?.date;

  let otherPartnersList;
  if (pressInvoiceRecipient) {
    otherPartnersList = findAllNotInRoles(
      OrderCustomerRole.Originator,
      OrderCustomerRole.Submitter,
      OrderCustomerRole.Payer,
      OrderCustomerRole.InvoiceRecipient
    );
  } else {
    otherPartnersList = findAllNotInRoles(OrderCustomerRole.Originator, OrderCustomerRole.Submitter, OrderCustomerRole.Payer);
  }

  return {
    productGroup: translateOrder.productGroup,
    orderId: translateOrder.orderId,
    orderCategory: translateOrder.orderCategory,
    dialogpostBaseProduct:
      translateOrder.productGroup === ProductGroup.DIALOGPOST ||
      translateOrder.orderCategoryProductKey === OrderCategoryProductKey.DiP_EINZEL__DIALOGPOSTWURFSPEZIAL
        ? (translateOrder as GetDialogPostOrderRep).baseProduct?.productNumber
        : undefined,
    pickupAddress: translateOrder.pickupAddress?.orderAddress as AddressSectionSchema | undefined,
    details: {
      productionPlantId: translateOrder.orderDetail?.productionPlantId,
      quantity:
        translateOrder.productGroup === ProductGroup.DIALOGPOST ||
        translateOrder.orderCategoryProductKey === OrderCategoryProductKey.DiP_EINZEL__DIALOGPOSTWURFSPEZIAL
          ? (translateOrder as GetDialogPostOrderRep).baseProduct?.quantity
          : undefined,
      additionalQuantity:
        translateOrder.productGroup === ProductGroup.DIALOGPOST ||
        translateOrder.orderCategoryProductKey === OrderCategoryProductKey.DiP_EINZEL__DIALOGPOSTWURFSPEZIAL
          ? (translateOrder as GetDialogPostOrderRep).additionalQuantity
          : undefined,
      individualWeight:
        translateOrder.productGroup === ProductGroup.DIALOGPOST ||
        translateOrder.orderCategoryProductKey === OrderCategoryProductKey.DiP_EINZEL__DIALOGPOSTWURFSPEZIAL
          ? (translateOrder as GetDialogPostOrderRep).baseProduct?.weight
          : undefined,
      date: date || new Date(),
      time: date && toFormattedTime(date)
    },
    destinations: {
      stackable: translateOrder.packaging.stackable,
      totalWeight: translateOrder.packaging.totalWeight,
      matrix: translateOrder.destinations as DestinationSchema[],
      priority: translateOrder.destinations?.find((d) => d.zipCode === InternationalDestinationZipCodes.PRIORTY)?.quantity,
      economy: translateOrder.destinations?.find((d) => d.zipCode === InternationalDestinationZipCodes.ECONOMY)?.quantity,
      mixed: translateOrder.destinations?.find((d) => d.zipCode === InternationalDestinationZipCodes.MIXED)?.quantity
    },
    internationalDestinations: {
      stackable: (translateOrder as GetPressDistributionOrderRep).internationalPackaging?.stackable,
      totalWeight: (translateOrder as GetPressDistributionOrderRep).internationalPackaging?.totalWeight,
      matrix: (translateOrder as GetPressDistributionOrderRep).internationalDestinations as DestinationSchema[],
      priority: (translateOrder as GetPressDistributionOrderRep).internationalDestinations?.find(
        (d) => d.zipCode === InternationalDestinationZipCodes.PRIORTY
      )?.quantity,
      economy: (translateOrder as GetPressDistributionOrderRep).internationalDestinations?.find(
        (d) => d.zipCode === InternationalDestinationZipCodes.ECONOMY
      )?.quantity,
      mixed: (translateOrder as GetPressDistributionOrderRep).internationalDestinations?.find(
        (d) => d.zipCode === InternationalDestinationZipCodes.MIXED
      )?.quantity
    },
    subProducts: {
      easy: !!getDependentProduct(dependentProducts.easy?.productNumber),
      trackAndMatch:
        translateOrder.productGroup === ProductGroup.PRESS_DISTRIBUTION
          ? !!getPressDependentProduct(dependentProductsAndVariants.trackAndMatch)
          : !!getDependentProduct(dependentProducts.trackAndMatch?.productNumber),
      nichtAutomationsfaehig: !!getDependentProduct(dependentProducts.nichtAutomationsfaehig?.productNumber),
      premium:
        translateOrder.productGroup === ProductGroup.PRESS_DISTRIBUTION
          ? !!getPressDependentProduct(dependentProductsAndVariants.premium)
          : getDependentProduct(...dependentProducts.premium?.map(({ productNumber }) => productNumber))?.productNumber,
      origPremium: getDependentProduct(...dependentProducts.premium?.map(({ productNumber }) => productNumber))?.productNumber,
      deliveryAreaSelection:
        dependentProducts?.deliveryAreaSelection &&
        getDependentProduct(...dependentProducts.deliveryAreaSelection?.map(({ productNumber }) => productNumber))?.productNumber,
      startPointSelection:
        dependentProducts?.startPointSelection &&
        getDependentProduct(...dependentProducts.startPointSelection?.map(({ productNumber }) => productNumber))?.productNumber,
      seasonalSurcharge: getDependentProduct(...(dependentProducts?.seasonalSurcharge || [])?.map(({ productNumber }) => productNumber))
        ?.productNumber,
      coverplus: !!getPressDependentProduct(dependentProductsAndVariants.coverPlus),
      sortingService: !!getPressDependentProduct(dependentProductsAndVariants.sortingService),
      additionalFeeSLN: !!getPressDependentProduct(dependentProductsAndVariants.additionalFeeSLN),
      gogreen: translateOrder.variants?.includes(LineItemVariantEnum.GOGREEN)
    },
    fertigung: transferfertigung(dependentProducts.fertigung, translateOrder.dependentProducts),
    furtherInfo: translateOrder.variants as LineItemVariantEnum[] | undefined,
    targetGroup: translateOrder.variants?.find((v) => v === LineItemVariantEnum.NKD || v === LineItemVariantEnum.BKD) as
      | LineItemVariantEnum.NKD
      | LineItemVariantEnum.BKD
      | undefined,
    shipmentData:
      translateOrder.productGroup === ProductGroup.DIALOGPOST
        ? ((translateOrder as GetDialogPostOrderRep).shipmentData as ShipmentData[])
        : undefined,
    preservedAmexOrder: translateOrder.amexOrder,
    postageType: translateOrder.postage?.type as PostageType | undefined,
    additionalCharge: translateOrder.postage?.additionalCharge as PostageType | undefined,
    paymentClearingNumber: translateOrder.postage?.paymentClearingNumber?.toString(),
    takeOriginatorAsPayer: originatorId === payerId,
    orderPrice: translateOrder?.orderPrice || undefined,
    productionState: translateOrder?.productionState as ProductionState,
    product:
      translateOrder.productGroup === ProductGroup.PARTIAL_SERVICE_LETTER
        ? (translateOrder as GetPartialOrderRep).partialServiceType === 'BZA'
          ? 'BZA'
          : (translateOrder as GetPartialOrderRep).partialServiceType === 'BZE'
          ? 'BZE'
          : undefined
        : undefined,
    originator: originatorId,
    submitter: submitterId,
    takeOriginatorAsSubmitter: originatorId === submitterId,
    contractor: payerId,
    payer: payerId,
    netPayroll: undefined, // TODO: settlement number has procedure 38 please clarify if checkbox Nettoentgeltabrechnung is obsolete !!!
    orderLabel: translateOrder.orderLabel,
    partialServiceNumber:
      translateOrder.productGroup === ProductGroup.PARTIAL_SERVICE_LETTER
        ? (translateOrder as GetPartialOrderRep).submissionId?.toString()
        : undefined,
    contactForQuestions,
    otherPartners: otherPartnersList
      ?.map((x) => ({
        customerId: x.customerId,
        role: x.role as OrderCustomerRole
      }))
      .filter((x) => x.customerId) as OtherPartnerSchema[] | undefined,
    postageMachineCode: translateOrder.postage?.machineCode || undefined,
    packaging: {
      type: translateOrder.packaging.type as MailEntityType,
      quantity: translateOrder.packaging.quantity
    },
    internationalPackaging: {
      type: (translateOrder as GetPressDistributionOrderRep).internationalPackaging?.type as MailEntityType,
      quantity: (translateOrder as GetPressDistributionOrderRep).internationalPackaging?.quantity
    },
    journal: (translateOrder as GetPressDistributionOrderRep).journal,
    nationalProducts:
      transformPressBaseProducts(
        (translateOrder as GetPressDistributionOrderRep).nationalProducts,
        translateOrder?.packaging,
        translateOrder.destinations,
        baseProductsNational
      ) || [],
    internationalProducts:
      transformPressBaseProducts(
        (translateOrder as GetPressDistributionOrderRep).internationalProducts,
        translateOrder?.packaging,
        translateOrder.destinations,
        baseProductsNational,
        translateOrder?.dependentProducts,
        additionalLetterServiceForPressInt
      ) || [],
    additionallyPaidItems: (translateOrder as GetInternationalOrderRep)?.additionalQuantity,
    supplements: (translateOrder as GetPressDistributionOrderRep).supplements,
    transportServices: (translateOrder as GetInternationalOrderRep)?.letterBaseProducts,
    letterBaseProducts: (translateOrder.productGroup === ProductGroup.BRIEF ||
    translateOrder.orderCategoryProductKey === OrderCategoryProductKey.POSTAKTUELL
      ? (translateOrder as GetLetterOrderRep | GetPostaktuellOrderRep).letterBaseProducts
      : undefined) as LetterBaseProduct[]
  };
};

const transferfertigung = (
  fertigung: UseDependentProductsReturn['fertigung'],
  dependentProducts: GetOrderRep['dependentProducts'] = []
): Fertigung => {
  const getDependentProduct = (productNumber?: string) =>
    productNumber ? dependentProducts?.find((p) => p.productNumber === productNumber) : undefined;

  return fertigungTypes.reduce(
    (acc, type) => ({ ...acc, [type]: getDependentProduct(fertigung[type]?.productNumber)?.shipmentQuantity }),
    {} as Fertigung
  );
};

export function translateCopyOrders(
  translateOrder: GetOrderRep | null | undefined,
  dependentProducts: UseDependentProductsReturn,
  baseProductsNational: PressDistributionBaseProduct[]
): DeepPartial<CopyOrderSchema> | undefined {
  if (!translateOrder || dependentProducts.isLoading) {
    return;
  }

  const pressInvoiceRecipient =
    translateOrder.productGroup === ProductGroup.PRESS_DISTRIBUTION &&
    translateOrder?.parties?.find((party) => party.role === OrderCustomerRole.InvoiceRecipient)?.customerId === '0020000000';
  const findAllNotInRoles = (...roles: OrderCustomerRole[]) =>
    translateOrder?.parties.filter((p) => !!p.role && !roles.includes(p.role as OrderCustomerRole));
  const getDependentProduct = (...productNumbers: (string | undefined)[]) =>
    productNumbers ? translateOrder?.dependentProducts?.find((p) => productNumbers.includes(p.productNumber)) : undefined;
  const findByRole = (role: OrderCustomerRole) => translateOrder?.parties.find((p) => p.role === role);
  const contactForQuestions = findByRole(OrderCustomerRole.Submitter)?.contact;
  const payerId = findByRole(OrderCustomerRole.Payer)?.customerId;
  const originatorId = findByRole(OrderCustomerRole.Originator)?.customerId;
  const submitterId = findByRole(OrderCustomerRole.Submitter)?.customerId;
  const nationalProducts = (translateOrder as any)?.nationalProducts ?? [];
  nationalProducts.forEach((n: Product) => {
    n.weight = undefined;
    n.quantity = undefined;
  });
  const internationalProducts = (translateOrder as any)?.internationalProducts ?? [];
  internationalProducts.forEach((n: Product) => {
    n.weight = undefined;
    n.quantity = undefined;
    n.discounts = [];
  });
  const date = translateOrder?.orderDetail?.date;

  let otherPartnersList;
  if (pressInvoiceRecipient) {
    otherPartnersList = findAllNotInRoles(
      OrderCustomerRole.Originator,
      OrderCustomerRole.Submitter,
      OrderCustomerRole.Payer,
      OrderCustomerRole.InvoiceRecipient
    );
  } else {
    otherPartnersList = findAllNotInRoles(OrderCustomerRole.Originator, OrderCustomerRole.Submitter, OrderCustomerRole.Payer);
  }

  const transportServicesWithoutQtyWeight = (translateOrder as GetInternationalOrderRep)?.letterBaseProducts?.map((ts) => {
    return { ...ts, baseProduct: { ...ts.baseProduct, quantity: undefined, grossWeight: undefined }, discounts: [] };
  });

  return {
    productGroup: translateOrder.productGroup as OrderCreate['productGroup'],
    orderId: '',
    orderCategory: translateOrder.orderCategory,
    dialogpostBaseProduct:
      translateOrder.productGroup === ProductGroup.DIALOGPOST ||
      translateOrder.orderCategoryProductKey === OrderCategoryProductKey.DiP_EINZEL__DIALOGPOSTWURFSPEZIAL
        ? (translateOrder as GetDialogPostOrderRep).baseProduct?.productNumber
        : undefined,
    pickupAddress: undefined,
    otherPartners: otherPartnersList
      ?.map((x) => {
        return {
          customerId: x.customerId,
          role: x.role as OrderCustomerRole
        };
      })
      .filter((x) => x.customerId) as OtherPartnerSchema[] | undefined,
    subProducts: {
      easy: !!getDependentProduct(dependentProducts.easy?.productNumber),
      trackAndMatch: !!getDependentProduct(dependentProducts.trackAndMatch?.productNumber),
      nichtAutomationsfaehig: !!getDependentProduct(dependentProducts.nichtAutomationsfaehig?.productNumber),
      premium: getDependentProduct(...dependentProducts.premium?.map(({ productNumber }) => productNumber))?.productNumber,
      origPremium: getDependentProduct(...dependentProducts.premium?.map(({ productNumber }) => productNumber))?.productNumber
    },
    furtherInfo: translateOrder.variants as LineItemVariant[] | undefined,
    targetGroup: translateOrder.variants?.find((v) => v === 'NKD' || v === 'BKD') as 'NKD' | 'BKD' | undefined,
    shipmentData: [],
    postageType: translateOrder.postage?.type as PostageType | undefined,
    additionalCharge: translateOrder.postage?.additionalCharge as PostageType | undefined,
    paymentClearingNumber: translateOrder.postage?.paymentClearingNumber?.toString(),
    takeOriginatorAsPayer: originatorId === payerId,
    payer: payerId,
    orderLabel: translateOrder.orderLabel,
    originator: originatorId,
    details: {
      // date: date || new Date(),
      productionPlantId: translateOrder?.orderDetail?.productionPlantId
      // time: '12:00'
    },
    postageMachineCode: translateOrder.postage?.machineCode,
    contactForQuestions: contactForQuestions,
    takeOriginatorAsSubmitter: originatorId === submitterId,
    nationalProducts:
      transformPressBaseProducts(
        (translateOrder as GetPressDistributionOrderRep).nationalProducts,
        translateOrder?.packaging,
        translateOrder.destinations,
        baseProductsNational
      ) || [],
    internationalProducts:
      transformPressBaseProducts(
        (translateOrder as GetPressDistributionOrderRep).internationalProducts,
        translateOrder?.packaging,
        translateOrder.destinations,
        baseProductsNational
      ) || [],
    supplements: [],
    submitter: submitterId,
    letterBaseProducts: (translateOrder as GetLetterOrderRep).letterBaseProducts as LetterBaseProduct[],
    transportServices: transportServicesWithoutQtyWeight,
    journal: {
      ...(translateOrder as any)?.journal,
      journalNumber: '',
      issueNumberRange: {}
    }
  };
}
