import React from 'react';
import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import deliveryBold from '../../fonts/Delivery.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import deliveryRegular from '../../fonts/Delivery_Rg.ttf';
import { styles } from '../pdf/palettenleitzettelStyles';
import { now } from '../../../../common/utils/timeAndDate';

import { generateAndDownloadPdf } from '../utils/pdfUtils';
import { OrderCategory } from '../../../common/context/order/dtos/OrderCategory';
import {
  compCustomerAddress,
  compCustomerNumber,
  compCustomerOrderId,
  compOrderId,
  compPickupDate,
  compProduct,
  compWeight
} from './palettenleitzettelComponents';
import { getPaletteIdBarcodeImageUrl } from './palettenleitzettelBarcodes';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';
import {
  CustomerInfoWithAddressTO,
  GetDialogPostOrderRep,
  GetDialogPostWurfSpecialOrderRep,
  GetInternationalOrderRep,
  GetOrderRep,
  GetPressDistributionOrderRep,
  JournalPaymentInfoRep,
  OrderCustomerRoleEnum,
  OrderPackaging
} from 'generated';
import customWordBreakForPDF from '../utils/customWordBreakForPDF';
import { PalettenlEitzettelInternational } from './PalettenlEitzettelInternational';

const lineBreak = '\n';

Font.register({
  family: 'delivery',
  fonts: [
    {
      src: deliveryRegular
    },
    {
      src: deliveryBold,
      fontWeight: 'bold'
    }
  ]
});

export const downloadPalettenleitzettelPdf = async (
  order: GetDialogPostOrderRep | GetDialogPostWurfSpecialOrderRep | GetPressDistributionOrderRep,
  originatorInfo: CustomerInfoWithAddressTO | undefined,
  submitterInfo: CustomerInfoWithAddressTO | undefined,
  version: string | undefined,
  journalPaymentInfo: JournalPaymentInfoRep | undefined,
  finished: () => void
): Promise<void> => {
  try {
    // checking order
    if (!order.destinations || order.destinations.length <= 0) {
      console.error(`${now()} - error printing Palettenleitzettel: order ${order.orderId} invalid or does not contain package destinations.`);
    } else {
      let doc = <></>;
      switch (order.orderCategory) {
        case OrderCategory.DIP_EINZEL:
        case OrderCategory.DIP_VARIO:
        case OrderCategory.DIP_SAMMEL:
          doc = (
            <PalettenleitzettelDiP
              order={order as GetDialogPostOrderRep | GetDialogPostWurfSpecialOrderRep}
              originatorInfo={originatorInfo}
              submitterInfo={submitterInfo}
              version={version}
            />
          );
          break;

        case OrderCategory.PRESS_DISTRIBUTION:
          doc = (
            <PalettenleitzettelPresse
              order={order as GetPressDistributionOrderRep}
              originatorInfo={originatorInfo}
              submitterInfo={submitterInfo}
              version={version}
              journalPaymentInfo={journalPaymentInfo}
            />
          );
          break;

        case OrderCategory.IP_INTERNATIONAL:
        case OrderCategory.PUB_INTERNATIONAL:
        case OrderCategory.BRIEF_INTERNATIONAL:
        case OrderCategory.BRIEF_DMC_INTERNATIONAL:
          doc = (
            <PalettenlEitzettelInternational
              order={order as GetInternationalOrderRep}
              originatorInfo={originatorInfo}
              submitterInfo={submitterInfo}
              version={version}
            />
          );
          break;
      }
      await generateAndDownloadPdf(doc, order as GetOrderRep, 'Palettenleitzettel');
    }
  } catch (e) {
    console.error(`${now()} - error printing Palettenleitzettel: ${e}`);
  } finally {
    finished();
  }
};

interface PalettenleitzettelDiPProps {
  order: GetDialogPostOrderRep | GetDialogPostWurfSpecialOrderRep;
  originatorInfo?: CustomerInfoWithAddressTO;
  submitterInfo?: CustomerInfoWithAddressTO;
  version?: string;
}

export interface PkgInfo extends OrderPackaging {
  zipCode?: string;
  variantDescription?: string;
  customZipCode?: string;
  shipmentRefs?: number[];
}

export const PalettenleitzettelDiP = ({ order, originatorInfo, submitterInfo, version }: PalettenleitzettelDiPProps): JSX.Element => {
  const producer = order?.parties?.find((orderCustomer) => orderCustomer.role === OrderCustomerRoleEnum.Producer)?.customerId;
  const isPostwurfspezial = order.orderCategoryProductKey === OrderCategoryProductKey.DiP_EINZEL__DIALOGPOSTWURFSPEZIAL;
  const destinationsByVariants: PkgInfo[] = [];
  order?.destinations?.flatMap((dest, idxDest) => {
    if (dest && dest.packaging) {
      return dest?.packaging.map((pkg: PkgInfo) => {
        destinationsByVariants.push({
          ...pkg,
          zipCode: dest?.zipCode,
          customZipCode: dest?.zipCode == 'DE' ? '-1' : dest?.zipCode,
          variantDescription:
            pkg?.shipmentRefs?.length === 1
              ? order.shipmentData?.find((shipmentData) => pkg?.shipmentRefs?.[0] === shipmentData?.referenceShipmentId)?.description ?? ''
              : ''
        });
      });
    }
    console.warn(
      `${now()} - warning printing Palettenleitzettel for order ${order?.orderId}: ${idxDest + 1}. destination does not contain packagings.`
    );
    return [];
  });
  const sortedDestinations = destinationsByVariants
    ?.sort((a, b) => {
      // equal items sort equally
      if (a?.variantDescription === b?.variantDescription) {
        return 0;
      }

      // nulls sort after anything else
      if (!a?.variantDescription) {
        return 1;
      }
      if (!b?.variantDescription) {
        return -1;
      }

      // otherwise, if we're ascending, lowest sorts first
      return a?.variantDescription < b?.variantDescription ? -1 : 1;
    })
    ?.sort((a, b) => {
      if (a?.customZipCode == b?.customZipCode) {
        return 0;
      }
      if (!a?.customZipCode) {
        return 1;
      }
      if (!b?.customZipCode) {
        return -1;
      }
      return a?.customZipCode < b?.customZipCode ? -1 : 1;
    });

  const variantDescriptionList = Array.from(new Set(sortedDestinations.map((sd) => sd.variantDescription)));
  const sortedDestinationsBySubArrays: Array<PkgInfo[]> = [];

  variantDescriptionList.forEach((a) => {
    sortedDestinationsBySubArrays.push(sortedDestinations.flatMap((b) => (a === b?.variantDescription ? b : [])));
  });

  // Package IDs are usable if they are not NVE
  const isPkgIdUsable = sortedDestinationsBySubArrays?.every((subArr) =>
    subArr.every((pkg) => pkg?.packageId?.length && pkg?.packageId?.length < 20)
  );
  const hasPkgIdGreaterThanListLength = sortedDestinationsBySubArrays?.some((subArr) =>
    subArr.find((pkg) => pkg?.packageId && order?.destinations?.length && +pkg?.packageId > order?.destinations?.length)
  );

  // Serial number is usable if it's vale is  less than 10000
  const isSerialNumberUsable = sortedDestinationsBySubArrays?.every((subArr) =>
    subArr.every((pkg) => pkg?.serialNumber && pkg?.serialNumber <= 10000)
  );
  const hasSerialNumberGreaterThanListLength = sortedDestinationsBySubArrays?.some((subArr) =>
    subArr.find((pkg) => pkg?.serialNumber && order?.destinations?.length && pkg?.serialNumber > order?.destinations?.length)
  );

  const palletNumberCal = (listLength: number, pkgId?: string, serialNumber?: number) => {
    if (isPkgIdUsable) {
      return hasPkgIdGreaterThanListLength ? pkgId : `${pkgId}/${listLength}`;
    } else if (isSerialNumberUsable) {
      return hasSerialNumberGreaterThanListLength ? serialNumber : `${serialNumber}/${listLength}`;
    } else {
      return ``;
    }
  };

  return sortedDestinationsBySubArrays.length > 0 ? (
    <Document pdfVersion={'1.3'}>
      {sortedDestinationsBySubArrays
        ?.flatMap((sa) => sa)
        ?.map((pkg, idx, arr) => (
          <Page key={`page_${idx + 1}`} style={styles.page} orientation={'landscape'} size="A4" wrap={true}>
            <View style={styles.linesContainer}>
              {/* Absender */}
              <View
                style={[
                  { width: '278mm', height: '23mm' },
                  styles.lineContainer,
                  styles.borderTop,
                  styles.borderLeft,
                  styles.borderRight,
                  styles.borderBottom
                ]}
              >
                <View style={[{ width: '67mm' }, styles.fieldContainer, styles.borderRight]}>{compCustomerAddress('Absender', originatorInfo)}</View>
                <View style={[{ width: '67mm' }, styles.fieldContainer, styles.borderRight]}>
                  {compCustomerNumber('Absender', originatorInfo?.customerId)}
                </View>
                <View style={[{ width: '77mm' }, styles.fieldContainer, styles.borderRight]}>
                  <Text style={[styles.textLabel, { marginBottom: '1mm' }]}>Auftragsbezeichnung:</Text>
                  <View style={[{ marginBottom: '2mm' }]}>
                    <Text style={styles.textValue}>{customWordBreakForPDF(order.orderLabel, 28)}</Text>
                  </View>
                  {pkg?.variantDescription && (
                    <View>
                      <Text style={[styles.textLabel, { marginBottom: '1mm' }]}>{`Variantenbezeichnung:`}</Text>
                      <View style={[styles.fieldContainerStretchedLeft]}>
                        <Text style={styles.textValue}>{pkg?.variantDescription}</Text>
                      </View>
                    </View>
                  )}
                </View>
                <View style={styles.fieldContainerStretched}>
                  <Text style={styles.textLabel}>Palettennr. von Gesamtanzahl:</Text>
                  <View style={styles.fieldContainerStretchedCenter}>
                    <Text style={styles.textPaletteCount}>{palletNumberCal(arr.length, pkg?.packageId, pkg?.serialNumber)}</Text>
                  </View>
                </View>
              </View>
              {/* Einlieferer */}
              <View
                style={[
                  {
                    width: '278mm',
                    height: '23mm'
                  },
                  styles.lineContainer,
                  styles.borderLeft,
                  styles.borderRight,
                  styles.borderBottom
                ]}
              >
                <View style={[{ width: '67mm' }, styles.fieldContainer, styles.borderRight]}>
                  {compCustomerAddress('Einlieferer', submitterInfo)}
                </View>
                <View style={[{ width: '67mm' }, styles.fieldContainer, styles.borderRight]}>
                  {compCustomerNumber('Einlieferer', submitterInfo?.customerId)}
                </View>
                <View style={[{ width: '77mm' }, styles.fieldContainer, styles.borderRight]}>{compCustomerNumber('Datenproduzent', producer)}</View>
              </View>
              {/* Produkt */}
              <View
                style={[
                  {
                    width: '278mm',
                    height: '66mm'
                  },
                  styles.lineContainer,
                  styles.borderLeft,
                  styles.borderRight,
                  styles.borderBottom
                ]}
              >
                <View style={[{ width: '133.7mm', alignItems: 'center', justifyContent: 'center' }, styles.fieldContainer]}>
                  {isPostwurfspezial ? compProduct('Postwurfspezial', pkg?.zipCode, true) : compProduct('Dialogpost', pkg?.zipCode)}
                </View>
                <View style={[{ height: '66mm', width: '76.3mm', flexGrow: 1, flexDirection: 'column' }, styles.borderLeft]}>
                  {/* <View style={[{ height: '19mm' }, styles.fieldContainer, styles.borderBottom]} /> */}
                  <View style={[styles.fieldContainer]}>
                    <Text style={styles.textLabel}>Bereich für postalische Zwecke:</Text>
                    <Text style={[{ paddingTop: '3mm', paddingBottom: '2mm' }, styles.textLabel]}>Sortierprogramme:</Text>
                    {pkg.sortProc?.split(' ').map((sortProc, itemIndex) => {
                      return (
                        <Text style={[{ lineHeight: '0.5mm' }, styles.textValue]} key={itemIndex}>
                          {sortProc}
                        </Text>
                      );
                    })}
                  </View>
                </View>
                <View style={[{ width: '66mm', flexGrow: 1, flexDirection: 'column' }, styles.borderLeft]}></View>
              </View>
              {/* Palette */}
              <View
                style={[
                  {
                    width: '278mm',
                    height: '80mm'
                  },
                  styles.lineContainer,
                  styles.borderLeft,
                  styles.borderRight,
                  styles.borderBottom
                ]}
              >
                <View style={[{ width: '134mm' }, styles.linesContainer, styles.borderRight]}>
                  <View style={[{ height: '19mm' }, styles.fieldContainer, styles.borderBottom]}>{compPickupDate(order?.orderDetail?.date)}</View>
                  <View style={[{ height: '19mm' }, styles.fieldContainer, styles.borderBottom]}>{compOrderId(order?.orderId)}</View>
                  <View style={[{ height: '19mm' }, styles.fieldContainer, styles.borderBottom]}>{compCustomerOrderId(order?.customerOrderId)}</View>
                  <View style={[{ height: '19mm' }, styles.fieldContainer, styles.borderBottom]}>{compWeight(pkg?.totalWeight, pkg?.unit)}</View>
                  <View style={[{ height: '8mm' }, styles.fieldContainer, styles.fieldContainerStretchedBottom]}>
                    <Text style={styles.textLabel}>{`erstellt durch AM.GK-P ${version || ''}`}</Text>
                  </View>
                </View>
                <View style={styles.fieldContainer}>
                  <Text style={styles.textLabel}>Feld für Palettenlabel</Text>
                </View>
              </View>
            </View>
          </Page>
        ))}
    </Document>
  ) : (
    <></>
  );
};

interface PalettenleitzettelPresseProps {
  order: GetPressDistributionOrderRep;
  originatorInfo?: CustomerInfoWithAddressTO;
  submitterInfo?: CustomerInfoWithAddressTO;
  version?: string;
  journalPaymentInfo?: JournalPaymentInfoRep;
}

export const PalettenleitzettelPresse = ({
  order,
  originatorInfo,
  submitterInfo,
  version,
  journalPaymentInfo
}: PalettenleitzettelPresseProps): JSX.Element => {
  // Serial number is usable if it's value is less than 10000
  const isSerialNumberUsable = order.destinations?.every((subArr) =>
    subArr.packaging?.every((pkg) => pkg?.serialNumber && pkg?.serialNumber <= 10000)
  );
  const hasSerialNumberGreaterThanListLength = order?.destinations?.some((subArr) =>
    subArr.packaging?.find((pkg) => pkg?.serialNumber && order?.destinations?.length && pkg?.serialNumber > order?.destinations?.length)
  );

  const palletNumberCal = (listLength: number, serialNumber?: number) => {
    if (isSerialNumberUsable) {
      return hasSerialNumberGreaterThanListLength ? serialNumber : `${serialNumber}/${listLength}`;
    } else {
      return ``;
    }
  };

  return order && order.destinations && order.destinations.length > 0 ? (
    <Document pdfVersion={'1.3'}>
      {order.destinations
        .flatMap((dest, idxDest) => {
          if (dest && dest.packaging) {
            return dest.packaging.map((pkg) => {
              return { ...pkg, zipCode: dest.zipCode } as PkgInfo;
            });
          }
          console.warn(
            `${now()} - warning printing Palettenleitzettel for order ${order?.orderId}: ${idxDest + 1}. destination does not contain packagings.`
          );
          return [];
        })
        .sort((pkgA, pkgB) => (pkgA.serialNumber || 0) - (pkgB.serialNumber || 0))
        .map((pkg, idx, arr) => (
          <Page key={`page_${idx + 1}`} style={styles.page} orientation={'landscape'} size="A4" wrap={true}>
            <View style={styles.linesContainer}>
              {/* Absender */}
              <View
                style={[
                  { width: '278mm', height: '22mm' },
                  styles.lineContainer,
                  styles.borderTop,
                  styles.borderLeft,
                  styles.borderRight,
                  styles.borderBottom
                ]}
              >
                <View style={[{ width: '67mm' }, styles.fieldContainer, styles.borderRight]}>{compCustomerAddress('Absender', originatorInfo)}</View>
                <View style={[{ width: '67mm' }, styles.fieldContainer, styles.borderRight]}>
                  {compCustomerNumber('Absender', originatorInfo?.customerId, false)}
                </View>
                <View style={[{ width: '77mm' }, styles.fieldContainer, styles.borderRight]}>
                  <Text style={styles.textLabel}>ZKZ:</Text>
                  <View style={styles.fieldContainerStretched}>
                    <Text style={styles.textValue}>{order?.journal?.zkz || ''}</Text>
                  </View>
                </View>
                <View style={styles.fieldContainerStretched}>
                  <Text style={styles.textLabel}>Palettennr. von Gesamtanzahl:</Text>
                  <View style={styles.fieldContainerStretchedCenter}>
                    <Text style={styles.textPaletteCount}>{palletNumberCal(arr.length, pkg?.serialNumber)}</Text>
                  </View>
                </View>
              </View>
              {/* Einlieferer */}
              <View
                style={[
                  {
                    width: '278mm',
                    height: '22mm'
                  },
                  styles.lineContainer,
                  styles.borderLeft,
                  styles.borderRight,
                  styles.borderBottom
                ]}
              >
                <View style={[{ width: '67mm' }, styles.fieldContainer, styles.borderRight]}>
                  {compCustomerAddress('Einlieferer', submitterInfo)}
                </View>
                <View style={[{ width: '67mm' }, styles.fieldContainer, styles.borderRight]}>
                  {compCustomerNumber('Einlieferer', submitterInfo?.customerId, false)}
                </View>
                <View style={[{ width: '77mm' }, styles.fieldContainer, styles.borderRight]}>
                  <Text style={styles.textLabel}>Zeitungstitel:</Text>
                  <View style={styles.fieldContainerStretched}>
                    <Text style={styles.textValue}>{journalPaymentInfo?.title || ''}</Text>
                  </View>
                </View>
                <View style={styles.fieldContainerStretched}>
                  <Text style={styles.textLabel}>Auftragsnummer:</Text>
                  <View style={styles.fieldContainerStretched}>
                    <Text style={styles.textValue}>{order?.orderId}</Text>
                  </View>
                </View>
              </View>
              {/* Produkt */}
              <View
                style={[
                  {
                    width: '278mm',
                    height: '83mm'
                  },
                  styles.lineContainer,
                  styles.borderLeft,
                  styles.borderRight,
                  styles.borderBottom
                ]}
              >
                <View style={[{ width: '133.7mm', alignItems: 'center', justifyContent: 'center' }, styles.fieldContainer]}>
                  <Text style={styles.textProductMedium}>{`Presse${
                    pkg?.transport === 'SLN' ? ' / SLN' : (pkg?.transport || '').startsWith('ELN') ? ' / ELN' : ''
                  }`}</Text>
                  {!pkg?.deliveryBase ? (
                    <Text style={styles.textTarget}>{['de'].includes((pkg?.zipCode || '').toLowerCase()) ? '0-9' : pkg?.zipCode || ''}</Text>
                  ) : (
                    <>
                      <Text style={styles.textProduct}>
                        {['de'].includes((pkg?.zipCode || '').toLowerCase()) ? '0-9' : pkg?.zipCode || ''}
                        {pkg?.deliveryBase ? ' ZSP' : ''}
                      </Text>
                      <Text style={styles.textProduct}>{pkg?.deliveryBase || ''}</Text>
                    </>
                  )}
                </View>
                <View style={[{ width: '76.3mm', flexGrow: 1, flexDirection: 'column' }, styles.borderLeft]}>
                  <View style={styles.fieldContainerStretched}>
                    <Text style={styles.textLabel}>Bereich für postalische Zwecke:</Text>
                    <Text style={[{ paddingTop: '3mm', paddingBottom: '2mm' }, styles.textLabel]}>Sortierprogramme:</Text>
                    {pkg.sortProc?.split(' ').map((sortProc, itemIndex) => {
                      return (
                        <Text style={[{ lineHeight: '0.5mm' }, styles.textValue]} key={itemIndex}>
                          {sortProc}
                        </Text>
                      );
                    })}
                  </View>
                </View>
                <View style={[{ width: '66mm', flexGrow: 1, flexDirection: 'column' }, styles.borderLeft]}>
                  <View style={[styles.fieldContainer]}>
                    <Text style={styles.textLabel}>Bereich für postalische Zwecke:</Text>
                    <View style={styles.fieldContainerStretchedCenter}>
                      <Text style={styles.textTarget}>{pkg?.transport === 'ELN2' ? 'X2' : pkg?.transport === 'ELN4' ? 'X4' : ''}</Text>
                    </View>
                  </View>
                </View>
              </View>
              {/* Palette */}
              <View
                style={[
                  {
                    width: '278mm',
                    height: '65mm'
                  },
                  styles.lineContainer,
                  styles.borderLeft,
                  styles.borderRight,
                  styles.borderBottom
                ]}
              >
                <View style={[{ width: '110mm' }, styles.linesContainer, styles.borderRight]}>
                  <View style={[{ height: '19mm' }, styles.fieldContainer, styles.borderBottom]}>{compPickupDate(order?.orderDetail?.date)}</View>
                  <View style={[{ height: '19mm' }, styles.fieldContainer, styles.borderBottom]}>
                    {compWeight(pkg?.totalWeight, pkg?.unit, true)}
                  </View>
                  <View style={[styles.fieldContainer, styles.fieldContainerStretchedBottom]}>
                    <Text style={styles.textLabel}>{`erstellt durch AM.GK-P ${version || ''}`}</Text>
                  </View>
                </View>
                <View style={styles.fieldContainerStretched}>
                  <Text style={styles.textLabel}>Palettenlabel:</Text>
                  <View style={styles.fieldContainerStretchedCenter}>
                    {(() => {
                      const barcodeUrl = pkg?.packageId ? getPaletteIdBarcodeImageUrl(pkg.packageId) : undefined;
                      return barcodeUrl ? <Image style={{ width: '150mm', height: '33mm' }} src={barcodeUrl} /> : <></>;
                    })()}
                    <Text style={[{ marginTop: '3mm' }, styles.textAdress]}>
                      {pkg?.packageId ? (pkg.packageId.startsWith('00') ? `(00)${pkg.packageId.substring(2)}` : pkg.packageId) : ''}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </Page>
        ))}
    </Document>
  ) : (
    <></>
  );
};
