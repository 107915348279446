import React, { ReactElement, useRef } from 'react';
import { FieldPath, FieldValues, useController, UseControllerProps, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DHLDateInput } from '@gkuis/gkp-base-widgets/dist/lib';
import moment from 'moment';

import { useKeyPress } from 'common/utils/hooks';
import { isValidDate } from 'order/common/utils/isValidDate';
import { disabledKeys } from './keys';

export type DateInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & {
  disabled?: boolean;
  error?: string;
  label?: string;
  onChange?: (date: Date) => void;
};

export const DateInput = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  disabled,
  error,
  label,
  ...props
}: DateInputProps<TFieldValues, TName>): ReactElement => {
  const { trigger } = useFormContext<TFieldValues>();
  const {
    field: { name, value, onChange }
  } = useController(props);
  const { t } = useTranslation('orderCreate');
  const dateRef = useRef<HTMLDivElement>(null);
  useKeyPress([dateRef], disabledKeys, (e) => {
    if (disabledKeys.includes(e.key) || disabledKeys.includes(e.code)) {
      e.preventDefault();
    }
  });

  const changeDate = (day?: number, month?: number, year?: number) => {
    if (typeof day !== 'undefined' && typeof month !== 'undefined' && typeof year !== 'undefined') {
      const hours = value ? new Date(value).getHours() : 0;
      const minutes = value ? new Date(value).getMinutes() : 0;
      const newDate = new Date(year, month - 1, day, hours, minutes);
      onChange(newDate);
      props.onChange?.(newDate);
      trigger(props.name);
    }
  };

  const calcDate = (input: string) => {
    const parsedNumbers = input.match(/(\d+)/g)?.map((v) => Number(v)) || [];
    if (moment.locale() === 'de') {
      const [day, month, year] = parsedNumbers;
      changeDate(day, month, year);
    } else {
      const [month, day, year] = parsedNumbers;
      changeDate(day, month, year);
    }
  };

  const validDate = isValidDate(value);
  const dateString =
    !validDate && value
      ? new Date(value)?.toLocaleDateString(moment.locale() === 'de' ? 'de-DE' : 'en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric'
        })
      : (value as any)?.toLocaleDateString(moment.locale() === 'de' ? 'de-DE' : 'en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric'
        });

  return (
    <div ref={dateRef}>
      <DHLDateInput
        name={name}
        minDate={moment().year(2010).dayOfYear(1)}
        label={label}
        value={dateString}
        onChange={(input) => {
          calcDate(input);
        }}
        disabled={disabled}
        error={error && t(`error.${props.name}.${error}`, { date: moment().format('DD.MM.yyyy') })}
      />
    </div>
  );
};
