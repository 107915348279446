import { DHLTextInput } from '@gkuis/gkp-base-widgets/dist/lib';
import { FormRow } from 'common/components/FormRow';
import { PostageType } from 'order/common/dtos/PostageType';
import { validPostageMachineCode } from 'order/productGroups/common/utils/validation';
import React, { VFC } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type PostageInfoSectionProps = {
  type?: PostageType.AFM | PostageType.AS;
};

export const PostageInfoSection: VFC<PostageInfoSectionProps> = ({ type }) => {
  const { t } = useTranslation('orderCreate', { keyPrefix: 'postage.additionalInformation' });

  if (!type) {
    return <></>;
  }

  const errorKey = type === PostageType.AS ? 'error.postageAmountValue' : 'error.postageMachineCode';
  const labelKey = type === PostageType.AFM ? 'postageMachineCode' : 'postageAmountValue';
  const name = type === PostageType.AFM ? 'postageMachineCode' : 'postageAmountValue';
  return (
    <FormRow mode="two-col">
      <Controller
        name={name}
        rules={{
          validate: {
            valid: (value) => {
              if (type === PostageType.AFM) {
                return validPostageMachineCode(value);
              }
            }
          }
          // required: type === PostageType.AS
        }}
        render={({ field: { ref, ...field }, fieldState }) => (
          <DHLTextInput
            {...field}
            type="text"
            placeholder={t(labelKey)}
            label={t(labelKey)}
            functionIcon={field.value ? 'close-thin' : undefined}
            onFunctionClick={() => field.onChange('')}
            error={fieldState.error ? t(errorKey) : undefined}
          />
        )}
      />
    </FormRow>
  );
};
