// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PressProductSection_tableNP__YAZ-R {
  margin-bottom: 25px;
  margin-top: 10px;
}
table th {
  color: #323232;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 10px;
}

/* .ipSeparator:last-child {
  border-bottom: none;
} */

.PressProductSection_ipContent__B9qbO {
  max-width: 43%;
  padding: 25px 0;
}

/* .ipContent:first-child {
  padding-top: 0px;
} */

.PressProductSection_ipContent__B9qbO:last-child,
.PressProductSection_ipContent__B9qbO:only-child {
  padding-bottom: 0px;
}

/* .ipContentOverview:first-child {
  padding-top: 0px;
} */

.PressProductSection_ipSeparator__zoOT7,
.PressProductSection_ipSeparatorOverview__eflDZ {
  border-bottom: 1px solid #e6e6e6;
  width: 47%;
}

.PressProductSection_ipSeparator__zoOT7:last-child {
  border-bottom: none;
}

.PressProductSection_singleIPTitle__\\+jJcN {
  margin-bottom: 0 !important;
}

.PressProductSection_suppTable__qj5eo {
  margin-bottom: 0;
}

.PressProductSection_variantsTable__fZPlK {
  margin-bottom: 25px;
}

.PressProductSection_suppTable__qj5eo th,
.PressProductSection_suppTable__qj5eo td {
  width: 100%;
}

.PressProductSection_orderTitle__SHQ0B {
  color: #323232;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.PressProductSection_orderTitle__SHQ0B {
  margin-bottom: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/order/common/components/Overview/PressProductInfoSection/PressProductSection.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;;GAEG;;AAEH;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;;GAEG;;AAEH;;EAEE,mBAAmB;AACrB;;AAEA;;GAEG;;AAEH;;EAEE,gCAAgC;EAChC,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;;EAEE,WAAW;AACb;;AAEA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".tableNP {\n  margin-bottom: 25px;\n  margin-top: 10px;\n}\ntable th {\n  color: #323232;\n  font-size: 15px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  padding-bottom: 10px;\n}\n\n/* .ipSeparator:last-child {\n  border-bottom: none;\n} */\n\n.ipContent {\n  max-width: 43%;\n  padding: 25px 0;\n}\n\n/* .ipContent:first-child {\n  padding-top: 0px;\n} */\n\n.ipContent:last-child,\n.ipContent:only-child {\n  padding-bottom: 0px;\n}\n\n/* .ipContentOverview:first-child {\n  padding-top: 0px;\n} */\n\n.ipSeparator,\n.ipSeparatorOverview {\n  border-bottom: 1px solid #e6e6e6;\n  width: 47%;\n}\n\n.ipSeparator:last-child {\n  border-bottom: none;\n}\n\n.singleIPTitle {\n  margin-bottom: 0 !important;\n}\n\n.suppTable {\n  margin-bottom: 0;\n}\n\n.variantsTable {\n  margin-bottom: 25px;\n}\n\n.suppTable th,\n.suppTable td {\n  width: 100%;\n}\n\n.orderTitle {\n  color: #323232;\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 800;\n  line-height: normal;\n}\n\n.orderTitle {\n  margin-bottom: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableNP": `PressProductSection_tableNP__YAZ-R`,
	"ipContent": `PressProductSection_ipContent__B9qbO`,
	"ipSeparator": `PressProductSection_ipSeparator__zoOT7`,
	"ipSeparatorOverview": `PressProductSection_ipSeparatorOverview__eflDZ`,
	"singleIPTitle": `PressProductSection_singleIPTitle__+jJcN`,
	"suppTable": `PressProductSection_suppTable__qj5eo`,
	"variantsTable": `PressProductSection_variantsTable__fZPlK`,
	"orderTitle": `PressProductSection_orderTitle__SHQ0B`
};
export default ___CSS_LOADER_EXPORT___;
