import { DependentProduct, OrderDestination, OrderPackaging, Product, ProductTO } from 'generated';
import { PressBaseProduct } from 'order/productGroups/press/schema/pressSchema';
import { DestinationSchema } from '../../productGroups/common/components/atom/AMDHLDestinationInput';
import { InternationalDestinationZipCodes } from '../../productGroups/international/schema/internationalClusterSchema';
import { PressDistributionBaseProduct } from '../dtos/PressDistributionBaseProduct';
import { requestSpecialLetterProducts } from '../services/specialLetterProducts';
import { ALSBaseProductsIntPress } from 'order/productGroups/international/components/AdditionalLetterService';

export interface AdditionalLetterService {
  productNumber: string;
  specialLetterProducts: ProductTO[];
}

export const discountFn = async (productNumber?: string, date = new Date()) => {
  const date1: string = new Date(date).getDate().toString().padStart(2, '0');
  const month: string = (new Date(date).getMonth() + 1).toString().padStart(2, '0');
  const year = new Date(date).getFullYear();

  const SLP = await requestSpecialLetterProducts(productNumber, `${year}-${month}-${date1}`);
  const validEnumValues = [
    ALSBaseProductsIntPress['Eigenhändig Presse&Buch'],
    ALSBaseProductsIntPress['Einschreiben Intern. Presse&Buch'],
    ALSBaseProductsIntPress['Rückschein Intern. Presse&Buch']
  ];

  return SLP.filter((p) => validEnumValues.find((a) => p.descriptionShort?.includes(a)));
};

export const transformPressBaseProducts = (
  pbp?: Product[],
  packaging?: OrderPackaging,
  destinations?: OrderDestination[],
  baseProductsNational?: PressDistributionBaseProduct[],
  dependentProducts?: DependentProduct[],
  additionalLetterServiceForPressInt?: AdditionalLetterService[]
) => {
  let i = 0;

  const groupedProd = pbp?.reduce(
    (acc, bp) =>
      acc.set(bp.productNumber, {
        productNumber: bp.productNumber,
        preExisting: bp?.preExisting,
        pressVariant: bp?.pressVariant,
        variants: [
          ...(acc.get(bp.productNumber)?.variants || []),
          {
            description: bp?.description || '',
            itemId: bp?.itemId,
            referenceShipmentId: bp?.referenceShipmentId || '',
            weight: bp?.weight,
            quantity: bp?.quantity,
            preExisting: bp?.preExisting
          }
        ],
        referenceShipmentId: bp?.referenceShipmentId || '',
        id: i++,
        packaging: packaging,
        destinations: {
          stackable: packaging?.stackable,
          totalWeight: packaging?.totalWeight,
          matrix: destinations as DestinationSchema[],
          priority: destinations?.find((d) => d.zipCode === InternationalDestinationZipCodes.PRIORTY)?.quantity,
          economy: destinations?.find((d) => d.zipCode === InternationalDestinationZipCodes.ECONOMY)?.quantity,
          mixed: destinations?.find((d) => d.zipCode === InternationalDestinationZipCodes.MIXED)?.quantity
        },
        baseProductType: baseProductsNational?.find((item) => item.productNumber === bp.productNumber)?.duration,
        discounts: [
          ...(dependentProducts
            ?.filter(
              (item) =>
                item.referenceShipmentId === bp.referenceShipmentId &&
                additionalLetterServiceForPressInt
                  ?.filter((a) => a.productNumber === bp.productNumber)[0]
                  ?.specialLetterProducts?.map((a) => a.productNumber)
                  .includes(item.productNumber)
            )
            .map((a) => {
              return { productNumber: a.productNumber, shipmentQuantity: a.shipmentQuantity };
            }) || [])
        ]
      }),
    new Map()
  );
  return Array.from(groupedProd?.values() || []) as PressBaseProduct[];
};
