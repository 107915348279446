const firstJan25 = new Date('2030-01-01');
const firstJan25AsNumber = firstJan25.valueOf();

export const isAvailableFromJan25 = (inputDate?: Date): boolean => {
  const date = inputDate ? new Date(inputDate) : new Date();
  if (!date) {
    return false;
  } else {
    const dateToNumber = date.valueOf();
    if (dateToNumber >= firstJan25AsNumber) {
      return true;
    } else if (date.toDateString() === firstJan25?.toDateString()) {
      return true;
    } else {
      return false;
    }
  }
};
