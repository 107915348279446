import React, { FC, useMemo } from 'react';
import { OrderOverviewRow, OrderOverviewWidget } from '../../../../../productGroups/common/components/molecule/OrderOverview';
import { OrderPickupShippingDisplay } from '../../../../../productGroups/common/components/atom/OrderPickupShippingDisplay';
import { CustomerDetailSection } from 'order/common/components/Overview/CustomerDetailSection';
import i18n from '../../../../../../i18n';
import { useAuthContext } from '../../../../../../common/context/auth/AuthContext';
import { useTranslation } from 'react-i18next';
import { OrderCustomerRoleEnum } from '../../../../../../generated';
import { useProductionFacilities } from '../../../../../common/hooks/useProductionFacilities';
import { useOrderContext } from '../../../../../common/context/order/OrderContext';
import { toFormattedTime } from '../../../../../../common/formatting';
import { OrderContentTabProps } from '../OrderContentTab';

export const CollectionTab: FC<OrderContentTabProps> = (props: OrderContentTabProps) => {
  const { order } = props;
  const language = useAuthContext().user.language;
  const { t: tCatalogValues } = useTranslation('catalogValues');
  const { t: oc } = useTranslation('orderCreate');
  const { t: oc2 } = useTranslation('orderCreate', { keyPrefix: 'step4Overview' });
  const { allocationsOrders } = useOrderContext();
  const collectionParties = useMemo(() => {
    return [
      {
        role: OrderCustomerRoleEnum.Payer,
        customerId: order.parties.find((a) => a.role === OrderCustomerRoleEnum.Payer)?.customerId
      },
      {
        role: OrderCustomerRoleEnum.Submitter,
        customerId: order.parties.find((a) => a.role === OrderCustomerRoleEnum.Submitter)?.customerId
      },
      {
        role: OrderCustomerRoleEnum.Beneficiary,
        customerId: order.parties.find((a) => a.role === OrderCustomerRoleEnum.Beneficiary)?.customerId
      }
    ];
  }, [order]);
  const { getFacility } = useProductionFacilities(order.orderDetail?.date);
  const facility = getFacility(order.orderDetail?.productionPlantId || '');
  const totalPackagingQty = allocationsOrders.map((r) => r.data.packagingQty).reduce((sum, current) => (sum as number) + (current as number), 0);
  const totalNetWeight = allocationsOrders.map((r) => r.data.totalNetWeight).reduce((sum, current) => (sum as number) + (current as number), 0);
  const totalGrossWeight = allocationsOrders.map((r) => r.data.totalGrossWeight).reduce((sum, current) => (sum as number) + (current as number), 0);
  const anyUnstackable = allocationsOrders.find((a) => !a.data.stackable);
  const totalNetWeightInKg = (totalNetWeight ? totalNetWeight / 1000 : 0)?.toLocaleString(language);
  const totalGrossWeightInKg = totalGrossWeight?.toLocaleString(language);

  return (
    <div data-testid={'order-content-tab-collection-testid'}>
      <OrderOverviewRow>
        <OrderOverviewWidget title={oc('step4Overview.product')}>
          <p data-testid="base-product-test-id">{oc('aside.abholungProduct')}</p>
        </OrderOverviewWidget>
      </OrderOverviewRow>
      <OrderOverviewRow>
        <OrderPickupShippingDisplay
          data={{
            date: order.orderDetail?.date,
            pickup: true,
            time: toFormattedTime(order.orderDetail?.date),
            facility,
            contactForQuestions: order.parties.find((p) => p.role === OrderCustomerRoleEnum.Submitter)?.contact,
            pickupAddress: { orderAddress: order.pickupAddress?.orderAddress }
          }}
        />
        <OrderOverviewWidget title={oc('step4Overview.bundles')}>
          <table data-testid="gebinde-testid">
            <tbody>
              <tr>
                <td>
                  <p>{oc2('typeOfBundle')}</p>
                </td>
                <td>
                  <p>{tCatalogValues('mailEntityType.display.PAL')}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{oc2('numberOfBundles')}</p>
                </td>
                <td data-testid="total-packaging-qty-testid">
                  <p>{totalPackagingQty}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{oc2('totalNetWeight')}:</p>
                </td>
                <td data-testid="total-net-weight-testid">
                  <p>{totalNetWeightInKg ? `${totalNetWeightInKg} kg` : ''}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{oc2('stackable')}</p>
                </td>
                <td>
                  <p>{!anyUnstackable ? i18n.t('yes') : i18n.t('no')}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </OrderOverviewWidget>
      </OrderOverviewRow>
      <CustomerDetailSection parties={collectionParties} validityDate={order?.orderDetail?.date} />
    </div>
  );
};
