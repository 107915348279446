import { DHLButton, DHLHeadline } from '@gkuis/gkp-base-widgets/dist/lib';
import { FormRow } from 'common/components/FormRow';
import { isValidInteger } from 'common/utils/formHelpers';
import { TextInput } from 'order/productGroups/common/components/atom/TextInput';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Controller, ControllerFieldState, useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classes from './transport-services.module.css';
import { Product } from 'order/productGroups/common/services/ProductService';
import { useOrderTransferForm } from 'order/productGroups/common/utils/OrderTransferForm';
import { InternationalClusterOrderCreate } from '../../schema/internationalClusterSchema';
import { InternationalBaseProduct } from '../../dtos/InternationalBaseProducts';
import { AMDHLSelectSingle } from '../../../../../common/components/AMDHLSelectSingle';
import { useBoolean } from '../../../../../common/hooks/useBoolean';
import { usePrevious } from '../../../../../common/utils/hooks';
import { LetterBaseProduct } from 'generated';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { IdAssignmentReadOnlyTable } from 'order/common/components/QtyAllocation/IdAssignmentReadOnlyTable';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { AdditionalLetterService } from '../AdditionalLetterService';
import { ProductGroup } from '../../../../common/dtos/ProductGroup';

export type TransportServicesProps = {
  items?: Product[];
  title?: string;
  maxAmount?: number;
  name: 'transportServices';
  disabled?: boolean;
  onDelete?: (index: number, baseProduct: Product, isLast: boolean) => void;
  selectedIBP?: InternationalBaseProduct;
  children?: ReactElement;
  productGroup: ProductGroup;
  selectedDate?: Date;
};

export const TransportServices = ({
  items = [],
  title,
  maxAmount = 99,
  name,
  disabled,
  selectedIBP,
  productGroup,
  selectedDate,
  ...props
}: TransportServicesProps): ReactElement => {
  const { control, trigger, setValue, resetField, getValues } = useOrderTransferForm<InternationalClusterOrderCreate>();
  const { orderMode, order } = useOrderContext();
  const { t } = useTranslation('orderCreate');
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });
  const {
    fields: transportServiceFields,
    remove: removeTransportServiceField,
    append: appendTransportServiceField
  } = useFieldArray({ control, name });

  const [loaded, setLoaded] = useState(false);
  const [designationInc, setDesignationInc] = useState<number>(1);
  const [tsProds, setTsProds] = useState<Product[]>([]);
  const [addBtnValid, setAddBtnValid] = useBoolean(false);
  const previousSelectedIBP = usePrevious(selectedIBP);
  const language = useAuthContext().user.language;
  const values = useWatch({
    control,
    name: name
  });
  type WatchedProduct = { id: string } & LetterBaseProduct;
  let watchedFields = useRef<WatchedProduct[]>([]).current;
  if (values) {
    watchedFields = transportServiceFields.map(({ id }, index) => ({
      id,
      ...(values as any)[index]
    }));
  }
  useEffect(() => {
    if (previousSelectedIBP && previousSelectedIBP?.productNumber != selectedIBP?.productNumber) {
      removeTransportServiceField(Array.from(Array(watchedFields.length).keys()));
    }
  }, [previousSelectedIBP, selectedIBP]);
  useEffect(() => {
    if (watchedFields.length < 1) {
      setValue(name, [{} as WatchedProduct]);
    }
  }, [watchedFields]);

  useEffect(() => {
    if (items.length) {
      const filteredTsProds = items.filter((tsProd) =>
        selectedIBP?.allowedProductNumbers.some((prodNum: string) => prodNum === tsProd.productNumber)
      );

      const sortedTsProds = filteredTsProds.sort((a, b) => {
        const descriptionA = a.descriptionShort || '';
        const descriptionB = b.descriptionShort || '';

        if (descriptionA < descriptionB) {
          return -1;
        }
        if (descriptionA > descriptionB) {
          return 1;
        }
        return 0;
      });

      setTsProds(sortedTsProds);
    }
  }, [selectedIBP, items]);

  useEffect(() => {
    const unUsedProd = tsProds.filter((prod) => watchedFields.every((prodData) => prodData.baseProduct?.productNumber !== prod.productNumber));
    const transportServicesCount = watchedFields
      ?.map((s) => Number(s.baseProduct?.description?.replace(`${t('description')} - `, '')))
      .filter((value) => {
        return value;
      });
    if (watchedFields.length && !loaded) {
      setLoaded(true);
    }
    setAddBtnValid.off();
    if (watchedFields[watchedFields?.length - 1]?.baseProduct?.productNumber && Boolean(unUsedProd.length)) setAddBtnValid.on();

    if (loaded && watchedFields.length && unUsedProd.length === 1) {
      watchedFields.forEach((watchedProduct, index, watchedProducts) => {
        if (index === tsProds.length - 1 && watchedProduct && watchedProducts) {
          setValue(`${name}.${index}.baseProduct.productNumber`, unUsedProd[0].productNumber);
          setValue(`${name}.${index}.baseProduct.description`, unUsedProd[0].descriptionShort);
        }
      });
    }
  }, [watchedFields, loaded]);

  const unUsedTsProd = tsProds.filter((prod) => watchedFields.every((prodData) => prodData.baseProduct?.productNumber !== prod.productNumber));

  const getErrorMessage =
    () =>
    ({ fieldState }: { fieldState: ControllerFieldState }) =>
      fieldState.error ? t(`error.${fieldState.error.message}`) : undefined;

  return (
    <>
      {title && (
        <DHLHeadline type="h6" color={'gray'} textAlignment={'left'}>
          {title}
        </DHLHeadline>
      )}
      {watchedFields.map((transportServiceProductField, index) => {
        return (
          // Important: for correct re-rendering in case of added or removed items it is necessary to use the id as key!
          <div key={`${name}.${transportServiceProductField.id}`} data-testid={`${name}.${index}`} className={classes.parentDiv}>
            <div className={classes.heading}>
              <DHLHeadline type="h6" color={'gray'} textAlignment={'left'}>
                {productGroup === ProductGroup.BRIEF_INTERNATIONAL ? `${oc('transportService')}` : `${oc('transportService')} ${index + 1}`}
              </DHLHeadline>
              <DHLButton
                icon="delete"
                iconPosition={'icon'}
                size="xs"
                disabled={disabled}
                onClick={
                  watchedFields.length === 1
                    ? () => {
                        props.onDelete?.(index, transportServiceProductField.baseProduct, true);
                        resetField(name, { defaultValue: [{ baseProduct: {} }] });
                        if (watchedFields.length === 1) {
                          setValue('transportServices.0.baseProduct.productNumber', '');
                          setValue('transportServices.0.baseProduct.quantity', undefined);
                          setValue('transportServices.0.baseProduct.grossWeight', undefined);
                        }
                      }
                    : () => {
                        setValue(`transportServices.${index}.baseProduct.productNumber`, '');
                        setValue(`transportServices.${index}.baseProduct.quantity`, undefined);
                        setValue(`transportServices.${index}.baseProduct.grossWeight`, undefined);
                        removeTransportServiceField(index);
                      }
                }
                name={`products-${index}-delete`}
              />
            </div>
            <FormRow mode="two-col">
              <Controller
                name={`${name}.${index}.baseProduct.productNumber`}
                rules={{
                  required: {
                    value: true,
                    message: 'baseProductInput.productNumber.empty'
                  }
                }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <div>
                    <AMDHLSelectSingle
                      name={`${name}.${index}.baseProduct.productNumber`}
                      items={unUsedTsProd}
                      disabled={disabled || productGroup === ProductGroup.BRIEF_INTERNATIONAL}
                      placeholder={`${oc('transportService')}*`}
                      valueToString={(option) => option?.descriptionShort || ''}
                      value={tsProds.find((product1) => product1.productNumber === field.value)}
                      error={getErrorMessage()({ fieldState })}
                      onChange={(item) => {
                        setValue(`${name}.${index}.baseProduct.productNumber`, item?.productNumber);
                        setValue(`${name}.${index}.baseProduct.description`, item?.descriptionShort);
                        trigger(`${name}.${index}.baseProduct.productNumber`);
                        resetField(`${name}.${index}.discounts` as any, { defaultValue: [] });
                      }}
                      data-testid={`${name}.${index}.baseProduct.productNumber`}
                    />
                  </div>
                )}
              />
            </FormRow>
            <FormRow mode="two-col">
              <TextInput
                type="number"
                name={`${name}.${index}.baseProduct.quantity`}
                label={`${oc('shipmentVolume')}`}
                disabled={disabled}
                rules={{
                  required: {
                    value: true,
                    message: 'baseProductInput.quantity.empty'
                  },
                  validate: isValidInteger,
                  max: { value: 1e9 - 1, message: 'baseProductInput.quantity.tooBig' },
                  min: { value: 1, message: 'min' }
                }}
                error={getErrorMessage()}
                onChange={(e) => {
                  trigger(`${name}.${index}.baseProduct.quantity`);
                }}
              />
              <TextInput
                type="number"
                name={`${name}.${index}.baseProduct.grossWeight`}
                label={`${oc('totalWeightInKg')}*`}
                disabled={disabled}
                rules={{
                  required: {
                    value: true,
                    message: 'baseProductInput.weight.empty'
                  },
                  max: { value: 1e4 - 1, message: 'baseProductInput.weight.tooBig' },
                  min: { value: 0.001, message: 'min' }
                }}
                error={getErrorMessage()}
                onChange={() => {
                  trigger(`${name}.${index}.baseProduct.grossWeight`);
                }}
              />
            </FormRow>
            {productGroup !== ProductGroup.DIALOGPOST_INTERNATIONAL && (
              <AdditionalLetterService
                name={`transportServices.${index}.discounts`}
                title={`${oc('additionalLetterService')}`}
                productNumber={getValues(`${name}.${index}.baseProduct.productNumber`)}
                selectedDate={selectedDate}
                productGroup={productGroup}
              />
            )}
            {orderMode === OrderMode.CHANGE && (
              <div style={{ marginTop: '15px' }}>
                <DHLHeadline type="h6" color={'gray'} textAlignment={'left'}>
                  {oc('shipmentPlan')}
                </DHLHeadline>
                <IdAssignmentReadOnlyTable
                  header={'frankingIdPrefix'}
                  postageFrankingIdPrefix={order?.postage?.frankingIdPrefix || order?.postage?.machineCode}
                  frankingIdEncoding={order?.postage?.frankingIdEncoding}
                  id={`${index}`}
                  ranges={transportServiceProductField?.destinations}
                  showShipmentQtyColumn
                  meta={{
                    language: language
                  }}
                />
              </div>
            )}
          </div>
        );
      })}
      {(maxAmount < 1 || watchedFields.length < maxAmount) && addBtnValid && (
        <DHLButton
          name="products-add"
          size="xs"
          type="ghost"
          icon="plus"
          iconPosition={'icon-first'}
          label={`${oc('addAdditionalTransportService')}`}
          onClick={() => {
            setDesignationInc(designationInc + 1);
            appendTransportServiceField({ baseProduct: {} } as LetterBaseProduct);
          }}
          disabled={disabled}
          className={classes.addButton}
        />
      )}
    </>
  );
};
