import React, { FC, useEffect, useRef } from 'react';
import { useBoolean } from 'common/hooks/useBoolean';
import { useFormContext, useWatch } from 'react-hook-form';
import iconAccountDelivery from 'assets/icon-account-delivery.svg';
import classNames from 'classnames';
import { Accordion } from 'common/components/Accordion';
import { Button } from 'common/components/Button';
import { useTranslation } from 'i18n';
import { useDialogPostDependentFields } from 'order/common/hooks/useDialogPostDependentFields';
import { useOrderStep } from 'order/common/services/OrderStepProvider';
import { OrderStepHeadline } from 'order/productGroups/common/components/atom/OrderStepHeadline';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { AddressSection } from 'order/productGroups/common/components/molecule/AddressSection';
import { ContactSection } from 'order/productGroups/common/components/molecule/ContactSection';
import { CustomerSection } from 'order/productGroups/common/components/molecule/CustomerSection';
import { DestinationSection } from 'order/productGroups/common/components/molecule/DestinationSection';
import { MarginalColumn } from 'order/productGroups/common/components/molecule/MarginalColumn';
import { JobTitleSection } from 'order/productGroups/common/components/molecule/JobTitleSection';
import { PackagingSection } from 'order/productGroups/common/components/molecule/PackagingSection';
import { QuantityWeightSection } from 'order/productGroups/common/components/molecule/QuantityWeightSection';
import { TimeCitySection } from 'order/productGroups/common/components/molecule/TimeCitySection';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { OrderSearchKey } from 'order/common/context/search/dtos/OrderSearchKey';
import { AccordionItemOtherPartners } from 'order/orderChange/common/components/AccordionItems/OtherPartners';
import { AccordionItemFertigungSection } from 'order/orderChange/common/components/AccordionItems/FertigungSection';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import './translate';
import orderClasses from 'common/styles/order.module.css';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { DisplayQuantityWeightSection } from 'order/productGroups/common/components/molecule/DisplayQuantityWeightSection';
import { PostageType } from 'order/common/dtos/PostageType';
import { BackToSearchButton } from '../../../../common/components/BackToSearch/BackToSearchButton';
import { useAlerts } from 'common/AlertContext';
import { AlertTypes } from 'order/common/components/Alerts/dtos/AlertTypes';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';
import { BaseProductSection } from '../../components/BaseProductSection';
import PostaktuellBaseProducts from '../../components/PostaktuellBaseProducts/PostaktuellBaseProducts';
import { SubProductsSection } from '../../components/SubProductsSection';
import { DialogMarketingOrderCreate, validateDpContent } from '../../schema/dialogMarketingSchema';
import { FurtherInfoSection } from '../../components/FurtherInfoSection/FurtherInfoSection';

const inputDisabled = false;
export const DialogMarketingStep2: FC = () => {
  const { previousStep, nextStep } = useOrderStep();
  const { addAlert } = useAlerts();
  const { orderMode, isDirty, setDirty, order, orderCategoryProductKey, productGroup: pg } = useOrderContext();
  const { setValue, trigger, control, getValues, setError } = useFormContext<DialogMarketingOrderCreate>();

  const [
    takeOriginatorAsSubmitter,
    originatorUcp,
    productGroup,
    selectedDate,
    submitterUcp,
    dialogpostBaseProduct,
    pickupAddress,
    selectedPackagingType,
    orderLabel,
    orderCategory,
    currentPostageType,
    matrix = [],
    letterBaseProducts
  ] = useWatch({
    control,
    name: [
      'takeOriginatorAsSubmitter',
      'originator',
      'productGroup',
      'details.date',
      'submitter',
      'dialogpostBaseProduct',
      'pickupAddress',
      'packaging.type',
      'orderLabel',
      'orderCategory',
      'postageType',
      'destinations.matrix',
      'letterBaseProducts'
    ]
  });
  const currentOriginator = useRef<string | undefined>(undefined);
  useDialogPostDependentFields();
  const { t } = useTranslation('orderCreate');
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });
  const matrixValue = matrix?.reduce((acc, v) => acc + v.quantity, 0);
  const pickup = !!pickupAddress;
  const isPostaktuell = orderCategoryProductKey === OrderCategoryProductKey.POSTAKTUELL;
  useEffect(() => {
    if (order?.pendingIds) {
      addAlert({
        type: AlertTypes.Warning,
        description: t(`notifications.pendingIdsWarning`),
        title: t('notifications.warning')
      });
    }
  }, []);

  useEffect(() => {
    if (currentOriginator.current !== originatorUcp) {
      currentOriginator.current = originatorUcp;

      if (takeOriginatorAsSubmitter) {
        setValue('submitter', originatorUcp);
      }
    }
  }, [originatorUcp, takeOriginatorAsSubmitter]);

  const checked = takeOriginatorAsSubmitter;
  const [einliefererUbernehmen, setEinliefererUbernehmen] = useBoolean(takeOriginatorAsSubmitter);

  const originatorCheckboxConfig = {
    controlName: 'takeOriginatorAsSubmitter',
    name: 'einliefererUbernehmen',
    label: `${oc('alsoAcceptAsConsignor')}`,
    value: einliefererUbernehmen || checked,
    onChange: () => {
      setEinliefererUbernehmen.toggle();
      setValue('submitter', einliefererUbernehmen ? '' : getValues('originator'), { shouldValidate: true });
      setValue('takeOriginatorAsSubmitter', !einliefererUbernehmen);
    }
  };
  const submitterCheckboxConfig = {
    name: 'pickupAddress.toggle',
    label: `${oc('pickupDesired')}`,
    value: pickup,
    onChange: () => {
      if (pickup) {
        setValue('pickupAddress', undefined, { shouldTouch: true });
      } else {
        setValue(
          'pickupAddress',
          {
            companyName: '',
            street: '',
            streetNumber: '',
            postCode: '',
            city: '',
            country: '',
            further: ''
          },
          { shouldTouch: true }
        );
        setValue('details.productionPlantId', '');
      }
    }
  };

  useEffect(() => {
    setDirty(true);
  }, []);

  return (
    <>
      <div className={orderClasses.formContent}>
        <div className={orderClasses.rowWithSidebar}>
          <main>
            <OrderStepHeadline icon={iconAccountDelivery} alt="Icon Auftragsinhalt">
              {oc('title')}
            </OrderStepHeadline>
            {isPostaktuell ? (
              <PostaktuellBaseProducts letterBaseProducts={letterBaseProducts} />
            ) : (
              <BaseProductSection<DialogMarketingOrderCreate>
                name="dialogpostBaseProduct"
                disabled={order?.pendingIds || inputDisabled}
                productGroup={productGroup}
                selectedDate={selectedDate}
              />
            )}
            <SubProductsSection<DialogMarketingOrderCreate>
              name="subProducts"
              disabled={order?.pendingIds || inputDisabled}
              productGroup={productGroup}
              selectedDate={selectedDate}
              baseProduct={dialogpostBaseProduct}
              orderMode={orderMode}
            />
            <FurtherInfoSection<DialogMarketingOrderCreate>
              name="furtherInfo"
              disabled={order?.pendingIds || inputDisabled}
              productGroup={productGroup}
            />
            <JobTitleSection<DialogMarketingOrderCreate>
              hasTargetGroup={productGroup === ProductGroup.DIALOGPOST || order?.productGroup === ProductGroup.DIALOGPOST}
              disabled={order?.pendingIds || inputDisabled}
              orderLabel={orderLabel}
            />
            <CustomerSection<DialogMarketingOrderCreate>
              headline={oc('sender')}
              inputName="originator"
              disabled={order?.pendingIds || orderMode === OrderMode.CHANGE ? true : inputDisabled}
              customerRole={OrderSearchKey.Originator}
              errorMessage={t('error.customerIdUnknown')}
              checkboxConfig={originatorCheckboxConfig}
            />
            <CustomerSection<DialogMarketingOrderCreate>
              headline={oc('consignor')}
              inputName="submitter"
              disabled={order?.pendingIds || inputDisabled || takeOriginatorAsSubmitter}
              customerRole={OrderSearchKey.Submitter}
              errorMessage={t('error.customerIdUnknown')}
              checkboxConfig={submitterCheckboxConfig}
            />
            {pickup && (
              <AddressSection<DialogMarketingOrderCreate>
                name="pickupAddress"
                fallbackCustomerId={takeOriginatorAsSubmitter ? originatorUcp : submitterUcp}
              />
            )}
            <ContactSection<DialogMarketingOrderCreate> name="contactForQuestions" disabled={order?.pendingIds || inputDisabled} />
            <TimeCitySection<DialogMarketingOrderCreate>
              name="details"
              disabled={order?.pendingIds || inputDisabled}
              pickup={pickup}
              selectedProductGroup={ProductGroup.DIALOGPOST}
            />

            {!isPostaktuell &&
              (orderMode === OrderMode.CHANGE ? (
                orderCategory !== OrderCategory.DIP_VARIO && orderCategory !== OrderCategory.DIP_SAMMEL ? (
                  <QuantityWeightSection<DialogMarketingOrderCreate>
                    name="details"
                    disabled={order?.pendingIds || inputDisabled}
                    changeOrderFormDisabled={currentPostageType === PostageType.DV}
                  />
                ) : (
                  <DisplayQuantityWeightSection<DialogMarketingOrderCreate>
                    name="shipmentData"
                    showVariant={orderCategory === OrderCategory.DIP_VARIO}
                    disabled={order?.pendingIds || inputDisabled}
                  />
                )
              ) : (
                <QuantityWeightSection<DialogMarketingOrderCreate> name="details" disabled={order?.pendingIds || inputDisabled} />
              ))}

            <PackagingSection<DialogMarketingOrderCreate>
              name="packaging"
              disabled={order?.pendingIds || inputDisabled}
              destinationAmount={matrixValue}
            />
            {selectedPackagingType === 'PAL' && <DestinationSection<DialogMarketingOrderCreate> name="destinations" />}
            <OrderStepSection>
              <Accordion allowMultiple={true}>
                {(register) => (
                  <>
                    {productGroup === ProductGroup.DIALOGPOST && (
                      <AccordionItemFertigungSection
                        id={2}
                        register={register}
                        disabled={order?.pendingIds || inputDisabled}
                        productGroup={productGroup}
                        selectedDate={selectedDate}
                        baseProduct={dialogpostBaseProduct}
                      />
                    )}
                    <AccordionItemOtherPartners name={'otherPartners'} id={1} register={register} />
                  </>
                )}
              </Accordion>
            </OrderStepSection>
          </main>
          <MarginalColumn />
        </div>
      </div>
      <footer className={classNames('d-flex', 'justify-content-between')}>
        <div>
          <Button
            style={{ marginRight: '10px' }}
            variant="default"
            onClick={() => previousStep()}
            dataTestId="btnBack"
            label={t('buttons.back')}
            type="button"
          />
          <BackToSearchButton isDirty={isDirty} />
        </div>
        <Button
          variant="primary"
          dataTestId="btnSubmit"
          onClick={async () => {
            // const isContentValid = await trigger('content');
            const fieldName: any = [
              'dialogpostBaseProduct',
              'details.date',
              'details.productionPlantId',
              'originator',
              'submitter',
              'details.quantity',
              'details.individualWeight',
              'pickupAddress'
            ];
            pickup && fieldName.push('details.time');
            const isContentValid = await trigger(fieldName);
            const isOrderLabelValid = orderLabel ? orderLabel?.length <= 80 : true;
            const areDependentFieldsValid = validateDpContent(getValues(), setError, 2);
            if (isContentValid && areDependentFieldsValid && isOrderLabelValid) {
              nextStep();
            }
          }}
          disabled={order?.pendingIds}
          type="button"
          label={t('buttons.continue')}
        />
      </footer>
    </>
  );
};
