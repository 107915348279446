import React, { useRef, VFC } from 'react';
import { useHistory } from 'react-router-dom';
import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';
import classNames from 'classnames';

import { useAlerts } from 'common/AlertContext';
import { Menu } from 'common/components/ContextMenu';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { UserRights } from 'common/dtos/userRights';
import { useBoolean } from 'common/hooks/useBoolean';
import { useClickOutside, useKeyPress } from 'common/utils/hooks';
import i18n, { useTranslation } from 'i18n';
import { AppPaths } from 'Main';
import { useSearchContext } from 'order/common/context/search/SearchContext';
import { PostageType } from 'order/common/dtos/PostageType';
import { PrintDialog } from 'order/orderView/components/PrintDialog/PrintDialog';
import { CancelOrder } from 'order/productGroups/common/components/molecule/CancelOrder/CancelOrder';
import { OrderTypes } from 'order/orderSearch/components/OrderTypes';
import { Order } from 'order/common/context/order/dtos/Order';
import { OrderMode, WhitelistMethod } from 'order/common/dtos/OrderMode';
import { ProductionState } from 'order/common/context/order/dtos/ProductionState';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import ErrorBoundary from 'common/components/ErrorBoundary/ErrorBoundary';
import { GetOrderRep } from 'generated';

import styles from './ToolBar.module.css';
import { ProductGroup } from '../../../common/dtos/ProductGroup';
import { useOrderContext } from '../../../common/context/order/OrderContext';
import { OrderCategoryProductKey } from '../../../common/context/order/dtos/OrderCategoryProductKey';
import { Button } from 'common/components/Button';
import { OnlineHelpLinks } from '../../../common/dtos/OnlineHelpLink';
import { SplitOrderModal } from '../SplitOrder/SplitOrderModal/SplitOrderModal';
import { FormProvider, useForm } from 'react-hook-form';
import { OrderCreate } from '../../../productGroups/common/utils/orderCreateSchema';
import { OrderMergeModal } from '../OrderMerge/components/OrderMergeModal/OrderMergeModal';
import { OnlineHelp } from '../../../common/components/OnlineHelp/OnlineHelp';
import { MovePalletModal } from 'order/orderView/components/MovePallet/MovePalletModal/MovePalletModal';

export type ToolBarProps = {
  order: Order;
  productionState?: ProductionState;
};

export const ToolBar: VFC<ToolBarProps> = ({ order, productionState }) => {
  const [menuVisible, setMenuVisible] = useBoolean(false);
  const headerCellRef = useRef<HTMLDivElement>(null);
  const focusRef = useRef<HTMLDivElement>(null);
  useClickOutside([headerCellRef], () => setMenuVisible.off());
  useKeyPress([focusRef], ['Enter', 'Escape'], () => {
    setMenuVisible.off();
  });
  const { hasPermission, checkWhitelistingKey, checkWhitelistingWithProductionState } = useAuthContext();
  const [isPrintDialogOpen, setPrintDialogOpen] = useBoolean(false);
  const [isCancelDialogOpen, setCancelDialogOpen] = useBoolean(false);
  const [isSplitOrderDialogOpen, setSplitOrderDialogOpen] = useBoolean(false);
  const [isMovePalletDialogOpen, setMovePalletDialogOpen] = useBoolean(false);
  const [isMergeOrderDialogOpen, setMergeOrderDialogOpen] = useBoolean(false);
  const history = useHistory();
  const { clear, addAlert } = useAlerts();
  const { setCancelOrderStatus } = useSearchContext();
  const { t } = useTranslation('orderSearch');
  const isPartial = order.orderType === OrderTypes.Zusatzauftrag;
  const {
    allocationsLoading,
    order: rawOrder,
    orderPrice,
    allocationsOrders,
    setOrderCategoryProductKey,
    resetAllocations,
    reset,
    resetOrderReferences
  } = useOrderContext();

  // Checks for Pallets move, split & merge buttons
  const isPalletMoveAllowed =
    hasPermission(UserRights.PalletsMove) &&
    order?.orderCategoryProductKey &&
    order?.productionState &&
    checkWhitelistingWithProductionState(order.orderCategoryProductKey, order.productionState, WhitelistMethod.PALLETSMOVE);
  const isSplitAllowed =
    hasPermission(UserRights.OrderSplit) &&
    order?.orderCategoryProductKey &&
    order?.productionState &&
    checkWhitelistingWithProductionState(order.orderCategoryProductKey, order.productionState, WhitelistMethod.SPLIT);

  const isMergeAllowed =
    hasPermission(UserRights.OrderMerge) &&
    order?.orderCategoryProductKey &&
    order?.productionState &&
    checkWhitelistingWithProductionState(order.orderCategoryProductKey, order.productionState, WhitelistMethod.MERGE);

  const methods = useForm<OrderCreate>({
    mode: 'onBlur'
  });
  const helpLinkOnProduct = (): OnlineHelpLinks => {
    return OnlineHelpLinks[(order?.orderCategoryProductKey + '_DISPLAY') as keyof typeof OnlineHelpLinks];
  };

  const isPrintReady =
    rawOrder?.productGroup === ProductGroup.PARTIAL_SERVICE_LETTER ||
    rawOrder?.productGroup === ProductGroup.TEILLEISTUNG ||
    rawOrder?.productGroup === ProductGroup.COLLECTION
      ? true
      : !!orderPrice?.lastChangedAt;

  return (
    <>
      <ErrorBoundary context={'OderView-Toolbar-PrintDialog'}>
        <PrintDialog
          show={isPrintDialogOpen}
          orderId={order.orderId || ''}
          onCancel={() => setPrintDialogOpen.off()}
          rawOrder={{ ...order, orderPrice: orderPrice } as GetOrderRep}
          allocatedOrders={allocationsOrders}
        />
      </ErrorBoundary>
      <CancelOrder
        orderId={order.orderId || ''}
        orderLabel={order.orderLabel || ''}
        show={isCancelDialogOpen}
        onCancel={() => {
          setCancelDialogOpen.off();
        }}
        orderMode={OrderMode.VIEW}
      />
      <FormProvider {...methods}>
        <SplitOrderModal order={order} show={isSplitOrderDialogOpen} onCancel={() => setSplitOrderDialogOpen.off()} />
        <MovePalletModal order={order} show={isMovePalletDialogOpen} onCancel={() => setMovePalletDialogOpen.off()} />
      </FormProvider>

      <FormProvider {...methods}>
        <OrderMergeModal order={order} show={isMergeOrderDialogOpen} onCancel={() => setMergeOrderDialogOpen.off()} />
      </FormProvider>
      <div className={classNames(styles.toolbarList)}>
        <div className={classNames(styles.toolbarLists)}>
          <DHLButton
            name={'neuen-auftrag'}
            label={i18n.t('viewOrderToolBar.NewOrder')}
            type={'default'}
            size="sm"
            disabled={!hasPermission(UserRights.OrderEdit)}
            onClick={() => {
              clear();
              reset();
              resetOrderReferences();
              resetAllocations();
              setOrderCategoryProductKey(OrderCategoryProductKey.DiP_EINZEL__DIALOGPOST);
              history.push(AppPaths.orderCreatePath);
            }}
          />
        </div>
        <div className={classNames(styles.toolbarLists)} ref={focusRef} data-testid="focusWrapper">
          <DHLButton
            name={'more-functions'}
            icon={'more-functions'}
            iconPosition={'icon'}
            type={'default'}
            size="sm"
            onClick={() => {
              setMenuVisible.toggle();
            }}
          />

          <Menu ref={headerCellRef} isOpen={menuVisible} className={classNames(styles.toolbarcontextMenu)} data-testid="subMenu">
            <DHLButton
              name={'auftrag-bearbeiten'}
              label={i18n.t('viewOrderToolBar.toggleMenu.edit')}
              icon={'edit'}
              iconPosition={'icon-first'}
              type={'ghost'}
              size="sm"
              disabled={
                rawOrder?.pendingIds ||
                rawOrder?.disableEditing ||
                (order.orderCategoryProductKey && !checkWhitelistingKey(order.orderCategoryProductKey, WhitelistMethod.CHANGE)) ||
                !hasPermission(UserRights.OrderEdit) ||
                productionState === ProductionState.CANCELED ||
                productionState === ProductionState.CANCELED_AND_MERGED ||
                productionState === ProductionState.BILLED ||
                productionState === ProductionState.BEING_PROCESSED
              }
              onClick={() => {
                clear();
                history.push(`${AppPaths.orderChangePath}/${order.orderId}${isPartial ? '?orderType=ZA' : ''}`);
              }}
            />
            <DHLButton
              name={'auftrag-stornieren'}
              label={i18n.t('viewOrderToolBar.toggleMenu.delete')}
              icon={'delete'}
              iconPosition={'icon-first'}
              type={'ghost'}
              size="sm"
              disabled={
                rawOrder?.pendingIds ||
                (order.orderCategoryProductKey && !checkWhitelistingKey(order.orderCategoryProductKey, WhitelistMethod.DELETE)) ||
                !hasPermission(UserRights.OrderEdit) ||
                productionState === ProductionState.CANCELED ||
                productionState === ProductionState.CANCELED_AND_MERGED ||
                productionState === ProductionState.BILLED ||
                productionState === ProductionState.BEING_PROCESSED
              }
              onClick={async () => {
                setCancelDialogOpen.on();
                setCancelOrderStatus(undefined, '');
              }}
            />
            <DHLButton
              name={'auftrag-drucken'}
              label={i18n.t('viewOrderToolBar.toggleMenu.print')}
              icon={'print'}
              iconPosition={'icon-first'}
              type={'ghost'}
              size="sm"
              disabled={
                rawOrder?.pendingIds ||
                (order.orderCategoryProductKey && !checkWhitelistingKey(order.orderCategoryProductKey, WhitelistMethod.PRINT)) ||
                !hasPermission(UserRights.OrderPrint) ||
                ((order.productGroup === ProductGroup.PARTIAL_SERVICE_LETTER || order.productGroup === ProductGroup.TEILLEISTUNG) &&
                  allocationsLoading) ||
                !isPrintReady
              }
              onClick={() => {
                setMenuVisible.off();
                setPrintDialogOpen.on();
              }}
            />
            <DHLButton
              name={'auftrag-duplizieren'}
              label={i18n.t('viewOrderToolBar.toggleMenu.copy')}
              icon={'copy'}
              iconPosition={'icon-first'}
              type={'ghost'}
              size="sm"
              disabled={
                rawOrder?.pendingIds ||
                rawOrder?.disableEditing ||
                (order.orderCategoryProductKey && !checkWhitelistingKey(order.orderCategoryProductKey, WhitelistMethod.COPY)) ||
                !hasPermission(UserRights.OrderEdit) ||
                isPartial ||
                order.postage?.type === PostageType.DV ||
                order.orderCategory === OrderCategory.DIP_SAMMEL ||
                order.orderCategory === OrderCategory.DIP_VARIO ||
                order.orderCategory === OrderCategory.DV_BRIEF
              }
              onClick={() => {
                clear();
                history.push(`${AppPaths.orderCopyPath}/${order.orderId}`);
              }}
            />
            {isSplitAllowed && (
              <div className={styles.flexButton}>
                <Button
                  name={'auftrag-teilen'}
                  label={i18n.t('viewOrderFooter.menu.share')}
                  JSXIcon={<span className="icon icon-split"></span>}
                  type={'button'}
                  variant="ghost"
                  sizing="sm"
                  dataTestId="splitOrderButton"
                  onClick={() => {
                    setSplitOrderDialogOpen.on();
                    clear();
                  }}
                />
              </div>
            )}
            {isMergeAllowed && (
              <div className={styles.flexButton}>
                <Button
                  name={'auftrag-zusammenfuhren'}
                  label={i18n.t('viewOrderFooter.menu.merge')}
                  JSXIcon={<span className="icon icon-merge"></span>}
                  type={'button'}
                  variant="ghost"
                  sizing="sm"
                  dataTestId="mergeOrderButton"
                  onClick={() => {
                    resetOrderReferences();
                    resetAllocations();
                    setMergeOrderDialogOpen.on();
                  }}
                />
              </div>
            )}
            {isPalletMoveAllowed && (
              <div className={styles.flexButton}>
                <Button
                  name={'auftrag-paletten-verschieben'}
                  label={i18n.t('viewOrderFooter.menu.movePallets')}
                  JSXIcon={<span className="icon icon-move"></span>}
                  type={'button'}
                  variant="ghost"
                  sizing="sm"
                  dataTestId="movePalletsButton"
                  onClick={() => {
                    setMovePalletDialogOpen.on();
                    clear();
                  }}
                />
              </div>
            )}
          </Menu>
        </div>
        <OnlineHelp link={helpLinkOnProduct()} />
      </div>
    </>
  );
};
