import React, { useEffect, useRef, useState, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { SortOrder } from '@gkuis/gkp-base-widgets/dist/lib';

import { QtyAllocation } from 'order/common/context/order/dtos/Order';
import { Pagination } from 'common/components/Paginator/Pagination';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { getCurrentSlice, rowContainsFilter, sortRows } from 'order/common/context/table/utils/table';
import { TableLoadingState } from 'order/common/context/table/dtos/TableColumnData';

import i18n from 'i18n';

import transDE from './i18n/translate.de.json';
import transEN from './i18n/translate.en.json';

import idStyles from './idAssignment.module.css';

import { Range } from 'generated';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { Table } from 'common/components/Table/Table';
import { ColumnType } from 'common/components/Table/dtos/ColumnType';
import { Filter } from 'common/components/Table/dtos/Filter';
import { Sort } from 'common/components/Table/dtos/Sort';
import {
  shipmentTableConfigurationWithFrankingIdPrefix,
  shipmentTableConfigurationWithMachineCode,
  shipmentTableConfigurationWithShipmentQty
} from '../AllocationTable/columnConfiguration';
import { ErrorKeys, InternalHandler } from './IdAssignment';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import useError from 'common/hooks/useError';
import { isRangeOverlapping } from './helper/isRangeOverlapping';

import styles from './idAssignment.module.css';

interface Props {
  orderId?: string;
  productNumber?: string;
  allocQty?: number;
  allocatedValue?: string | number;
  ranges?: Range[];
  onChange?: (ranges: InternalHandler[], totalRangesQty: number) => void;
  onHasFrankingIdPrefix?: () => void;
  frankingIdPrefix?: string;
  machineCode?: string;
  disabled?: boolean;
  errorHandler?: (error: boolean) => void;
  postageFrankingIdPrefix?: string;
  frankingIdEncoding?: string;
  qtyAllocation?: QtyAllocation | null;
  productFormat?: string;
  isLetter?: boolean;
  readOnly?: boolean;
  header: 'machineCode' | 'frankingIdPrefix';
  meta: { language: string };
  orderMode?: OrderMode;
  inModal?: boolean;
  id: string;
  showShipmentQtyColumn?: boolean;
}

export interface shipmentTableData {
  data?: InternalHandler;
  id: string;
}

i18n.addResourceBundle('de', 'shipmentAssignment', transDE);
i18n.addResourceBundle('en', 'shipmentAssignment', transEN);

export const IdAssignmentReadOnlyTable: VFC<Props> = ({ id, ...props }) => {
  const hasNonStandardEncoding = props.frankingIdEncoding === 'HEX' || props.frankingIdEncoding === 'C40' || props.frankingIdEncoding === 'DEC';
  const { t } = useTranslation('', { keyPrefix: 'orderTable.tables' });
  const { t: st } = useTranslation('shipmentAssignment');
  const language = useAuthContext().user.language;
  const { meta, upsertMetaData } = useOrderContext();

  const transformedRanges: shipmentTableData[] =
    props.ranges
      ?.map((range, key) => {
        const updatedRange = { ...range, frankingIdPrefix: props?.postageFrankingIdPrefix || props?.machineCode };
        return {
          data: updatedRange,
          id: `${range.from}-${range.to}-${key}`
        } as unknown as shipmentTableData;
      })
      .sort((a, b) => (a.data?.from && b.data?.from ? a.data?.from - b.data?.from : 0)) || [];

  const { setErrors, clearError, getError, hasErrors } = useError();
  const [rows, setRows] = useState<shipmentTableData[]>([]);
  const [currentSlice, setCurrentSlice] = useState<shipmentTableData[]>(meta?.[`shippingTableCurrentSlice${id}`] || []);
  const filter = useRef<Filter>({ name: null, value: null });
  const sort = useRef<Sort>(meta?.[`shippingTableCurrentSort${id}`] || { name: null, order: null });
  const sorting = useRef<Sort>(meta?.[`shippingTableCurrentSorting${id}`] || { name: null, order: SortOrder.ASC, type: ColumnType.CUSTOM_CELL });
  const page = useRef<number>(meta?.[`shippingTableCurrentPage${id}`] || 1);
  const defaultPageSize = 5;
  const pageSize = useRef<number>(
    meta?.[`shippingTableCurrentPageSize${id}`] === 0 ? 0 : meta?.[`shippingTableCurrentPageSize${id}`] || defaultPageSize
  );
  const filteredRows = useRef<shipmentTableData[]>(meta?.[`shippingTableFilterRows${id}`] || []);

  const filterRows = (allocationsOrders: shipmentTableData[]) => {
    let filtered = [...allocationsOrders];
    const { name, value } = filter.current;
    if (name && value) {
      filtered = filtered.filter((r) => rowContainsFilter(name, value, r));
    }
    return filtered;
  };

  useEffect(() => {
    setRows(transformedRanges || []);
    const filtered = filterRows(transformedRanges);
    const sortedRows = sortRows(
      meta?.[`shippingTableCurrentSorting${id}`]?.name || sorting.current.name,
      filtered || [],
      meta?.[`shippingTableCurrentSorting${id}`]?.order || sorting.current.order
    );
    const curSlice = getCurrentSlice(
      sortedRows,
      meta?.[`shippingTableCurrentPage${id}`] || page.current,
      meta?.[`shippingTableCurrentPageSize${id}`] || pageSize.current
    );
    filteredRows.current = sortedRows || [];
    upsertMetaData(`shippingTableFilterRows${id}`, filteredRows.current);
    setCurrentSlice(curSlice);
  }, []);

  useEffect(() => {
    if (props.ranges) {
      const newRanges = props.ranges.map((range) => {
        return {
          ...range,
          to: hasNonStandardEncoding ? range.to : Number(range.to),
          from: hasNonStandardEncoding ? range.from : Number(range.from),
          id: id
        };
      }) as InternalHandler[];
      if (newRanges.length > 1 && !hasNonStandardEncoding) {
        const overlaps = isRangeOverlapping(newRanges);
        Object.keys(overlaps).map((key) => setErrors(key, [[ErrorKeys.OVERLAP, overlaps[parseInt(key)]]]));
      }
    }
  }, [props.ranges]);

  const setSorting = (name: string, order: SortOrder, type: ColumnType) => {
    const filtered = [...filteredRows.current];
    const sortedRows = sortRows(name, filtered, order);
    const currentSlice = getCurrentSlice(sortedRows, page.current, pageSize.current);
    sort.current = { name, order, type };
    upsertMetaData(`shippingTableCurrentSort${id}`, { name, order, type });
    sorting.current = { name, order, type };
    upsertMetaData(`shippingTableCurrentSorting${id}`, sorting.current);
    filteredRows.current = sortedRows;
    upsertMetaData(`shippingTableFilterRows${id}`, sortedRows);
    setCurrentSlice(currentSlice);
    upsertMetaData(`shippingTableCurrentSlice${id}`, currentSlice);
  };

  const handlePageSizeChange = (size: number, id: string) => {
    let newSlice = [...filteredRows.current];

    page.current = 1;
    upsertMetaData(`shippingTableCurrentPage${id}`, 1);

    if (size > 0) {
      newSlice = getCurrentSlice(filteredRows.current, page.current, size);
    }

    pageSize.current = size;
    upsertMetaData(`shippingTableCurrentSlice${id}`, newSlice);
    upsertMetaData(`shippingTableCurrentPageSize${id}`, size);
    setCurrentSlice(newSlice);
  };

  const handlePageChange = (p: number, id?: string) => {
    const newSlice = getCurrentSlice(filteredRows.current, p, pageSize.current);
    page.current = p;
    upsertMetaData(`shippingTableCurrentPage${id}`, p);
    upsertMetaData(`shippingTableCurrentSlice${id}`, newSlice);
    setCurrentSlice(newSlice);
  };

  return (
    <>
      <div>
        {props.frankingIdEncoding !== 'DEC' && (
          <p className={styles.frankingIDEncodingText}>
            {t('frankingIdEncoding')}: <span>{`${props?.frankingIdEncoding}`}</span>
          </p>
        )}
        <Table
          columnConfig={
            props?.showShipmentQtyColumn
              ? shipmentTableConfigurationWithShipmentQty()
              : props?.header === 'frankingIdPrefix'
              ? shipmentTableConfigurationWithFrankingIdPrefix()
              : shipmentTableConfigurationWithMachineCode()
          }
          currentSlice={currentSlice || []}
          loadingState={TableLoadingState.LOADED}
          t={t}
          filter={filter.current}
          sort={sort.current}
          onSortChange={setSorting}
          language={language}
          isShippingTable={true}
        />
        {getError(id, ErrorKeys.OVERLAP) && (
          <div
            style={{
              padding: '10px',
              background: '#f5f5f5'
            }}
            className={idStyles.error}
          >
            {st('error.rangeOverflowError')}
          </div>
        )}
        {filteredRows.current.length > defaultPageSize && (
          <div className={idStyles.actionBar}>
            <Pagination
              loadingState={TableLoadingState.LOADED}
              totalItems={filteredRows.current.length}
              defaultPageSize={defaultPageSize}
              onPageChange={(e) => handlePageChange(e, id)}
              onSizeChange={(e) => handlePageSizeChange(e, id)}
              currentPage={meta?.[`shippingTableCurrentPageSize${id}`] > filteredRows.current.length ? 1 : meta?.[`shippingTableCurrentPage${id}`]}
              currentPageSize={meta?.[`shippingTableCurrentPageSize${id}`]}
            />
          </div>
        )}
      </div>
    </>
  );
};
