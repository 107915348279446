import React, { useEffect, useMemo, useRef, useState, VFC } from 'react';
import { observer } from 'mobx-react-lite';
import { DHLFormGroup } from '@gkuis/gkp-base-widgets/dist/lib';

import { toFormattedDate } from 'common/formatting';
import { useTranslation } from 'i18n';
import { useProductionFacilities } from 'order/common/hooks/useProductionFacilities';
import { CustomerDetailSection } from 'order/common/components/Overview/CustomerDetailSection';
import { DialogPostContainer } from 'order/productGroups/common/components/atom/DialogPostContainer';
import { OrderStepHeadline } from 'order/productGroups/common/components/atom/OrderStepHeadline';
import { OrderOverviewRow, OrderOverviewWidget } from 'order/productGroups/common/components/molecule/OrderOverview';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { ShipmentQuantityFeeReduction } from '../ShipmentQuantityFeeReduction/ShipmentQuantityFeeReduction';
import { ShipmentQuantityFeeReductionFourColumn } from '../ShipmentQuantityFeeReductionFourColumn/ShipmentQuantityFeeReductionFourColumn';
import { QtyAllocation } from 'order/common/context/order/dtos/Order';
import { OrderReference } from 'order/common/context/order/dtos/OrderReference';
import { preProcessOrderReferencesForPartialService } from 'order/common/context/order/utils/preProcessOrderReferencesForPartialService';
import { OrderCustomerRoleEnum } from 'generated';
import iconDataEdit from 'assets/icon-data-edit.svg';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { OrderPickupShippingDisplay } from 'order/productGroups/common/components/atom/OrderPickupShippingDisplay';
import { isValidDate } from 'order/common/utils/isValidDate';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { AdditionalOrdersOrderCreate } from 'order/productGroups/additionalOrders/schema/additionalOrdersSchema';
import { isAvailableFromJan25 } from '../../../../../common/utils/availabilityByDate';

export type additionalOrdersOverviewProps = {
  value: AdditionalOrdersOrderCreate;
  orderMode: OrderMode;
  orderReferences: OrderReference[];
};

export const PartialServiceLetterOverview: VFC<additionalOrdersOverviewProps> = observer(({ value, orderMode, orderReferences }) => {
  const { getFacility } = useProductionFacilities(value.details.date);
  const { order, orderCategory, meta, orderMode: oMode, orderCategoryProductKey, allocationsLoading, allocationsOrders } = useOrderContext();
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step4Overview' });
  const facility = getFacility(value.details.productionPlantId || '');
  const qtyAllocation = useRef<QtyAllocation[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [_, setUpdated] = useState(new Date().getTime());
  const validDate = isValidDate(value.details.date);
  const postingDate = !validDate && value.details.date ? new Date(value.details.date) : value.details.date;
  const isCollection = orderCategory === OrderCategory.ABHOLAUFTRAG;
  const { t: tCatalogValues } = useTranslation('catalogValues');
  const language = useAuthContext().user.language;
  const partialParties = useMemo(() => {
    return [
      {
        role: OrderCustomerRoleEnum.Payer,
        customerId: value.payer
      },
      {
        role: OrderCustomerRoleEnum.Submitter,
        customerId: value.submitter
      },
      {
        role: OrderCustomerRoleEnum.Contractor,
        customerId: value.contractor
      },
      ...(value.otherPartners?.map((partner) => ({
        role: partner.role,
        customerId: partner.customerId
      })) || [])
    ];
  }, [value]);
  const collectionParties = useMemo(() => {
    return [
      {
        role: OrderCustomerRoleEnum.Payer,
        customerId: value.payer
      },
      {
        role: OrderCustomerRoleEnum.Submitter,
        customerId: value.submitter
      },
      ...(value.otherPartners?.map((partner) => ({
        role: partner.role,
        customerId: partner.customerId
      })) || [])
    ];
  }, [value]);

  const generateQtyAllocation = async (orderRefs: OrderReference[]) => {
    qtyAllocation.current = [];
    const preProcessedOrderRefs = await preProcessOrderReferencesForPartialService(orderRefs, orderMode);
    for (const o of preProcessedOrderRefs) {
      o.qtyAllocation.forEach((qtyAlloc) => {
        qtyAllocation.current.push(qtyAlloc);
      });
    }
    setUpdated(new Date().getTime());
    setLoaded(true);
  };

  useEffect(() => {
    if (orderReferences?.length) {
      generateQtyAllocation(orderReferences);
    }
  }, [orderReferences]);

  const { t: translateCategory } = useTranslation('translation', { keyPrefix: 'orderCategoryByKey' });

  return (
    <DialogPostContainer>
      <DHLFormGroup>
        {orderMode !== OrderMode.VIEW && (
          <>
            <OrderStepHeadline icon={iconDataEdit} alt={'data Edit Icon'}>
              {`${oc('orderOverview')}`}
            </OrderStepHeadline>
            <h6 style={{ marginBottom: '-10px' }}>{oc('orderInfo')}</h6>

            <OrderOverviewRow>
              <OrderOverviewWidget width={1}>
                <table>
                  <tr>
                    <td>
                      <p>{`${oc('orderCategory')}:`}</p>
                    </td>
                    <td>
                      <p>{order?.orderCategoryProductKey ? translateCategory(order?.orderCategoryProductKey as string) : orderCategory}</p>
                    </td>
                  </tr>
                </table>
              </OrderOverviewWidget>
              <OrderOverviewWidget width={1}>
                <table>
                  <tr>
                    <td>
                      <p>{`${oc('orderName')}:`}</p>
                    </td>
                    <td>
                      <p>{value.orderLabel}</p>
                    </td>
                  </tr>
                </table>
              </OrderOverviewWidget>
              <OrderOverviewWidget width={1}></OrderOverviewWidget>
              {order?.orderId && orderMode !== OrderMode.COPY && (
                <OrderOverviewWidget width={1}>
                  <table>
                    <tr>
                      <td>
                        <p>{`${oc('orderNumber')}:`}</p>
                      </td>
                      <td>
                        <p>{order?.orderId}</p>
                      </td>
                    </tr>
                  </table>
                </OrderOverviewWidget>
              )}
              {order?.customerOrderId && orderMode !== OrderMode.COPY && (
                <OrderOverviewWidget width={1}>
                  <table>
                    <tr>
                      <td>
                        <p>{`${oc('customerOrderNumber')}:`}</p>
                      </td>
                      <td>
                        <p>{order?.customerOrderId}</p>
                      </td>
                    </tr>
                  </table>
                </OrderOverviewWidget>
              )}
              {!isCollection && (oMode === OrderMode.COPY || oMode === OrderMode.CHANGE) && <OrderOverviewWidget width={1}></OrderOverviewWidget>}
              {!isCollection && (
                <OrderOverviewWidget width={1}>
                  <table>
                    <tr>
                      <td>
                        <p>{`${oc('partialServiceNumber')}:`}</p>
                      </td>
                      <td>
                        <p>{value.partialServiceNumber}</p>
                      </td>
                    </tr>
                  </table>
                </OrderOverviewWidget>
              )}
            </OrderOverviewRow>
          </>
        )}
        {/*
        orderPhase !== OrderMode.VIEW && (
          <OrderOverviewRow>
            <OrderOverviewWidget width={1}>
              <table>
                <tr>
                  <td>
                    <h6>{oc('feeReductions')}</h6>
                  </td>
                  <td>
                    <p>{oc('discountsAfterOrder')}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>{oc('partialServiceDiscount')}</p>
                  </td>
                  <td>
                    <p></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>{oc('idDiscount')}</p>
                  </td>
                  <td>
                    <p></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>{oc('termDiscount')}</p>
                  </td>
                  <td>
                    <p></p>
                  </td>
                </tr>
              </table>
            </OrderOverviewWidget>
          </OrderOverviewRow>
        )
        */}
        <OrderOverviewRow>
          <OrderOverviewWidget width={1} title={oc('product')}>
            <table data-testid={'product-information-table'}>
              <tr>
                <td>
                  <p>{isCollection ? 'Abholung Allgemein' : value.product ? oc(value.product) : ''}</p>
                </td>
              </tr>
            </table>
          </OrderOverviewWidget>
        </OrderOverviewRow>
        <OrderOverviewRow>
          <OrderPickupShippingDisplay
            data={{
              date: postingDate,
              pickup: !!value.pickupAddress,
              time: value.details.time,
              facility,
              contactForQuestions: value.contactForQuestions,
              pickupAddress: { orderAddress: value.pickupAddress }
            }}
          />
          {isCollection && (
            <OrderOverviewWidget title={oc('bundles')}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>{oc('typeOfBundle')}</p>
                    </td>
                    <td>
                      <p>{tCatalogValues('mailEntityType.display.PAL')}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>{oc('numberOfBundles')}</p>
                    </td>
                    <td>
                      <p>{meta?.sumTotalPallets}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>{oc('totalNetWeight')}:</p>
                    </td>
                    <td>
                      <p>{meta?.sumTotalNetWeight ? `${meta?.sumTotalNetWeight} kg` : ''}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>{oc('stackable')}</p>
                    </td>
                    <td>
                      <p>{meta?.stackable ? 'ja' : 'nein'}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </OrderOverviewWidget>
          )}
        </OrderOverviewRow>
        {!isCollection && !isAvailableFromJan25(postingDate) && (
          <ShipmentQuantityFeeReduction qtyAlloc={qtyAllocation.current} pickupDate={postingDate} loaded={loaded} />
        )}
        {!isCollection && isAvailableFromJan25(postingDate) && (
          <ShipmentQuantityFeeReductionFourColumn
            qtyAlloc={qtyAllocation.current}
            loaded={loaded}
            allocationsLoading={allocationsLoading}
            allocationsOrders={allocationsOrders}
            orderReferences={orderReferences}
          />
        )}
        <CustomerDetailSection
          parties={isCollection ? collectionParties : partialParties}
          orderCategoryProductKey={orderCategoryProductKey}
          validityDate={order?.orderDetail?.date}
        />
      </DHLFormGroup>
    </DialogPostContainer>
  );
});
