import React, { useEffect, useState } from 'react';
import { CustomTextInput } from 'order/common/components/CustomTextInput';
import { FormRow } from 'common/components/FormRow';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { Controller, FieldValues, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'i18n';
import { DHLCheckbox } from '@gkuis/gkp-base-widgets/dist/lib';
import { InternationalDestinationSectionSchema } from '../../schema/internationalClusterSchema';
import { SFCProps } from '../../../../../common/utils/formHelpers';
import { useOrderContext } from '../../../../common/context/order/OrderContext';

export type InternationalDestinationSectionProps<T extends FieldValues> = SFCProps<T, InternationalDestinationSectionSchema> & {
  showEcomony?: boolean;
  showMixed?: boolean;
  disabled?: boolean;
};

export const InternationalDestinationSection = <T extends FieldValues>({
  name,
  showEcomony,
  showMixed,
  disabled
}: InternationalDestinationSectionProps<T>) => {
  const { t } = useTranslation('orderCreate');
  const { setValue, control, trigger } = useFormContext();
  const { upsertMetaData } = useOrderContext();
  const [priority, economy, mixed, totalWeight, packagingQuantity] = useWatch({
    control,
    name: [`${name}.priority`, `${name}.economy`, `${name}.mixed`, `${name}.totalWeight`, 'packaging.quantity']
  });

  const [priorityError, setPriorityError] = useState('');
  const [economyError, setEconomyError] = useState('');
  const [mixedError, setMixedError] = useState('');
  const [totalWeightError, setTotalWeightError] = useState('');

  useEffect(() => {
    upsertMetaData('internationalPackagingSum', (priority ? priority : 0) + (economy ? economy : 0) + (mixed ? mixed : 0));
  }, [priority, economy, mixed]);

  useEffect(() => {
    const quantity = packagingQuantity || 0;
    quantity > 0 && totalWeight && totalWeight / quantity > 700
      ? setTotalWeightError(t(`palletsInternational.error.totalWeight`))
      : setTotalWeightError('');
  }, [packagingQuantity]);

  const priorityInputHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e?.target?.valueAsNumber <= 0) {
      setPriorityError(t('error.min'));
      setValue(`${name}.priority`, e?.target?.valueAsNumber as any);
    } else if (isNaN(e?.target?.valueAsNumber)) {
      setPriorityError('');
      setValue(`${name}.priority`, 0 as any);
    } else {
      setPriorityError('');
      setValue(`${name}.priority`, e?.target?.valueAsNumber as any);
    }
  };
  const economyInputHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e?.target?.valueAsNumber <= 0) {
      setEconomyError(t('error.min'));
      setValue(`${name}.economy`, e?.target?.valueAsNumber as any);
    } else if (isNaN(e?.target?.valueAsNumber)) {
      setEconomyError('');
      setValue(`${name}.economy`, 0 as any);
    } else {
      setEconomyError('');
      setValue(`${name}.economy`, e?.target?.valueAsNumber as any);
    }
  };
  const mixedInputHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e?.target?.valueAsNumber <= 0) {
      setMixedError(t('error.min'));
      setValue(`${name}.mixed`, e?.target?.valueAsNumber as any);
    } else if (isNaN(e?.target?.valueAsNumber)) {
      setMixedError('');
      setValue(`${name}.mixed`, 0 as any);
    } else {
      setMixedError('');
      setValue(`${name}.mixed`, e?.target?.valueAsNumber as any);
    }
  };

  const totalWeightInputHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    setValue(`${name}.totalWeight`, (e?.target?.valueAsNumber as any) ?? 0);
    trigger(`${name}.totalWeight`);
    const totalWeight = e?.target?.valueAsNumber || 0;
    const quantity = packagingQuantity || 0;
    quantity > 0 && totalWeight / quantity > 700 ? setTotalWeightError(t(`palletsInternational.error.totalWeight`)) : setTotalWeightError('');
  };

  return (
    <>
      <OrderStepSection headline={t('palletsInternational.palletsPerIPZ')}>
        <FormRow mode="two-col">
          <CustomTextInput
            label={t('palletsInternational.priority')}
            defaultValue={priority}
            onBlur={priorityInputHandler}
            testid={`priority`}
            error={priorityError}
            disabled={disabled}
            name={`${name}.priority`}
            type="number"
          />
        </FormRow>
        {showEcomony && (
          <FormRow mode="two-col">
            <CustomTextInput
              label={t('palletsInternational.economy')}
              defaultValue={economy}
              onBlur={economyInputHandler}
              testid={`economy`}
              error={economyError}
              disabled={disabled}
              name={`${name}.economy`}
              type="number"
            />
          </FormRow>
        )}
        {showMixed && (
          <FormRow mode="two-col">
            <CustomTextInput
              label={t('palletsInternational.mixed')}
              defaultValue={mixed}
              onBlur={mixedInputHandler}
              testid={`mixed`}
              error={mixedError}
              disabled={disabled}
              name={`${name}.mixed`}
              type="number"
            />
          </FormRow>
        )}
      </OrderStepSection>
      <OrderStepSection headline={t('palletsInternational.weightOfPallets')}>
        <FormRow mode="two-col">
          <CustomTextInput
            name={`${name}.totalWeight`}
            label={t('palletsInternational.totalWeightLabel')}
            testid={`totalWeight`}
            disabled={disabled}
            defaultValue={totalWeight}
            onBlur={totalWeightInputHandler}
            type="number"
            error={totalWeightError}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Controller
              name={`${name}.stackable`}
              render={({ field: { ref, ...field } }) => (
                <DHLCheckbox
                  disabled={disabled}
                  label={t('palletsInternational.stackable')}
                  {...field}
                  onChange={() => field.onChange(!field.value)}
                />
              )}
            />
          </div>
        </FormRow>
      </OrderStepSection>
    </>
  );
};
