import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';
import classNames from 'classnames';

import { useAlerts } from 'common/AlertContext';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { UserRights } from 'common/dtos/userRights';
import { envValues } from 'common/environment/env';
import { useBoolean } from 'common/hooks/useBoolean';
import { AppPaths } from 'Main';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';
import { WhitelistMethod } from 'order/common/dtos/OrderMode';
import { PrintDialog } from 'order/orderView/components/PrintDialog/PrintDialog';
import { CancelOrder } from 'order/productGroups/common/components/molecule/CancelOrder/CancelOrder';
import { PostageType } from 'order/common/dtos/PostageType';
import { OrderTypes } from 'order/orderSearch/components/OrderTypes';
import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../table.module.css';

export interface Props {
  statusData?: string;
  id: string;
  noOfChildren?: number;
  orderId?: string;
  orderLabel?: string;
  postageType?: PostageType | string;
  orderCategory?: string;
  orderType?: OrderTypes | string;
  orderCategoryProductKey?: OrderCategoryProductKey;
}

export const TableIconCell: React.FC<Props> = (props): JSX.Element => {
  const plaMatcher: string = 'PLA' || 'In planning';
  const [showCancelOrderModal, setShowCancelOrderModal] = useBoolean(false);
  const [showPrintDialogModal, setShowPrintDialogModal] = useBoolean(false);
  const history = useHistory();
  const { checkWhitelistingKey, hasPermission } = useAuthContext();
  const { clear } = useAlerts();
  // TODO clean-up all order category related cases and only use whitelisting
  const orderCategoryUpdateWhitelisted = props.orderCategoryProductKey && checkWhitelistingKey(props.orderCategoryProductKey, WhitelistMethod.CHANGE);
  const orderCategoryCopyWhitelisted = props.orderCategoryProductKey && checkWhitelistingKey(props.orderCategoryProductKey, WhitelistMethod.COPY);
  const orderCategoryReadWhitelisted = props.orderCategoryProductKey && checkWhitelistingKey(props.orderCategoryProductKey, WhitelistMethod.GET);
  const orderCategoryDeleteWhitelisted = props.orderCategoryProductKey && checkWhitelistingKey(props.orderCategoryProductKey, WhitelistMethod.DELETE);
  const orderCategoryPrintWhitelisted = props.orderCategoryProductKey && checkWhitelistingKey(props.orderCategoryProductKey, WhitelistMethod.PRINT);
  const additionalOrderPartialServiceDisable = props.orderType === OrderTypes.Zusatzauftrag;
  const isMissingPermissionForPartialService = props.orderType === OrderTypes.Zusatzauftrag ? !hasPermission(UserRights.ProductTL) : false;
  const detailsDisabled = !orderCategoryReadWhitelisted || !hasPermission(UserRights.OrderShow) || props.orderType === OrderTypes.Teileinlieferung;
  const editDisabled =
    !orderCategoryUpdateWhitelisted ||
    !hasPermission(UserRights.OrderEdit) ||
    plaMatcher !== props.statusData ||
    isMissingPermissionForPartialService;
  const copyDisabled =
    !orderCategoryCopyWhitelisted ||
    !hasPermission(UserRights.OrderEdit) ||
    additionalOrderPartialServiceDisable ||
    props.postageType === PostageType.DV ||
    isMissingPermissionForPartialService;
  const deleteDisabled =
    !orderCategoryDeleteWhitelisted ||
    !hasPermission(UserRights.OrderEdit) ||
    plaMatcher !== props.statusData ||
    isMissingPermissionForPartialService;
  const printDisabled = !orderCategoryPrintWhitelisted || !orderCategoryReadWhitelisted || !hasPermission(UserRights.OrderPrint);

  const orderTypeSearchQuery = props.orderType === OrderTypes.Zusatzauftrag ? `?orderType=ZA` : '';

  return (
    <>
      <CancelOrder
        orderId={props.orderId as string}
        orderLabel={props.orderLabel as string}
        show={showCancelOrderModal}
        onCancel={() => setShowCancelOrderModal.off()}
      />
      <PrintDialog show={showPrintDialogModal} orderId={props.orderId as string} onCancel={() => setShowPrintDialogModal.off()} />
      <div className={classNames(styles.amTableIconFlexBox)}>
        <DHLButton
          name={props.id + '-details'}
          type="default"
          icon="details"
          onClick={() => {
            clear();
            history.push(`${AppPaths.orderViewPath}/${props.orderId}${orderTypeSearchQuery}`);
          }}
          tooltip={!hasPermission(UserRights.OrderShow) ? 'Für die Auftragsanzeige haben Sie nicht die notwendigen Rechte' : 'Anzeigen'}
          tooltipPlacement="top"
          iconPosition="icon"
          disabled={detailsDisabled}
          className={classNames(styles.amTableCustomIcon)}
        />
        <DHLButton
          name={props.id + '-edit'}
          type="default"
          icon="edit"
          onClick={() => {
            clear();
            history.push(`${AppPaths.orderChangePath}/${props.orderId}${orderTypeSearchQuery}`);
          }}
          tooltip={!hasPermission(UserRights.OrderEdit) ? 'Für die Auftragsbearbeitung haben Sie nicht die notwendigen Rechte' : 'Bearbeiten'}
          tooltipPlacement="top"
          iconPosition="icon"
          disabled={editDisabled}
          className={classNames(styles.amTableCustomIcon)}
        />
        <DHLButton
          name={props.id + '-copy'}
          type="default"
          icon="copy"
          onClick={() => {
            clear();
            history.push(`${AppPaths.orderCopyPath}/${props.orderId}${orderTypeSearchQuery}`);
          }}
          tooltip={!hasPermission(UserRights.OrderEdit) ? 'Für das Kopieren eines Auftrags haben Sie nicht die notwendigen Rechte' : 'Kopieren'}
          tooltipPlacement="top"
          iconPosition="icon"
          className={classNames(styles.amTableCustomIcon)}
          disabled={copyDisabled}
        />
        <DHLButton
          name={props.id + '-delete'}
          icon="storniert"
          onClick={() => setShowCancelOrderModal.on()}
          tooltip={!hasPermission(UserRights.OrderEdit) ? 'Für die Auftragsstornierung haben Sie nicht die notwendigen Rechte' : 'Stornieren'}
          tooltipPlacement="top"
          iconPosition="icon"
          disabled={deleteDisabled}
          className={classNames(styles.amTableCustomIcon)}
        />
        <DHLButton
          name={props.id + '-print'}
          icon="print"
          onClick={() => setShowPrintDialogModal.on()}
          tooltip={!hasPermission(UserRights.OrderPrint) ? 'Für das Auftragsdrucken haben Sie nicht die notwendigen Rechte' : 'Drucken'}
          tooltipPlacement="top"
          iconPosition="icon"
          disabled={printDisabled}
          className={classNames(styles.amTableCustomIcon)}
        />
      </div>
    </>
  );
};
