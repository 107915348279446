import React, { ReactElement } from 'react';
import { DHLHeadline } from '@gkuis/gkp-base-widgets/dist/lib';

import i18n, { useTranslation } from 'i18n';
import { Shipment } from 'order/productGroups/common/components/molecule/DisplayQuantityWeightSection';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';

import styles from './CollectivePostingVariantsAdaptation.module.css';

export type CollectivePostingVariantsAdaptationProps = {
  orderCategory?: OrderCategory;
  shipmentData: Shipment[];
  meta: { language: string };
};

const translationDE = {
  collectivePostHeading: 'Sendungsmengen und -gewichte einer Sammeleinlieferung',
  variationAdaptationHeading: 'Sendungsmengen und -gewichte der Varianten',
  designation: 'Bezeichnung',
  shipmentQuantity: 'Sendungsmenge',
  paidUp: 'Aufgezahlt',
  singleWeightInG: 'Einzelgewicht in g'
};
const translationEN = {
  collectivePostHeading: 'Item quantities and weights of a collective posting',
  variationAdaptationHeading: 'Shipment quantities and weights of the variants',
  designation: 'Designation',
  shipmentQuantity: 'Shipment quantity ',
  paidUp: 'Paid up',
  singleWeightInG: 'Single weight in g'
};

i18n.addResourceBundle('de', 'CollectivePostingVariantsAdaptation', translationDE);
i18n.addResourceBundle('en', 'CollectivePostingVariantsAdaptation', translationEN);

export const CollectivePostingVariantsAdaptation = (props: CollectivePostingVariantsAdaptationProps): ReactElement => {
  const { t } = useTranslation('CollectivePostingVariantsAdaptation');
  const language = props.meta.language;
  const isDPHeavy = props.orderCategory === OrderCategory.DHL_INFOPOST;
  return (
    <>
      <DHLHeadline type="h6" className={`${styles.variantsTableHeading}`}>
        {props.orderCategory === OrderCategory.DIP_SAMMEL ? t('collectivePostHeading') : t('variationAdaptationHeading')}
      </DHLHeadline>
      <table className={isDPHeavy ? `${styles.collectiveTableWithThreeCol} ${styles.collectiveTable}` : ` ${styles.collectiveTable}`}>
        <thead>
          <tr>
            <th>
              <p>{t('designation')}</p>
            </th>
            <th>
              <p>{t('shipmentQuantity')}</p>
            </th>
            {!isDPHeavy && (
              <th>
                <p>{t('paidUp')}</p>
              </th>
            )}
            <th>
              <p>{t('singleWeightInG')}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.shipmentData?.map((item, idx) => {
            return (
              <tr key={idx}>
                <td>
                  <p>{item.description}</p>
                </td>
                <td>
                  <p>{item.totalQuantity?.toLocaleString(language)}</p>
                </td>
                {!isDPHeavy && (
                  <td>
                    <p>{item.additionalQuantity?.toLocaleString(language)}</p>
                  </td>
                )}
                <td>
                  <p>{item.individualWeight?.toLocaleString(language)}</p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
