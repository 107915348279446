import { ObjectContaining } from 'common/utils/formHelpers';
import { CreatePartialOrder, OrderCustomer, OrderCustomerRoleEnum, OrderDetail } from 'generated';
import { Order, QtyAllocation } from 'order/common/context/order/dtos/Order';
import { OrderReference } from 'order/common/context/order/dtos/OrderReference';
import { preProcessOrderReferencesForPartialService } from 'order/common/context/order/utils/preProcessOrderReferencesForPartialService';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { isValidDate } from 'order/common/utils/isValidDate';
import { OtherPartnerSchema } from 'order/productGroups/common/components/atom/AMDHLOtherPartners/otherPartnerSchema';
import { ContactSchema } from 'order/productGroups/common/components/molecule/ContactSection/contactSchema';
import { OrderTypes } from 'order/orderSearch/components/OrderTypes';
import { UseFormSetError } from 'react-hook-form';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { ProductionState } from '../../../common/context/order/dtos/ProductionState';
import { AddressSectionSchema } from 'order/productGroups/common/components/molecule/AddressSection/addressSectionSchema';
import { OrderCategory } from '../../../common/context/order/dtos/OrderCategory';
import { maxiBriefDiscountAvailable } from '../utils/maxiBriefDeliveryDiscountDiscontinuedDate';
import { validateSettlementNumber } from '../../../../common/utils/helpers';

interface QtyAlloc extends QtyAllocation {
  orderNumber?: string;
}

export type AdditionalOrdersOrderCreate = {
  productGroup: ProductGroup.PARTIAL_SERVICE_LETTER | string;
  customerOrderId?: string;
  orderLabel?: string;
  productionState?: ProductionState;
  orderType?: string;
  submitter: string;
  partialServiceNumber?: string;
  details: { date: Date; productionPlantId?: string; time?: string };
  product: 'BZA' | 'BZE' | undefined;
  contactForQuestions?: ContactSchema;
  otherPartners?: OtherPartnerSchema[];
  parties?: OrderCustomer[];
  orderDetail?: OrderDetail;
  orderReferences?: OrderReference[];
  contractor?: string;
  netPayroll?: boolean;
  allocOrders?: any[];
  allocOrderIds?: string[];
  selectedAllocOrders?: any[];
  selectedSearchedOrderIds?: string[];
  currentSelectedSlice?: any[];
  qtyAlloc?: QtyAlloc[];
  orderId?: string;
  searchedOrders?: any[];
  filteredSelectedAllocOrders?: any[];
  selectAll?: boolean;
  change?: boolean;
  pickupAddress?: AddressSectionSchema;
  payer?: string;
  maxiBriefDiscountAvailable?: boolean;
};

export const validateAdditionalOrdersOrderCreate = (
  values: AdditionalOrdersOrderCreate | undefined,
  setError: UseFormSetError<AdditionalOrdersOrderCreate>
): boolean => {
  let validates = true;
  const error = setError as unknown as UseFormSetError<ObjectContaining<AdditionalOrdersOrderCreate>>;
  const customerIdRegex = /^\s*(?:\d\s*){10}$/;

  if (!values?.product) {
    validates = false;
    error(`product`, { type: 'required', message: 'required' });
  }
  if (!values?.submitter) {
    validates = false;
    error(`submitter`, { type: 'required', message: 'required' });
  }
  if (!values?.contractor) {
    validates = false;
    error(`contractor`, { type: 'required', message: 'required' });
  }
  if (!values?.details?.date) {
    validates = false;
    error(`details.date`, { type: 'required', message: 'required' });
  }
  if (values?.otherPartners && values?.otherPartners?.length) {
    values?.otherPartners?.map((p, i) => {
      if (p.role && !p.customerId?.length) {
        validates = false;
        error(`otherPartners[${i}].customerId`, { type: 'required', message: 'error.customerIdUnknown' });
      } else if (!p.role && p.customerId?.length) {
        validates = false;
        error(`otherPartners[${i}].role`, { type: 'required', message: 'error.partnerRole' });
      } else if (p.role && p.customerId && p.customerId.length && !customerIdRegex.test(p.customerId)) {
        validates = false;
        error(`otherPartners[${i}].customerId`, { type: 'required', message: 'error.customerIdUnknown' });
      }
    });
  }
  if (!values?.details?.productionPlantId) {
    validates = false;
    error(`details.productionPlantId`, { type: 'required', message: 'required' });
  }
  return validates;
};
export const validateAbholauftragOrderCreate = (
  values: AdditionalOrdersOrderCreate | undefined,
  setError: UseFormSetError<AdditionalOrdersOrderCreate>
): boolean => {
  let validates = true;
  const error = setError as unknown as UseFormSetError<ObjectContaining<AdditionalOrdersOrderCreate>>;
  const customerIdRegex = /^\s*(?:\d\s*){10}$/;
  if (!values?.submitter) {
    validates = false;
    error(`submitter`, { type: 'required', message: 'required' });
  }
  if (!values?.details?.date) {
    validates = false;
    error(`details.date`, { type: 'required', message: 'required' });
  }
  // if (!values?.payer) {
  //   validates = false;
  //   error(`payer`, { type: 'required', message: 'required' });
  // }
  if (!values?.pickupAddress) {
    validates = false;
    error(`pickupAddress`, { type: 'required', message: 'required' });
  }
  if (values?.otherPartners && values?.otherPartners?.length) {
    values?.otherPartners?.map((p, i) => {
      if (p.role && !p.customerId?.length) {
        validates = false;
        error(`otherPartners[${i}].customerId`, { type: 'required', message: 'error.customerIdUnknown' });
      } else if (!p.role && p.customerId?.length) {
        validates = false;
        error(`otherPartners[${i}].role`, { type: 'required', message: 'error.partnerRole' });
      } else if (p.role && p.customerId && p.customerId.length && !customerIdRegex.test(p.customerId)) {
        validates = false;
        error(`otherPartners[${i}].customerId`, { type: 'required', message: 'error.customerIdUnknown' });
      }
    });
  }
  if (!values?.payer) {
    validates = false;
    error(`payer`, { type: 'required', message: 'error.required' });
  }
  if (values?.payer && !validateSettlementNumber(values?.payer)) {
    validates = false;
    error(`payer`, { type: 'error.required', message: 'payer.bankInformation.error.abrechnungsValidation' });
  }
  return validates;
};

export const translatePartialServiceLetter = async (
  value: AdditionalOrdersOrderCreate,
  orderReferences: OrderReference[],
  orderId: string | number | undefined,
  orderMode: OrderMode | undefined,
  orderCategory: OrderCategory | undefined
): Promise<CreatePartialOrder & { productionState?: ProductionState }> => {
  const parties: OrderCustomer[] = [
    {
      role: OrderCustomerRoleEnum.Submitter,
      customerId: value.submitter.toString().replace(/ /g, ''),
      contact: value.contactForQuestions ? value.contactForQuestions : undefined
    },
    {
      role: OrderCustomerRoleEnum.Payer,
      customerId:
        orderCategory === OrderCategory.ABHOLAUFTRAG ? value.payer?.toString().replace(/ /g, '') : value.contractor?.toString().replace(/ /g, '')
    }
  ];

  const otherPartners =
    value.otherPartners
      ?.filter((customer) => customer.role && customer.customerId)
      .map<OrderCustomer>((customer) => ({
        role: customer.role,
        customerId: customer.customerId.toString().replace(/ /g, '')
      })) || [];

  parties.push(...otherPartners);
  const validDate = isValidDate(value.details.date);
  const detailsDate = validDate ? value.details.date : new Date(value.details.date);
  const [hour, minute] = value.details.time?.match(/(\d{2,})/g)?.map(Number) || [0, 0];
  const earliestDateTime = new Date(Date.UTC(detailsDate.getFullYear(), detailsDate.getMonth(), detailsDate.getDate(), hour, minute));
  let submissionId: number | undefined = undefined;
  let requestOrderReferences = orderReferences;
  if (orderCategory === OrderCategory.TL_BRIEF) {
    requestOrderReferences = (await preProcessOrderReferencesForPartialService(orderReferences, orderMode)) as any;
  }

  if (!isNaN(parseInt(value.partialServiceNumber || ''))) {
    submissionId = parseInt(value.partialServiceNumber || '');
  }
  return {
    productGroup: orderCategory === OrderCategory.ABHOLAUFTRAG ? ProductGroup.COLLECTION : ProductGroup.PARTIAL_SERVICE_LETTER,
    customerOrderId: value.customerOrderId ? value.customerOrderId : '5037346381-B2B',
    submissionId,
    maxiBriefDiscountAvailable:
      detailsDate && value?.productGroup === ProductGroup.PARTIAL_SERVICE_LETTER ? maxiBriefDiscountAvailable(detailsDate) : undefined,
    orderLabel: value.orderLabel ? value.orderLabel : '',
    productionState: value.productionState,
    orderType: value.orderType ? value.orderType : OrderTypes.Zusatzauftrag,
    partialServiceType: value.product,
    parties: parties.filter((party) => party.role && party.customerId),
    orderDetail: {
      productionPlantId: value.details.productionPlantId,
      date: earliestDateTime,
      pickup: orderCategory === OrderCategory.ABHOLAUFTRAG
    },
    pickupAddress: value?.pickupAddress
      ? {
          orderAddress: value?.pickupAddress
        }
      : undefined,
    orderCategory: orderCategory === OrderCategory.ABHOLAUFTRAG ? orderCategory : ProductGroup.PARTIAL_SERVICE_LETTER,
    orderReferences: requestOrderReferences as any,
    orderId: orderId?.toString()
  };
};

export const translateGetOrderPartialServiceLetter = (value: Order): AdditionalOrdersOrderCreate => {
  const findByRole = (role: OrderCustomerRoleEnum) => value?.parties.find((p) => p.role === role);
  const contractorId = findByRole(OrderCustomerRoleEnum.Payer)?.customerId?.toString().replace(/ /g, '');
  const submitterId = findByRole(OrderCustomerRoleEnum.Submitter)?.customerId?.toString().replace(/ /g, '');
  const findAllNotInRoles = (...roles: OrderCustomerRoleEnum[]) =>
    value?.parties.filter((p) => !!p.role && !roles.includes(p.role as OrderCustomerRoleEnum));
  const contactForQuestions = findByRole(OrderCustomerRoleEnum.Submitter)?.contact;

  return {
    ...value,
    submitter: submitterId ? submitterId : '',
    partialServiceNumber: value.submissionId ?? '',
    productionState: value.productionState,
    contractor: contractorId,
    orderLabel: value.orderLabel,
    maxiBriefDiscountAvailable:
      value?.orderDetail?.date && value?.productGroup === ProductGroup.PARTIAL_SERVICE_LETTER
        ? maxiBriefDiscountAvailable(new Date(value.orderDetail?.date ? value.orderDetail?.date : ''))
        : undefined,
    otherPartners: findAllNotInRoles(OrderCustomerRoleEnum.Submitter, OrderCustomerRoleEnum.Contractor, OrderCustomerRoleEnum.Payer)
      ?.map((x) => {
        return {
          customerId: x.customerId?.toString().replace(/ /g, ''),
          role: x.role
        };
      })
      .filter((x) => x.customerId) as OtherPartnerSchema[] | undefined,
    productGroup: value.productGroup ? value.productGroup : ProductGroup.PARTIAL_SERVICE_LETTER,
    details: {
      date: new Date(value.orderDetail?.date ? value.orderDetail?.date : ''),
      productionPlantId: value.orderDetail?.productionPlantId
    },
    product: value.partialServiceType,
    contactForQuestions
  };
};
