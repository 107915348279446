import React from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { GetInternationalOrderRep, CustomerInfoWithAddressTO, OrderCustomerRoleEnum } from 'generated';
import { now } from 'moment';
import {
  compCustomerAddress,
  compCustomerNumber,
  compCustomerOrderId,
  compOrderId,
  compPickupDate,
  compWeight
} from './palettenleitzettelComponents';
import { PkgInfo } from './PrintPalettenleitzettelPdf';
import { styles } from '../pdf/palettenleitzettelStyles';
import customWordBreakForPDF from '../utils/customWordBreakForPDF';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { InternationalDestinationZipCodes } from 'order/productGroups/international/schema/internationalClusterSchema';

interface PalettenleitzettelINTProps {
  order: GetInternationalOrderRep;
  originatorInfo?: CustomerInfoWithAddressTO;
  submitterInfo?: CustomerInfoWithAddressTO;
  version?: string;
}

export const PalettenlEitzettelInternational = ({ order, originatorInfo, submitterInfo, version }: PalettenleitzettelINTProps): JSX.Element => {
  const producer = order?.parties?.find((orderCustomer) => orderCustomer.role === OrderCustomerRoleEnum.Producer)?.customerId;
  const destinations: PkgInfo[] = [];
  order?.destinations?.flatMap((dest, idxDest) => {
    if (dest && dest.packaging) {
      return dest?.packaging?.map((pkg: PkgInfo) => {
        destinations.push({
          ...pkg,
          zipCode: dest?.zipCode,
          customZipCode: dest?.zipCode == 'DE' ? '-1' : dest?.zipCode == '62' ? '1' : dest?.zipCode
        });
      });
    }
    console.warn(
      `${now()} - warning printing Palettenleitzettel for order ${order?.orderId}: ${idxDest + 1}. destination does not contain packagings.`
    );
    return [];
  });

  // Package IDs are usable if they are not NVE
  const isPkgIdUsable = destinations?.every((d) => d?.packageId?.length && d?.packageId?.length < 20);
  const hasPkgIdGreaterThanListLength = destinations?.some((d) => d?.packageId && destinations?.length && +d?.packageId > destinations?.length);

  // Serial number is usable if it's values is  less than 10000
  const isSerialNumberUsable = destinations?.every((d) => d?.serialNumber && d?.serialNumber <= 10000);
  const hasSerialNumberGreaterThanListLength = destinations?.some(
    (d) => d?.serialNumber && destinations?.length && d?.serialNumber > destinations?.length
  );

  const palletNumberCal = (listLength: number, pkgId?: string, serialNumber?: number) => {
    if (isPkgIdUsable) {
      return hasPkgIdGreaterThanListLength ? pkgId : `${pkgId}/${listLength}`;
    } else if (isSerialNumberUsable) {
      return hasSerialNumberGreaterThanListLength ? serialNumber : `${serialNumber}/${listLength}`;
    } else {
      return ``;
    }
  };

  // Sort destinations by pallet number
  const sortedDestinationsByPalletNumber = isPkgIdUsable
    ? destinations?.sort((a, b) => (a?.packageId && b?.packageId ? +a?.packageId - +b?.packageId : 0))
    : destinations?.sort((a, b) => (a?.serialNumber && b?.serialNumber ? a?.serialNumber - b?.serialNumber : 0));

  // Split destinations by zip code
  const palletsDE: PkgInfo[] = [];
  const palletsPriority: PkgInfo[] = [];
  const palletsEconomy: PkgInfo[] = [];

  sortedDestinationsByPalletNumber.forEach((d) => {
    if (d?.zipCode === InternationalDestinationZipCodes.MIXED) {
      palletsDE.push(d);
    } else if (d?.zipCode === InternationalDestinationZipCodes.PRIORTY) {
      palletsPriority.push(d);
    } else {
      palletsEconomy.push(d);
    }
  });

  // Merge sorted sub pallets arrays
  const sortedDestinations: PkgInfo[] = [...palletsDE, ...palletsPriority, ...palletsEconomy];

  return sortedDestinations.length > 0 ? (
    <Document pdfVersion={'1.3'}>
      {sortedDestinations?.map((pkg, idx, arr) => (
        <Page key={`page_${idx + 1}`} style={styles.page} orientation={'landscape'} size="A4" wrap={true}>
          <View style={styles.linesContainer}>
            {/* Absender */}
            <View
              style={[
                { width: '278mm', height: '23mm' },
                styles.lineContainer,
                styles.borderTop,
                styles.borderLeft,
                styles.borderRight,
                styles.borderBottom
              ]}
            >
              <View style={[{ width: '67mm' }, styles.fieldContainer, styles.borderRight]}>{compCustomerAddress('Absender', originatorInfo)}</View>
              <View style={[{ width: '67mm' }, styles.fieldContainer, styles.borderRight]}>
                {compCustomerNumber('Absender', originatorInfo?.customerId)}
              </View>
              <View style={[{ width: '77mm' }, styles.fieldContainer, styles.borderRight]}>
                <Text style={[styles.textLabel, { marginBottom: '1mm' }]}>Auftragsbezeichnung:</Text>
                <View style={[{ marginBottom: '2mm' }]}>
                  <Text style={styles.textValue}>{customWordBreakForPDF(order.orderLabel, 28)}</Text>
                </View>
              </View>
              <View style={styles.fieldContainerStretched}>
                <Text style={styles.textLabel}>Palettennr. von Gesamtanzahl:</Text>
                <View style={styles.fieldContainerStretchedCenter}>
                  <Text style={styles.textPaletteCount}>{palletNumberCal(arr.length, pkg?.packageId, pkg?.serialNumber)}</Text>
                </View>
              </View>
            </View>
            {/* Einlieferer */}
            <View
              style={[
                {
                  width: '278mm',
                  height: '23mm'
                },
                styles.lineContainer,
                styles.borderLeft,
                styles.borderRight,
                styles.borderBottom
              ]}
            >
              <View style={[{ width: '67mm' }, styles.fieldContainer, styles.borderRight]}>{compCustomerAddress('Einlieferer', submitterInfo)}</View>
              <View style={[{ width: '67mm' }, styles.fieldContainer, styles.borderRight]}>
                {compCustomerNumber('Einlieferer', submitterInfo?.customerId)}
              </View>
              <View style={[{ width: '77mm' }, styles.fieldContainer, styles.borderRight]}>{compCustomerNumber('Datenproduzent', producer)}</View>
            </View>
            {/* Produkt */}
            <View
              style={[
                {
                  width: '278mm',
                  height: '66mm'
                },
                styles.lineContainer,
                styles.borderLeft,
                styles.borderRight,
                styles.borderBottom
              ]}
            >
              <View style={[{ width: '210.7mm', alignItems: 'center', justifyContent: 'center' }, styles.fieldContainer]}>
                <Text style={styles.textProductMedium}>
                  {order?.productGroup === ProductGroup?.DIALOGPOST_INTERNATIONAL
                    ? 'Dialogpost International'
                    : order?.productGroup === ProductGroup?.BRIEF_INTERNATIONAL
                    ? 'Brief International'
                    : order?.productGroup === ProductGroup?.PRESS_INTERNATIONAL
                    ? 'Presse International'
                    : 'Brief 50+ International'}
                </Text>
                <Text style={styles.textProductMedium}>
                  {['de'].includes((pkg?.zipCode || '').toLowerCase())
                    ? ''
                    : pkg?.zipCode == InternationalDestinationZipCodes.ECONOMY
                    ? 'ECONOMY'
                    : 'PRIORITY'}
                </Text>
                <Text style={styles.textProductMedium}>
                  {['de'].includes((pkg?.zipCode || '').toLowerCase())
                    ? '0-9'
                    : pkg?.zipCode == InternationalDestinationZipCodes.ECONOMY
                    ? 'IPZ 2 Niederaula'
                    : 'IPZ 1 Frankfurt'}
                </Text>
              </View>
              <View style={[{ height: '66mm', width: '66mm', flexGrow: 1, flexDirection: 'column' }, styles.borderLeft]}>
                <View style={[styles.fieldContainer]}>
                  <Text style={styles.textLabel}>Bereich für postalische Zwecke:</Text>
                </View>
              </View>
              {/* <View style={[{ width: '66mm', flexGrow: 1, flexDirection: 'column' }, styles.borderLeft]}></View> */}
            </View>
            {/* Palette */}
            <View
              style={[
                {
                  width: '278mm',
                  height: '80mm'
                },
                styles.lineContainer,
                styles.borderLeft,
                styles.borderRight,
                styles.borderBottom
              ]}
            >
              <View style={[{ width: '134mm' }, styles.linesContainer, styles.borderRight]}>
                <View style={[{ height: '19mm' }, styles.fieldContainer, styles.borderBottom]}>{compPickupDate(order?.orderDetail?.date)}</View>
                <View style={[{ height: '19mm' }, styles.fieldContainer, styles.borderBottom]}>{compOrderId(order?.orderId)}</View>
                <View style={[{ height: '19mm' }, styles.fieldContainer, styles.borderBottom]}>{compCustomerOrderId(order?.customerOrderId)}</View>
                <View style={[{ height: '19mm' }, styles.fieldContainer, styles.borderBottom]}>{compWeight(pkg?.totalWeight, pkg?.unit)}</View>
                <View style={[{ height: '8mm' }, styles.fieldContainer, styles.fieldContainerStretchedBottom]}>
                  <Text style={styles.textLabel}>{`erstellt durch AM.GK-P ${version || ''}`}</Text>
                </View>
              </View>
              <View style={styles.fieldContainer}>
                <Text style={styles.textLabel}>Feld für Palettenlabel</Text>
              </View>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  ) : (
    <></>
  );
};
