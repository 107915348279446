import React, { VFC } from 'react';
import { useTranslation } from 'react-i18next';

import { ProductInfoTableRow } from './ProductInfoTableRow/ProductInfoTableRow';
import {
  computedValues,
  ProductInfoTableSchemea
} from 'order/productGroups/common/components/molecule/ProductInfoTable/schemas/ProductInfoTableSchemea';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { sortLetterTypeList } from 'order/common/context/order/dtos/Order';
import { deepClone } from 'common/utils/deepClone';

import styles from './ProductInfoTable.module.css';

export type ProductInfoTableProps = {
  editing?: boolean;
  data?: ProductInfoTableSchemea;
  showMultipleProducts?: boolean;
  values?: computedValues;
  meta: { language: string };
};

export const RowSpacing: VFC = () => (
  <tr>
    <td colSpan={2}></td>
  </tr>
);

export const ProductInfoTable: VFC<ProductInfoTableProps> = ({ data, editing, showMultipleProducts, values, meta }) => {
  const pressProductGroup: boolean = data?.productGroup === ProductGroup.PRESS_DISTRIBUTION;
  // const isBrief: boolean = data?.productGroup === ProductGroup.BRIEF;
  const pickup = !!data?.pickupAddress;
  const noDisplayingOfQuantityAndWeight =
    editing && (data?.orderCategory === OrderCategory.DIP_SAMMEL || data?.orderCategory === OrderCategory.DIP_VARIO);
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'aside' });
  const { t: translateCategory } = useTranslation('translation', { keyPrefix: 'orderCategoryByKey' });
  const language = meta.language;
  const { order, orderCategory } = useOrderContext();
  const letterBaseProducts = deepClone(data?.letterBaseProducts || []);
  const isCollection = orderCategory === OrderCategory.ABHOLAUFTRAG;
  const sortedLetterBaseProducts =
    data?.productGroup === ProductGroup.BRIEF
      ? letterBaseProducts?.sort(
          (a, b) =>
            sortLetterTypeList.indexOf(a.baseProduct?.description?.toLowerCase() || '') -
            sortLetterTypeList.indexOf(b.baseProduct?.description?.toLowerCase() || '')
        )
      : [];

  return (
    <table className={styles.table}>
      <tbody>
        {order?.orderCategory ? (
          <ProductInfoTableRow
            label={`${oc('orderCategory')}:`}
            value={order?.orderCategoryProductKey ? translateCategory(order?.orderCategoryProductKey as string) : orderCategory}
            data-testId={'orderCategory'}
          />
        ) : (
          ''
        )}
        {order?.orderId ? <ProductInfoTableRow label={`${oc('orderNumber')}:`} value={order?.orderId} data-testId={'orderId'} /> : ''}
        {order?.customerOrderId ? (
          <ProductInfoTableRow label={`${oc('customerOrderNumber')}:`} value={order?.customerOrderId} data-testid={'customerOrderNumber'} />
        ) : (
          ''
        )}
        {data?.partialServiceNumber ? (
          <ProductInfoTableRow label={`${oc('partialServiceNumber')}:`} value={data?.partialServiceNumber} data-testId={'partialServiceNumber'} />
        ) : (
          ''
        )}
        {pressProductGroup && (
          <>
            <ProductInfoTableRow label={oc('zkz')} value={data?.zkz} dataTestId={'zkzTestId'} />
            <ProductInfoTableRow label={oc('newspaperTitle')} value={values?.title} />
            <ProductInfoTableRow label={oc('newsPaperNumber')} value={data?.newsPaperNumber} />
            {data?.nationalProducts?.some(
              (a) =>
                (a.productNumber !== undefined && a.productNumber !== '') ||
                (a?.variants?.length && a.variants.some((a) => a?.quantity && a?.quantity > 0))
            ) && (
              <tr>
                <td colSpan={2}>
                  <h6 className="dhlHeadline h6 left gray mb-0 mt-3">{oc('nationalProduct')}</h6>
                </td>
              </tr>
            )}
            {data?.nationalProducts?.map((i, index) => (
              <>
                {index > 0 && <RowSpacing />}
                <ProductInfoTableRow
                  label={
                    data?.nationalProducts && data?.nationalProducts?.length > 1
                      ? `${oc('basicProductWithNumber', { number: index + 1 })}`
                      : oc('basicProduct')
                  }
                  value={values?.baseProducts?.find((p) => p.productNumber === i.productNumber)?.descriptionShort || ''}
                  key={`Basic${index}`}
                />
                <ProductInfoTableRow
                  label={oc('volume')}
                  value={i?.variants?.reduce((acc, variant) => acc + (variant?.quantity || 0), 0).toLocaleString(language)}
                  key={`Quantity${index}`}
                />
              </>
            ))}
            {data?.internationalProducts?.some(
              (a) =>
                (a.productNumber !== undefined && a.productNumber !== '') ||
                (a?.variants?.length && a.variants.some((a) => a?.quantity && a?.quantity > 0))
            ) && (
              <tr>
                <td colSpan={2}>
                  <h6 className="dhlHeadline h6 left gray mb-0 mt-3">{oc('interNationalProduct')}</h6>
                </td>
              </tr>
            )}
            {data?.internationalProducts?.map((i, index) => (
              <>
                {index > 0 && <RowSpacing />}
                <ProductInfoTableRow
                  label={
                    data?.internationalProducts && data?.internationalProducts?.length > 1
                      ? `${oc('basicProductWithNumber', { number: index + 1 })}`
                      : oc('basicProduct')
                  }
                  value={values?.baseProducts?.find((p) => p.productNumber === i.productNumber)?.descriptionShort || ''}
                  key={`IntBasic${index}`}
                />
                <ProductInfoTableRow
                  label={oc('volume')}
                  value={i?.variants?.reduce((acc, variant) => acc + (variant?.quantity || 0), 0)}
                  key={`IntQuantity${index}`}
                />
              </>
            ))}
          </>
        )}
        {!isCollection && data?.product && (
          <>
            <ProductInfoTableRow label={oc('product')} value={oc(data?.product)} dataTestId={'productTestId'} />
          </>
        )}
        {isCollection && (
          <>
            <ProductInfoTableRow label={oc('product')} value={oc('abholungProduct')} dataTestId={'productTestId'} />
          </>
        )}
        {data?.orderLabel && data.orderCategory !== OrderCategory.PRESS_DISTRIBUTION && (
          <ProductInfoTableRow label={oc('orderName')} value={data?.orderLabel} dataTestId={'DPOrderLabelTestId'} />
        )}

        {data?.productGroup === ProductGroup.BRIEF &&
          sortedLetterBaseProducts &&
          sortedLetterBaseProducts.length &&
          sortedLetterBaseProducts.map((i, index) => (
            <>
              <RowSpacing />
              <ProductInfoTableRow
                label={`${oc('basicProductWithNumber', { number: index + 1 })}`}
                value={values?.baseProducts?.find((p) => p.productNumber === i.baseProduct?.productNumber)?.descriptionShort || ''}
                key={`Basic${index}`}
              />
              <ProductInfoTableRow label={oc('volume')} value={i.baseProduct?.quantity?.toLocaleString(language)} key={`Quantity${index}`} />
            </>
          ))}

        {values?.productNames && (
          <>
            <RowSpacing />
            <ProductInfoTableRow
              label={showMultipleProducts ? oc('product') : oc('basicProduct')}
              value={values?.productNames}
              dataTestId={'DPBasicProductTestId'}
            />
          </>
        )}

        {values?.internationalBaseProductName && (
          <>
            <RowSpacing />
            <ProductInfoTableRow
              label={showMultipleProducts ? oc('product') : oc('basicProduct')}
              value={values?.internationalBaseProductName}
              dataTestId={'DPBasicProductTestId'}
            />
          </>
        )}

        {(data?.productGroup === ProductGroup.DIALOGPOST_INTERNATIONAL ||
          data?.productGroup === ProductGroup.PRESS_INTERNATIONAL ||
          data?.productGroup === ProductGroup.BRIEF_INTERNATIONAL ||
          data?.productGroup === ProductGroup.BRIEF_DMC_INTERNATIONAL) &&
          data?.transportServices &&
          data?.transportServices?.map((i, index) => (
            <>
              <RowSpacing />
              <ProductInfoTableRow
                label={
                  data?.productGroup === ProductGroup.BRIEF_INTERNATIONAL ? `${oc('transportService')}:` : `${oc('transportService')} ${index + 1}:`
                }
                value={values?.transportServiceProducts?.find((p) => p.productNumber === i.baseProduct?.productNumber)?.descriptionShort || ''}
                key={`transportService${index}`}
              />
              <ProductInfoTableRow label={oc('volume')} value={i.baseProduct?.quantity?.toLocaleString(language)} key={`Quantity${index}`} />
              {!!i.baseProduct?.grossWeight && (
                <ProductInfoTableRow
                  label={oc('totalWeight')}
                  value={`${i.baseProduct?.grossWeight?.toLocaleString(language)}\u00A0kg`}
                  key={`Weight${index}`}
                />
              )}
            </>
          ))}

        {(!!data?.quantity || !!data?.individualWeightValue) && !noDisplayingOfQuantityAndWeight && (
          <>
            <RowSpacing />
            <ProductInfoTableRow label={oc('volume')} value={parseInt(data?.quantity as unknown as string)?.toLocaleString(language)} />
            <ProductInfoTableRow
              label={oc('weight')}
              value={data?.individualWeightValue && `${data?.individualWeightValue?.toLocaleString(language)}\u00A0g`}
            />
          </>
        )}
        {data?.productGroup === ProductGroup.POSTAKTUELL &&
          data?.postaktuellLetterBaseProducts &&
          data?.postaktuellLetterBaseProducts.map((bp, index) => (
            <>
              <RowSpacing />
              <ProductInfoTableRow
                label={`${oc('basicProductWithNumber', { number: index + 1 })}`}
                value={bp.baseProduct?.description}
                key={`IntBasic${index}`}
              />
              <ProductInfoTableRow label={oc('volume')} value={bp.baseProduct?.quantity?.toLocaleString(language)} key={`IntQuantity${index}`} />
              {!!bp.baseProduct?.weight && (
                <ProductInfoTableRow
                  label={oc('weight')}
                  value={`${bp.baseProduct?.weight?.toLocaleString(language)}\u00A0g`}
                  key={`IntWeight${index}`}
                />
              )}
            </>
          ))}
        <>
          {pickup || isCollection ? (
            <>
              <RowSpacing />
              <ProductInfoTableRow
                label={oc(pickup || isCollection ? 'datePickup' : 'date')}
                value={data?.earliestDateTime ? `${values?.dateString} ${data?.time || ''}` : ` ${data?.time || ''}`}
              />
            </>
          ) : (
            values?.dateString !== undefined && (
              <>
                <RowSpacing />
                <ProductInfoTableRow label={oc('date')} value={values?.dateString} />
              </>
            )
          )}
        </>
        {(!!data?.earliestDateTime || !!values?.productionFacility?.address || !!data?.packagingQuantity || !!data?.totalWeight) && (
          <>
            <ProductInfoTableRow
              label={oc('location')}
              value={values?.productionFacility?.address && `${values?.productionFacility.address.postCode} ${values?.productionFacility?.comment}`}
            />
            {!!data?.packagingQuantity && <RowSpacing />}
            {data?.productGroup !== ProductGroup.PRESS_DISTRIBUTION && (
              <>
                <ProductInfoTableRow
                  label={oc('bundles')}
                  value={
                    data?.packagingQuantity && `${data?.packagingQuantity?.toLocaleString(language)} ${data?.packagingType ? values?.datatype : ''}`
                  }
                />
                <ProductInfoTableRow
                  label={oc('totalWeight')}
                  value={data?.totalWeight && `${data?.totalWeight?.toLocaleString(language)}\u00A0kg`}
                />
              </>
            )}
          </>
        )}
      </tbody>
    </table>
  );
};
