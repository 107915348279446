import useSWR from 'swr/immutable';

import { distinctBy, isEntityWithDatesValid } from 'common/utils/helpers';
import { PressDistributionSupplementProduct } from '../dtos/PressDistributionSupplementProduct';
import { requestPressDistributionSupplementProductsBasedOnZkz } from '../services/pressDistributionSupplementProducts';
import { useMemo } from 'react';

export const usePressDistributionSupplementProducts = (zkz?: string, date = new Date()): PressDistributionSupplementProduct[] => {
  const { data: products = [] } = useSWR(zkz?.length ? ['pressDistributionSupplementProducts', zkz] : [], async (_, zkz) =>
    requestPressDistributionSupplementProductsBasedOnZkz(zkz).catch(() => [] as PressDistributionSupplementProduct[])
  );
  const filteredProducts = useMemo(() => products.filter((p) => isEntityWithDatesValid(p, date)), [products, date]);
  return filteredProducts.filter(distinctBy((a, b) => a.productNumber === b.productNumber));
};
