import React, { HTMLProps, ReactElement, useMemo, useState } from 'react';
import { uid } from 'react-uid';
import classNames from 'classnames';

import { OrderReplyTDO, OrderTreeItem } from 'order/common/services/OrderService';
import { TableRowElement } from './TableRowElement';
import { ColumnConfig } from '../dtos/ColumnConfig';

export type Props<T> = Omit<HTMLProps<HTMLTableRowElement>, 'selected' | 'data'> & {
  name: string;
  data: T;
  colItems: ColumnConfig[];
  selected: (event: React.MouseEvent, data: T) => void;
  formatter: Map<string, unknown>;
  idPropertiesName: string;
  highlightRow?: (data: T) => boolean;
  autoExpand?: boolean;
  allChildrenExpanded?: boolean;
  visible?: boolean;
  level?: number;
  uncleBelow?: boolean;
  highlight?: boolean;
  language?: string;
};

/** Ausgabe einer Daten-Tabellenzeile. */
export const TableRow = ({
  name,
  colItems,
  data,
  selected,
  formatter,
  idPropertiesName,
  highlightRow,
  className,
  visible = true,
  autoExpand = false,
  allChildrenExpanded = false,
  uncleBelow,
  highlight = false,
  level = 0,
  ...props
}: Props<OrderTreeItem<OrderReplyTDO>>) => {
  const allAutoExpanded = useMemo(() => data.children?.every((c) => c.autoExpanded), [data.children]);
  const [expanded, setExpanded] = useState(allAutoExpanded);
  const [rowChildExpanded, setRowChildExpanded] = useState<boolean | undefined>(allChildrenExpanded);
  const hasChildren: boolean = !!data.children?.length;
  const columnDefinitions = Array.isArray(colItems) ? colItems : [colItems];
  const rowChildRenderer = columnDefinitions.find((c) => c.childRenderer)?.childRenderer;

  if (!visible && !autoExpand) {
    return <></>;
  }

  return (
    <>
      <tr
        key={uid(data.id)}
        {...props}
        data-testid={name}
        className={classNames(
          className,
          highlightRow?.(data) ? 'dhlTable-row dhlTable-row-highlight' : 'dhlTable-row',
          'dhlTable-row-default',
          {
            [`tree-item level-${level}`]: level > 0 || hasChildren
          },
          { leaf: !hasChildren },
          { expanded: expanded && hasChildren },
          { uncleBelow },
          { highlight }
        )}
      >
        <TableRowElement
          columnDefinitions={columnDefinitions}
          hasChildren={hasChildren}
          expanded={expanded}
          childExpanded={allChildrenExpanded || rowChildExpanded}
          setExpanded={setExpanded}
          setChildExpanded={setRowChildExpanded}
          allAutoExpanded={allAutoExpanded}
          data={data}
          selected={selected}
          formatter={formatter}
          idPropertiesName={idPropertiesName}
          language={props.language}
        />
      </tr>
      {(allChildrenExpanded || rowChildExpanded) && rowChildRenderer ? (
        <tr>
          <td colSpan={columnDefinitions.length}>{rowChildRenderer(data, { cellId: idPropertiesName, language: props.language })}</td>
        </tr>
      ) : null}
      {data.children?.map((child, i) => {
        if (level === 0 && data.children) {
          const slice = data.children.slice(-1);
          uncleBelow = i !== data.children.length - 1 && (slice[0].autoExpanded || expanded);
        }
        return (
          <TableRow
            key={uid(child)}
            name={name + '-r-' + child.id}
            data={child}
            colItems={colItems}
            selected={() => {}}
            formatter={formatter}
            idPropertiesName={idPropertiesName}
            visible={expanded}
            autoExpand={child.autoExpanded}
            level={level + 1}
            uncleBelow={uncleBelow}
            highlight={child.highlight}
            language={props.language}
          />
        );
      })}
    </>
  );
};
