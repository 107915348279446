import i18n from 'i18n';

import { CustomerNameTO } from 'generated';
import { ColumnConfig, ContentAlignment } from 'common/components/Table/dtos/ColumnConfig';
import { ColumnType } from 'common/components/Table/dtos/ColumnType';
import { customDeleteRenderer } from './custom-cells/CustomDeleteCell';
import { customDeliveryOption } from './custom-cells/CustomDeliveryOptionCell';
import { customAllOrdersPartialServiceRelevantCellRenderer } from './custom-cells/CustomAllOrdersPartialServiceRelevantCell';
import { customPlanWithFrankingIdsRendererCellRenderer } from './custom-cells/CustomPlanWithFrankingIdsRendererCell';
import { qtyAllocationRenderer } from './custom-cells/CustomRowChildCell';
import { customProcedureRenderer } from './custom-cells/CustomProcedureCell';
import { customNewlyAddedCellRenderer } from './custom-cells/CustomNewlyAddedCell';
import { customPartialServiceProductsRenderer } from './custom-cells/CustomPartialServiceProductsCell';
import { customExpandAllHeaderRenderer } from './custom-cells/CustomExpandAllCell';

import styles from './table-container.module.css';
import {
  customRendererForFrankierung,
  customRendererForInitalTotalQty,
  customRendererForOrderValue,
  customRendererForShipmentFrankingIdPrefix,
  customRendererForShipmentFrom,
  customRendererForShipmentQty,
  customRendererForShipmentTo,
  customRendererForShipmentZipCode,
  customRendererForTotalNetWeightInKg,
  customRendererForTotalQty,
  customRendererForVariantDescriptionOrderMerge,
  customRendererForVariantQtyOrderMerge,
  customRendererFormachineCodeOrFrankingIdPrefix,
  customRendererFortotalNumberOfFrankingId,
  customRendererWithWordBreak
} from 'order/orderSearch/components/table/functions/customRendererFunctions';
import { OrderCategory } from '../../context/order/dtos/OrderCategory';
import { ProductGroup } from '../../dtos/ProductGroup';
import { customDeleteRendererForOrderMergeVariantsList } from 'order/orderView/components/OrderMerge/components/customDeleteCellForVariantsList/CustomDeleteCellForVariantList';

const customerRenderer = (value: CustomerNameTO): string => (value?.name ? `${value.customerId} ${value.name}` : value?.customerId || '');

export const statusRenderer = (value: string): string => (value ? i18n.t('orderStatus.status.' + value) : '');

export const customRendererProduct = (value: string): string => (value ? i18n.t('product.' + value) : '');

const translate = (key: string) => i18n.t('orderTable.column.config.' + key);

const columns: Record<string, ColumnConfig> = {
  allOrdersPartialServiceRelevant: {
    name: 'allOrdersPartialServiceRelevant',
    property: 'allOrdersPartialServiceRelevant',
    title: translate('allOrdersPartialServiceRelevant'),
    sortable: true,
    hideable: false,
    customRenderer: customAllOrdersPartialServiceRelevantCellRenderer,
    type: ColumnType.CUSTOM_CELL,
    innerClassName: styles.columnWithWrappedText
  },
  planWithFrankingIds: {
    name: 'planWithFrankingIds',
    property: 'planWithFrankingIds',
    title: translate('planWithFrankingIds'),
    sortable: true,
    hideable: false,
    customRenderer: customPlanWithFrankingIdsRendererCellRenderer,
    type: ColumnType.CUSTOM_CELL,
    innerClassName: styles.columnWithWrappedText
  },
  select: {
    name: 'flexOption',
    property: 'data.flexOption',
    sortable: true,
    hideable: false,
    customRenderer: customDeliveryOption,
    title: translate('deliveryOption'),
    type: ColumnType.CUSTOM_CELL
  },
  orderNumber: {
    name: 'orderNumber',
    property: 'data.orderNumber',
    sortable: true,
    movable: false,
    hideable: false,
    filterable: true,
    title: translate('orderNumberBreaking'),
    type: ColumnType.READONLY,
    innerClassName: styles.columnWithWrappedTextAndSmall
  },
  customerOrderId: {
    name: 'customerOrderId',
    property: 'data.customerOrderId',
    sortable: true,
    movable: true,
    hideable: true,
    filterable: true,
    title: translate('customerOrderNumberBreaking'),
    type: ColumnType.COMMON,
    innerClassName: styles.columnWithWrappedTextAndSmall,
    hidden: false
  },
  sender: {
    name: 'sender',
    property: 'data.originator',
    sortable: true,
    movable: true,
    hideable: true,
    filterable: true,
    title: translate('sender'),
    customRenderer: customerRenderer,
    type: ColumnType.CUSTOM,
    hidden: false
  },
  orderLabel: {
    name: 'orderLabel',
    property: 'data.orderLabel',
    sortable: true,
    movable: true,
    hideable: true,
    filterable: true,
    title: translate('actionNameBreaking'),
    type: ColumnType.CUSTOM_CELL,
    customRenderer: customRendererWithWordBreak,
    innerClassName: styles.columnWithWrappedTextAndSmall,
    hidden: false
  },
  postage: {
    name: 'frankierung',
    property: 'data.frankierung',
    sortable: true,
    movable: true,
    hideable: true,
    filterable: true,
    title: translate('postageType'),
    customRenderer: customRendererForFrankierung,
    type: ColumnType.CUSTOM_CELL,
    hidden: false
  },
  procedure: {
    contentAlignment: ContentAlignment.RIGHT,
    name: 'payerProcedure',
    property: 'data.payerProcedure',
    sortable: true,
    movable: true,
    hideable: true,
    filterable: true,
    title: translate('procedure'),
    type: ColumnType.CUSTOM_CELL,
    customRenderer: customProcedureRenderer,
    hidden: false
  },
  participation: {
    contentAlignment: ContentAlignment.RIGHT,
    name: 'payerParticipation',
    property: 'data.payerParticipation',
    sortable: true,
    movable: true,
    hideable: true,
    filterable: true,
    title: translate('participation'),
    type: ColumnType.COMMON,
    hidden: false
  },
  paymentClearingNumber: {
    contentAlignment: ContentAlignment.RIGHT,
    name: 'paymentClearingNumber',
    property: 'data.paymentClearingNumber',
    sortable: true,
    movable: true,
    hideable: true,
    filterable: true,
    title: translate('deliveryReceiptNumberBreaking'),
    type: ColumnType.COMMON,
    innerClassName: styles.columnWithWrappedTextAndSmall,
    hidden: false
  },
  partialServiceProducts: {
    name: 'partialServiceProducts',
    property: 'partialServiceProducts',
    movable: true,
    hideable: false,
    title: translate('partialServiceProducts'),
    customRenderer: customPartialServiceProductsRenderer,
    type: ColumnType.CUSTOM_CELL,
    innerClassName: styles.columnWithWrappedText,
    hidden: false
  },
  deliveryDate: {
    dateFormat: 'DD.MM.YYYY',
    name: 'deliveryDate',
    property: 'data.firstInductionDate',
    sortable: true,
    movable: true,
    hideable: true,
    filterable: true,
    title: translate('deliveryDate'),
    type: ColumnType.DATE,
    contentAlignment: ContentAlignment.RIGHT,
    hidden: false
  },
  status: {
    name: 'status',
    title: 'Status',
    property: 'data.productionState',
    movable: true,
    hideable: true,
    sortable: true,
    filterable: true,
    customRenderer: statusRenderer,
    type: ColumnType.CUSTOM,
    hidden: false
  },
  product: {
    hideable: true,
    name: 'product',
    property: 'data.product',
    sortable: true,
    filterable: true,
    movable: true,
    title: translate('product'),
    customRenderer: customRendererProduct,
    type: ColumnType.CUSTOM_CELL,
    hidden: false
  },
  totalQty: {
    contentAlignment: ContentAlignment.RIGHT,
    name: 'totalQty',
    property: 'data.totalQty',
    sortable: true,
    movable: true,
    hideable: true,
    filterable: false,
    title: translate('numberOfShipments'),
    customRenderer: customRendererForTotalQty,
    type: ColumnType.CUSTOM_CELL,
    hidden: false
  },
  intialTotalQty: {
    contentAlignment: ContentAlignment.LEFT,
    name: 'initialTotalQty',
    property: 'data.initialTotalQty',
    sortable: true,
    movable: true,
    hideable: true,
    filterable: false,
    title: translate('numberOfShipments'),
    customRenderer: customRendererForInitalTotalQty,
    type: ColumnType.CUSTOM_CELL,
    hidden: false
  },
  totalNetWeight: {
    contentAlignment: ContentAlignment.RIGHT,
    name: 'totalNetWeight',
    property: 'data.totalNetWeight',
    sortable: true,
    movable: true,
    hideable: true,
    filterable: false,
    title: translate('totalNetWeight'),
    customRenderer: customRendererForTotalNetWeightInKg,
    type: ColumnType.CUSTOM_CELL,
    hidden: false
  },
  packagingQty: {
    contentAlignment: ContentAlignment.RIGHT,
    name: 'packagingQty',
    property: 'data.packagingQty',
    sortable: true,
    movable: true,
    hideable: true,
    filterable: false,
    title: translate('pallets'),
    type: ColumnType.COMMON,
    hidden: false
  },
  childExpand: {
    name: 'childExpand',
    childRenderer: qtyAllocationRenderer,
    property: 'childExpand',
    title: '',
    customHeader: customExpandAllHeaderRenderer,
    type: ColumnType.CHILD_EXPAND
  },
  delete: {
    name: 'delete',
    property: 'delete',
    icon: 'delete',
    customRenderer: customDeleteRenderer,
    title: '',
    type: ColumnType.CUSTOM_ICON_BUTTON
  },
  machineCode: {
    name: 'machineCode',
    property: 'data.machineCode',
    sortable: true,
    movable: true,
    hideable: true,
    filterable: true,
    title: translate('machineCode'),
    type: ColumnType.COMMON,
    hidden: false
  },
  machineCodeOrFrankingIdPrefix: {
    name: 'machineCodeOrFrankingIdPrefix',
    property: 'data.postage',
    sortable: true,
    movable: true,
    hideable: true,
    filterable: true,
    title: `${translate('machineCodeOrFrankingIdPrefix.machineCode')} \n${translate('machineCodeOrFrankingIdPrefix.or')} \n${translate(
      'machineCodeOrFrankingIdPrefix.frankingIdPrefix'
    )}`,
    type: ColumnType.CUSTOM_CELL,
    customRenderer: customRendererFormachineCodeOrFrankingIdPrefix,
    innerClassName: styles.columnWithWrappedText,
    hidden: false
  },
  assignedShipments: {
    contentAlignment: ContentAlignment.RIGHT,
    name: 'assignedShipments',
    property: 'data.idsQty',
    sortable: true,
    movable: true,
    hideable: false,
    title: translate('assignedShipments'),
    type: ColumnType.COMMON,
    hidden: false
  },
  variantLabel: {
    name: 'variantLabel',
    property: 'data.mergeOrderVariants.variantLabel',
    sortable: true,
    movable: false,
    hideable: false,
    filterable: true,
    title: translate('variantDescription'),
    type: ColumnType.CUSTOM_CELL,
    customRenderer: customRendererForVariantDescriptionOrderMerge,
    hidden: false
  },
  variantQuantity: {
    contentAlignment: ContentAlignment.LEFT,
    name: 'variantQuantity',
    property: 'data.mergeOrderVariants.variantQuantity',
    sortable: true,
    movable: false,
    hideable: false,
    filterable: false,
    title: translate('numberOfShipments'),
    customRenderer: customRendererForVariantQtyOrderMerge,
    type: ColumnType.CUSTOM_CELL,
    hidden: false
  },
  variantDelete: {
    name: 'variantDelete',
    property: 'delete',
    icon: 'delete',
    customRenderer: customDeleteRendererForOrderMergeVariantsList,
    title: '',
    type: ColumnType.CUSTOM_ICON_BUTTON
  },
  variantsListOrderNumber: {
    name: 'orderNumber',
    property: 'data.orderNumber',
    sortable: true,
    movable: false,
    hideable: false,
    filterable: true,
    title: translate('orderNumber'),
    type: ColumnType.READONLY,
    innerClassName: styles.columnWithWrappedTextAndSmall,
    hidden: false
  },
  variantsListOrderLabel: {
    name: 'orderLabel',
    property: 'data.orderLabel',
    sortable: true,
    movable: false,
    hideable: false,
    filterable: true,
    title: translate('actionName'),
    type: ColumnType.CUSTOM_CELL,
    customRenderer: customRendererWithWordBreak,
    innerClassName: styles.columnWithWrappedTextAndSmall,
    hidden: false
  },
  orderValue: {
    name: 'orderValue',
    property: 'data.totalAmountValue',
    sortable: true,
    filterable: true,
    title: translate('orderValue'),
    customRenderer: customRendererForOrderValue,
    type: ColumnType.CUSTOM_CELL
  },
  shipmentFrankingIdPrefix: {
    name: 'frankingIdPrefix',
    property: 'data.letterBaseProducts.destinations.frankingIdPrefix',
    sortable: true,
    hideable: false,
    title: translate('machineCodeOrFrankingIdPrefix.frankingIdPrefix'),
    type: ColumnType.CUSTOM_CELL,
    contentAlignment: ContentAlignment.CENTER,
    customRenderer: customRendererForShipmentFrankingIdPrefix,
    hidden: false
  },
  shipmentMachineCode: {
    name: 'machineCode',
    property: 'data.letterBaseProducts.destinations.frankingIdPrefix',
    sortable: true,
    hideable: false,
    title: translate('machineCodeOrFrankingIdPrefix.machineCode'),
    type: ColumnType.CUSTOM_CELL,
    contentAlignment: ContentAlignment.CENTER,
    customRenderer: customRendererForShipmentFrankingIdPrefix,
    hidden: false
  },
  shipmentZipCode: {
    name: 'zipCode',
    property: 'data.letterBaseProducts.destinations.zipCode',
    sortable: true,
    hideable: false,
    title: translate('zipCode'),
    customRenderer: customRendererForShipmentZipCode,
    type: ColumnType.CUSTOM_CELL,
    contentAlignment: ContentAlignment.CENTER,
    hidden: false
  },
  shipmentFrom: {
    name: 'shipmentFrom',
    property: 'data.letterBaseProducts.destinations.from',
    sortable: true,
    hideable: false,
    title: translate('shipmentFrom'),
    customRenderer: customRendererForShipmentFrom,
    type: ColumnType.CUSTOM_CELL,
    contentAlignment: ContentAlignment.CENTER,
    hidden: false
  },
  shipmentTo: {
    name: 'shipmentTo',
    property: 'data.letterBaseProducts.destinations.to',
    sortable: true,
    hideable: false,
    title: translate('shipmentTo'),
    type: ColumnType.CUSTOM_CELL,
    customRenderer: customRendererForShipmentTo,
    contentAlignment: ContentAlignment.CENTER,
    hidden: false
  },
  dash: {
    name: 'dash',
    property: '',
    sortable: false,
    hideable: false,
    title: translate('dash'),
    type: ColumnType.CUSTOM_CELL,
    contentAlignment: ContentAlignment.CENTER,
    customRenderer: () => '-',
    hidden: false
  },
  totalNumberOfFrankingId: {
    name: 'totalNumberOfFrankingId',
    property: 'data.letterBaseProducts.destinations',
    sortable: true,
    hideable: false,
    title: translate('totalNumberOfFrankingId'),
    type: ColumnType.CUSTOM_CELL,
    customRenderer: customRendererFortotalNumberOfFrankingId,
    contentAlignment: ContentAlignment.CENTER,
    hidden: false
  },
  shipmentQty: {
    name: 'shipmentQty',
    property: 'data.letterBaseProducts.destinations.shipmentQty',
    sortable: true,
    hideable: false,
    title: translate('numberOfShipments'),
    type: ColumnType.CUSTOM_CELL,
    customRenderer: customRendererForShipmentQty,
    contentAlignment: ContentAlignment.CENTER,
    hidden: false
  },
  options: {
    name: 'tableOption',
    property: 'options',
    title: '',
    type: ColumnType.COMMON
  },
  emptyCell: {
    hideable: false,
    sortable: false,
    movable: false,
    name: null,
    property: 'emptyCell',
    title: '',
    type: ColumnType.CUSTOM_CELL
  }
};

export const allocationTableConfiguration = (orderCategory: OrderCategory | undefined, edit?: boolean): ColumnConfig[] => {
  let config: ColumnConfig[] = [
    columns.allOrdersPartialServiceRelevant,
    columns.planWithFrankingIds,
    columns.select,
    columns.orderNumber,
    columns.customerOrderId,
    columns.sender,
    columns.orderLabel,
    columns.postage,
    columns.machineCodeOrFrankingIdPrefix,
    columns.procedure,
    columns.participation,
    columns.paymentClearingNumber,
    columns.deliveryDate,
    columns.partialServiceProducts,
    columns.intialTotalQty,
    columns.childExpand,
    columns.delete
  ];

  if (orderCategory === OrderCategory.ABHOLAUFTRAG) {
    config = [
      columns.orderNumber,
      columns.customerOrderId,
      columns.sender,
      columns.orderLabel,
      columns.product,
      columns.packagingQty,
      columns.totalNetWeight,
      columns.totalQty,
      columns.deliveryDate,
      columns.status,
      columns.emptyCell,
      columns.delete
    ];
  }

  if (edit) {
    return [
      {
        name: 'age',
        property: 'data.age',
        title: translate('newlyAdded'),
        sortable: true,
        movable: false,
        hideable: false,
        customRenderer: customNewlyAddedCellRenderer,
        type: ColumnType.CUSTOM_CELL
      } as ColumnConfig
    ].concat(config);
  }

  return config;
};

export const orderMergeVariantTableConfiguration = (): ColumnConfig[] => {
  return [columns.variantsListOrderLabel, columns.variantLabel, columns.variantsListOrderNumber, columns.variantQuantity, columns.variantDelete];
};

export const shipmentTableConfigurationWithMachineCode = (): ColumnConfig[] => {
  return [
    columns.shipmentMachineCode,
    columns.shipmentZipCode,
    columns.shipmentFrom,
    columns.dash,
    columns.shipmentTo,
    columns.totalNumberOfFrankingId
  ];
};

export const shipmentTableConfigurationWithFrankingIdPrefix = (): ColumnConfig[] => {
  return [
    columns.shipmentFrankingIdPrefix,
    columns.shipmentZipCode,
    columns.shipmentFrom,
    columns.dash,
    columns.shipmentTo,
    columns.totalNumberOfFrankingId
  ];
};

export const shipmentTableConfigurationWithShipmentQty = (): ColumnConfig[] => {
  return [columns.shipmentFrankingIdPrefix, columns.shipmentZipCode, columns.shipmentFrom, columns.dash, columns.shipmentTo, columns.shipmentQty];
};

export const orderViewAllocationTableConfiguration = (productGroup?: ProductGroup | undefined): ColumnConfig[] => {
  if (productGroup === ProductGroup.COLLECTION) {
    return [
      columns.orderNumber,
      columns.customerOrderId,
      columns.sender,
      columns.orderLabel,
      columns.product,
      columns.packagingQty,
      columns.totalNetWeight,
      columns.totalQty,
      columns.deliveryDate,
      columns.status,
      columns.emptyCell,
      columns.options
    ];
  } else {
    return [
      columns.allOrdersPartialServiceRelevant,
      columns.planWithFrankingIds,
      columns.select,
      columns.orderNumber,
      columns.customerOrderId,
      columns.sender,
      columns.orderLabel,
      columns.postage,
      columns.machineCodeOrFrankingIdPrefix,
      columns.procedure,
      columns.participation,
      columns.paymentClearingNumber,
      columns.deliveryDate,
      columns.partialServiceProducts,
      columns.intialTotalQty,
      columns.assignedShipments,
      columns.childExpand,
      columns.options
    ];
  }
};
