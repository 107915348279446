import { AxiosResponseTransformer } from 'axios';
import classNames, { Argument } from 'classnames';
import { isValid, parseJSON } from 'date-fns';
import {
  AllHTMLAttributes,
  createElement,
  Dispatch,
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  HTMLProps,
  PropsWithChildren,
  PropsWithoutRef,
  Reducer,
  ReducerAction,
  ReducerState,
  RefAttributes
} from 'react';

export type FunctionOrReturn<P, R> = ((props: P) => R) | R;

export const reference = <T extends HTMLElement, P extends AllHTMLAttributes<T> = AllHTMLAttributes<T>>(
  displayName: string,
  type: string,
  classMap: FunctionOrReturn<P, Argument>,
  propFilter: FunctionOrReturn<P, Partial<P>> = (p) => p
): ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<T>> => {
  const componentRef = forwardRef<T, P>((props: P, ref: ForwardedRef<T>): JSX.Element => {
    const { children, ...filteredProps } = typeof propFilter === 'function' ? propFilter(propsWithRefAndClasses(props, ref, classMap)) : propFilter;
    return createElement(type, filteredProps, children);
  });
  componentRef.displayName = displayName;
  return componentRef;
};

const propsWithRefAndClasses = <T extends HTMLElement, P extends HTMLProps<T>>(
  props: PropsWithChildren<P>,
  ref: ForwardedRef<T>,
  classMap: FunctionOrReturn<PropsWithChildren<P>, Argument>
) => ({
  ...props,
  className: classNames(props.className, typeof classMap === 'function' ? classMap(props) : classMap),
  ref
});

export const inRange = (value: number, min?: number, max?: number): boolean =>
  (typeof min === 'undefined' || value >= min) && (typeof max === 'undefined' || value <= max);

export const dateToISODate = (date: Date): string => date.toISOString().split('T')[0];

export const toggleElementInList = <T>(list: T[], element: T): T[] => {
  const filteredList = list.filter((t) => t !== element);
  return list.length !== filteredList.length ? filteredList : [...filteredList, element];
};

export const rangeArray = (n: number): number[] => Array.from(Array(n).keys());

export const parseIsoDate = (value: string): Date | undefined => {
  const parsed = parseJSON(value);
  if (isValid(parsed)) {
    return parsed;
  }
};

export const indexOf = <T>(list: T[] | undefined, predicate: (t: T) => boolean): number => {
  if (list) {
    for (let i = 0; i < list.length; i++) {
      if (predicate(list[i])) {
        return i;
      }
    }
  }
  return -1;
};

/** Convert a date object to the format YYYY-mm-DD HH:MM:SS
 *
 * @param date
 */
export function getFormattedDate(date: Date): string {
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const year = date.getFullYear();
  const hour = ('0' + date.getHours()).slice(-2);
  const min = ('0' + date.getMinutes()).slice(-2);
  const seg = ('0' + date.getSeconds()).slice(-2);
  return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + seg;
}

export const deDateFormatter = new Intl.DateTimeFormat('de-DE', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const dateReviver = (key: string, value: any): any => (typeof value === 'string' ? parseIsoDate(value) || value : value);

export const transformResponse: AxiosResponseTransformer = (r) => JSON.parse(r || '{}', dateReviver);

export const scrollIntoView = (element: HTMLElement): void => {
  const { offsetTop, clientHeight, offsetParent } = element;
  const container = offsetParent as HTMLElement | null;
  const containerHeight = container?.clientHeight || 0;
  const scrollTop = container?.scrollTop || 0;
  const bottomLine = containerHeight + scrollTop;
  if (offsetTop + clientHeight >= bottomLine) {
    container?.scrollTo(0, offsetTop - containerHeight + clientHeight);
  }
  if (offsetTop <= scrollTop) {
    container?.scrollTo(0, offsetTop);
  }
};

export const concatNonUndefined = (...values: (string | number | undefined)[]): string | undefined => {
  const undefinedExists = values.indexOf(undefined) >= 0;
  return undefinedExists || values.length === 0 ? undefined : values.join('');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ReducerTuple<R extends Reducer<any, any>> = [ReducerState<R>, Dispatch<ReducerAction<R>>];

export const IDENTITY = <T>(x: T): T => x;

/**
 * Do a breadth-first-search on a list of trees where the nodes children are set in a certain childProps.
 *
 * @param list list of object trees
 * @param childProp name of the property holding the child nodes
 * @param predicate search predicate applied to all nodes
 */
export const findFirstRecursively = <TChildrenKey extends string, TType extends { [K in TChildrenKey]?: TType[] }>(
  list: TType[],
  childProp: TChildrenKey,
  predicate: (item: TType) => boolean
): TType | undefined => {
  const firstElement = list.find(predicate);
  if (firstElement) {
    return firstElement;
  }
  for (const { [childProp]: children = [] } of list) {
    const found = findFirstRecursively(children, childProp, predicate);
    if (found) {
      return found;
    }
  }
};

/**
 * Map a list of object trees and their sub-nodes.
 *
 * @param list list of object trees
 * @param childProp name of the property holding the child nodes
 * @param callback mapping function to map the nodes
 */
export const mapRecursively = <TChildrenKey extends string, TType extends { [K in TChildrenKey]?: TType[] }>(
  list: TType[],
  childProp: TChildrenKey,
  callback: (value: TType, index: number, array: TType[]) => TType
): TType[] =>
  list.map((value, index, array) => {
    const children = value[childProp];
    return {
      ...callback(value, index, array),
      [childProp]: children && mapRecursively(children, childProp, callback)
    };
  });

export const distinctBy =
  <T>(callback: (a: T, b: T) => boolean) =>
  (value: T, index: number, array: T[]): boolean => {
    for (let other = 0; other < array.length; other++) {
      if (callback(array[other], value)) {
        return other === index;
      }
    }
    return true;
  };

export const isEntityWithDatesValid = (entity: { validFrom?: string; validTo?: string }, date: Date): boolean => {
  const selectedTime = new Date(date).getTime();
  if (!entity.validFrom || !entity.validTo) {
    return true;
  }

  const validFromFormatted = new Date(
    new Date(entity.validFrom).getFullYear(),
    new Date(entity.validFrom).getMonth(),
    new Date(entity.validFrom).getDate()
  ).getTime();

  const validToFormatted = new Date(
    new Date(entity.validTo).getFullYear(),
    new Date(entity.validTo).getMonth(),
    new Date(entity.validTo).getDate()
  ).getTime();

  return !selectedTime || (validFromFormatted <= selectedTime && validToFormatted >= selectedTime);
};

export const flatten = <R>(array: R[][]): R[] => array.reduce((acc, arr) => acc.concat(arr), []);

export const uuid = (): string =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const groupBy = <T extends Record<string, any>, K extends keyof T>(arr: T[], key: K): Record<string, T[]> =>
  arr.reduce(
    (acc, item) => ({
      ...acc,
      [item[key]]: [...(acc[item[key]] || []), item]
    }),
    {} as Record<string, T[]>
  );

export const validateSettlementNumber = (settlementNumber = '', isEkpAllowed = true): boolean => {
  const trimmed = settlementNumber?.replace(/\s+/g, '');
  if (!trimmed) {
    return false;
  }
  // Validation for customerNumber
  if (trimmed.length === 10 && isEkpAllowed) {
    return /^\d{10}$/.test(trimmed);
  }
  if (trimmed.length === 17) {
    return /^\d{17}$/.test(trimmed);
  }
  if (trimmed.length === 14) {
    return /^\d{12}[\da-zA-Z]{2}$/.test(trimmed);
  }
  return false;
};
export const validateTrimSettlementNumber = (settlementNumber = ''): string => {
  const trimmed = settlementNumber?.replace(/\s+/g, '');
  if (!trimmed) {
    return '';
  }
  // Validation for customerNumber
  if (trimmed.length === 10) {
    if (/^\d{10}$/.test(trimmed)) return trimmed;
  }
  if (trimmed.length === 17) {
    if (/^\d{17}$/.test(trimmed)) return trimmed;
  }
  if (trimmed.length === 14) {
    if (/^\d{12}[\da-zA-Z]{2}$/.test(trimmed)) return trimmed;
  }
  return trimmed;
};

export const validateCustomerNumber = (CustomerNumber = ''): string => {
  const trimmed = CustomerNumber?.replace(/\s+/g, '');
  if (!trimmed) {
    return '';
  }
  // Validation for customerNumber
  if (trimmed.length === 10) {
    if (/^\d{10}$/.test(trimmed)) return trimmed;
  }
  return trimmed;
};
