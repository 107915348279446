import React, { useEffect, useRef, useState } from 'react';
import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';
import { useTranslation } from 'react-i18next';

import { IdAssignment, InternalHandler } from './IdAssignment';
import i18n from 'i18n';
import { QtyAllocation, QtyAllocationRange } from '../../context/order/dtos/Order';
import { useOrderContext } from '../../context/order/OrderContext';
import { OrderReference } from '../../context/order/dtos/OrderReference';
import { publishToTopic } from 'common/dtos/PubSubEvents';
import { Occurrence } from 'common/dtos/Occurrence';

import transDE from './i18n/translate.de.json';
import transEN from './i18n/translate.en.json';

interface Props {
  orderId: string;
  productNumber: string;
  productFormat: string;
  allocQty: number;
  allocatedValue: string | number;
  onSubmit: (productNumber: string, range: QtyAllocationRange[], totalRangesQty: number) => void;
  onCancel: () => void;
  frankingIdPrefix?: string;
  machineCode?: string;
  postageFrankingIdPrefix?: string;
  meta: { language: string };
}

export const IdAssignmentModal = (props: Props): null => {
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [hasFrankingIdPrefix, setHasFrankingIdPrefix] = useState(false);
  const { orderReferences, changeOrderReference, orderMode } = useOrderContext();
  const currentOrderReference = useRef<OrderReference | null>(null);
  const currentQtyAllocation = useRef<QtyAllocation | null>(null);
  i18n.addResourceBundle('de', 'shipmentAssignment', transDE);
  i18n.addResourceBundle('en', 'shipmentAssignment', transEN);
  const { t } = useTranslation('shipmentAssignment');
  const qtyRanges = useRef<QtyAllocationRange[]>([]);
  const totalQtyRanges = useRef<number>(0);

  useEffect(() => {
    if (!loaded) {
      const currentRef = orderReferences?.find((o) => o.orderId === props.orderId);
      if (currentRef) {
        currentOrderReference.current = currentRef;
        const qtyAllocation = currentRef.qtyAllocation.find((q) => q.format === props.productFormat);
        if (qtyAllocation) {
          currentQtyAllocation.current = qtyAllocation;
          qtyRanges.current = qtyAllocation.ranges || [];
        }
      }

      if (props.frankingIdPrefix || props.machineCode || props.postageFrankingIdPrefix) {
        setHasFrankingIdPrefix(true);
      }

      setLoaded(true);
    }
  }, [loaded, orderReferences]);

  useEffect(() => {
    if (loaded) {
      publishToTopic('am.modal.open', {
        content: (
          <IdAssignment
            {...props}
            header="frankingIdPrefix"
            onChange={handleOnChange}
            onHasFrankingIdPrefix={handleOnHasFrankingIdPrefix}
            ranges={qtyRanges.current.length ? (qtyRanges.current as InternalHandler[]) : undefined}
            qtyAllocation={currentQtyAllocation.current}
            productFormat={props.productFormat}
            meta={{ language: props.meta.language }}
            orderMode={orderMode}
            inModal={true}
            id={props.orderId}
          />
        ),
        headline: ``,
        cancelConfig: {
          title: t(`cancel`),
          onCancel: onCancel
        },
        containerClassNames: 'pslIdAssignmentModalContainer',
        contentClassNames: 'pslIdAssignmentModalContent',
        submitConfig: {
          button: (
            <DHLButton
              name={'save-button'}
              label={t(`addId`)}
              onClick={onSubmit}
              type={'primary'}
              disabled={!hasFrankingIdPrefix || submitDisabled}
            />
          )
        }
      });
    }
  }, [loaded, submitDisabled, hasFrankingIdPrefix]);

  const onSubmit = () => {
    if (currentOrderReference.current) {
      currentOrderReference.current?.qtyAllocation.forEach((q) => {
        if (q.productNumber === props.productNumber) {
          q.ranges = qtyRanges.current;
          q.idsQty = totalQtyRanges.current;
        }
      });
      const countAllContainsIds = currentOrderReference.current?.qtyAllocation.filter((q) => q.containsIds).length;
      const countAllNotContainsIds = currentOrderReference.current?.qtyAllocation.filter((q) => !q.containsIds).length;
      currentOrderReference.current.idsQty = totalQtyRanges.current;
      currentOrderReference.current.containsIds =
        !countAllNotContainsIds && countAllContainsIds
          ? Occurrence.ALL
          : countAllNotContainsIds && countAllContainsIds
          ? Occurrence.SOME
          : Occurrence.NONE;
      changeOrderReference(props.orderId, currentOrderReference.current);
    }
    props.onSubmit(props.productNumber, qtyRanges.current, totalQtyRanges.current);
    publishToTopic('am.modal.trigger.close', null);
  };

  const onCancel = () => {
    props.onCancel();
  };

  const handleOnChange = (ranges: any, rangeQties: number) => {
    qtyRanges.current = ranges;
    totalQtyRanges.current = rangeQties;
    setSubmitDisabled(false);
    /*
    if (rangeQties <= 0) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
     */
  };

  const handleOnHasFrankingIdPrefix = () => setHasFrankingIdPrefix(true);

  return null;
};
