import styles from './pslbilling-table.module.css';
import React, { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { MoneyDT } from 'generated';
import { useAuthContext } from '../../../../common/context/auth/AuthContext';
type PSLBillingTableSumRowProps = {
  partialTotalQty: number;
  partialTotalPrice: number;
  idTotalQty: number;
  idTotalPrice: number;
  termTotalQty: number;
  termTotalPrice: number;
  unitAmountCol: boolean;
  currency: string;
  totalFeeReductionWithoutVAT?: MoneyDT;
  totalFeeReductionWithVAT?: MoneyDT;
};
export const PSLBillingTableSumRow: VFC<PSLBillingTableSumRowProps> = (props) => {
  const { t } = useTranslation('', { keyPrefix: 'billingTable' });
  const { user } = useAuthContext();
  return (
    <>
      <tr key={'row-data-summary'} className={classNames(styles.parentRow, 'dhlTable-row dhlTable-row-default leaf')}>
        <td></td>
        <td>{t('total')}</td>
        <td className={styles.rightAlign}>{props.partialTotalQty.toLocaleString(user.language, { maximumFractionDigits: 3 })}</td>
        {props.unitAmountCol && <td></td>}
        <td></td>
        <td className={styles.rightAlign}>
          {props.partialTotalPrice.toLocaleString(user.language, {
            style: 'currency',
            currency: props.currency,
            maximumFractionDigits: 3
          })}
        </td>
        <td className={styles.rightAlign}>{props.idTotalQty.toLocaleString(user.language, { maximumFractionDigits: 3 })}</td>
        <td></td>
        <td className={styles.rightAlign}>
          {props.idTotalPrice.toLocaleString(user.language, {
            style: 'currency',
            currency: props.currency,
            maximumFractionDigits: 3
          })}
        </td>
        <td className={styles.rightAlign}>{props.termTotalQty.toLocaleString(user.language, { maximumFractionDigits: 3 })}</td>
        <td></td>
        <td className={styles.rightAlign}>
          {props.termTotalPrice.toLocaleString(user.language, {
            style: 'currency',
            currency: props.currency,
            maximumFractionDigits: 3
          })}
        </td>
        <td className={styles.rightAlign}>
          {(props.partialTotalPrice + props.idTotalPrice + props.termTotalPrice).toLocaleString(user.language, {
            style: 'currency',
            currency: props.currency,
            maximumFractionDigits: 3
          })}
        </td>
      </tr>
      <tr className={styles.parentRow}>
        <td className={classNames(styles.rightAlign, styles.removeBorder, styles.summaryRowHeight)} colSpan={props.unitAmountCol ? 12 : 11}>
          {t('totalFeeReductionWithoutVAT')}
        </td>
        <td className={classNames(styles.rightAlign, styles.removeBorder, styles.summaryRowHeight)}>
          {(props.totalFeeReductionWithoutVAT?.value as number).toLocaleString(user.language, {
            style: 'currency',
            currency: props.totalFeeReductionWithoutVAT?.currency,
            maximumFractionDigits: 3
          })}
        </td>
      </tr>
      <tr className={styles.parentRow}>
        <td className={classNames(styles.rightAlign, styles.removeBorder, styles.summaryRowHeight)} colSpan={props.unitAmountCol ? 12 : 11}>
          {t('VAT')}
        </td>
        <td className={classNames(styles.rightAlign, styles.removeBorder, styles.summaryRowHeight)}>
          {((props.totalFeeReductionWithVAT?.value as number) - (props.totalFeeReductionWithoutVAT?.value as number)).toLocaleString(user.language, {
            style: 'currency',
            currency: props.totalFeeReductionWithoutVAT?.currency,
            maximumFractionDigits: 3
          })}
        </td>
      </tr>
      <tr className={styles.parentRow}>
        <th
          className={classNames(styles.rightAlign, styles.removeBorder, styles.addBorderBottom, styles.summaryRowHeight)}
          colSpan={props.unitAmountCol ? 12 : 11}
        >
          {t('totalFeeReductionWithVAT')}
        </th>
        <td className={classNames(styles.rightAlign, styles.removeBorder, styles.addBorderBottom, styles.summaryRowHeight)}>
          {(props.totalFeeReductionWithVAT?.value as number).toLocaleString(user.language, {
            style: 'currency',
            currency: props.totalFeeReductionWithVAT?.currency,
            maximumFractionDigits: 3
          })}
        </td>
      </tr>
    </>
  );
};
