import { ObjectContaining } from 'common/utils/formHelpers';
import {
  CreateDialogPostOrder,
  CreatePostaktuellOrder,
  DependentProduct,
  LetterBaseProduct,
  OrderCustomer,
  OrderCustomerRoleEnum,
  OrderPriceRep
} from 'generated';
import { LineItemVariant } from 'order/common/dtos/LineItemVariant';
import { PostageType } from 'order/common/dtos/PostageType';
import { UseDependentProductsReturn } from 'order/common/hooks/useDependentProducts';
import { isValidDate } from 'order/common/utils/isValidDate';
import { OtherPartnerSchema } from 'order/productGroups/common/components/atom/AMDHLOtherPartners/otherPartnerSchema';
import { AddressSectionSchema } from 'order/productGroups/common/components/molecule/AddressSection/addressSectionSchema';
import { ContactSchema } from 'order/productGroups/common/components/molecule/ContactSection/contactSchema';
import { DestinationSectionSchema } from 'order/productGroups/common/components/molecule/DestinationSection';
import { PackagingSchema } from 'order/productGroups/common/components/molecule/PackagingSection';
import { TimeCitySchema } from 'order/productGroups/common/components/molecule/TimeCitySection';
import { UseFormSetError } from 'react-hook-form';
import { Shipment } from '../../common/components/molecule/DisplayQuantityWeightSection';
import { ProductionState } from '../../../common/context/order/dtos/ProductionState';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import {
  SubProductsSchema,
  subProductsToDependentProducts
} from 'order/productGroups/dialogMarketing/components/SubProductsSection/subProductsSchema';
import { Fertigung, fertigungTypes } from '../components/FertigungSection/FertigungSection';
import { validateSettlementNumber } from '../../../../common/utils/helpers';
import { isServiceAllowed } from '../utils/isServiceAllowed';

export const validateDpContent = (
  values: DialogMarketingOrderCreate | undefined,
  setError: UseFormSetError<DialogMarketingOrderCreate>,
  step: number = 2
): boolean => {
  let validates = true;
  const error = setError as unknown as UseFormSetError<ObjectContaining<DialogMarketingOrderCreate>>;
  const customerIdRegex = /^\s*(?:\d\s*){10}$/;
  if (step === 2) {
    if (!values?.packaging?.quantity) {
      validates = false;
      error(`packaging.quantity`, { message: 'notMatching' });
    }
    if (values?.packaging?.type) {
      if (!values?.packaging?.quantity) {
        validates = false;
        error(`packaging.quantity`, { message: 'required' });
      }
    } else {
      if (values?.packaging?.quantity) {
        validates = false;
        error(`packaging.type`, { message: 'required' });
      } else {
        validates = true;
        error(`packaging.quantity`, { message: '' });
      }
    }
    if (values?.packaging?.type === 'PAL') {
      const destinationSum = values.destinations?.matrix?.map((v) => v?.quantity || 0).reduce((acc, val) => acc + val, 0);

      if (destinationSum !== values.packaging?.quantity) {
        validates = false;
        error(`packaging.quantity`, { message: 'notMatching' });
      }
    }
    if (values?.otherPartners && values?.otherPartners?.length) {
      values?.otherPartners?.map((p, i) => {
        if (p.role && !p.customerId?.length) {
          validates = false;
          error(`otherPartners[${i}].customerId`, { type: 'required', message: 'error.customerIdUnknown' });
        } else if (!p.role && p.customerId?.length) {
          validates = false;
          error(`otherPartners[${i}].role`, { type: 'required', message: 'error.partnerRole' });
        } else if (p.role && p.customerId && p.customerId.length && !customerIdRegex.test(p.customerId)) {
          validates = false;
          error(`otherPartners[${i}].customerId`, { type: 'required', message: 'error.customerIdUnknown' });
        }
      });
    }
    if (!values?.submitter) {
      validates = false;
      error(`submitter`, { type: 'required', message: 'required' });
    }
    if (!values?.originator) {
      validates = false;
      error(`originator`, { type: 'required', message: 'required' });
    }
    if (!values?.pickupAddress && !values?.details?.productionPlantId) {
      validates = false;
      error(`details.productionPlantId`, { type: 'required', message: 'required' });
    }
    const totalWeight = values?.destinations?.totalWeight || 0;
    const quantity = values?.packaging?.quantity || 0;

    if (quantity > 0 && totalWeight / quantity > 700) {
      validates = false;
      error(`destinations.totalWeight`, { type: 'max', message: 'max' });
    }
  }

  if (step === 3) {
    if (!values?.payer) {
      validates = false;
      error(`payer`, { type: 'required', message: 'error.required' });
    }
    if (values?.payer && !validateSettlementNumber(values?.payer, values?.productGroup !== ProductGroup.DIALOGPOST)) {
      validates = false;
      error(`payer`, { type: 'error.required', message: 'payer.bankInformation.error.abrechnungsValidation' });
    }
  }

  return validates;
};

export type DialogMarketingOrderCreate = {
  productGroup: ProductGroup;
  productionState?: ProductionState;
  orderCategory?: string;
  orderId?: string;
  dialogpostBaseProduct: string;
  details: TimeCitySchema & {
    quantity: number;
    additionalQuantity?: number;
    individualWeight: number;
  };
  furtherInfo: LineItemVariant[];
  orderLabel: string;
  originator: string;
  submitter: string;
  subProducts: SubProductsSchema;
  takeOriginatorAsSubmitter?: boolean;
  targetGroup?: LineItemVariant.NKD | LineItemVariant.BKD | LineItemVariant.NS;
  contactForQuestions?: ContactSchema;
  destinations?: DestinationSectionSchema;
  packaging: PackagingSchema;
  changeOrder?: CreateDialogPostOrder;
  fertigung?: Fertigung;
  pickupAddress?: AddressSectionSchema;
  otherPartners?: OtherPartnerSchema[];
  orderNumber?: string | undefined;
  shipmentData: Shipment[];
  preservedAmexOrder?: string;
  orderPrice?: OrderPriceRep;
  payer?: string;
  takeOriginatorAsPayer?: boolean;
  postageType?: PostageType;
  additionalCharge?: PostageType;
  paymentClearingNumber?: string;
  postageAmountValue?: number;
  postageMachineCode?: string;
  letterBaseProducts?: LetterBaseProduct[];
};

const fertigungToDependentProducts = (quantities: Fertigung, products: UseDependentProductsReturn['fertigung']): DependentProduct[] =>
  fertigungTypes
    .map((type) => ({
      productNumber: products[type]?.productNumber,
      shipmentQuantity: quantities?.[type] || 0
    }))
    .filter(({ productNumber, shipmentQuantity }) => !!productNumber && !!shipmentQuantity);

export const translateDialogPost = (
  // { content, payment, orderCategory, orderId, productionState }: DialogMarketingOrderCreate,
  values: DialogMarketingOrderCreate,
  dependent: UseDependentProductsReturn,
  change: boolean = false
): CreateDialogPostOrder & { productionState?: ProductionState } => {
  const parties: OrderCustomer[] = [
    {
      role: OrderCustomerRoleEnum.Submitter,
      customerId: values.takeOriginatorAsSubmitter ? values.originator.toString().replace(/ /g, '') : values.submitter.toString().replace(/ /g, ''),
      contact: values.contactForQuestions ? values.contactForQuestions : undefined
    },
    {
      role: OrderCustomerRoleEnum.Originator,
      customerId: values.originator.toString().replace(/ /g, '')
    }
  ];
  if (values.payer) {
    parties.push({
      role: OrderCustomerRoleEnum.Payer,
      customerId: values.payer.toString().replace(/ /g, '')
    });
  }

  const otherPartners =
    values.otherPartners
      ?.filter((customer) => customer.role && customer.customerId)
      .map<OrderCustomer>((customer) => ({
        role: customer.role as OrderCustomerRoleEnum,
        customerId: customer.customerId.toString().replace(/ /g, '')
      })) || [];

  parties.push(...otherPartners);

  const products = dependent.fertigung;
  const dependentProducts: DependentProduct[] = products ? subProductsToDependentProducts(values.subProducts, dependent) : [];

  if (values.fertigung) {
    dependentProducts.push(...fertigungToDependentProducts(values.fertigung, products));
  }

  let variants = values.furtherInfo;

  if (values.targetGroup) {
    variants = (variants || []).filter((v) => v !== LineItemVariant.BKD && v !== LineItemVariant.NKD && LineItemVariant.NS);

    if (values.targetGroup !== LineItemVariant.NS) {
      variants.push(values.targetGroup);
    }
  }

  const { quantity, additionalQuantity, individualWeight: individualWeightValue } = values.details;

  const validDate = isValidDate(values.details.date!);
  const detailsDate = validDate ? values.details.date : new Date(values.details.date || '');
  const [hour = 0, minute = 0] = values.details.time?.match(/(\d{2,})/g)?.map(Number) || [];

  const earliestDateTime = new Date(Date.UTC(detailsDate.getFullYear()!, detailsDate.getMonth(), detailsDate?.getDate(), hour, minute));

  return {
    productGroup: values.productGroup,
    customerOrderId: '5037346381-B2B',
    orderLabel: values.orderLabel,
    orderType: 'EA',
    orderCategory: values.orderCategory,
    orderId: values.orderId,
    packaging: {
      ...(change && { ...values.changeOrder?.packaging }),
      type: values.packaging.type,
      quantity: values.packaging.quantity,
      stackable: values.destinations?.stackable,
      totalWeight: values.destinations?.totalWeight
    },
    shipmentData: values.shipmentData || [],
    destinations: values.destinations?.matrix,
    productionState: values.productionState,
    parties: parties.filter((party) => party.role && party.customerId),
    variants,
    orderDetail: {
      ...(change && { pickup: values.changeOrder?.orderDetail?.pickup }),
      productionPlantId: values.details.productionPlantId,
      date: earliestDateTime
    },
    pickupAddress: values.pickupAddress
      ? {
          orderAddress: values.pickupAddress
        }
      : undefined,
    dependentProducts,
    postage: {
      type: values.postageType,
      additionalCharge: !isServiceAllowed(values?.details?.date)
        ? values.postageType === PostageType.FV
          ? PostageType.FV
          : undefined
        : values.additionalCharge,
      paymentClearingNumber: values.postageType && values.postageType === PostageType.DV ? values.paymentClearingNumber : undefined,
      machineCode: values.postageType && values.postageType === PostageType.AFM ? values.postageMachineCode : undefined,
      // amount:
      //   values.postageType && values.postageType === PostageType.AS
      //     ? parseFloat(values.postageAmountValue?.toString().replace(/,/g, '.') || '')
      //     : values.postageAmountValue
      amount: values.postageAmountValue
    },
    baseProduct: {
      ...(change && { ...values.changeOrder?.baseProduct }),
      productNumber: values.dialogpostBaseProduct,
      quantity,
      weight: individualWeightValue
    },
    totalQuantity: quantity,
    additionalQuantity,
    ...(change && values.orderNumber && { orderId: values.orderNumber }),
    ...(change && { amexOrder: values.preservedAmexOrder })
  };
};

export const translatePostaktuell = (
  values: DialogMarketingOrderCreate,
  dependent: UseDependentProductsReturn,
  change: boolean = false
): CreatePostaktuellOrder => {
  const parties: OrderCustomer[] = [
    {
      role: OrderCustomerRoleEnum.Submitter,
      customerId: values.takeOriginatorAsSubmitter ? values.originator.toString().replace(/ /g, '') : values.submitter.toString().replace(/ /g, ''),
      contact: values.contactForQuestions ? values.contactForQuestions : undefined
    },
    {
      role: OrderCustomerRoleEnum.Originator,
      customerId: values.originator.toString().replace(/ /g, '')
    }
  ];
  if (values.payer) {
    parties.push({
      role: OrderCustomerRoleEnum.Payer,
      customerId: values.payer.toString().replace(/ /g, '')
    });
  }

  const otherPartners =
    values.otherPartners
      ?.filter((customer) => customer.role && customer.customerId)
      .map<OrderCustomer>((customer) => ({
        role: customer.role as OrderCustomerRoleEnum,
        customerId: customer.customerId.toString().replace(/ /g, '')
      })) || [];

  parties.push(...otherPartners);

  const dependentProducts: DependentProduct[] = subProductsToDependentProducts(values.subProducts, dependent);

  const variants = values.furtherInfo;

  // if (values.targetGroup) {
  //   variants = (variants || []).filter((v) => v !== LineItemVariant.BKD && v !== LineItemVariant.NKD && LineItemVariant.NS);

  //   if (values.targetGroup !== LineItemVariant.NS) {
  //     variants.push(values.targetGroup);
  //   }
  // }

  const { quantity, additionalQuantity, individualWeight: individualWeightValue } = values.details;

  const validDate = isValidDate(values.details.date!);
  const detailsDate = validDate ? values.details.date : new Date(values.details.date || '');
  const [hour = 0, minute = 0] = values.details.time?.match(/(\d{2,})/g)?.map(Number) || [];

  const earliestDateTime = new Date(Date.UTC(detailsDate.getFullYear()!, detailsDate.getMonth(), detailsDate?.getDate(), hour, minute));

  return {
    productGroup: values.productGroup,
    customerOrderId: '5037346381-B2B',
    orderLabel: values.orderLabel,
    orderType: 'EA',
    orderCategory: values.orderCategory,
    orderId: values.orderId,
    packaging: {
      ...(change && { ...values.changeOrder?.packaging }),
      type: values.packaging.type,
      quantity: values.packaging.quantity,
      stackable: values.destinations?.stackable,
      totalWeight: values.destinations?.totalWeight
    },
    shipmentData: values.shipmentData || [],
    destinations: values.destinations?.matrix,
    productionState: values.productionState,
    parties: parties.filter((party) => party.role && party.customerId),
    variants,
    orderDetail: {
      ...(change && { pickup: values.changeOrder?.orderDetail?.pickup }),
      productionPlantId: values.details.productionPlantId,
      date: earliestDateTime
    },
    pickupAddress: values.pickupAddress
      ? {
          orderAddress: values.pickupAddress
        }
      : undefined,
    dependentProducts,
    postage: {
      type: values.postageType
    },
    letterBaseProducts: values.letterBaseProducts,
    totalQuantity: quantity,
    additionalQuantity,
    ...(change && values.orderNumber && { orderId: values.orderNumber }),
    ...(change && { amexOrder: values.preservedAmexOrder })
  };
};
