import React from 'react';
import i18n from 'i18n';
import classNames from 'classnames';
import { CellMetaData, ColumnConfig } from 'common/components/Table/dtos/ColumnConfig';
import { ColumnType } from 'common/components/Table/dtos/ColumnType';
import { TableIconCell } from 'common/components/Table/row/TableIconCell';
import { procedureCache, ProcedureTDO } from 'common/services/ProcedureService';
import { OrderTreeItem } from 'generated';
import { AppPaths } from 'Main';
import { AllocatedOrderData } from 'order/common/context/order/dtos/AllocatedOrder';
import { Link } from 'react-router-dom';
import { OrderTypes } from '../../OrderTypes';
import styles from '../customRenderer.module.css';

export interface rowData {
  data?: AllocatedOrderData;
  children?: OrderTreeItem[];
  autoExpanded?: boolean;
  highlight?: boolean;
  id?: number;
}

export const customRendererForOrderType = (value: unknown, column: ColumnConfig, row: rowData, meta?: CellMetaData): string => {
  return i18n.t('orderTable.rowElement.orderTypeBreaking.' + row?.data?.orderType);
};

export const customRendererForOrderNumber = (value: unknown, column: ColumnConfig, row: rowData, meta?: CellMetaData): JSX.Element => {
  const orderNumber = row?.data?.orderNumber;
  const orderType = row?.data?.orderType;
  const orderTypeParameter = orderType === 'ZA' ? `?orderType=ZA` : undefined;

  if (orderType === OrderTypes.Teileinlieferung) {
    return (
      <span className={classNames(styles.orderNumberSpan)} data-testid={`custom-cell-${column.name}-${row.id}`}>
        {orderNumber}
      </span>
    );
  }
  return meta?.isOrderViewHyperlinkDisabled ? (
    <span data-testid={`custom-cell-${column.name}-${row.id}`}>{orderNumber}</span>
  ) : (
    <Link
      to={{ pathname: `${AppPaths.orderViewPath}/${orderNumber}`, search: orderTypeParameter }}
      data-testid={`custom-cell-${column.name}-${row.id}`}
      target="_self"
    >
      {orderNumber}
    </Link>
  );
  /*
  const orderType = row?.data?.orderType;

   orderType === OrderTypes.Zusatzauftrag ? (
    <span className={classNames(styles.orderNumberSpan)}>{orderNumber}</span>
    )
   */
};

export const customRendererForFrankierung = (value: unknown, column: ColumnConfig, row: rowData, meta?: CellMetaData): string => {
  const postageTypeData = row?.data?.frankierung;
  const translatePostTypeString = i18n.t('orderFilter.postageType.display.' + postageTypeData);
  return postageTypeData === null || postageTypeData === undefined ? '' : translatePostTypeString;
};

export const customRendererForProduct = (value: unknown, column: ColumnConfig, row: rowData, meta?: CellMetaData): string => {
  const productTypeData = row?.data?.product;
  const translatedProductTypeString = i18n.t('orderFilter.productList.types.' + productTypeData);
  return productTypeData === null || productTypeData === undefined ? '' : translatedProductTypeString;
};

export const statusRenderer = (value: string): string => (value ? i18n.t('orderStatus.status.' + value) : '');

export const customRendererForOrderValue = (value: unknown, column: ColumnConfig, row: rowData, meta?: CellMetaData): unknown => {
  const orderType = row?.data?.orderType;
  return (
    <span data-testid={`custom-cell-${column.name}-${row.id}`}>
      {orderType === OrderTypes.Zusatzauftrag || orderType === OrderTypes.Teileinlieferung
        ? ''
        : row?.data?.totalAmountValue?.toLocaleString(meta?.language, {
            style: 'currency',
            currency: row?.data?.totalAmountCurrency
          })}
    </span>
  );
};

export const customRendererForQuantity = (value: unknown, column: ColumnConfig, row: rowData, meta?: CellMetaData): unknown => {
  const orderType = row?.data?.orderType;
  const orderTotalQty = row?.data?.totalQty;
  return (
    <span data-testid={`custom-cell-${column.name}-${row.id}`}>
      {orderType === OrderTypes.Zusatzauftrag ? '' : orderTotalQty?.toLocaleString(meta?.language)}
    </span>
  );
};

export const customRendererForIcons = (value: any, column: ColumnConfig, row: rowData, meta?: CellMetaData): JSX.Element => {
  return (
    <div
      data-testid={`test-${column?.property}-${row?.data?.orderNumber}`}
      className={classNames('dhlTablecell', styles.dhlTableCellWithIcons)}
      key={`${column?.property}-${row?.data?.orderNumber}`}
    >
      <TableIconCell
        id={`${column?.property}-${row?.data?.orderNumber}`}
        key={`${column?.property}-${row?.data?.orderNumber}`}
        noOfChildren={row?.children?.length}
        statusData={row?.data?.state}
        orderId={row?.data?.orderNumber}
        orderLabel={row?.data?.orderLabel}
        postageType={row?.data?.frankierung}
        orderCategory={row?.data?.orderCategory}
        orderType={row?.data?.orderType}
        orderCategoryProductKey={row?.data?.orderCategoryProductKey}
      />
    </div>
  );
};

export const customRendererForProcedure = (value: unknown, column: ColumnConfig, row: rowData, meta?: CellMetaData): unknown => {
  const procedureArray: ProcedureTDO[] = Array.from(procedureCache.values());
  const procedure = row?.data?.procedure;
  return (
    <span data-testid={`custom-cell-${column.name}-${row.id}`}>
      {procedure === null || procedure === undefined
        ? ''
        : procedureArray.map((p) => {
            if (procedure === p.name) {
              return p.id;
            } else {
              return '';
            }
          })}
    </span>
  );
};

export const customRendererForPackagingType = (value: unknown, column: ColumnConfig, row: rowData, meta?: CellMetaData): unknown => {
  return <span data-testid={`custom-cell-${column.name}-${row.id}`}>{value ? i18n.t('products.' + value) : ''}</span>;
};

export const customRendererForPackagingQty = (value: any, column: ColumnConfig, row: rowData, meta?: CellMetaData): unknown => {
  return <span data-testid={`custom-cell-${column.name}-${row.id}`}>{value ? value : ''}</span>;
};

export const customRendererForInitalTotalQty = (value: unknown, column: ColumnConfig, row: rowData, meta?: CellMetaData): string => {
  return row.data?.initialTotalQty ? row.data?.initialTotalQty?.toLocaleString(meta?.language) : '0';
};

export const customRendererForTotalQty = (value: unknown, column: ColumnConfig, row: rowData, meta?: CellMetaData) => {
  return row.data?.totalQty ? row.data?.totalQty?.toLocaleString(meta?.language) : '0';
};

export const customRendererForTotalNetWeightInKg = (value: any, column: ColumnConfig, row: rowData, meta?: CellMetaData): string => {
  return value ? (value / 1000).toLocaleString(meta?.language) : '0';
};

export const customRendererWithWordBreak = (value: any, column: ColumnConfig, row: rowData, meta?: CellMetaData) => {
  return <div className={styles.wordBreak}>{value}</div>;
};

// For Order Merge Variant List
export const customRendererForVariantDescriptionOrderMerge = (value: any, column: ColumnConfig, row: any, meta?: CellMetaData) => {
  return <div className={styles.wordBreak}>{row.data.variantLabel}</div>;
};

export const customRendererForVariantQtyOrderMerge = (value: any, column: ColumnConfig, row: any, meta?: CellMetaData) => {
  return <div>{row.data?.variantQuantity ? row.data?.variantQuantity?.toLocaleString(meta?.language) : '0'}</div>;
};

export const customRendererFormachineCodeOrFrankingIdPrefix = (value: any, column: ColumnConfig, row: any, meta?: CellMetaData) => {
  return <div>{row?.data?.postage?.machineCode || row?.data?.postage?.frankingIdPrefix}</div>;
};

// For Shipment table
export const customRendererForShipmentFrankingIdPrefix = (value: any, column: ColumnConfig, row: any, meta?: CellMetaData) => {
  return <div>{row?.data?.frankingIdPrefix}</div>;
};

export const customRendererForShipmentFrom = (value: any, column: ColumnConfig, row: any, meta?: CellMetaData) => {
  return <div>{row?.data?.from ? row?.data?.from?.toLocaleString(meta?.language)?.toUpperCase() : ''}</div>;
};

export const customRendererForShipmentTo = (value: any, column: ColumnConfig, row: any, meta?: CellMetaData) => {
  return <div>{row?.data?.to ? row?.data?.to?.toLocaleString(meta?.language)?.toUpperCase() : ''}</div>;
};

export const customRendererForShipmentZipCode = (value: any, column: ColumnConfig, row: any, meta?: CellMetaData) => {
  return <div>{row?.data?.zip === 'Q' ? 'DE' : row?.data?.zip}</div>;
};

export const customRendererFortotalNumberOfFrankingId = (value: any, column: ColumnConfig, row: any, meta?: CellMetaData) => {
  const totalNumberOfFrankingId = row?.data?.quantity
    ? +row?.data?.quantity
    : row?.data?.to && row?.data?.from
    ? !isNaN(row?.data?.to - row?.data?.from + 1)
      ? row?.data?.to - row?.data?.from + 1
      : ''
    : '';
  return <div>{totalNumberOfFrankingId.toLocaleString(meta?.language)}</div>;
};
export const customRendererForShipmentQty = (value: any, column: ColumnConfig, row: any, meta?: CellMetaData) => {
  const quantity = row?.data?.quantity
    ? +row?.data?.quantity
    : row?.data?.to && row?.data?.from
    ? !isNaN(row?.data?.to - row?.data?.from + 1)
      ? row?.data?.to - row?.data?.from + 1
      : ''
    : '';
  return <div>{quantity.toLocaleString(meta?.language)}</div>;
};
