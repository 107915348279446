import React, { FC } from 'react';
import i18n, { useTranslation } from 'i18n';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';
import { LineItemVariant } from 'order/common/dtos/LineItemVariant';
import { OrderOverviewRow, OrderOverviewWidget } from 'order/productGroups/common/components/molecule/OrderOverview';
import { Order } from 'order/common/context/order/dtos/Order';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { toFormattedTime } from 'common/formatting';
import { OrderCustomerRoleEnum } from 'generated';
import { useDependentProducts } from 'order/common/hooks/useDependentProducts';
import { useProductionFacilities } from 'order/common/hooks/useProductionFacilities';
import { OrderPickupShippingDisplay } from 'order/productGroups/common/components/atom/OrderPickupShippingDisplay';
import styles from './PAProductInfoSection.module.css';

export type ProductInfoSectionProps = {
  order?: Order;
};

export const PAProductInfoSection: FC<ProductInfoSectionProps> = ({ order }) => {
  const { t } = useTranslation('translation');
  const { t: tCatalogorders } = useTranslation('catalogValues');
  const language = useAuthContext().user.language;
  const date = order?.orderDetail?.date;
  const { facilities } = useProductionFacilities(date);
  const { startPointSelection, deliveryAreaSelection, seasonalSurcharge } = useDependentProducts(order?.productGroup, date);
  const facility = facilities.find(({ catalogId }) => catalogId === order?.orderDetail?.productionPlantId);
  const contactForQuestions = order?.parties.find((p) => p.role === OrderCustomerRoleEnum.Submitter)?.contact;
  const { t: tOrderCreate } = useTranslation('orderCreate', { keyPrefix: 'step4Overview' });
  return (
    <>
      <OrderOverviewRow>
        <OrderOverviewWidget title={t('Produkte')} width={3}>
          <div className={styles.tableContainer}>
            <table>
              <thead>
                <tr>
                  <th>
                    <p>{t('Basisprodukt')}</p>
                  </th>
                  <th style={{ textAlign: 'right' }}>
                    <p>{t('Sendungsmenge')}</p>
                  </th>
                  <th style={{ textAlign: 'right' }}>
                    <p>{t('Einzelgewicht')}</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {order?.letterBaseProducts?.map((bp, i) => {
                  return (
                    <tr data-testid={`base-product-${i}`} key={`baseproductRow${i}`} id={`baseProductRowId${i}`}>
                      <td>
                        <p data-testid={`base-product-testid-description${i}`}>{bp?.baseProduct?.description}</p>
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        <p data-testid={`base-product-testid-quantity${i}`}>{bp?.baseProduct?.quantity?.toLocaleString(language)}</p>
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        <p data-testid={`base-product-testid-weight${i}`}>{bp?.baseProduct?.weight?.toLocaleString(language)} g</p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </OrderOverviewWidget>
      </OrderOverviewRow>
      <OrderOverviewRow>
        <OrderOverviewWidget title="Zusatzleistungen">
          {deliveryAreaSelection?.find(({ productNumber: p }) => order?.dependentProducts?.some(({ productNumber: d }) => d == p)) && (
            <p>{t('deliveryAreaSelection')}</p>
          )}
          {startPointSelection?.find(({ productNumber: p }) => order?.dependentProducts?.some(({ productNumber: d }) => d == p)) && (
            <p>{t('startPointSelection')}</p>
          )}
          <p>
            {seasonalSurcharge?.find((ss) => order?.dependentProducts?.some((p) => p.productNumber == ss.productNumber)) &&
              `${t('seasonalSurcharge')}`}
          </p>
        </OrderOverviewWidget>
        {order?.orderCategoryProductKey !== OrderCategoryProductKey.DHL_INFOPOST && (
          <OrderOverviewWidget title={t('furtherProductFeatures')}>
            {order?.variants
              ?.filter((data) => data !== LineItemVariant.NS && data !== LineItemVariant.NKD && data !== LineItemVariant.BKD)
              .map((variant) => (
                <p key={variant}>{tCatalogorders(`lineItemVariant.display.${variant}`)}</p>
              ))}
          </OrderOverviewWidget>
        )}
      </OrderOverviewRow>
      <OrderOverviewRow>
        <OrderPickupShippingDisplay
          data={{
            date: date,
            pickup: !!order?.pickupAddress,
            time: toFormattedTime(date),
            facility,
            pickupAddress: order?.pickupAddress,
            contactForQuestions: contactForQuestions
          }}
        />
        <OrderOverviewWidget title="Gebinde">
          <table>
            <tbody>
              <tr>
                <td>
                  <p>{tOrderCreate('typeOfBundle')}</p>
                </td>
                <td>
                  <p>{order?.packaging?.type && tCatalogorders('mailEntityType.display.' + order.packaging?.type)}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{tOrderCreate('numberOfBundles')}</p>
                </td>
                <td>
                  <p>{order?.packaging?.quantity?.toLocaleString(language)}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{tOrderCreate('totalGrossWeightInKg')}</p>
                </td>
                <td>
                  <p>{order?.packaging?.totalWeight?.toLocaleString(language)} kg</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{tOrderCreate('stackable')}</p>
                </td>
                <td>
                  <p>{order?.packaging?.stackable ? i18n.t('yes') : i18n.t('no')}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </OrderOverviewWidget>
      </OrderOverviewRow>
    </>
  );
};
