import React, { FC } from 'react';
import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import AMDHLDataFilterChipGroup from './filter/AMDHLDataFilterChipGroup';
import { SearchState } from '../../common/context/search/dtos/SearchState';

import styles from './HistorySearchItem.module.css';
import { useSearchContext } from 'order/common/context/search/SearchContext';
import { getFormattedDateTime } from 'common/utils/dates';

interface HistoryItemProps {
  name?: string;
  isFavourite: boolean;
  orderSearchState: Partial<SearchState>;
  deleteItemFromHistory: () => void;
  addItemToFavourites: () => void;
  goToOriginalSearchTab: () => void;
  index: number;
}

const HistorySearchItem: FC<HistoryItemProps> = (props: HistoryItemProps) => {
  const { t } = useTranslation('', { keyPrefix: 'orderSearch.history' });
  const { searchHistory } = useSearchContext();
  const favs = searchHistory.filter((item) => item.isFavourite);
  const itemDate = searchHistory[props.index]?.dateCreated;

  const hasReachedLimit = (arr: any[]): boolean => {
    return arr.length >= 20;
  };

  return (
    <div className={classNames('dhlDialog dhlDialog-NonModal', styles.historySearchItem)}>
      <div className={styles.criteriaHistory}>
        <AMDHLDataFilterChipGroup attributes={props.orderSearchState.attributes || []} />
        <div data-testid={'history-date-time'} className={styles.dateTime}>
          {t('lastSearched')}
          {itemDate && getFormattedDateTime(new Date(itemDate))}
        </div>
      </div>
      <div className={styles.historyActions}>
        <DHLButton
          name={'showcase-' + props.index}
          label={t('search')}
          type={'primary'}
          size={'normal'}
          disabled={false}
          onClick={() => {
            props.goToOriginalSearchTab();
          }}
        />
        <DHLButton
          name={'deleteIconButton-' + props.index}
          label={'deleteIconButton'}
          iconPosition={'icon'}
          icon={'delete'}
          size={'normal'}
          tooltip={t('delete')}
          disabled={false}
          onClick={() => {
            props.deleteItemFromHistory();
          }}
        />
        <DHLButton
          name={'pin-empty-' + props.index}
          label={'addFavoriteButton'}
          iconPosition={'icon'}
          icon={'pin-empty'}
          size={'normal'}
          tooltip={props.isFavourite ? null : t('save')}
          disabled={props.isFavourite || hasReachedLimit(favs)}
          onClick={() => {
            props.addItemToFavourites();
          }}
        />
      </div>
    </div>
  );
};
export default HistorySearchItem;
