import {
  AmErrorCodeRep,
  DependentProduct,
  LetterBaseProduct,
  Journal,
  OrderCustomer,
  OrderDestination,
  OrderDetail,
  OrderPackaging,
  OrderPostage,
  OrderPriceRep,
  PickupAddress,
  Product
} from 'generated';
import { Supplements } from 'order/productGroups/press/components/SupplementInput/SupplementSchema';
import { OrderCategoryProductKey } from './OrderCategoryProductKey';
import { OrderReference as PSLOrderReferences } from './OrderReference';
import { ProductionState } from './ProductionState';
import { OrderCategory } from './OrderCategory';
import { PressBaseProduct } from 'order/productGroups/press/schema/pressSchema';

export interface ShipmentData {
  description?: string;
  totalQuantity?: number;
  additionalQuantity?: number;
  individualWeight?: number;
  referenceShipmentId?: number;
}

export interface QtyAllocationRange {
  from: number;
  to: number;
  frankingIdPrefix: string;
  zip: string;
  precision: number;
  id?: number;
}

export interface QtyAllocation {
  productNumber: string;
  quantity: number;
  refShipmentId: string;
  disabled?: boolean;
  containsIds?: boolean;
  flexOption?: boolean;
  initialQty?: number;
  originalAssignedQty?: number;
  includeTotalQty?: boolean;
  idsQty?: number;
  initialIdsQty?: number;
  ranges?: QtyAllocationRange[];
  format: string;
  editable?: boolean;
  reducible?: boolean;
  combinedCounter?: number;
  refShipmentIdZA?: string | number | null;
  fromOriginal?: boolean;
  partialServiceItem?: number | string | null;
  idItem?: number | string | null;
  flexItem?: number | string | null;
  multipleContainsIds?: boolean;
  collectedQuantities?: CollectedQuantity[];
}

export interface CollectedQuantity {
  productNumber: string;
  quantity: number;
  refShipmentId: string;
  disabled?: boolean;
  containsIds?: boolean;
  flexOption?: boolean;
  initialQty?: number;
  includeTotalQty?: boolean;
  idsQty?: number;
  initialIdsQty?: number;
  ranges?: QtyAllocationRange[];
  format: string;
  editable?: boolean;
  reducible?: boolean;
  combinedCounter?: number;
  refShipmentIdZA?: string | number | null;
  fromOriginal?: boolean;
  partialServiceItem?: number | string | null;
  idItem?: number | string | null;
  flexItem?: number | string | null;
  multipleContainsIds?: boolean;
  collectedQuantities?: undefined;
}

export interface Order {
  resultStatus?: string;
  durationInMillis?: number;
  timings?: { [key: string]: number };
  amErrors?: Array<AmErrorCodeRep>;
  orderId?: string;
  orderVersion?: string;
  productGroup?: string;
  customerOrderId?: string;
  orderLabel?: string;
  orderType?: string;
  packaging: OrderPackaging;
  destinations?: Array<OrderDestination>;
  parties: Array<OrderCustomer>;
  variants?: Array<string>;
  orderDetail?: OrderDetail;
  baseProduct?: Product;
  shipmentData?: Array<ShipmentData>;
  pickupAddress?: PickupAddress;
  dependentProducts?: Array<DependentProduct>;
  postage?: OrderPostage;
  orderPrice?: OrderPriceRep;
  orderCategory?: OrderCategory;
  state?: string;
  productionState?: ProductionState;
  journal?: Journal;
  nationalProducts?: Array<PressBaseProduct>;
  internationalProducts?: Array<PressBaseProduct>;
  supplements?: Array<Supplements>;
  amexOrder?: string;
  submissionId?: string;
  qtyAllocation?: QtyAllocation[];
  orderReferences?: PSLOrderReferences[];
  partialServiceType?: 'BZA' | 'BZE';
  letterBaseProducts: LetterBaseProduct[];
  additionalQuantity?: number;
  otherAttributes?: { ZOP?: 'std' | 'flex' };
  orderCategoryProductKey?: OrderCategoryProductKey;
  hasOrderTree?: boolean;
  lastChangedOn?: string;
  packagesPerVariant?: VariantPackage[];
  constraints: {
    splittableByVariant: boolean;
    splittableWithSubmitter: boolean;
  };
  maxiBriefDiscountAvailable?: boolean;
  letterProductGroup?: string;
  internationalBaseProduct: string;
  transportServices: LetterBaseProduct[];
  additionallyPaidItems: number;
}

export interface VariantPackage {
  refShipmentId: string;
  description: string;
  packageQuantity: string;
}

export const sortFormatList = ['S', 'K', 'G', 'M', 'P'];
export const sortLetterTypeList = ['standardbrief', 'kompaktbrief', 'großbrief', 'maxibrief', 'postkarte'];
