/**
 * Erweitert die Suchanfrage um den Favourit und Erstellungszeitpunkt.
 */
import { AuthUser } from 'common/dtos/auth';
import { orderSearchStatesEqual } from 'order/orderSearch/services/orderSearchState';
import { HistoryItem } from '../../common/context/search/dtos/HistoryItem';
import { OrderTableConfiguration } from '../../common/context/search/dtos/OrderTableConfiguration';
import { SearchState } from '../../common/context/search/dtos/SearchState';
import { OrderSearchAttribute } from './orderSearchAttribute';
import { PersistColumn } from 'order/common/context/search/hooks/useSearchPersistence';
import { OrderSearchType } from 'order/common/context/search/dtos/OrderSearchType';
import { DefaultDayRangePreset } from 'common/components/AMDHLDateRangeInput/DayRangePreset';

/**
 * Beschreibt eine Liste aller gespeicherten Suchanfragen.
 */
export type SavedSearches = {
  searches: HistoryItem[];
};

export interface AddSearchToHistory {
  user: AuthUser;
  orderSearchState: SearchState;
  id?: string;
  table?: OrderTableConfiguration;
  oldHistory?: HistoryItem[];
}

const defaultColumn: Record<string, boolean> = {
  sender: true,
  deliverer: true,
  payer: true,
  producer: true,
  beneficiary: true,
  packagingType: true,
  packagingQty: true,
  customerOrderId: true,
  settlementNumber: true,
  zkz: true,
  newspaperNumber: true,
  procedure: true,
  frankierung: true,
  participation: true,
  paymentClearingNumber: true
};
const defaultColumnPress: Record<string, boolean> = {
  sender: true,
  deliverer: true,
  payer: true,
  producer: true,
  beneficiary: true,
  packagingType: true,
  packagingQty: true,
  customerOrderId: true,
  settlementNumber: true
};

export const checkHistoryItemForFavourite = (searchState: Partial<SearchState>, history?: HistoryItem[]): boolean => {
  return history?.find(({ orderSearchState: stateFromHistory }) => orderSearchStatesEqual(stateFromHistory, searchState))?.isFavourite || false;
};

export const deduplicateSearchHistory = (newItem: HistoryItem, history?: HistoryItem[]): HistoryItem[] => {
  return [
    ...(history?.filter(({ orderSearchState: stateFromHistory }) => !orderSearchStatesEqual(stateFromHistory, newItem.orderSearchState)) ?? []),
    newItem
  ];
};

export const deduplicateColumnHistory = (newItem: PersistColumn, columnConfig?: PersistColumn[]): PersistColumn[] => {
  return [...(columnConfig?.filter(({ currentType: orderSearchType }) => orderSearchType !== newItem.currentType) ?? []), { ...newItem }];
};
/**
 * Fügt für eine Suche die Suchparameter in die lokale Suchhistorie ein. Die Parameter werden im Broker-Datastore
 * abgelegt.
 *
 * @param data
 */
export const addSearchToHistory = async (data: AddSearchToHistory): Promise<HistoryItem[]> => {
  // const history = (await getSearchHistory(data.user)) || [];
  const isFavourite = checkHistoryItemForFavourite(data.orderSearchState, data.oldHistory);
  const newHistoryItem: HistoryItem = {
    orderSearchState: data.orderSearchState,
    isFavourite,
    dateCreated: new Date(),
    id: data.id || undefined,
    table: data.table || {}
  };
  const newSearchHistory = deduplicateSearchHistory(newHistoryItem, data.oldHistory);
  // setSearchHistory(newSearchHistory, data.user);
  return newSearchHistory;
};

/**
 * Validate OrderSearchAttribute array in orderSearchState
 * @param attributes
 */
export function validateOrderSearchAttribute(attributes: OrderSearchAttribute[]): boolean {
  let valid = true;

  attributes.forEach((a) => {
    valid = !!(valid && a.key);
    valid = !!(valid && (a.value || a.value === DefaultDayRangePreset.LAST_30_DAYS)); // enum value of LAST_30_DAYS returns 0 runtime issue
  });

  return valid;
}

/**
 * Validate orderSearchState in historyItem
 * @param orderSearchState
 */
export function validateOrderSearchState(orderSearchState: Partial<SearchState>): boolean {
  return !!(
    orderSearchState.attributes &&
    Array.isArray(orderSearchState.attributes) &&
    'type' in orderSearchState &&
    (orderSearchState.type === OrderSearchType.SIMPLE ||
      orderSearchState.type === OrderSearchType.ADVANCED ||
      orderSearchState.type === OrderSearchType.PRESS) &&
    validateOrderSearchAttribute(orderSearchState.attributes)
  );
}

/**
 * Validate historyItem to be instanceof HistoryItem
 * @param historyItem
 */
export function validateHistoryItem(historyItem: HistoryItem): historyItem is HistoryItem {
  let valid;

  // check for top level properties
  valid = 'orderSearchState' in historyItem;
  valid = valid ? 'isFavourite' in historyItem : false;
  valid = valid ? 'dateCreated' in historyItem : false;
  valid = valid && !isNaN(Date.parse(historyItem.dateCreated as unknown as string));

  // check for subLevel
  valid = valid ? validateOrderSearchState(historyItem.orderSearchState) : false;

  return valid;
}

export const persistedColumnFn = (searchType: OrderSearchType, history: PersistColumn[]) => {
  const config: Record<string, boolean> = {};
  const persistedHistory = history.find((item) => {
    return item.currentType === searchType;
  });
  if (persistedHistory?.columnConfig) {
    for (let i = 0; i < persistedHistory?.columnConfig.length; i++) {
      const configTitle = persistedHistory?.columnConfig[i].name;

      if (configTitle && persistedHistory?.columnConfig[i]?.hidden !== undefined) {
        config[configTitle] = !!persistedHistory?.columnConfig.find((a) => a.name === configTitle)?.hidden;
      }
    }
  }
  return Object.keys(config).length ? config : searchType === OrderSearchType.PRESS ? defaultColumnPress : defaultColumn;
};
