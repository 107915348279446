import { OrderResponse } from '../dtos/GetOrder';
import { OrderReference } from '../dtos/OrderReference';
import { determineFlexOption } from './determineFlexOption';
import { QtyAllocation, sortFormatList } from '../dtos/Order';
import { qtySumCalculator } from './qtySumCalculator';
import { Occurrence } from 'common/dtos/Occurrence';
import { DeliveryOption } from 'common/dtos/DeliveryOption';
import { determineDeliveryOption } from './determineDeliveryOption';

export function createOrderReference(
  order: OrderResponse,
  qtyAllocation: QtyAllocation[]
): { orderReference: OrderReference; qtyAlloc: QtyAllocation[]; totalQty: number; initialTotalQty: number } {
  let qtyAlloc: QtyAllocation[] = [];
  let totalQty = 0;
  if (qtyAllocation) {
    qtyAlloc = qtyAllocation;
    qtyAlloc.forEach((q) => {
      q.initialQty = q.quantity;
      q.includeTotalQty = q.includeTotalQty ?? true;
      q.containsIds = q.containsIds ?? (!!q.ranges?.length || order.includesDestinations); // only true if ranges available
      q.flexOption =
        determineFlexOption(order?.otherAttributes || {}) && q.containsIds ? true : q.flexOption && q.containsIds ? true : q.flexOption ?? false; // only true if containsIds true
      q.reducible = true;
    });
    totalQty = qtySumCalculator(qtyAlloc, 'quantity') as number;
  }
  qtyAlloc = qtyAlloc.sort((a, b) => sortFormatList.indexOf(a.format) - sortFormatList.indexOf(b.format));

  const orderReference: OrderReference = {
    orderId: order?.orderId || '',
    frankingIdPrefix: order?.postage?.machineCode || order?.postage?.frankingIdPrefix || undefined,
    machineCode: order?.postage?.machineCode,
    postageFrankingIdPrefix: order?.postage?.frankingIdPrefix,
    includeTotalQty: Occurrence.ALL,
    includesDestinations: order?.includesDestinations,
    containsIds: order.includesDestinations ? Occurrence.ALL : Occurrence.NONE,
    flexOption: determineFlexOption(order?.otherAttributes || {}) ? Occurrence.ALL : Occurrence.NONE,
    totalQty,
    allocatedQty: totalQty,
    idsQty: totalQty,
    qtyAllocation: qtyAlloc,
    initialTotalQty: totalQty,
    selectedDeliveryOption: determineDeliveryOption(order?.otherAttributes || {}),
    new: true
  };

  return { orderReference, qtyAlloc, totalQty, initialTotalQty: totalQty };
}
