export const validPaymentClearingNumber = (value: string): boolean => {
  return value.length >= 1 && value.length <= 5;
};

export const validPostageAmountValue = (value: string): boolean => {
  const regEx = /^\d+,\d{0,2}$/;
  return regEx.test(value);
};

export const validPostageMachineCode = (value: string = ''): boolean => {
  if (value.length < 10) {
    return !value;
  }
  const regEx = /^[\dA-Fa-f]{10}$/;
  return regEx.test(value);
};
