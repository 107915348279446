import { DHLButton, DHLHeadline } from '@gkuis/gkp-base-widgets/dist/lib';
import { FormRow } from 'common/components/FormRow';
import { isValidInteger } from 'common/utils/formHelpers';
import { TextInput } from 'order/productGroups/common/components/atom/TextInput';
import { PressBaseProductVariant, PressOrderCreate } from 'order/productGroups/press/schema/pressSchema';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classes from './PressVariants.module.css';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { Product } from 'generated';
import { ControllerFieldState } from 'react-hook-form/dist/types/controller';
import { max } from 'date-fns';

export type PressVariantsProps = {
  title?: string;
  maxAmount?: number;
  name: `nationalProducts.${number}.variants` | `internationalProducts.${number}.variants`;
  disabled?: boolean;
  onDelete?: (index: number, variant: Product, isLast: boolean) => void;
  showErrorForNationalAndInternational?: boolean;
  setSetErrorMsg?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDate?: Date;
  currentBP?: Product;
  isNational: boolean;
};

let shipmentId: number = 0;

export const PressVariants = ({
  title,
  maxAmount = 99,
  name,
  disabled,
  selectedDate,
  currentBP,
  isNational,
  ...props
}: PressVariantsProps): ReactElement => {
  const { t } = useTranslation('orderCreate');
  const { setError, clearErrors, control, trigger, setValue, resetField } = useFormContext<PressOrderCreate>();
  const { fields: variants, remove, append } = useFieldArray({ control, name });

  const { order, orderMode, meta, upsertMetaData } = useOrderContext();

  const values = useWatch({
    control,
    name: name
  });

  const [nationalProducts, internationalProducts] = useWatch({
    control,
    name: ['nationalProducts', 'internationalProducts']
  });

  type WatchedProduct = { id: string } & PressBaseProductVariant;
  let watchedFields = useRef<WatchedProduct[]>([]).current;

  if (values) {
    watchedFields = variants?.map(
      ({ id }, index) =>
        ({
          id,
          ...values[index]
        } as WatchedProduct)
    );
  }

  useEffect(() => {
    if (watchedFields.length < 1) {
      setValue(name, [{} as WatchedProduct]);
    }
    const allRefIds: (number | string | undefined)[] = [];
    nationalProducts
      ?.flatMap((np) => np?.variants)
      .forEach((npv) => {
        if (npv?.referenceShipmentId) allRefIds.push(npv?.referenceShipmentId);
      });
    internationalProducts
      ?.flatMap((ip) => ip?.variants)
      .forEach((ipv) => {
        if (ipv?.referenceShipmentId) allRefIds.push(ipv?.referenceShipmentId);
      });
    if (allRefIds?.length) {
      shipmentId = Math.max(shipmentId, Math.max(...allRefIds.map((rID) => Number(rID ?? 0))));
    } else {
      const allUsedRefIds: (number | string | undefined)[] = [];
      order?.nationalProducts?.forEach((npv) => allUsedRefIds.push(npv?.referenceShipmentId));
      order?.internationalProducts?.forEach((ipv) => allUsedRefIds.push(ipv?.referenceShipmentId));
      shipmentId = Math.max(0, Math.max(...allUsedRefIds.map((rID) => Number(rID ?? 0))));
    }
  }, [watchedFields]);

  useEffect(
    () => () => {
      shipmentId = 0;
    },
    []
  );

  const getErrorMessage =
    () =>
    ({ fieldState }: { fieldState: ControllerFieldState }) =>
      fieldState.error ? (props.showErrorForNationalAndInternational ? ' ' : t(`error.${fieldState.error.message}`)) : undefined;

  const validateAllDescriptions = useCallback(() => {
    if (isNational) {
      const freqMap: any = {};
      const allPressVariants = nationalProducts?.flatMap((p, i) => {
        return p.variants.map((q, j) => {
          if (q?.description) freqMap[q?.description] = (freqMap[q?.description] || 0) + 1;
          return {
            value: q?.description,
            fieldName: `nationalProducts.${i}.variants.${j}.description`
          };
        });
      });
      allPressVariants?.forEach((ap) => {
        if (freqMap[`${ap?.value}`] > 1) {
          setError(ap.fieldName as `nationalProducts.${number}.variants`, {
            type: 'required',
            message: 'variantInput.description.duplicateValue'
          });
        } else {
          clearErrors(ap.fieldName as `nationalProducts.${number}.variants`);
        }
      });
    } else {
      const freqMap: any = {};
      const allPressVariants = internationalProducts?.flatMap((p, i) => {
        return p.variants.map((q, j) => {
          if (q?.description) freqMap[q?.description] = (freqMap[q?.description] || 0) + 1;
          return {
            value: q?.description,
            fieldName: `internationalProducts.${i}.variants.${j}.description`
          };
        });
      });
      allPressVariants?.forEach((ap) => {
        if (freqMap[`${ap?.value}`] > 1) {
          setError(ap.fieldName as `internationalProducts.${number}.variants`, {
            type: 'required',
            message: 'variantInput.description.duplicateValue'
          });
        } else {
          clearErrors(ap.fieldName as `internationalProducts.${number}.variants`);
        }
      });
    }
  }, [watchedFields]);

  // const disableFieldsWithTarrif = orderMode === OrderMode.CHANGE && meta?.hasTariffCode;

  return (
    <>
      {title && (
        <DHLHeadline type="h6" color={'gray'} textAlignment={'left'}>
          {title}
        </DHLHeadline>
      )}
      {watchedFields.map((variant, index) => {
        return (
          <div key={`${name}.${variant.id}`} data-testid={`${name}.${index}`} className={classes.parentDiv}>
            <FormRow mode="two-col">
              <div>
                <TextInput
                  label={`${t('description')}*`}
                  name={`${name}.${index}.description`}
                  defaultValue={`${t('description')} - ${isNational ? meta?.designationCountNP || 1 : meta?.designationCountIP || 1}`}
                  rules={{
                    required: {
                      value: true,
                      message: 'variantInput.description.empty'
                    },
                    maxLength: { value: 120, message: 'variantInput.description.tooLong' }
                  }}
                  error={getErrorMessage()}
                  onBlur={() => validateAllDescriptions()}
                />
              </div>
              <div>
                <FormRow mode="two-col" style={{ marginBottom: '0px' }}>
                  <TextInput
                    type="number"
                    name={`${name}.${index}.quantity`}
                    label={'Sendungsmenge*'}
                    rules={{
                      required: {
                        value: true,
                        message: 'variantInput.quantity.empty'
                      },
                      validate: isValidInteger,
                      max: { value: 1e9 - 1, message: 'variantInput.quantity.tooBig' },
                      min: { value: 1, message: 'min' }
                    }}
                    error={getErrorMessage()}
                    onChange={(e) => {
                      trigger(`${name}.${index}.quantity`);
                      props.setSetErrorMsg?.(false);
                      variant.referenceShipmentId
                        ? setValue(`${name}.${index}.referenceShipmentId`, variant.referenceShipmentId)
                        : setValue(`${name}.${index}.referenceShipmentId`, String(++shipmentId));
                    }}
                  />
                  <div className={classes.flexBox}>
                    <div className={classes.maxWidthCol}>
                      <TextInput
                        type="number"
                        name={`${name}.${index}.weight`}
                        label="Einzelgewicht in g*"
                        rules={{
                          required: {
                            value: true,
                            message: 'variantInput.weight.empty'
                          },
                          validate: isValidInteger,
                          max: { value: 1e4 - 1, message: 'variantInput.weight.tooBig' },
                          min: { value: 1, message: 'min' }
                        }}
                        error={getErrorMessage()}
                        onChange={() => {
                          trigger(`${name}.${index}.weight`);
                          props.setSetErrorMsg?.(false);
                        }}
                      />
                    </div>
                    <div className={classes.delBtnCustomSpacing}>
                      <DHLButton
                        icon="delete"
                        iconPosition={'icon'}
                        size="xs"
                        onClick={(e) => {
                          e?.preventDefault();
                          props.onDelete?.(index, variant, false);
                          if (watchedFields.length > 1) {
                            if (isNational) {
                              upsertMetaData(
                                'designationCountNP',
                                meta?.prevDesignationCountNP > meta?.designationCountNP
                                  ? meta?.prevDesignationCountNP - 1
                                  : meta?.prevDesignationCountNP + 1
                              );
                            } else {
                              upsertMetaData(
                                'designationCountIP',
                                meta?.prevDesignationCountIP > meta?.designationCountIP
                                  ? meta?.prevDesignationCountIP - 1
                                  : meta?.prevDesignationCountIP + 1
                              );
                            }
                          }

                          remove(index);
                          if (orderMode === OrderMode.CHANGE) {
                            if (variant?.referenceShipmentId) {
                              const filteredVariants = variants?.filter((v) => v?.referenceShipmentId != variant?.referenceShipmentId);
                              resetField(name, { defaultValue: filteredVariants });
                            }
                            if (!order?.constraints?.packageSectionEditable) {
                              upsertMetaData('enablePackaging', true);
                            }
                          }
                        }}
                        name={`products-${index}-delete`}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </FormRow>
              </div>
            </FormRow>
          </div>
        );
      })}
      {props.showErrorForNationalAndInternational && <p className="fieldNote error">{t(`error.baseProductInput.commonBaseProductError`)}</p>}
      {(maxAmount < 1 || watchedFields.length < maxAmount) && (
        <div className={`${classes.addBtnCustomSpacing}`}>
          <DHLButton
            name="products-add"
            size="xs"
            type="ghost"
            icon="plus"
            iconPosition={'icon-first'}
            label={currentBP?.description ? `Weiteres Basisprodukt "${currentBP.description}" hinzufügen` : `Weiteres Basisprodukt hinzufügen`}
            onClick={() => {
              if (orderMode === OrderMode.CHANGE) {
                if (isNational) {
                  const allVariants = nationalProducts?.flatMap((np) => np?.variants);
                  const labelArr = allVariants?.map((npv) => (npv?.description?.split(' - ')[1] ? +npv.description.split(' - ')[1] : 1));
                  const maxLabelCount = Math.max(...labelArr);
                  if (!isNaN(maxLabelCount)) {
                    upsertMetaData('designationCountNP', (maxLabelCount || 1) + 1);
                  } else {
                    upsertMetaData('prevDesignationCountNP', meta?.designationCountNP || 1);
                    upsertMetaData('designationCountNP', (meta?.designationCountNP || 1) + 1);
                  }
                } else {
                  const allVariants = internationalProducts?.flatMap((np) => np?.variants);
                  const labelArr = allVariants?.map((npv) => (npv?.description?.split(' - ')[1] ? +npv.description.split(' - ')[1] : 1));
                  const maxLabelCount = Math.max(...labelArr);
                  if (!isNaN(maxLabelCount)) {
                    upsertMetaData('designationCountIP', (maxLabelCount || 1) + 1);
                  } else {
                    upsertMetaData('prevDesignationCountIP', meta?.designationCountIP || 1);
                    upsertMetaData('designationCountIP', (meta?.designationCountIP || 1) + 1);
                  }
                }
                if (!order?.constraints?.packageSectionEditable) {
                  upsertMetaData('enablePackaging', true);
                }
              } else {
                if (isNational) {
                  upsertMetaData('prevDesignationCountNP', meta?.designationCountNP || 1);
                  upsertMetaData('designationCountNP', (meta?.designationCountNP || 1) + 1);
                } else {
                  upsertMetaData('prevDesignationCountIP', meta?.designationCountIP || 1);
                  upsertMetaData('designationCountIP', (meta?.designationCountIP || 1) + 1);
                }
              }
              append({} as PressBaseProductVariant);
            }}
            className={`${classes.addButton}`}
            disabled={disabled}
          />
        </div>
      )}
    </>
  );
};
