import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import deliveryBdIt from '../fonts/Delivery_BdIt.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import deliveryIt from '../fonts/Delivery_It.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import deliveryBold from '../fonts/Delivery.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import deliveryRegular from '../fonts/Delivery_Rg.ttf';
import i18n from 'i18next';
import { OrderSearchKey } from 'order/common/context/search/dtos/OrderSearchKey';
import { OrderSearchAttribute, printAttribute } from 'order/orderSearch/services/orderSearchAttribute';
import { formatSettlementNumber } from 'order/orderSearch/services/recommendationService';
import { OrderReplyTDO, OrderSearchResultRepTDO, OrderTreeItem } from 'order/common/services/OrderService';
import { deliveryDateRangeEvaluation } from './ExportUtils';
import { styles } from './pdf/styles';
import { PostLogo } from './pdf/PostLogo';
import { createBarcodeBitmapUrl } from './pdf/createBarcodeBitmapUrl';
import { deliveryDateEvaluation } from './pdf/deliveryDateEvaluation';
import { OrderTypes } from 'order/orderSearch/components/OrderTypes';
import customWordBreakForPDF from './utils/customWordBreakForPDF';

Font.register({
  family: 'delivery',
  fonts: [
    {
      src: deliveryRegular
    },
    {
      src: deliveryBold,
      fontWeight: 'bold'
    },
    {
      src: deliveryIt,
      fontWeight: 'normal',
      fontStyle: 'italic'
    },
    {
      src: deliveryBdIt,
      fontWeight: 'bold',
      fontStyle: 'italic'
    }
  ]
});

interface PdfOrder {
  order: OrderReplyTDO;
  children: OrderTreeItem<OrderReplyTDO>[];
  parentOrderDesc: string;
}

interface Props {
  attributes: OrderSearchAttribute[];
  rawResponse: OrderSearchResultRepTDO | undefined;
  response: OrderSearchResultRepTDO | undefined;
}

const ExportPdfUtils = (props: Props): JSX.Element => {
  const { t: tg } = useTranslation('', {});
  const { attributes, rawResponse, response } = props;
  const { t } = useTranslation('', { keyPrefix: 'pdf' });
  const { t: translate } = useTranslation('', { keyPrefix: 'productsInPlural' });

  return (
    <Document pdfVersion={'1.3'}>
      <Page style={styles.body} orientation={'landscape'} size="A4" wrap={true}>
        <View style={styles.pageHeader} fixed>
          <Text style={styles.header}>{t('title')}</Text>
          <PostLogo viewBox={'0 0 1000 167'} style={styles.headerLogo} />
        </View>
        <View style={styles.searchCriteriaSection}>
          <Text style={styles.sectionTitle}>{t('searchCriteria')}</Text>
          {attributes.map((attr, index) => {
            let { label, value } = printAttribute(attr);
            switch (attr.key) {
              case OrderSearchKey.Date:
                label = t('deliveryDate');
                break;
              case OrderSearchKey.SettlementNumber:
                value = formatSettlementNumber(value);
                break;
              default:
                break;
            }
            return (
              <View style={[styles.tableRow, styles.tableRowFixBorder]} key={`1.1.${index}`}>
                <Text key={`1.2.${index}`} style={styles.searchCriteria}>
                  {label}:
                </Text>
                <Text key={`1.3.${index}`} style={styles.searchValue}>
                  {value}
                </Text>
              </View>
            );
          })}
        </View>
        <View style={styles.searchResultSection}>
          <Text style={styles.sectionTitle}>{t('searchResults')}</Text>
          <Text style={styles.sectionData}>{t('amountFoundOrders', { amount: response?.orders?.length })}</Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow} fixed wrap={false}>
            <View style={[styles.tableColHeader, styles.tableCol11]}>
              <Text style={styles.tableCellHeader}>{t('headers.customerOrderId')}</Text>
            </View>
            <View style={[styles.tableColHeader, styles.tableCol11]}>
              <Text style={styles.tableCellHeader}>{t('headers.originator')}</Text>
            </View>
            <View style={[styles.tableColHeader, styles.tableCol11]}>
              <Text style={styles.tableCellHeader}>{t('headers.submitter')}</Text>
            </View>
            <View style={[styles.tableColHeader, styles.tableCol13]}>
              <Text style={styles.tableCellHeader}>{t('headers.orderLabel')}</Text>
            </View>
            <View style={[styles.tableColHeader, styles.tableCol5]}>
              <Text style={styles.tableCellHeader}>{t('headers.containerAmount')}</Text>
            </View>
            <View style={[styles.tableColHeader, styles.tableCol6]}>
              <Text style={styles.tableCellHeader}>{t('headers.totalQty')}</Text>
            </View>
            <View style={[styles.tableColHeader, styles.tableCol7]}>
              <Text style={styles.tableCellHeader}>{t('headers.totalAmountValue')}</Text>
            </View>
            <View style={[styles.tableColHeader, styles.tableCol8]}>
              <Text style={styles.tableCellHeader}>{t('headers.deliveryDate')}</Text>
            </View>
            <View style={[styles.tableColHeader, styles.tableCol7]}>
              <Text style={styles.tableCellHeader}>{t('headers.state')}</Text>
            </View>
            <View style={[styles.tableColHeader, styles.tableCol21]}>
              <Text style={styles.tableCellHeader}>{t('headers.orderNumber')}</Text>
            </View>
          </View>
          {rawResponse?.orders
            ?.flatMap((order) => {
              return order.children
                ? [{ order: order.data, children: order.children } as PdfOrder].concat(
                    order.children.map((child) => {
                      let parentOrderDesc = undefined;
                      switch (order.data?.orderType) {
                        case OrderTypes.Jobgruppe.valueOf():
                          parentOrderDesc = `${t('jobGroup')} ${order.data?.orderNumber}`;
                          break;
                        case OrderTypes.Teileinlieferung.valueOf():
                          parentOrderDesc = `${t('partialGroup')} ${order.data?.orderNumber}`;
                          break;
                        default:
                          break;
                      }
                      return { order: child.data, parentOrderDesc: parentOrderDesc } as PdfOrder;
                    })
                  )
                : [{ order: order.data } as PdfOrder];
            })
            .flatMap(({ order, children, parentOrderDesc }, index) => (
              <View key={`2.${index}`} style={styles.tableRow} wrap={false}>
                <View style={[styles.tableCol, styles.tableCol11]}>
                  <Text style={styles.tableCell}>
                    {(![OrderTypes.Teileinlieferung.valueOf(), OrderTypes.Jobgruppe.valueOf()].includes(order?.orderType || '') &&
                      order?.customerOrderId?.replace('-', '\u00AD')) ||
                      '-'}
                  </Text>
                </View>
                <View style={[styles.tableCol, styles.tableCol11]}>
                  <Text style={styles.tableCell}>{order?.originator ? `${order?.originator?.customerId} - ${order?.originator?.name}` : '-'}</Text>
                </View>
                <View style={[styles.tableCol, styles.tableCol11]}>
                  <Text style={styles.tableCell}>{order?.submitter ? `${order?.submitter?.customerId} - ${order?.submitter?.name}` : '-'}</Text>
                </View>
                <View style={[styles.tableCol, styles.tableCol13]}>
                  <Text style={styles.tableCell}>{customWordBreakForPDF(order.orderLabel, 16)}</Text>
                </View>
                <View style={[styles.tableCol, styles.tableCol5]}>
                  <Text style={styles.tableCell}>{order.packagingQty ? `${order.packagingQty}${'\n'}${translate(order.packagingType!)}` : '-'}</Text>
                </View>
                <View style={[styles.tableCol, styles.tableCol6]}>
                  <Text style={styles.tableCell}>
                    {(![OrderTypes.Teileinlieferung.valueOf(), OrderTypes.Jobgruppe.valueOf(), OrderTypes.Zusatzauftrag.valueOf()].includes(
                      order?.orderType || ''
                    ) &&
                      order?.totalQty &&
                      new Intl.NumberFormat(i18n.language, { maximumFractionDigits: 0 }).format(order.totalQty)) ||
                      '-'}
                  </Text>
                </View>
                <View style={[styles.tableCol, styles.tableCol7]}>
                  <Text style={styles.tableCell}>
                    {![OrderTypes.Teileinlieferung.valueOf(), OrderTypes.Jobgruppe.valueOf(), OrderTypes.Zusatzauftrag.valueOf()].includes(
                      order?.orderType || ''
                    ) && order?.totalAmountValue
                      ? new Intl.NumberFormat(i18n.language, {
                          style: 'currency',
                          currency: 'EUR'
                        }).format(order.totalAmountValue)
                      : '-'}
                  </Text>
                </View>
                <View style={[styles.tableCol, styles.tableCol8]}>
                  {![OrderTypes.Teileinlieferung.valueOf(), OrderTypes.Jobgruppe.valueOf()].includes(order?.orderType || '') ? (
                    <Text style={styles.tableCell}>{deliveryDateEvaluation(order)}</Text>
                  ) : (
                    <Text style={styles.tableCell}>{deliveryDateRangeEvaluation(order, children)}</Text>
                  )}
                </View>
                <View style={[styles.tableCol, styles.tableCol7]}>
                  <Text style={styles.tableCell}>
                    {(![OrderTypes.Teileinlieferung.valueOf(), OrderTypes.Jobgruppe.valueOf()].includes(order?.orderType || '') &&
                      tg('orderStatus.status.' + order?.state)) ||
                      '-'}
                  </Text>
                </View>
                <View style={[styles.tableCol, styles.tableCol21]}>
                  <Text style={styles.tableCellBarcode}>{order?.orderNumber}</Text>
                  {parentOrderDesc && <Text style={styles.tableCellBarcode}>{`(${parentOrderDesc})`}</Text>}
                  {order?.orderNumber &&
                    ![OrderTypes.Teileinlieferung.valueOf(), OrderTypes.Jobgruppe.valueOf()].includes(order?.orderType || '') && (
                      <Image style={styles.barcodeImage} src={createBarcodeBitmapUrl(order.orderNumber)} />
                    )}
                  {order?.orderType === OrderTypes.Teileinlieferung.valueOf() && <Text style={styles.tableCellBarcode}>{t('partialGroup')}</Text>}
                  {order?.orderType === OrderTypes.Jobgruppe.valueOf() && <Text style={styles.tableCellBarcode}>{t('jobGroup')}</Text>}
                </View>
              </View>
            ))}
        </View>

        <Text style={styles.footerDate} fixed>
          {`${t('printDate')}: ${moment().format('DD.MM.YYYY HH:mm')}`}
        </Text>
        <Text style={styles.footerPageNumber} render={({ pageNumber, totalPages }) => `Seite ${pageNumber} von ${totalPages}`} fixed />
      </Page>
    </Document>
  );
};

export default ExportPdfUtils;
