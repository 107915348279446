import { ObjectContaining } from 'common/utils/formHelpers';
import {
  CreatePressDistributionOrder,
  DependentProduct,
  JournalPostingIdentifierEnum,
  OrderCustomer,
  OrderCustomerRoleEnum,
  Product
} from 'generated';
import { AddressSectionSchema } from 'order/productGroups/common/components/molecule/AddressSection/addressSectionSchema';
import { ContactSchema } from 'order/productGroups/common/components/molecule/ContactSection/contactSchema';
import { DestinationSectionSchema } from 'order/productGroups/common/components/molecule/DestinationSection';
import { PackagingSchema } from 'order/productGroups/common/components/molecule/PackagingSection';
import { TimeCitySchema } from 'order/productGroups/common/components/molecule/TimeCitySection';
import { JournalInputSchema } from 'order/productGroups/press/components/JournalInput';
import { UseFormSetError } from 'react-hook-form';
import { OtherPartnerSchema } from '../../common/components/atom/AMDHLOtherPartners/otherPartnerSchema';
import { Supplements } from 'order/productGroups/press/components/SupplementInput/SupplementSchema';
import { ProductionState } from 'order/common/context/order/dtos/ProductionState';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { PostageType } from 'order/common/dtos/PostageType';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { PressDistributionDependentProduct } from '../../../common/dtos/PressDistributionDependentProduct';
import { isValidDate } from '../../../common/utils/isValidDate';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { validateSettlementNumber } from '../../../../common/utils/helpers';
import { PRESS_VARIANTS, PRODUCT_TYPES } from '../components/PressBaseProduct/PressBaseProducts';
import { OrderResponse } from '../../../common/context/order/dtos/GetOrder';
import { InternationalDestinationSectionSchema, InternationalDestinationZipCodes } from '../../international/schema/internationalClusterSchema';
import { DestinationSchema } from '../../common/components/atom/AMDHLDestinationInput';
import { UseFormClearErrors } from 'react-hook-form/dist/types/form';

export interface PressBaseProductVariant {
  description?: string;
  referenceShipmentId?: string;
  weight?: number;
  quantity?: number;
  itemId?: number;
  preExisting?: boolean;
}
export interface PressBaseProduct {
  id?: string;
  productNumber?: string;
  referenceShipmentId?: string;
  variants: PressBaseProductVariant[];
  discounts?: DependentProduct[];
  packaging?: PackagingSchema;
  destinations?: DestinationSectionSchema;
  baseProductType?: string;
  preExisting?: boolean;
  pressVariant?: PRESS_VARIANTS;
}

export type PressOrderCreate = {
  productGroup: ProductGroup.PRESS_DISTRIBUTION;
  journal?: JournalInputSchema;
  nationalProducts: PressBaseProduct[];
  internationalProducts: PressBaseProduct[];
  supplements: Supplements[];
  subProducts?: Partial<{
    trackAndMatch: boolean;
    premium: boolean;
    coverplus: boolean;
    gogreen: boolean;
    sortingService: boolean;
    additionalFeeSLN: boolean;
  }>;
  originator: string;
  submitter: string;
  payer: string;
  internationalPackaging: PackagingSchema;
  internationalDestinations: InternationalDestinationSectionSchema;
  pickupAddress?: AddressSectionSchema;
  contactForQuestions?: ContactSchema;
  details: TimeCitySchema;
  packaging: PackagingSchema;
  takeOriginatorAsSubmitter?: boolean;
  destinations?: DestinationSectionSchema;
  otherPartners?: OtherPartnerSchema[];
  productionState?: ProductionState;
  orderCategory?: OrderCategory;
  takeOriginatorAsPayer?: boolean;
  postageType?: PostageType;
  additionalCharge?: PostageType;
  paymentClearingNumber?: string;
  postageAmountValue?: number;
  postageMachineCode?: string;
};
export enum dependentProductsAndVariants {
  trackAndMatch = 'Track und Match',
  premium = 'Premiumadress',
  coverPlus = 'CoverPlus',
  goGreen = 'GOGREEN',
  sortingService = 'Sortierservice',
  additionalFeeSLN = 'Zusatzentgelt SLN'
}

export const validatePressOrderCreate = (
  values: PressOrderCreate | undefined,
  setError: UseFormSetError<PressOrderCreate>,
  clearErrors: UseFormClearErrors<PressOrderCreate>,
  step: number = 2
): boolean => {
  let validates = true;
  let nationalProductValidates = true;
  let internationalProductValidates = true;
  const error = setError as unknown as UseFormSetError<ObjectContaining<PressOrderCreate>>;
  const customerIdRegex = /^\s*(?:\d\s*){10}$/;

  if (step === 2) {
    const pressNBPTypes: PRODUCT_TYPES[] = [PRODUCT_TYPES.NEXT_DAY, PRODUCT_TYPES.SECOND_DAY, PRODUCT_TYPES.BASIC, PRODUCT_TYPES.SAME_DAY];
    const nationalProdWithIBP: { duration?: string } = { duration: undefined };
    const allSelectedNBPTypes = values?.nationalProducts?.flatMap((pd) => pd?.baseProductType);
    nationalProdWithIBP['duration'] = pressNBPTypes.find((value) => allSelectedNBPTypes?.includes(value));
    if (values?.nationalProducts && values.nationalProducts.length) {
      values?.nationalProducts?.map((q, i) => {
        q?.variants?.map((p, j) => {
          if (!p.weight && !q.productNumber?.length && !p.quantity) {
            nationalProductValidates = true;
          } else {
            if (!q.productNumber?.length) {
              nationalProductValidates = false;
              error(`nationalProducts.${i}.productNumber`, { type: 'required', message: 'baseProductInput.productNumber.empty' });
            }
            if (!p.quantity) {
              nationalProductValidates = false;
              error(`nationalProducts.${i}.variants.${j}.quantity`, { type: 'required', message: 'baseProductInput.quantity.empty' });
            }
            if (!p.weight) {
              nationalProductValidates = false;
              error(`nationalProducts.${i}.variants.${j}.weight`, { type: 'required', message: 'baseProductInput.weight.empty' });
            }
          }

          if (p?.description && p?.description?.length > 120) {
            error(`nationalProducts.${i}.variants.${i}.description`, { type: 'required', message: 'variantInput.description.tooLong' });
          }
        });
        if (q?.baseProductType === PRODUCT_TYPES.SAME_DAY && !values?.details?.productionPlantId) {
          validates = false;
          error(`details.productionPlantId`, { type: 'required', message: 'required' });
        }
        if (q?.packaging?.type) {
          if (!q?.packaging?.quantity) {
            validates = false;
            error(`nationalProducts.${i}.packaging.quantity`, { message: 'required' });
          }
          if (
            nationalProdWithIBP.duration === q.baseProductType &&
            values.internationalPackaging?.type &&
            q?.packaging?.type !== values.internationalPackaging?.type
          ) {
            validates = false;
            error(`nationalProducts.${i}.packaging.type`, { message: 'typeNotMatching' });
            error(`internationalPackaging.type`, { message: 'typeNotMatching' });
          } else if (nationalProdWithIBP.duration === q.baseProductType) {
            clearErrors && clearErrors(`nationalProducts.${i}.packaging.type`);
            clearErrors && clearErrors(`internationalPackaging.type`);
          }
        } else {
          if (q?.packaging?.quantity) {
            validates = false;
            error(`nationalProducts.${i}.packaging.type`, { message: 'required' });
          }
        }
        if (q?.packaging?.type === 'PAL' && q?.baseProductType !== PRODUCT_TYPES.SAME_DAY) {
          const destinationSum = q?.destinations?.matrix?.map((v) => v?.quantity || 0).reduce((acc, val) => acc + val, 0);
          if (destinationSum !== q?.packaging?.quantity) {
            validates = false;
            error(`nationalProducts.${i}.packaging.quantity`, { message: 'notMatching' });
          }
        }
      });
    }

    if (values?.internationalProducts && values.internationalProducts.length) {
      values?.internationalProducts?.map((q, i) => {
        q?.variants?.map((p, j) => {
          if (!p.weight && !q.productNumber?.length && !p.quantity) {
            internationalProductValidates = true;
          } else {
            if (!q.productNumber?.length) {
              internationalProductValidates = false;
              error(`internationalProducts.${i}.productNumber`, { type: 'required', message: 'baseProductInput.productNumber.empty' });
            }
            if (!p.quantity) {
              internationalProductValidates = false;
              error(`internationalProducts.${i}.variants.${j}.quantity`, { type: 'required', message: 'baseProductInput.quantity.empty' });
            }
            if (!p.weight) {
              internationalProductValidates = false;
              error(`internationalProducts.${i}.variants.${j}.weight`, { type: 'required', message: 'baseProductInput.weight.empty' });
            }
          }
        });
      });
    }

    if (!values?.journal?.zkz) {
      validates = false;
      error(`journal.zkz`, { type: 'required', message: 'required' });
    }
    if (!values?.nationalProducts) {
      validates = false;
      error(`nationalProducts`, { type: 'required', message: 'required' });
    }

    if (!values?.supplements) {
      validates = false;
      error(`supplements`, { type: 'required', message: 'required' });
    }

    if (!values?.originator) {
      validates = false;
      error(`originator`, { type: 'required', message: 'required' });
    }
    if (values?.packaging?.type) {
      if (!values?.packaging?.quantity) {
        validates = false;
        error(`packaging.quantity`, { message: 'required' });
      }
    } else {
      if (values?.packaging?.quantity) {
        validates = false;
        error(`packaging.type`, { message: 'required' });
      }
    }

    if (values?.otherPartners && values?.otherPartners?.length) {
      values?.otherPartners?.map((p, i) => {
        if (p.role && !p.customerId?.length) {
          validates = false;
          error(`otherPartners[${i}].customerId`, { type: 'required', message: 'error.customerIdUnknown' });
        } else if (!p.role && p.customerId?.length) {
          validates = false;
          error(`otherPartners[${i}].role`, { type: 'required', message: 'error.partnerRole' });
        } else if (p.role && p.customerId && p.customerId.length && !customerIdRegex.test(p.customerId)) {
          validates = false;
          error(`otherPartners[${i}].customerId`, { type: 'required', message: 'error.customerIdUnknown' });
        }
      });
    }
    const totalWeight = values?.destinations?.totalWeight || 0;
    const quantity = values?.packaging?.quantity || 0;

    if (quantity > 0 && totalWeight / quantity > 700) {
      validates = false;
      error(`destinations.totalWeight`, { type: 'max', message: 'max' });
    }
    if (quantity > 9999) {
      validates = false;
      error(`packaging.quantity`, { type: 'max', message: 'max4' });
    }

    if (!values?.submitter) {
      validates = false;
      error(`submitter`, { type: 'required', message: 'required' });
    }

    if (!values?.details?.date) {
      validates = false;
      error(`details.date`, { type: 'required', message: 'required' });
    }
    if (!values?.pickupAddress && !values?.details?.productionPlantId) {
      validates = false;
      error(`details.productionPlantId`, { type: 'required', message: 'required' });
    }

    if (values?.supplements && values.supplements.length) {
      values.supplements.map((a, i) => {
        if (a.type == 'MV') {
          if (!a.otherAttribute?.value) {
            validates = false;
            error(`supplements.${i}.otherAttribute`, { type: 'required', message: 'beilageInput.zkz.empty' });
          }
        }
      });
    }

    if (values?.internationalPackaging?.type) {
      if (!values?.internationalPackaging?.quantity) {
        validates = false;
        error(`internationalPackaging.quantity`, { message: 'required' });
      }
    } else {
      if (values?.internationalPackaging?.quantity) {
        validates = false;
        error(`internationalPackaging.type`, { message: 'required' });
      }
    }
    if (values?.internationalPackaging?.type === 'PAL') {
      const destinationSum =
        (values?.internationalDestinations?.economy ?? 0) +
        (values?.internationalDestinations?.mixed ?? 0) +
        (values?.internationalDestinations?.priority ?? 0);
      if (destinationSum !== values?.internationalPackaging?.quantity) {
        validates = false;
        error(`internationalPackaging.quantity`, { message: 'internationalPackagingNotMatching' });
      } else {
        // validates = true;
        error(`internationalPackaging.quantity`, { message: '' });
      }
    }
    const interNationalTotalWeight = values?.internationalDestinations?.totalWeight || 0;
    const interNationalQuantity = values?.internationalPackaging?.quantity || 0;

    if (interNationalQuantity > 0 && interNationalTotalWeight / interNationalQuantity > 700) {
      validates = false;
      error(`internationalDestinations.totalWeight`, { type: 'max', message: 'palletsInternational.error.totalWeight' });
    }
  }

  if (step === 3) {
    if (!values?.payer) {
      validates = false;
      error(`payer`, { type: 'required', message: 'error.required' });
    }
    if (values?.payer && !validateSettlementNumber(values?.payer)) {
      validates = false;
      error(`payer`, { type: 'error.required', message: 'payer.bankInformation.error.abrechnungsValidation' });
    }
  }

  return validates && nationalProductValidates && internationalProductValidates;
};

// noinspection JSUnusedLocalSymbols
export const translatePressDistribution = (
  value: PressOrderCreate,
  orderId: string | undefined,
  amexOrder?: string,
  pressDependentProducts?: PressDistributionDependentProduct[],
  isUTCTime: boolean = false,
  orderMode?: OrderMode
): CreatePressDistributionOrder => {
  const dependentProducts: DependentProduct[] = [];
  const parties: OrderCustomer[] = [
    {
      role: OrderCustomerRoleEnum.Submitter,
      customerId: value.submitter?.toString().replace(/ /g, ''),
      contact: value.contactForQuestions ? value.contactForQuestions : undefined
    },
    {
      role: OrderCustomerRoleEnum.Originator,
      customerId: value.originator?.toString().replace(/ /g, '')
    },
    {
      role: OrderCustomerRoleEnum.Payer,
      customerId: value.payer?.toString().replace(/ /g, '')
    }
  ];
  const otherPartners =
    value.otherPartners?.map<OrderCustomer>((customer) => ({
      role: customer.role as OrderCustomerRoleEnum,
      customerId: customer?.customerId?.toString().replace(/ /g, '')
    })) || [];

  otherPartners.length && otherPartners[0]?.role && parties.push(...otherPartners);

  if (value?.details?.time) {
    const date = new Date(value.details.date);
    date.setHours(Number(value.details.time.split(':')[0]));
    date.setMinutes(Number(value.details.time.split(':')[1]));
    value.details.date = date;
  }
  if (isUTCTime) {
    const validDate = isValidDate(value.details?.date);
    const detailsDate = validDate ? value.details.date : new Date(value.details?.date || '');
    const [hour = 0, minute = 0] = value.details?.time?.match(/(\d{2,})/g)?.map(Number) || [];
    value.details?.date
      ? (value.details.date = new Date(Date.UTC(detailsDate.getFullYear(), detailsDate.getMonth(), detailsDate.getDate(), hour, minute)))
      : '';
  }

  if (value?.subProducts?.trackAndMatch) {
    dependentProducts.push({
      productNumber: pressDependentProducts?.find((depProd) => depProd.hierarchyProductCharacteristic === dependentProductsAndVariants.trackAndMatch)
        ?.productNumber,
      national: true
    });
  }
  if (value?.subProducts?.premium) {
    dependentProducts.push({
      productNumber: pressDependentProducts?.find((depProd) => depProd.hierarchyProductCharacteristic === dependentProductsAndVariants.premium)
        ?.productNumber,
      national: true
    });
  }
  if (value?.subProducts?.coverplus) {
    dependentProducts.push({
      productNumber: pressDependentProducts?.find((depProd) => depProd.hierarchyProductCharacteristic === dependentProductsAndVariants.coverPlus)
        ?.productNumber,
      national: true
    });
  }
  if (value?.subProducts?.sortingService) {
    dependentProducts.push({
      productNumber: pressDependentProducts?.find((depProd) => depProd.hierarchyProductCharacteristic === dependentProductsAndVariants.sortingService)
        ?.productNumber,
      national: true
    });
  }
  if (value?.subProducts?.additionalFeeSLN) {
    dependentProducts.push({
      productNumber: pressDependentProducts?.find(
        (depProd) => depProd.hierarchyProductCharacteristic === dependentProductsAndVariants.additionalFeeSLN
      )?.productNumber,
      national: false
    });
  }

  if (!value.journal?.issueNumberRange?.to?.issueNumber) {
    delete value.journal?.issueNumberRange.to;
  }
  const supplements: Supplements[] = value?.supplements?.map((val) => {
    return {
      description: val?.description,
      productNumber: val?.productNumber,
      weight: val?.weight,
      weightUnit: val?.weightUnit ?? 'g',
      assignments: val?.assignments,
      type: val?.type,
      otherAttribute: { type: val?.otherAttribute?.value ? 'ZKZ' : undefined, value: val?.otherAttribute?.value }
    };
  });
  const matrix: DestinationSchema[] = [];

  if (value.internationalDestinations?.priority) {
    matrix.push({ zipCode: InternationalDestinationZipCodes.PRIORTY, quantity: value.internationalDestinations?.priority });
  }
  if (value.internationalDestinations?.economy) {
    matrix.push({ zipCode: InternationalDestinationZipCodes.ECONOMY, quantity: value.internationalDestinations?.economy });
  }
  if (value.internationalDestinations?.mixed) {
    matrix.push({ zipCode: InternationalDestinationZipCodes.MIXED, quantity: value.internationalDestinations?.mixed });
  }

  const intProduct = value.internationalProducts?.map((val) => {
    return {
      ...val,
      discounts: val.discounts
    };
  });

  if (value.internationalProducts?.length) {
    value.internationalProducts.forEach((bp) => {
      bp.discounts?.forEach((vr) => {
        dependentProducts.push({
          // ...vr,
          referenceShipmentId: bp.referenceShipmentId,
          national: false,
          productNumber: vr?.productNumber,
          shipmentQuantity: vr?.shipmentQuantity
        });
      });
    });
  }

  return {
    customerOrderId: '5037346381-B2B',
    orderType: 'EA',
    orderLabel: value.journal?.journalNumber,
    packaging: {
      type: value.packaging?.type ? value.packaging.type : undefined,
      quantity: value.packaging?.quantity ? value.packaging.quantity : undefined,
      stackable: value.destinations?.stackable,
      totalWeight: value.destinations?.totalWeight,
      packagingEditable: value?.packaging?.packagingEditable
    },
    internationalPackaging:
      value.internationalProducts?.length &&
      (value.internationalProducts[0]?.variants?.[0]?.quantity || (value.internationalProducts[0] as Product)?.quantity) &&
      value.internationalPackaging?.type
        ? {
            type: value.internationalPackaging?.type,
            quantity: value.internationalPackaging?.quantity,
            stackable: value.internationalDestinations?.stackable,
            totalWeight: value.internationalDestinations?.totalWeight,
            packagingEditable: value?.internationalPackaging?.packagingEditable
          }
        : undefined,
    internationalDestinations:
      value.internationalProducts?.length &&
      (value.internationalProducts[0]?.variants?.[0]?.quantity || (value.internationalProducts[0] as Product)?.quantity)
        ? matrix
        : [],
    productGroup: ProductGroup.PRESS_DISTRIBUTION,
    dependentProducts: dependentProducts,
    destinations: value.destinations?.matrix,
    parties,
    variants: value.subProducts?.gogreen ? [dependentProductsAndVariants.goGreen] : undefined,
    orderDetail: value.details,
    journal: {
      postingIdentifier: value.journal?.postingIdentifier as JournalPostingIdentifierEnum | undefined,
      ...(value.journal || {})
    },
    nationalProducts:
      value.nationalProducts?.length && (value.nationalProducts[0]?.variants?.[0]?.quantity || (value.nationalProducts[0] as Product)?.quantity)
        ? value.nationalProducts
        : [],
    productionState: value?.productionState,
    orderCategory: value.orderCategory ?? 'PRESSEDISTRIBUTION',
    pickupAddress: value?.pickupAddress
      ? {
          orderAddress: value.pickupAddress,
          companyName: value.pickupAddress.companyName
        }
      : undefined,
    postage: {
      type: value?.postageType ?? 'FV',
      additionalCharge: value?.additionalCharge
    },
    internationalProducts:
      value.internationalProducts?.length &&
      (value.internationalProducts[0]?.variants?.[0]?.quantity || (value.internationalProducts[0] as Product)?.quantity)
        ? intProduct
        : [],
    supplements: supplements,
    ...(orderId ? { orderId } : {}),
    ...(amexOrder ? { amexOrder } : {})
  };
};

export const createMultipleOrders = (
  value: PressOrderCreate,
  orderId: string | undefined,
  order?: OrderResponse,
  pressDependentProducts?: PressDistributionDependentProduct[],
  isUTCTime: boolean = false,
  orderMode?: OrderMode,
  nationalOrderTypeForInternational?: string,
  packagingEditable?: boolean
): CreatePressDistributionOrder[] => {
  const values = Object.assign({}, value);
  const intBsProducts: Product[] = [];
  if (value?.nationalProducts[0]?.variants?.[0]?.quantity) {
    return values?.nationalProducts?.map((np, prodIndex) => {
      const refShipmentArray: string[] = [];
      np.packaging && (values.packaging = np.packaging);
      values.packaging && (values.packaging.packagingEditable = packagingEditable ? packagingEditable : order?.constraints?.packageSectionEditable);
      values.internationalPackaging &&
        (values.internationalPackaging.packagingEditable = packagingEditable ? packagingEditable : order?.constraints?.packageSectionEditable);
      values.destinations = np.destinations;
      values.nationalProducts = np.variants.map((variant) => {
        variant?.referenceShipmentId && refShipmentArray.push(variant.referenceShipmentId);
        return {
          ...variant,
          productNumber: np.productNumber
        };
      }) as any;
      values.internationalProducts.forEach((bp) => {
        bp.variants?.forEach((vr) => {
          intBsProducts.push({ ...vr, productNumber: bp.productNumber, discounts: bp.discounts });
        });
      });
      values.supplements = value.supplements.map((suppl) => ({ ...suppl }));
      values.supplements = values.supplements.filter((suppl) => {
        suppl.assignments = suppl.assignments?.filter((assignment) => (refShipmentArray + '').includes(assignment?.referenceShipmentId + '' ?? '-1'));
        return suppl.assignments?.length;
      });
      values.pickupAddress = np.baseProductType === PRODUCT_TYPES.SAME_DAY ? undefined : value?.pickupAddress;
      let changeOrderId;
      let amexOrderId;
      if (orderMode === OrderMode.CHANGE && !prodIndex) {
        changeOrderId = orderId;
        amexOrderId = order?.amexOrder;
      }
      values.internationalProducts = nationalOrderTypeForInternational === np.baseProductType ? (intBsProducts as PressBaseProduct[]) : [];
      return translatePressDistribution(values, changeOrderId, amexOrderId, pressDependentProducts, true, orderMode);
    });
  } else {
    values.nationalProducts = [];
    values.internationalProducts.forEach((bp) => {
      bp.variants.forEach((vr) => {
        intBsProducts.push({ ...vr, productNumber: bp.productNumber, discounts: bp.discounts });
      });
    });
    let changeOrderId;
    let amexOrderId;
    if (orderMode === OrderMode.CHANGE) {
      changeOrderId = orderId;
      amexOrderId = order?.amexOrder;
    }
    values.internationalProducts = intBsProducts as PressBaseProduct[];
    return [translatePressDistribution(values, changeOrderId, amexOrderId, pressDependentProducts, true, orderMode)];
  }
};
