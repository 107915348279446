import { AMDHLSelectSingle } from 'common/components/AMDHLSelectSingle';
import { FormRow } from 'common/components/FormRow';
import { isValidInteger, SFCProps } from 'common/utils/formHelpers';
import i18n, { useTranslation } from 'i18n';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { TextInput } from 'order/productGroups/common/components/atom/TextInput';
import React, { ReactElement } from 'react';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import { ControllerFieldState } from 'react-hook-form/dist/types/controller';
import { MailEntityType, mailEntityType } from '../../../dtos/CatalogValues';
import { ProductionFacilitySelect } from '../../../../../common/components/ProductionFacilitySelect';

export type PackagingSchema = Partial<{
  quantity: number;
  type: MailEntityType;
  packagingEditable?: boolean;
}>;

export type PackagingSectionProps<T extends FieldValues> = SFCProps<T, PackagingSchema> & {
  disabled?: boolean;
  showSLN?: boolean;
  destinationAmount?: number;
  onTypeChange?: (value: unknown) => void;
  onQuantityChange?: (value: unknown) => void;
  internationalPackagingSum?: number;
  showProductionPlantId?: boolean;
  isInternational?: boolean;
  className?: string;
};

const translationDE = {
  heading: 'Gebinde',
  headingInternational: 'Internationale Gebinde',
  quantity: 'Anzahl Gebinde',
  type: 'Gebindeart',
  errors: {
    notMatching: {
      noSLN:
        'Die Summe der in Leitzonen und Leitregionen angegebenen Paletten ({{matrix}}) muss der Gesamtanzahl in Gebinde ({{quantity}}) entsprechen.',
      SLN: 'Die Summe der in Leitzonen, Leitregionen und SLN Depots angegebenen Paletten ({{matrix}}) muss der Gesamtanzahl in Gebinde ({{quantity}}) entsprechen. '
    },
    internationalPackagingNotMatching: `Die Summe der Paletten je IPZ ({{matrix}}) muss der Gesamtanzahl der Gebinde ({{quantity}}) entsprechen.`,
    required: 'Dies ist ein Pflichtfeld.',
    noValidInt: 'Der Wert muss ganzzahlig sein.',
    max4: 'Der Wert darf maximal 4 Ziffern enthalten.',
    min: 'Der Wert muss größer als 0 sein.',
    typeNotMatching: 'Die Gebindeart des nationalen und internationalen Produktes muss identisch sein. Eine Leerangabe ist auch möglich.'
  }
};

const translationEN = {
  heading: 'Bundles',
  headingInternational: 'International Bundles',
  quantity: 'Number of Bundles',
  type: 'Type of Bundle',
  errors: {
    notMatching: {
      noSLN:
        'Die Summe der in Leitzonen und Leitregionen angegebenen Paletten ({{matrix}}) muss der Gesamtanzahl in Gebinde ({{quantity}}) entsprechen.',
      SLN: 'Die Summe der in Leitzonen, Leitregionen und SLN Depots angegebenen Paletten ({{matrix}}) muss der Gesamtanzahl in Gebinde ({{quantity}}) entsprechen. '
    },
    required: 'This field is required.',
    noValidInt: 'The value must be an integer without fraction.',
    max4: 'The value can only be 4 digits long.',
    min: 'The value must be greater than 0.',
    typeNotMatching: 'The container type of the national and international product must be identical. Empty information is also possible.'
  }
};

i18n.addResourceBundle('de', 'packagingSection', translationDE);
i18n.addResourceBundle('en', 'packagingSection', translationEN);

export const PackagingSection = <T extends FieldValues>({
  disabled,
  name,
  showSLN,
  destinationAmount,
  internationalPackagingSum,
  ...props
}: PackagingSectionProps<T>): ReactElement => {
  const { t: tCatalogValues } = useTranslation('catalogValues');
  const { t } = useTranslation('packagingSection');
  const { trigger, getValues, setValue } = useFormContext<T>();
  const getErrorMessage =
    (fieldName: any) =>
    ({ fieldState }: { fieldState: ControllerFieldState }) => {
      let message = fieldState.error?.message;
      if (!message) {
        return undefined;
      } else if (message === 'notMatching') {
        message += `.${showSLN ? 'SLN' : 'noSLN'}`;
      } else if (message === 'internationalPackagingNotMatching') {
        return t(`errors.${message}`, {
          quantity: getValues(fieldName),
          matrix: internationalPackagingSum
        });
      }
      return t(`errors.${message}`, {
        quantity: getValues(fieldName),
        matrix: destinationAmount
      });
    };

  return (
    <OrderStepSection headline={props.isInternational ? t('headingInternational') : t('heading')} className={props.className}>
      <FormRow mode={'two-col'}>
        <TextInput
          type="number"
          name={`${name}.quantity`}
          label={`${t('quantity')}`}
          rules={{
            validate: isValidInteger,
            min: { value: 1, message: 'min' },
            max: { value: 1e4 - 1, message: 'max4' }
          }}
          disabled={disabled}
          error={getErrorMessage(`${name}.quantity`)}
          onBlur={(e) => {
            if (e.target.value === '0' || e.target.value === 0) {
              setValue(`${name}.quantity` as any, '' as any);
              trigger(`${name}.quantity` as any);
            }
          }}
          onChange={(e) => {
            props.onQuantityChange?.(e.target.value);
            trigger(`${name}.quantity` as any);
            trigger('destinations.totalWeight' as any);
          }}
        />
        {!props.showProductionPlantId && (
          <Controller
            name={`${name}.type`}
            render={({ field: { ref, ...field }, fieldState }) => (
              <AMDHLSelectSingle
                data-testid={'packaging-Id'}
                name={field.name}
                onChange={(value) => {
                  props.onTypeChange?.(value);
                  field.onChange(value);
                  field.onBlur();
                  trigger(`${name}.quantity` as any);
                }}
                value={field.value as string}
                valueToString={(item) => (item ? tCatalogValues('mailEntityType.display.' + item) : '')}
                items={['', ...mailEntityType.filter((v) => v !== 'LETTER')]}
                placeholder={`${t('type')}`}
                disabled={disabled}
                error={
                  fieldState.error && fieldState.error?.message
                    ? t(`errors.${fieldState.error?.message}`)
                    : fieldState.error
                    ? t('errors.required')
                    : undefined
                }
              />
            )}
          />
        )}
        {props.showProductionPlantId && (
          <FormRow mode={'two-col'}>
            <Controller
              name={`${name}.type`}
              render={({ field: { ref, ...field }, fieldState }) => (
                <AMDHLSelectSingle
                  data-testid={'packaging-Id'}
                  name={field.name}
                  onChange={(value) => {
                    props.onTypeChange?.(value);
                    field.onChange(value);
                    field.onBlur();
                    trigger(`${name}.quantity` as any);
                  }}
                  value={field.value as string}
                  valueToString={(item) => (item ? tCatalogValues('mailEntityType.display.' + item) : '')}
                  items={['', ...mailEntityType.filter((v) => v !== 'LETTER')]}
                  placeholder={`${t('type')}`}
                  disabled={disabled}
                  error={
                    fieldState.error && fieldState.error?.message
                      ? t(`errors.${fieldState.error?.message}`)
                      : fieldState.error
                      ? t('errors.required')
                      : undefined
                  }
                />
              )}
            />
            <ProductionFacilitySelect
              name={`details.productionPlantId`}
              data-testid={'production-plant-Id'}
              rules={{ required: false }}
              // selectedDate={}
              useDepot={true}
              useSLN={true}
              disabled={disabled}
            />
          </FormRow>
        )}
      </FormRow>
    </OrderStepSection>
  );
};
