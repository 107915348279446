import React, { FC, useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import cn from 'classnames';
import { Accordion } from 'common/components/Accordion';
import { Button } from 'common/components/Button';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { UserRights } from 'common/dtos/userRights';
import { OrderCustomerRoleEnum } from 'generated';
import { useTranslation } from 'i18n';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { OrderSearchKey } from 'order/common/context/search/dtos/OrderSearchKey';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { useOrderStep } from 'order/common/services/OrderStepProvider';
import { AccordionItemOtherPartners } from 'order/orderChange/common/components/AccordionItems/OtherPartners';
import { OrderStepHeadline } from 'order/productGroups/common/components/atom/OrderStepHeadline';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { ContactSection } from 'order/productGroups/common/components/molecule/ContactSection';
import { CustomerSection } from 'order/productGroups/common/components/molecule/CustomerSection';
import { MarginalColumn } from 'order/productGroups/common/components/molecule/MarginalColumn/MarginalColumn';
import { PartialProductGroup } from 'order/productGroups/common/components/molecule/PartialProductGroup/PartialProductGroup';
import { PartialServiceNumber } from 'order/productGroups/common/components/molecule/PartialServiceNumber/PartialServiceNumber';
import { TimeCitySection } from 'order/productGroups/common/components/molecule/TimeCitySection';
import { BackToSearchButton } from '../../../../common/components/BackToSearch/BackToSearchButton';
import { BillingOrPostcardNumberInput } from 'order/orderChange/common/components/BillingOrPostcardNumberInput/BillingOrPostcardNumberInput';
import { AddressSection } from 'order/productGroups/common/components/molecule/AddressSection';
import iconAccountDelivery from 'assets/icon-account-delivery.svg';
import orderClasses from 'common/styles/order.module.css';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { AlertTypes } from 'order/common/components/Alerts/dtos/AlertTypes';
import { useAlerts } from 'common/AlertContext';
import { FormRow } from 'common/components/FormRow';
import { AMDHLSelectSingle } from 'common/components/AMDHLSelectSingle';
import { DHLHeadline } from '@gkuis/gkp-base-widgets/dist/lib';
import { JobTitleSection } from 'order/productGroups/common/components/molecule/JobTitleSection';
import {
  AdditionalOrdersOrderCreate,
  validateAbholauftragOrderCreate,
  validateAdditionalOrdersOrderCreate
} from '../../schema/additionalOrdersSchema';

export enum TLProducts {
  TLBZA = 'BZA',
  TLBZE = 'BZE'
}

const inputDisabled = false;
export const AdditionalOrdersStep2: FC = () => {
  const { previousStep, nextStep } = useOrderStep();
  const { addAlert } = useAlerts();
  const { orderMode, isDirty, setDirty, orderCategory, upsertMetaData, order } = useOrderContext();
  const { hasPermission } = useAuthContext();
  const partialProducts = ['BZA', 'BZE'];
  const collectionProducts = ['AbholungProduct'];
  const { t } = useTranslation('orderCreate');
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });
  const { trigger, getValues, setValue, setError, control, clearErrors } = useFormContext<AdditionalOrdersOrderCreate>();
  const isCollection = orderCategory === OrderCategory.ABHOLAUFTRAG;
  const [partialServiceNumber, contractor, product, submitter, date, orderLabel, productGroup] = useWatch({
    control,
    name: ['partialServiceNumber', 'contractor', 'product', 'submitter', 'details.date', 'orderLabel', 'productGroup']
  });
  const isNetPayrollEnabled = contractor?.charAt(10) === '3' && contractor?.charAt(11) === '8';

  useEffect(() => {
    if (order?.pendingIds) {
      addAlert({
        type: AlertTypes.Warning,
        description: t(`notifications.pendingIdsWarning`),
        title: t('notifications.warning')
      });
    }
  }, []);

  useEffect(() => {
    setDirty(true);
  }, []);

  useEffect(() => {
    upsertMetaData('pickUpDate', date);
  }, [date]);

  const handleDateChange = (date: Date) => upsertMetaData('pickUpDate', date);

  // PRODUCT DROPDOWN HANDLING
  const [productErorr, setProductError] = useState<string | undefined>(undefined);
  const productOption = (p?: string) => {
    switch (p) {
      case TLProducts.TLBZA:
        return 'BZA - Sortierung auf Leitregionen, bundesweit';
      case TLProducts.TLBZE:
        return 'BZE - Sortierung auf eine Leitregion';
      case 'AbholungProduct':
        return 'Abholung Allgemein';
      default:
        return '';
    }
  };
  const productChangeHandler = (p?: string) => {
    setValue('product', p as any);
    productValidator(p);
  };
  const productValidator = (product?: string) => {
    if (product === undefined || product === '') {
      setProductError(t('error.product'));
    } else {
      clearErrors('product');
      setProductError(undefined);
    }
  };

  useEffect(() => {
    if (productGroup === ProductGroup.COLLECTION) {
      setValue('product', collectionProducts?.[0] as any);
    }
  }, [productGroup]);

  return (
    <div className="">
      <div className={orderClasses.formContent}>
        <div className={orderClasses.rowWithSidebar}>
          <main>
            <OrderStepHeadline icon={iconAccountDelivery} alt="Icon Auftragsinhalt">
              {oc('title')}
            </OrderStepHeadline>

            <>
              <DHLHeadline type="h6" color="gray">
                {oc('product')}
              </DHLHeadline>
              <FormRow mode={'two-col'}>
                <AMDHLSelectSingle
                  name="product"
                  data-testid={'productDropdown'}
                  items={productGroup === ProductGroup.PARTIAL_SERVICE_LETTER ? partialProducts : collectionProducts}
                  label={productGroup === ProductGroup.PARTIAL_SERVICE_LETTER ? `${oc('partialServiceType')}*` : `${oc('basProduct')}*`}
                  valueToString={productOption}
                  error={productErorr}
                  value={product}
                  onChange={productChangeHandler}
                  disabled={orderMode === OrderMode.CHANGE || productGroup === ProductGroup.COLLECTION}
                  required
                />
              </FormRow>
            </>

            {!isCollection && (
              <PartialServiceNumber
                name={'partialServiceNumber'}
                edit={orderMode === OrderMode.CHANGE}
                partialServiceNumber={partialServiceNumber}
                disabled={order?.pendingIds}
              />
            )}
            <JobTitleSection<AdditionalOrdersOrderCreate> disabled={inputDisabled} hasTargetGroup={false} orderLabel={orderLabel} />
            {!isCollection ? (
              <CustomerSection<AdditionalOrdersOrderCreate>
                placeholder={oc('billingNumber')}
                headline={oc('contractor')}
                inputName="contractor"
                disabled={order?.pendingIds || orderMode === OrderMode.CHANGE || inputDisabled}
                customerRole={OrderSearchKey.Payer}
                errorMessage={t('error.contractor')}
                rules={{
                  pattern: /^\d{8}[\da-zA-Z]\d3[89]\d{2}$/
                }}
                placement={'right'}
                tooltip={{
                  name: 'netPayrollInfo',
                  description: oc('netPayrollInfo')
                }}
                withAddress
                checkboxConfig={
                  hasPermission(UserRights.NetPayroll)
                    ? {
                        name: 'netPayroll',
                        label: oc('netPayroll'),
                        disabled: true,
                        value: isNetPayrollEnabled
                      }
                    : undefined
                }
              />
            ) : (
              <OrderStepSection headline={oc('customer')}>
                <BillingOrPostcardNumberInput name={'payer'} />
              </OrderStepSection>
            )}
            <CustomerSection<AdditionalOrdersOrderCreate>
              headline={oc('consignor')}
              inputName="submitter"
              // disabled={inputDisabled || takeOriginatorAsSubmitter}
              disabled={order?.pendingIds || orderMode === OrderMode.CHANGE || inputDisabled}
              customerRole={OrderSearchKey.Submitter}
              errorMessage={t('error.customerIdUnknown')}
            />
            {isCollection && (
              <AddressSection<AdditionalOrdersOrderCreate>
                name="pickupAddress"
                fallbackCustomerId={submitter}
                disabled={order?.pendingIds || inputDisabled}
                withProductionPlant={true}
              />
            )}
            <ContactSection<AdditionalOrdersOrderCreate> name="contactForQuestions" disabled={order?.pendingIds || inputDisabled} />
            <TimeCitySection<AdditionalOrdersOrderCreate>
              name="details"
              disabled={order?.pendingIds || inputDisabled}
              pickup={isCollection}
              selectedProductGroup={orderCategory === OrderCategory.ABHOLAUFTRAG ? ProductGroup.COLLECTION : ProductGroup.PARTIAL_SERVICE_LETTER}
              product={ProductGroup.PARTIAL_SERVICE_LETTER}
              editing={orderMode === OrderMode.CHANGE}
              isCollection={isCollection}
              onDateChange={handleDateChange}
            />
            <OrderStepSection>
              <Accordion allowMultiple={true}>
                {(register) => <AccordionItemOtherPartners id={1} name={'otherPartners'} register={register} disabled={order?.pendingIds} />}
              </Accordion>
            </OrderStepSection>
          </main>
          <MarginalColumn orderCategory={order?.orderCategory} />
        </div>
      </div>
      <footer className={cn('d-flex', 'justify-content-between')}>
        <div>
          <Button
            style={{ marginRight: '10px' }}
            variant="default"
            onClick={() => previousStep()}
            dataTestId="btnBack"
            label={t('buttons.back')}
            type="button"
          />
          <BackToSearchButton isDirty={isDirty} />
        </div>
        <Button
          variant="primary"
          dataTestId="btnSubmit"
          disabled={order?.pendingIds}
          onClick={async () => {
            const fieldName: any = ['product', 'contractor', 'submitter', 'details.date', 'pickupAddress', 'payer'];
            isCollection && fieldName.push('details.time');
            const isContentValid = await trigger(fieldName);
            const isOrderLabelValid = orderLabel ? orderLabel?.length <= 80 : true;
            const areDependentFieldsValid = isCollection
              ? validateAbholauftragOrderCreate(getValues(), setError)
              : validateAdditionalOrdersOrderCreate(getValues(), setError);
            productValidator(product);
            if (isContentValid && areDependentFieldsValid && isOrderLabelValid) {
              nextStep();
            }
          }}
          label={t('buttons.continue')}
          type="button"
        />
      </footer>
    </div>
  );
};
