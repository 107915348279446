// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Checkbox_alignCenter__vuxIV{
    display: flex;
    align-items: center;
}
.Checkbox_checkboxWrapper__NUD0n label{
    margin-bottom: 0;
    cursor: pointer;
}
.Checkbox_checkboxWrapper__NUD0n input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    width: 22px;
    height: 22px;
    border-radius: 0.15em;
    margin-right: 0.5em;
    border: 1px solid rgba(50, 50, 50, 0.4);
    border-radius: 4px;
    outline: none;
    cursor: pointer;
}
.Checkbox_checkboxWrapper__NUD0n input.Checkbox_checked__DL9vH {
    background-color: #D40511;
    position: relative;
    border-color: #D40511;
}
.Checkbox_checkboxWrapper__NUD0n input.Checkbox_checked__DL9vH::before {
    font-family: 'icomoon' !important;
    content: "\\e90c";
    font-size: 12px;
    color: #fff;
    position: absolute;
    right: 4px;
    top: 4px;
}

.Checkbox_checkboxWrapper__NUD0n input[type="checkbox"]:disabled {
    border-color: #c0c0c0;
    background-color: #c0c0c0;
}

.Checkbox_checkboxWrapper__NUD0n input[type="checkbox"]:disabled+span {
    color: #c0c0c0;
}`, "",{"version":3,"sources":["webpack://./src/common/components/Checkbox/Checkbox.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,wBAAwB;IACxB,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,mBAAmB;IACnB,uCAAuC;IACvC,kBAAkB;IAClB,aAAa;IACb,eAAe;AACnB;AACA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,qBAAqB;AACzB;AACA;IACI,iCAAiC;IACjC,gBAAgB;IAChB,eAAe;IACf,WAAW;IACX,kBAAkB;IAClB,UAAU;IACV,QAAQ;AACZ;;AAEA;IACI,qBAAqB;IACrB,yBAAyB;AAC7B;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".alignCenter{\n    display: flex;\n    align-items: center;\n}\n.checkboxWrapper label{\n    margin-bottom: 0;\n    cursor: pointer;\n}\n.checkboxWrapper input[type=\"checkbox\"] {\n    -webkit-appearance: none;\n    appearance: none;\n    width: 22px;\n    height: 22px;\n    border-radius: 0.15em;\n    margin-right: 0.5em;\n    border: 1px solid rgba(50, 50, 50, 0.4);\n    border-radius: 4px;\n    outline: none;\n    cursor: pointer;\n}\n.checkboxWrapper input.checked {\n    background-color: #D40511;\n    position: relative;\n    border-color: #D40511;\n}\n.checkboxWrapper input.checked::before {\n    font-family: 'icomoon' !important;\n    content: \"\\e90c\";\n    font-size: 12px;\n    color: #fff;\n    position: absolute;\n    right: 4px;\n    top: 4px;\n}\n\n.checkboxWrapper input[type=\"checkbox\"]:disabled {\n    border-color: #c0c0c0;\n    background-color: #c0c0c0;\n}\n\n.checkboxWrapper input[type=\"checkbox\"]:disabled+span {\n    color: #c0c0c0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alignCenter": `Checkbox_alignCenter__vuxIV`,
	"checkboxWrapper": `Checkbox_checkboxWrapper__NUD0n`,
	"checked": `Checkbox_checked__DL9vH`
};
export default ___CSS_LOADER_EXPORT___;
