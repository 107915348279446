import { createApi, Endpoint, responseOrUndefined } from 'common/utils/apiUtils';
import { CustomerInfoWithAddressTO, CustomerNameTO, MasterdataResourceApiFactory, PaymentApiFactory, PaymentRep } from 'generated';

export type CustomerName = CustomerNameTO;
export type CustomerWithAddress = CustomerInfoWithAddressTO;

const customerApi = createApi(MasterdataResourceApiFactory);
const payerApi = createApi(PaymentApiFactory);

export const getCustomerName: Endpoint<string, CustomerNameTO> = async (customerId) => responseOrUndefined(customerApi.getCustomerName(customerId));

export const getCustomer: Endpoint<string, CustomerWithAddress> = async (customerId) => responseOrUndefined(customerApi.getCustomer(customerId));

export const getPayer = async (payerId: string, validityDateStr: string): Promise<PaymentRep | undefined> =>
  responseOrUndefined(payerApi.getPaymentDetailsForPayer(payerId, validityDateStr));
