import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTableContext } from 'order/common/context/table/TableContext';
import { useSearchContext } from 'order/common/context/search/SearchContext';
import { advancedColumnList, pressColumnList, simpleColumnList } from './columnConfiguration';
import { OrderSearchType } from 'order/common/context/search/dtos/OrderSearchType';
import { Table } from 'common/components/Table/Table';
import { TableLoadingState } from 'common/components/Table/dtos/LoadingState';
import { OrderTableConfiguration } from 'order/common/context/search/dtos/OrderTableConfiguration';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { Pagination } from '../../../../common/components/Paginator/Pagination';
import ExportCsvButton from 'order/common/exports/ExportCsvButton';
import ExportPdfButton from 'order/common/exports/ExportPdfButton';
import { PersistColumn } from 'order/common/context/search/hooks/useSearchPersistence';

import styles from '../OrderSearch.module.css';
import { persistedColumnFn } from 'order/orderSearch/services/searchHistoryService';
import { ColumnConfig } from 'common/components/Table/dtos/ColumnConfig';

export const TableContainer = (): JSX.Element => {
  const {
    loadingState,
    columns,
    currentSlice,
    filter,
    rows,
    sort,
    resetSortAndFilters,
    setColumnConfig,
    setRows,
    setLoadingState,
    setPageSize,
    setPage,
    setFilter,
    setColumnMove,
    setSorting
  } = useTableContext();
  const {
    response,
    searchType,
    loading,
    tableConfiguration,
    setTableConfiguration,
    updateColumnHistory,
    columnHistory,
    columnFilter,
    setColumnFilter,
    columnSorting,
    setColumnSorting,
    triggerLastSearch
  } = useSearchContext();
  const { t } = useTranslation('', { keyPrefix: 'orderTable.tables' });
  const language = useAuthContext().user.language;
  useEffect(() => {
    if (searchType) {
      const columnConfig = columnHistory.find((item) => item.currentType === searchType)?.columnConfig;
      provideTableConfig(searchType, tableConfiguration, columnConfig);
      setRows(response?.orders || []);
      setPage(1);
      // resetFilterInput()
      // resetSortAndFilters();
    }
  }, [searchType]);
  useEffect(() => {
    if (!loading && response) {
      setRows(response.orders || []);
    }
    if (loading) {
      setLoadingState(TableLoadingState.LOADING);
    }
    if (loadingState === TableLoadingState.LOADING && !loading) {
      setLoadingState(TableLoadingState.LOADED);
    }
    if (response?.orders?.length !== 0 && !loading) {
      setLoadingState(TableLoadingState.LOADED);
    }
  }, [response, loading, loadingState]);

  const provideTableConfig = (searchType: OrderSearchType, config: OrderTableConfiguration | undefined, columnConfig?: ColumnConfig[]) => {
    switch (searchType) {
      case OrderSearchType.SIMPLE:
        const simpleList = simpleColumnList(config, columnConfig);
        setColumnConfig(simpleList);
        break;
      case OrderSearchType.PRESS:
        const pressList = pressColumnList(config, columnConfig);
        setColumnConfig(pressList);
        break;
      case OrderSearchType.ADVANCED:
        const advancedList = advancedColumnList(config, columnConfig);
        setColumnConfig(advancedList);
        break;
      default:
        break;
    }
  };

  const handleTableConfigChange = (config: Record<string, boolean>, columnHistory?: PersistColumn) => {
    setTableConfiguration(config);
    const columnConfig = columnHistory?.columnConfig;
    provideTableConfig(searchType, { hidden: config }, columnConfig);
    updateColumnHistory(columnHistory!);
  };

  const [load, setLoading] = useState(false);
  const saveColumnWithMove = () => {
    setLoading(true);
  };

  const loadCol = () => {
    const list: ColumnConfig[] = Object.assign([], columns);
    const updatedColumn = { currentType: searchType, columnConfig: list };
    updateColumnHistory(updatedColumn!);
    setLoading(false);
  };
  useEffect(() => {
    if (load) {
      loadCol();
    }
  }, [load]);

  const persistedTableConfig = (searchType: OrderSearchType) => {
    switch (searchType) {
      case OrderSearchType.SIMPLE:
        const simple = persistedColumnFn(searchType, columnHistory);
        const simple1 = columnHistory.find((item) => item.currentType === searchType)?.columnConfig;
        provideTableConfig(searchType, { hidden: simple }, simple1);
        break;
      case OrderSearchType.PRESS:
        const press = persistedColumnFn(searchType, columnHistory);
        const press1 = columnHistory.find((item) => item.currentType === searchType)?.columnConfig;
        provideTableConfig(searchType, { hidden: press }, press1);
        break;
      case OrderSearchType.ADVANCED:
        const advanced = persistedColumnFn(searchType, columnHistory);
        const advanced1 = columnHistory.find((item) => item.currentType === searchType)?.columnConfig;
        provideTableConfig(searchType, { hidden: advanced }, advanced1);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (columnHistory.length) {
      persistedTableConfig(searchType);
    }
  }, [columnHistory, searchType]);
  return (
    <div className={styles.tableContainer}>
      <Table
        withTableOptions={true}
        columnConfig={columns}
        currentSlice={currentSlice}
        loadingState={loadingState}
        t={t}
        filter={filter}
        sort={sort}
        language={language}
        onResetSortAndFilters={resetSortAndFilters}
        onFilterChange={setFilter}
        onSortChange={setSorting}
        onMoveChange={setColumnMove}
        onSetTableConfiguration={handleTableConfigChange}
        searchType={searchType}
        persistedColumn={columnHistory}
        saveColumnWithMove={saveColumnWithMove}
        setColumnFilter={setColumnFilter}
        columnFilter={columnFilter}
        rows={rows}
        columnSorting={columnSorting}
        setColumnSorting={setColumnSorting}
        triggerLastSearch={triggerLastSearch}
      />
      {/* <AMTable dataStore={ds} /> */}
      {filter.count === 0 ? (
        ''
      ) : (
        <div className="dhlTable-navigationbar">
          <Pagination
            loadingState={loadingState}
            totalItems={filter.count ? filter.count : rows.length}
            defaultPageSize={10}
            onPageChange={setPage}
            onSizeChange={setPageSize}
          />
        </div>
      )}

      <div className="dhlTable-navigationbar">
        <div />
        <div className={styles.searchTableFooter}>
          <ExportCsvButton />
          <ExportPdfButton />
        </div>
      </div>
    </div>
  );
};
