import { DHLButton } from '@gkuis/gkp-base-widgets/dist/lib';
import classNames from 'classnames';
import { AMDHLSelectSingle } from 'common/components/AMDHLSelectSingle';
import { FormRow } from 'common/components/FormRow';
import { TextInput } from 'order/productGroups/common/components/atom/TextInput';
import { PressBaseProduct, PressBaseProductVariant, PressOrderCreate } from 'order/productGroups/press/schema/pressSchema';
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classes from '../PressVariants/PressVariants.module.css';
import { Tooltip } from 'common/components/Tooltip/Tooltip';
import { Assignments } from './SupplementSchema';
import { useOrderTransferForm } from 'order/productGroups/common/utils/OrderTransferForm';

export type AssignmentInputProps = {
  maxAmount?: number;
  totalAssignmentCount: number;
  name: string;
  disabled?: boolean;
  nestIndex?: number;
  assignmentBaseProduct: PressBaseProduct[];
  onChange?: (assignment: Assignments) => void;
};

const varianQtyEqual = (a: PressBaseProductVariant[], b: PressBaseProductVariant[]) => {
  return a.length === b.length && a.every((val, index) => val.quantity === b[index].quantity);
};

export const AssignmentInput = ({
  nestIndex,
  maxAmount = 99,
  name,
  disabled,
  assignmentBaseProduct,
  totalAssignmentCount,
  onChange
}: AssignmentInputProps): ReactElement => {
  const { control, trigger, setValue } = useOrderTransferForm<PressOrderCreate>();
  const { t } = useTranslation('orderCreate');
  const getErrorMessage = useCallback((key?: string) => (!key ? undefined : t(`error.${key}`)), [t]);
  const [loopArray, setLoopArray] = useState(false);
  const {
    fields: assignmentFields,
    remove: assignmentRemove,
    append: assignmentAppend,
    update: assignmentUpdate
  } = useFieldArray<PressOrderCreate, `supplements.${number}.assignments`>({
    control,
    name: `supplements.${nestIndex!}.assignments`
  });
  const assignmentVariants = assignmentBaseProduct.flatMap((a) => a.variants);
  useEffect(() => {
    if (assignmentFields.length < 1) {
      setValue(`supplements.${nestIndex!}.assignments`, [
        { referenceShipmentId: undefined, quantity: undefined, supplementId: totalAssignmentCount + 1 } as Assignments
      ]);
    }
  }, [assignmentFields]);

  const deleteAssignmentActive = useMemo(() => assignmentFields.length > 1, [assignmentFields]);
  const unused = assignmentVariants?.filter((desc) => assignmentFields.every((a) => a?.referenceShipmentId != desc?.referenceShipmentId));
  const unusedField = assignmentFields?.filter(
    (desc, index) => assignmentFields.map((a) => a.referenceShipmentId).indexOf(desc.referenceShipmentId) === index
  );
  const removeAddMoreButton = unusedField.length === assignmentVariants.length;
  const previousVariantsValue = useRef(assignmentVariants);
  const varQtyCheck = varianQtyEqual(previousVariantsValue.current, assignmentVariants);
  useEffect(() => {
    assignmentFields.forEach((assignmentField) => {
      const refAssignment = assignmentVariants.find((a) => a?.referenceShipmentId == assignmentField?.referenceShipmentId);
      if (assignmentVariants.length === 1 && assignmentFields.length === 1) {
        assignmentField.quantity = assignmentBaseProduct[0].variants[0].quantity;
        assignmentField.referenceShipmentId = assignmentBaseProduct[0].variants[0].referenceShipmentId;
        if (assignmentField.referenceShipmentId) trigger(`supplements.0.assignments.0.referenceShipmentId`);
      }
      if (refAssignment) {
        return (assignmentField.quantity = refAssignment?.quantity), (assignmentField.referenceShipmentId = refAssignment?.referenceShipmentId);
      }
      const deletedBaseProduct = assignmentFields?.filter(
        (desc) => desc?.referenceShipmentId != undefined && !assignmentVariants.map((a) => a?.referenceShipmentId).includes(desc?.referenceShipmentId)
      );
      if (deletedBaseProduct.length) {
        deletedBaseProduct.forEach((value) => assignmentRemove(unusedField.findIndex((e) => e?.referenceShipmentId === value.referenceShipmentId)));
      }
    });
    previousVariantsValue.current = assignmentVariants;
    setLoopArray(true);
  }, [!varQtyCheck, unusedField.length]);

  useEffect(() => {
    assignmentFields.map((assignmentField, index) => {
      const a = assignmentVariants.find((a) => a?.referenceShipmentId == assignmentField?.referenceShipmentId);
      if (loopArray && a) {
        setValue(`supplements.${nestIndex!}.assignments.${index}.quantity`, assignmentField.quantity as number);
        setValue(`supplements.${nestIndex!}.assignments.${index}.referenceShipmentId`, assignmentField.referenceShipmentId);
      }
    });
    setLoopArray(false);
  }, [loopArray]);

  return (
    <>
      <div className={classes.parentDiv}>
        {unusedField.map((assignmentField, index) => (
          <div key={`${name}.${index}`} data-testid={`${name}.${index}`} className={classes.pl30}>
            <div className={classes.heading}>
              <div className="d-flex">
                <p className={classes.productName}>{`Zuordnung${deleteAssignmentActive ? ` ${index + 1}` : ''}`} </p>
                <Tooltip placement="right" content={t('step2Form.supplementAssignmentInfo')} className="pl-1">
                  <i className={classNames(classes.infoForTooltip, 'icon-alert-info')}></i>
                </Tooltip>
              </div>

              {deleteAssignmentActive && (
                <DHLButton
                  icon="delete"
                  iconPosition={'icon'}
                  size="xs"
                  onClick={() => deleteAssignmentActive && assignmentRemove(index)}
                  name={`products-${index}-delete`}
                />
              )}
            </div>
            <FormRow mode="two-col">
              <Controller
                name={`${name}.${index}.quantity`}
                defaultValue={assignmentField.quantity}
                rules={{ min: { value: 1, message: 'min' } }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextInput
                    {...field}
                    name={`${name}.${index}.quantity`}
                    type="number"
                    label="Sendungsmenge"
                    // value={assignmentField.quantity ?? undefined}
                    value={(field.value = assignmentField.quantity)}
                    error={({ fieldState }) => getErrorMessage(fieldState.error?.message)}
                    disabled={true}
                  />
                )}
              />
              <Controller
                name={`${name}.${index}.referenceShipmentId`}
                rules={{
                  required: {
                    value: true,
                    message: `zuordnung.zuordnungBaseProduct.empty`
                  },
                  minLength: {
                    value: 1,
                    message: `zuordnung.zuordnungBaseProduct.empty`
                  }
                }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <div>
                    <AMDHLSelectSingle
                      name={`${name}.${index}.referenceShipmentId`}
                      data-testid={`${name}.${index}.referenceShipmentId`}
                      placeholder="Zuordnung zu Bezeichnung Basisprodukt*"
                      items={unused}
                      value={
                        assignmentVariants.length === 1
                          ? assignmentVariants?.find((p) => p?.referenceShipmentId)
                          : assignmentVariants?.find((p) => p?.referenceShipmentId == field.value)
                      }
                      onChange={(item) => {
                        field.onChange(item?.referenceShipmentId);
                        const assignment = {
                          ...assignmentField,
                          referenceShipmentId: item?.referenceShipmentId,
                          quantity: item?.quantity
                        };
                        assignmentUpdate(index, assignment);
                        setValue(`supplements.${nestIndex!}.assignments.${index}.quantity`, item?.quantity);
                        onChange?.(assignment);
                        trigger(`supplements.${index}.description`);
                        trigger(`supplements.${nestIndex!}.assignments.${index}.referenceShipmentId`);
                      }}
                      valueToString={({ description }) => description!}
                      error={getErrorMessage(fieldState.error?.message)}
                      disabled={disabled}
                    />
                  </div>
                )}
              />
            </FormRow>
            {assignmentFields.length - 1 === assignmentFields.lastIndexOf(assignmentField) &&
              !removeAddMoreButton &&
              (maxAmount < 1 || assignmentFields.length < maxAmount) && (
                <p className="m-0">
                  <DHLButton
                    name="products-add-assignment"
                    size="xs"
                    type="ghost"
                    icon="plus"
                    iconPosition={'icon-first'}
                    label={t('pressDistribution.addMoreAssignmentButton')}
                    onClick={() =>
                      assignmentAppend({
                        referenceShipmentId: undefined,
                        quantity: undefined,
                        supplementId: totalAssignmentCount + 1
                      } as Assignments)
                    }
                    className={classNames('text-left', 'mt-0', classes.addButton)}
                    disabled={disabled}
                  />
                </p>
              )}
          </div>
        ))}
      </div>
    </>
  );
};
