import { getUser } from 'common/context/auth/AuthProvider';
import { CSVMatrix, matrixToCsv } from 'common/csv';
import { concatNonUndefined } from 'common/utils/helpers';
import { format as formatDate } from 'date-fns';
import { GetPartialOrderRep, OrderCustomerRoleEnum } from 'generated';
import i18n from 'i18n';
import { AllocatedOrder } from 'order/common/context/order/dtos/AllocatedOrder';
import {
  allocatedOrderToCsvRows,
  orderCsvRowsToMatrix,
  orderHeadings,
  orderHeadingsUpdated
} from 'order/orderView/components/ExportPartialOrderToCsv/partialOrdersToMatrix';
import {
  partialOrderSubmitterHeadings,
  partialOrderSubmitterHeadingsUpdated,
  partialOrderSubmitterToMatrix
} from 'order/orderView/components/ExportPartialOrderToCsv/partialOrderSubmitterToMatrix';
import { extractSettlementNumber } from 'order/orderSearch/services/SettlementNumber';
import { ProductionFacility } from 'order/productGroups/common/services/ProductionFacilitiesService';
import { isAvailableFromJan25 } from '../../../common/utils/availabilityByDate';

export const findCustomer = <TCustomer extends { role?: string }>(parties: TCustomer[] = [], role: TCustomer['role']): TCustomer | undefined =>
  parties.find((p) => p.role === role);

type PartialOrderToCsvParams = {
  order: GetPartialOrderRep;
  allocatedOrders: AllocatedOrder[];
  getProductionFacility: (id: string) => ProductionFacility | undefined;
};

export const baseInformation = (order: GetPartialOrderRep, getProductionFacility: (id: string) => ProductionFacility | undefined): CSVMatrix => {
  const submitter = findCustomer(order.parties, OrderCustomerRoleEnum.Submitter);
  const payer = findCustomer(order.parties, OrderCustomerRoleEnum.Payer);
  const user = getUser();
  const productionFacility = order.orderDetail?.productionPlantId ? getProductionFacility(order.orderDetail.productionPlantId) : undefined;
  const { process, participation } = extractSettlementNumber(payer?.customerId ?? '');

  return [
    ['AM.GK-P - Auftragsliste Zusatzauftrag Teilleistungen - vertraulich'],
    [],
    ['Erzeugt von', user.userName ?? ''],
    ['Erzeugt am', formatDate(new Date(), 'dd.MM.yyyy HH:mm')],
    [],
    ['Auftragsnummer', order.orderId],
    ['Kundenauftragsnummer', order.customerOrderId],
    ['Teilleistungsprodukt', order.partialServiceType],
    ['Teilleistungsnummer', order.submissionId],
    ['Einlieferer Kundennummer', submitter?.customerId],
    ['Einlieferer Name', submitter?.name],
    ['Zahlungspflichtiger Kundennummer', payer?.customerId?.slice(0, 10)],
    ['Zahlungspflichtiger Name', payer?.name],
    ['Verfahren', process],
    ['Teilnahme', participation],
    ['Einlieferungsdatum Zusatzauftrag', order.orderDetail?.date && formatDate(new Date(order.orderDetail.date), 'dd.MM.yyyy')],
    ['Einlieferungsstelle Zusatzauftrag', concatNonUndefined(productionFacility?.address?.postCode, ' ', productionFacility?.comment)],
    ['Auftragsstatus', order.productionState && i18n.t('orderStatus.status.' + order.productionState)?.toString()],
    ['Datum letzte Änderung', order?.lastChangedOn && formatDate(new Date(order.lastChangedOn), 'dd.MM.yyyy HH:mm')],
    [],
    []
  ];
};

export const partialOrderToCsv = (params: PartialOrderToCsvParams): string => {
  const { order, allocatedOrders, getProductionFacility } = params;
  const allocatedOrdersWithParentOrderDate: AllocatedOrder[] = allocatedOrders?.map((ao) => {
    return {
      ...ao,
      parentOrderDate: order?.orderDetail?.date
    };
  });
  const orderCsvRows = allocatedOrderToCsvRows(allocatedOrdersWithParentOrderDate);
  const content: CSVMatrix = [
    ...baseInformation(order, getProductionFacility),
    ['Liste der Absender (Konsolidierungsliste)'],
    isAvailableFromJan25(order.orderDetail?.date) ? partialOrderSubmitterHeadingsUpdated : partialOrderSubmitterHeadings,
    ...partialOrderSubmitterToMatrix(order, orderCsvRows),
    [],
    ['Liste zugeordnete Aufträge'],
    isAvailableFromJan25(order.orderDetail?.date) ? orderHeadingsUpdated : orderHeadings,
    ...orderCsvRowsToMatrix(orderCsvRows)
  ];

  return matrixToCsv(content);
};
