import { TooltipProps } from 'common/components/Tooltip/Tooltip';
import { UserRights } from 'common/dtos/userRights';
import { OrderCategory } from 'order/common/context/order/dtos/OrderCategory';
import { OrderCategoryProductKey } from 'order/common/context/order/dtos/OrderCategoryProductKey';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { useTranslation } from 'react-i18next';

type ProductGroupTile = {
  title: string;
  permission: UserRights | undefined;
  children: {
    productGroup: ProductGroup;
    orderCategory: OrderCategory;
    keys: OrderCategoryProductKey[];
    title: string;
    text: string;
    link: string;
    tooltip: TooltipProps | undefined;
  }[];
};

export const useProductGroupTiles = (): { productGroupTiles: ProductGroupTile[] } => {
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'productGroupTileTooltipText' });
  const { t } = useTranslation('translation', { keyPrefix: 'step1Tiles' });

  return {
    productGroupTiles: [
      {
        title: t('titles.dialogmarketing'),
        permission: UserRights.ProductDiPPWSp,
        children: [
          {
            productGroup: ProductGroup.DIALOGPOST,
            orderCategory: OrderCategory.DIP_EINZEL,
            keys: [OrderCategoryProductKey.DiP_EINZEL__DIALOGPOST, OrderCategoryProductKey.DiP_SAMMEL, OrderCategoryProductKey.DiP_VARIO],
            title: t('dialogpost.title'),
            text: t('dialogpost.text'),
            link: t('dialogpost.link'),
            tooltip: {
              content: oc('dialogPostTileTooltipText'),
              placement: 'right',
              disabled: false
            }
          },
          {
            productGroup: ProductGroup.DIALOGPOSTWURFSPEZIAL,
            orderCategory: OrderCategory.DIP_EINZEL,
            keys: [OrderCategoryProductKey.DiP_EINZEL__DIALOGPOSTWURFSPEZIAL],
            title: t('dialogpostwurfspezial.title'),
            text: t('dialogpostwurfspezial.text'),
            link: t('dialogpostwurfspezial.link'),
            tooltip: undefined
          },
          {
            productGroup: ProductGroup.POSTAKTUELL,
            orderCategory: OrderCategory.POSTAKTUELL,
            keys: [OrderCategoryProductKey.POSTAKTUELL],
            title: t('postaktuell.title'),
            text: t('postaktuell.text'),
            link: t('postaktuell.link'),
            tooltip: undefined
          }
        ]
      },
      {
        title: t('titles.letter'),
        permission: UserRights.OrderAccessLetter,
        children: [
          {
            productGroup: ProductGroup.BRIEF,
            orderCategory: OrderCategory.AFM_BRIEF,
            keys: [OrderCategoryProductKey.AFM_BRIEF],
            title: t('frankit.title'),
            text: t('frankit.text'),
            link: t('frankit.link'),
            tooltip: undefined
          },
          {
            productGroup: ProductGroup.BRIEF,
            orderCategory: OrderCategory.DV_BRIEF,
            keys: [OrderCategoryProductKey.DV_BRIEF],
            title: t('dvLetter.title'),
            text: t('dvLetter.text'),
            link: t('dvLetter.link'),
            tooltip: undefined
          }
        ]
      },
      {
        title: t('titles.press'),
        permission: UserRights.ProductPrD,
        children: [
          {
            productGroup: ProductGroup.PRESS_DISTRIBUTION,
            orderCategory: OrderCategory.PRESS_DISTRIBUTION,
            keys: [OrderCategoryProductKey.PRESSDISTRIBUTION],
            title: t('pressDistribution.title'),
            text: t('pressDistribution.text'),
            link: t('pressDistribution.link'),
            tooltip: undefined
          }
        ]
      },
      {
        title: t('titles.international'),
        permission: UserRights.OrderEdit,
        children: [
          {
            productGroup: ProductGroup.BRIEF_INTERNATIONAL,
            orderCategory: OrderCategory.BRIEF_INTERNATIONAL,
            keys: [OrderCategoryProductKey.BRIEF_INTERNATIONAL],
            title: t('briefInternational.title'),
            text: t('briefInternational.text'),
            link: t('briefInternational.link'),
            tooltip: undefined
          },
          {
            productGroup: ProductGroup.DIALOGPOST_INTERNATIONAL,
            orderCategory: OrderCategory.IP_INTERNATIONAL,
            keys: [OrderCategoryProductKey.DIALOGPOST_INTERNATIONAL],
            title: t('diagpostInternational.title'),
            text: t('diagpostInternational.text'),
            link: t('diagpostInternational.link'),
            tooltip: undefined
          },
          {
            productGroup: ProductGroup.PRESS_INTERNATIONAL,
            orderCategory: OrderCategory.PUB_INTERNATIONAL,
            keys: [OrderCategoryProductKey.PRESS_INTERNATIONAL],
            title: t('pressInternational.title'),
            text: t('pressInternational.text'),
            link: t('pressInternational.link'),
            tooltip: undefined
          },
          {
            productGroup: ProductGroup.BRIEF_DMC_INTERNATIONAL,
            orderCategory: OrderCategory.BRIEF_DMC_INTERNATIONAL,
            keys: [OrderCategoryProductKey.BRIEF50_INTERNATIONAL],
            title: t('briefe50International.title'),
            text: t('briefe50International.text'),
            link: t('briefe50International.link'),
            tooltip: undefined
          }
        ]
      },
      {
        title: t('titles.partial'),
        permission: UserRights.ProductTL,
        children: [
          {
            productGroup: ProductGroup.PARTIAL_SERVICE_LETTER,
            orderCategory: OrderCategory.TL_BRIEF,
            keys: [OrderCategoryProductKey.TL_BRIEF],
            title: t('partialServiceLetter.title'),
            text: t('partialServiceLetter.text'),
            link: t('partialServiceLetter.link'),
            tooltip: undefined
          },
          {
            productGroup: ProductGroup.COLLECTION,
            orderCategory: OrderCategory.ABHOLAUFTRAG,
            keys: [OrderCategoryProductKey.ABHOLAUFTRAG],
            title: t('collection.title'),
            text: t('collection.text'),
            link: t('collection.link'),
            tooltip: undefined
          }
        ]
      }
    ]
  };
};
