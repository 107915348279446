import React from 'react';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { useAdditionalServices } from 'order/common/hooks/useAdditionalServices';
import { OrderOverviewRow, OrderOverviewWidget } from 'order/productGroups/common/components/molecule/OrderOverview';
import { InternationalDestinationZipCodes } from 'order/productGroups/international/schema/internationalClusterSchema';
import { useTranslation } from 'react-i18next';
import { ALSBaseProductsIntPress } from 'order/productGroups/international/components/AdditionalLetterService';

export const PressIPContainerSection = () => {
  const { t } = useTranslation();
  const { t: oc } = useTranslation('orderCreate');
  const { t: tCatalogValues } = useTranslation('catalogValues');
  const { t: ocs4 } = useTranslation('orderCreate', { keyPrefix: 'step4Overview' });

  const language = useAuthContext().user.language;

  const { order } = useOrderContext();

  const date = order?.orderDetail?.date ? new Date(order?.orderDetail?.date) : new Date();

  const additionalLetterServicesNumbers = useAdditionalServices(ProductGroup.PRESS_DISTRIBUTION, date, 'SPECIAL_LETTER', false);
  const renderAdditionalLetterServices = (id?: string) => {
    const filteredProducts = order?.dependentProducts
      ?.filter((dP) => {
        if (id) return dP.referenceShipmentId === id;
        return !dP.referenceShipmentId;
      })
      .filter((dP) => additionalLetterServicesNumbers?.map((service) => service.productNumber).includes(dP.productNumber || ''));

    return (
      <table>
        <tbody>
          {filteredProducts?.map((fP, i) => (
            <tr key={i}>
              <td>
                <p>
                  {t(
                    ALSBaseProductsIntPress[
                      additionalLetterServicesNumbers?.find((service) => service.productNumber === fP.productNumber)
                        ?.descriptionShort as keyof typeof ALSBaseProductsIntPress
                    ] ?? ''
                  )}
                  :
                </p>
              </td>
              <td>
                <p>{fP.shipmentQuantity}</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const priority = order?.internationalDestinations?.find((d) => d.zipCode === InternationalDestinationZipCodes.PRIORTY)?.quantity;
  const economy = order?.internationalDestinations?.find((d) => d.zipCode === InternationalDestinationZipCodes.ECONOMY)?.quantity;
  const mixed = order?.internationalDestinations?.find((d) => d.zipCode === InternationalDestinationZipCodes.MIXED)?.quantity;

  return (
    <OrderOverviewRow>
      <OrderOverviewWidget title={ocs4('additionalLetterServices')}>{renderAdditionalLetterServices()}</OrderOverviewWidget>
      {order?.internationalPackaging?.type === 'PAL' && (
        <OrderOverviewWidget title={oc('palletsPerIPZ', { keyPrefix: 'palletsInternational' })}>
          <table style={{ tableLayout: 'auto', width: 'auto' }}>
            <tbody>
              {priority && !Number.isNaN(priority) && (
                <tr>
                  <td>
                    <p>{oc('priority', { keyPrefix: 'palletsInternational' })}:</p>
                  </td>
                  <td style={{ paddingLeft: '30px' }}>
                    <p>{priority}</p>
                  </td>
                </tr>
              )}
              {economy && !Number.isNaN(economy) && (
                <tr>
                  <td>
                    <p>{oc('economy', { keyPrefix: 'palletsInternational' })}:</p>
                  </td>
                  <td style={{ paddingLeft: '30px' }}>
                    <p>{economy}</p>
                  </td>
                </tr>
              )}
              {mixed && !Number.isNaN(mixed) && (
                <tr>
                  <td>
                    <p>{oc('mixed', { keyPrefix: 'palletsInternational' })}:</p>
                  </td>
                  <td style={{ paddingLeft: '30px' }}>
                    <p>{mixed}</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </OrderOverviewWidget>
      )}

      <OrderOverviewWidget title="Gebinde">
        <table>
          <tbody>
            <tr>
              <td>
                <p>{ocs4('typeOfBundle')}</p>
              </td>
              <td>
                <p>{order?.internationalPackaging?.type && tCatalogValues('mailEntityType.display.' + order?.internationalPackaging?.type)}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>{ocs4('numberOfBundles')}</p>
              </td>
              <td>
                <p>{order?.internationalPackaging?.quantity?.toLocaleString(language)}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>{ocs4('totalGrossWeightInKg')}</p>
              </td>
              <td>
                <p>{order?.internationalPackaging?.totalWeight?.toLocaleString(language)} kg</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>{ocs4('stackable')}</p>
              </td>
              <td>
                <p>{order?.internationalPackaging?.stackable ? t('yes') : t('no')}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </OrderOverviewWidget>
    </OrderOverviewRow>
  );
};
