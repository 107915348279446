// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pslbilling-table_parentRow__Ss25H {
    background-color: #ffffff !important;
}

.pslbilling-table_removeBorder__QLxGN {
    border-top-width: 0 !important;
    border-right-width: 0 !important;
    border-bottom-width: 0 !important;
    border-left-width: 0 !important;
}

.pslbilling-table_expandBtn__6sUfL svg * {
    fill: #d40511;
}

.pslbilling-table_internalRowHeader__Yp9G- {
    padding-left: 27px !important;
}

.pslbilling-table_addBorderBottom__pX3a0 {
    border-bottom: 1px solid #dee2e6 !important;
}

.pslbilling-table_parentRow__Ss25H td:first-child {
    vertical-align: middle;
}

.pslbilling-table_rightAlign__rBSFT {
    text-align: right !important;
}

.pslbilling-table_billingTableP__OKc70 {
    margin-top: 15px;
    margin-bottom: 15px !important;
}

.pslbilling-table_removeWhiteSpace__vZOVq{
    white-space: pre-wrap !important;
}

.pslbilling-table_summaryRowHeight__rRfe2{
    height:30px !important;
}

.pslbilling-table_tableMargin__3GzI5{
    margin:20px 0;
}

.pslbilling-table_correctionTable__7vwuE{
    margin:20px auto;
    width:75%;

}
`, "",{"version":3,"sources":["webpack://./src/order/orderView/components/PSLBillingTable/pslbilling-table.module.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;;AAEA;IACI,8BAA8B;IAC9B,gCAAgC;IAChC,iCAAiC;IACjC,+BAA+B;AACnC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,2CAA2C;AAC/C;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,gBAAgB;IAChB,8BAA8B;AAClC;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,SAAS;;AAEb","sourcesContent":[".parentRow {\n    background-color: #ffffff !important;\n}\n\n.removeBorder {\n    border-top-width: 0 !important;\n    border-right-width: 0 !important;\n    border-bottom-width: 0 !important;\n    border-left-width: 0 !important;\n}\n\n.expandBtn svg * {\n    fill: #d40511;\n}\n\n.internalRowHeader {\n    padding-left: 27px !important;\n}\n\n.addBorderBottom {\n    border-bottom: 1px solid #dee2e6 !important;\n}\n\n.parentRow td:first-child {\n    vertical-align: middle;\n}\n\n.rightAlign {\n    text-align: right !important;\n}\n\n.billingTableP {\n    margin-top: 15px;\n    margin-bottom: 15px !important;\n}\n\n.removeWhiteSpace{\n    white-space: pre-wrap !important;\n}\n\n.summaryRowHeight{\n    height:30px !important;\n}\n\n.tableMargin{\n    margin:20px 0;\n}\n\n.correctionTable{\n    margin:20px auto;\n    width:75%;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parentRow": `pslbilling-table_parentRow__Ss25H`,
	"removeBorder": `pslbilling-table_removeBorder__QLxGN`,
	"expandBtn": `pslbilling-table_expandBtn__6sUfL`,
	"internalRowHeader": `pslbilling-table_internalRowHeader__Yp9G-`,
	"addBorderBottom": `pslbilling-table_addBorderBottom__pX3a0`,
	"rightAlign": `pslbilling-table_rightAlign__rBSFT`,
	"billingTableP": `pslbilling-table_billingTableP__OKc70`,
	"removeWhiteSpace": `pslbilling-table_removeWhiteSpace__vZOVq`,
	"summaryRowHeight": `pslbilling-table_summaryRowHeight__rRfe2`,
	"tableMargin": `pslbilling-table_tableMargin__3GzI5`,
	"correctionTable": `pslbilling-table_correctionTable__7vwuE`
};
export default ___CSS_LOADER_EXPORT___;
