import { DHLButton, DHLHeadline } from '@gkuis/gkp-base-widgets/dist/lib';
import { FormRow } from 'common/components/FormRow';
import { PressOrderCreate, PressBaseProduct } from 'order/productGroups/press/schema/pressSchema';
import React, { ReactElement, useEffect, useCallback, useRef, useState } from 'react';
import { Controller, useFieldArray, useWatch, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classes from '../PressVariants/PressVariants.module.css';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { Product } from 'generated';
import { ControllerFieldState } from 'react-hook-form/dist/types/controller';
import { ActiveMultiList } from '../../../../../common/components/ActiveMultiList';
import { PressVariants } from '../PressVariants';
import { AdditionalLetterService } from 'order/productGroups/international/components/AdditionalLetterService';
import ErrorBoundary from '../../../../../common/components/ErrorBoundary/ErrorBoundary';
import { PackagingSection } from '../../../common/components/molecule/PackagingSection';
import { Packaging } from '../../../common/dtos/Packaging';
import { DestinationSection } from '../../../common/components/molecule/DestinationSection';
import { ProductGroup } from '../../../../common/dtos/ProductGroup';

type Item = {
  productNumber: string;
  descriptionShort: string;
  duration?: string;
};

export type PressBaseProductsProps = {
  items?: Item[];
  title?: string;
  maxAmount?: number;
  name: 'nationalProducts' | 'internationalProducts';
  disabled?: boolean;
  onDelete?: (index: number, baseProduct: Product, isLast: boolean) => void;
  showErrorForNationalAndInternational?: boolean;
  setSetErrorMsg?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDate?: Date;
  isSmart?: boolean;
};

let shipmentId: number = 0;

export enum PRODUCT_TYPES {
  SAME_DAY = 'E+0',
  NEXT_DAY = 'E+1',
  SECOND_DAY = 'E+2',
  BASIC = 'E+4'
}

export enum PRESS_VARIANTS {
  CLASSIC = 'CLASSIC',
  SMART = 'SMART'
}

export const PressBaseProducts = ({
  items = [],
  title,
  maxAmount = 99,
  name,
  disabled,
  selectedDate,
  isSmart,
  ...props
}: PressBaseProductsProps): ReactElement => {
  const { control, trigger, setValue, resetField, getValues } = useFormContext<PressOrderCreate>();
  const { t } = useTranslation('orderCreate');
  const { t: oc } = useTranslation('orderCreate', { keyPrefix: 'step2Form' });
  const { fields: baseProductFields, remove, append } = useFieldArray({ control, name });
  const { orderMode, meta, order, upsertMetaData } = useOrderContext();

  const values = useWatch({
    control,
    name: name
  });

  const [nationalProducts, internationalProducts] = useWatch({
    control,
    name: ['nationalProducts', 'internationalProducts']
  });

  let watchedFields = useRef<PressBaseProduct[]>([]).current;

  if (values) {
    watchedFields = baseProductFields.map(({ id }, index) => ({
      id,
      ...values[index]
    }));
  }

  useEffect(() => {
    if (watchedFields.length < 1) {
      setValue(name, [{} as PressBaseProduct]);
    } else {
      shipmentId = Math.max(shipmentId, Math.max(...watchedFields.map((prod) => Number(prod.referenceShipmentId ?? 0))));
    }
  }, [watchedFields]);

  const getErrorMessage =
    () =>
    ({ fieldState }: { fieldState: ControllerFieldState }) =>
      fieldState.error ? (props.showErrorForNationalAndInternational ? ' ' : t(`error.${fieldState.error.message}`)) : undefined;

  const unUsedNationalBaseProducts = items.filter((p) => watchedFields.every((np) => np.productNumber != p.productNumber));
  const isNational = name === 'nationalProducts';

  const disableNPPackaging =
    orderMode === OrderMode.CHANGE && !order?.constraints?.packageSectionEditable && values?.some((p) => p?.preExisting === true);

  return (
    <>
      {title && (
        <DHLHeadline type="h6" color={'gray'} textAlignment={'left'}>
          {title}
        </DHLHeadline>
      )}
      {watchedFields.map((baseProductField, index) => {
        return (
          // Important: for correct re-rendering in case of added or removed items it is necessary to use the id as key!
          <div
            key={`${name}.${baseProductField.id}-${index}`}
            data-testid={`${name}.${index}`}
            className={`${classes.parentDiv}  ${classes.borderLine} ${classes.borderLineSpace}`}
          >
            <div className={classes.heading}>
              <p className={classes.productName}>{`Basisprodukt ${index + 1}`}</p>
              <div className={classes.delBtnBPCustomSpacing}>
                <DHLButton
                  icon="delete"
                  iconPosition={'icon'}
                  size="xs"
                  onClick={() => {
                    if (watchedFields.length === 1) {
                      props.onDelete?.(index, baseProductField, true);
                      resetField(name, { defaultValue: [{ productNumber: undefined, variants: [{}] }] });
                      if (orderMode === OrderMode.CHANGE && !order?.constraints?.packageSectionEditable) {
                        upsertMetaData('enablePackaging', true);
                      }
                      props.setSetErrorMsg?.(false);
                    } else {
                      props.onDelete?.(index, baseProductField, false);
                      remove(index);
                      if (orderMode === OrderMode.CHANGE) {
                        if (baseProductField?.productNumber) {
                          const filteredBP = watchedFields?.filter((v) => v?.productNumber != baseProductField?.productNumber);
                          resetField(name, { defaultValue: filteredBP });
                        }
                      }
                    }
                  }}
                  data-TestId={`products-${index}-delete`}
                  name={`products-${index}-delete`}
                  disabled={disabled || isSmart}
                />
              </div>
            </div>
            <FormRow mode="two-col">
              <Controller
                name={`${name}.${index}.productNumber`}
                rules={{
                  required: {
                    value: true,
                    message: 'baseProductInput.productNumber.empty'
                  }
                }}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <div>
                    <ActiveMultiList
                      name={`${name}.${index}.productNumber`}
                      onChange={(item) => {
                        if (orderMode === OrderMode.CHANGE && !order?.constraints?.packageSectionEditable) {
                          if (
                            (nationalProducts?.[0]?.baseProductType != PRODUCT_TYPES.BASIC &&
                              nationalProducts?.[0]?.baseProductType != PRODUCT_TYPES.SECOND_DAY) ||
                            item?.duration == PRODUCT_TYPES.SAME_DAY ||
                            item?.duration == PRODUCT_TYPES.NEXT_DAY
                          ) {
                            setValue(`${name}.${index}.destinations.matrix`, []);
                            setValue(`${name}.${index}.destinations.totalWeight`, 0);
                            setValue(`${name}.${index}.destinations.stackable`, false);
                            resetField(`${name}.${index}.packaging`, { defaultValue: { quantity: null as any, type: null as any } });
                            upsertMetaData('enablePackaging', true);
                          }
                        } else {
                          resetField(`${name}.${index}.packaging`, { defaultValue: { quantity: null as any, type: null as any } });
                          setValue(`${name}.${index}.destinations.matrix`, []);
                          setValue(`${name}.${index}.destinations.totalWeight`, 0);
                          setValue(`${name}.${index}.destinations.stackable`, false);
                        }
                        resetField(`${name}.${index}.discounts` as any, { defaultValue: [] });
                        const prod = {
                          productNumber: item?.productNumber,
                          referenceShipmentId: watchedFields[index].referenceShipmentId
                        };
                        setValue(`${name}.${index}.productNumber`, item?.productNumber);
                        trigger(`${name}.${index}.productNumber`);
                        props.setSetErrorMsg?.(false);
                        baseProductField.referenceShipmentId
                          ? setValue(`${name}.${index}.referenceShipmentId`, baseProductField.referenceShipmentId)
                          : setValue(`${name}.${index}.referenceShipmentId`, String(++shipmentId));
                        setValue(`${name}.${index}.baseProductType`, item?.duration);
                      }}
                      onBlur={field.onBlur}
                      valueToString={(selectedProduct) => selectedProduct?.descriptionShort}
                      value={items.find((product1) => product1.productNumber === field.value)}
                      items={unUsedNationalBaseProducts}
                      placeholder="Basisprodukt*"
                      data-testid={`${name}.${index}.productNumber`}
                      error={getErrorMessage()({ fieldState })}
                      disabled={disabled}
                    />
                  </div>
                )}
              />
            </FormRow>
            <PressVariants
              name={`${name}.${index}.variants`}
              isNational={isNational}
              disabled={disabled || isSmart}
              currentBP={
                {
                  productNumber: values[index]?.productNumber,
                  description: items?.find((product1) => product1.productNumber === baseProductField.productNumber)?.descriptionShort
                } as Product
              }
              {...props}
            />
            {name !== `internationalProducts` && (
              <>
                <ErrorBoundary context={`PressDistribution::${orderMode}::packaging`}>
                  <PackagingSection<PressOrderCreate>
                    name={`${name}.${index}.packaging`}
                    disabled={meta?.enablePackaging ? !meta?.enablePackaging : disabled || disableNPPackaging}
                    destinationAmount={values[index]?.destinations?.matrix?.reduce((acc, v) => acc + v.quantity, 0) || 0}
                    onTypeChange={() => {
                      setValue(`${name}.${index}.destinations.matrix`, []);
                      setValue(`${name}.${index}.destinations.totalWeight`, 0);
                      setValue(`${name}.${index}.destinations.stackable`, false);
                    }}
                    showSLN={values[index]?.baseProductType === PRODUCT_TYPES.NEXT_DAY}
                    showProductionPlantId={values[index]?.baseProductType === PRODUCT_TYPES.SAME_DAY}
                    className={classes.packagingSection}
                  />
                </ErrorBoundary>
                {values[index]?.packaging?.type === Packaging.PAL && values[index]?.baseProductType !== PRODUCT_TYPES.SAME_DAY && (
                  <ErrorBoundary context={`PressDistribution::${orderMode}::destinations`}>
                    <DestinationSection<PressOrderCreate>
                      productGroup={ProductGroup.PRESS_DISTRIBUTION}
                      name={`${name}.${index}.destinations`}
                      showSLN={values[index]?.baseProductType === PRODUCT_TYPES.NEXT_DAY}
                      showOnlyDEZone={values[index]?.baseProductType === PRODUCT_TYPES.NEXT_DAY}
                      disabled={meta?.enablePackaging ? !meta?.enablePackaging : disabled || disableNPPackaging}
                    />
                  </ErrorBoundary>
                )}
              </>
            )}
            {name === `internationalProducts` && (
              <AdditionalLetterService
                name={`internationalProducts.${index}.discounts`}
                title={`${oc('additionalLetterService')}`}
                productNumber={getValues(`${name}.${index}.productNumber`)}
                selectedDate={selectedDate}
                disabled={disabled}
                productGroup={ProductGroup.PRESS_DISTRIBUTION}
              />
            )}
          </div>
        );
      })}

      {items?.length !== watchedFields?.length && (
        <div className={`${classes.addBtnCustomSpacing} ${classes.borderLine} ${classes.borderLineSpaceEven}`}>
          <DHLButton
            name="products-add"
            size="xs"
            type="ghost"
            icon="plus"
            iconPosition={'icon-first'}
            label={name === 'nationalProducts' ? `Weiteres nationales Basisprodukt hinzufügen` : `Weiteres internationales Basisprodukt hinzufügen`}
            onClick={() => {
              // upsertMetaData('designationCount', (meta?.designationCount || 0) + 1);
              if (orderMode === OrderMode.CHANGE) {
                const labelArr = watchedFields
                  ?.flatMap((np) => np?.variants)
                  ?.map((npv) => (npv?.description?.split(' - ')[1] ? +npv.description.split(' - ')[1] : 1));
                const maxLabelCount = Math.max(...labelArr);
                if (isNational) {
                  if (!isNaN(maxLabelCount)) {
                    upsertMetaData('designationCountNP', (maxLabelCount || 1) + 1);
                  } else {
                    upsertMetaData('designationCountNP', (meta?.designationCountNP || 1) + 1);
                  }
                } else {
                  if (!isNaN(maxLabelCount)) {
                    upsertMetaData('designationCountIP', (maxLabelCount || 1) + 1);
                  } else {
                    upsertMetaData('designationCountIP', (meta?.designationCountIP || 1) + 1);
                  }
                }
                if (!order?.constraints?.packageSectionEditable) {
                  upsertMetaData('enablePackaging', true);
                }
              } else {
                if (isNational) {
                  upsertMetaData('designationCountNP', (meta?.designationCountNP || 1) + 1);
                } else {
                  upsertMetaData('designationCountIP', (meta?.designationCountIP || 1) + 1);
                }
              }
              append({} as PressBaseProduct);
            }}
            className={`${classes.addButton}`}
            disabled={disabled || (isNational && order?.includesDestinations)}
          />
        </div>
      )}
    </>
  );
};
