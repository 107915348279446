import { OrderReference } from '../dtos/OrderReference';
import { deepClone } from 'common/utils/deepClone';
import { Occurrence } from 'common/dtos/Occurrence';
import { QtyAllocation } from '../dtos/Order';
import { OrderMode } from '../../../dtos/OrderMode';

export async function preProcessOrderReferencesForPartialService(
  references: OrderReference[],
  orderMode: OrderMode | undefined
): Promise<OrderReference[]> {
  const orderRefs = deepClone(references);
  for (const o of orderRefs) {
    const formattedAlloc = [];
    for (const alloc of o.qtyAllocation) {
      if (alloc.multipleContainsIds) {
        alloc.collectedQuantities?.map((q) => {
          if (!q.containsIds && !q.flexOption) {
            q.includeTotalQty = false;
          }
        });
        formattedAlloc.push(alloc.collectedQuantities?.filter((a) => a.quantity) || []);
      } else {
        const copyOfAllocation = { ...alloc };
        delete copyOfAllocation.combinedCounter;
        delete copyOfAllocation.originalAssignedQty;
        delete copyOfAllocation.multipleContainsIds;
        formattedAlloc.push([copyOfAllocation]);
      }
    }
    const flattenedAlloc = formattedAlloc.flat();
    o.qtyAllocation = flattenedAlloc;

    const newQtyAlloc: QtyAllocation[] = [];
    if (o.allocatedQty !== o.idsQty) {
      const qtyAllocation = deepClone(o.qtyAllocation);
      qtyAllocation.forEach((q) => {
        let duplicatedQtyAlloc = undefined;
        q.collectedQuantities = undefined;
        q.flexOption = q.containsIds ? q.flexOption : false;

        if (q.idsQty && q.quantity !== q.idsQty) {
          const qtyForDuplicate = q.quantity - (q.idsQty ?? 0);
          if (qtyForDuplicate >= 0) {
            q.quantity = q.idsQty ?? 0;
            duplicatedQtyAlloc = {
              ...q,
              quantity: qtyForDuplicate,
              ranges: undefined,
              containsIds: false,
              flexOption: false,
              includeTotalQty: false,
              refShipmentIdZA: orderMode === OrderMode.CHANGE ? null : undefined
            };
          }
        }
        newQtyAlloc.push(q);

        if (duplicatedQtyAlloc) {
          newQtyAlloc.push(duplicatedQtyAlloc);
        }
      });
      o.qtyAllocation = newQtyAlloc;
    }

    const allocsWithContainsIds = o.qtyAllocation.filter((q) => q.containsIds);
    const allocsWithFlexOptions = o.qtyAllocation.filter((q) => q.flexOption);
    const allocsWithIncludeTotalQty = o.qtyAllocation.filter((q) => q.includeTotalQty);

    o.containsIds =
      allocsWithContainsIds.length === o.qtyAllocation.length ? Occurrence.ALL : allocsWithContainsIds.length > 0 ? Occurrence.SOME : Occurrence.NONE;
    o.flexOption =
      allocsWithFlexOptions.length === o.qtyAllocation.length ? Occurrence.ALL : allocsWithFlexOptions.length > 0 ? Occurrence.SOME : Occurrence.NONE;
    o.includeTotalQty =
      allocsWithIncludeTotalQty.length === o.qtyAllocation.length
        ? Occurrence.ALL
        : allocsWithIncludeTotalQty.length > 0
        ? Occurrence.SOME
        : Occurrence.NONE;
  }

  return orderRefs;
}
