import { DHLCheckbox, DHLFormGroup, DHLTooltip, DHLTooltipIcon } from '@gkuis/gkp-base-widgets/dist/lib';
import { FormRow } from 'common/components/FormRow';
import { SFCProps } from 'common/utils/formHelpers';
import i18n, { useTranslation } from 'i18n';
import { AMDHLDestinationInput } from 'order/productGroups/common/components/atom/AMDHLDestinationInput';
import { OrderStepSection } from 'order/productGroups/common/components/atom/OrderStepSection';
import { TextInput } from 'order/productGroups/common/components/atom/TextInput';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import React, { ReactElement, useState } from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import classes from './destination-section.module.css';
import { DestinationSectionSchema } from './destinationSectionSchema';
import { useOrderTransferForm } from 'order/productGroups/common/utils/OrderTransferForm';

export type DestinationSectionProps<T extends FieldValues> = SFCProps<T, DestinationSectionSchema | undefined> & {
  disabled?: boolean;
  showOnlyRequired?: boolean;
  showSLN?: boolean;
  productGroup?: ProductGroup;
  showOnlyDEZone?: boolean;
};

const translationsDE = {
  title: 'Paletten je Leitzone oder Leitregion',
  tooltip: 'Bitte wählen Sie eine der Leitzonen aus, um die Palettenanzahl je Leitregion und -zone anzugeben.',
  chooseZones: 'Leitzonen auswählen',
  totalWeightLabel: 'Gesamtgewicht brutto in kg',
  stackableLable: 'Stapelfähig',
  error: {
    content: {
      destinations: {
        totalWeight: {
          max: 'Bitte prüfen Sie das Gesamtgewicht und die Anzahl der Gebinde. Das Einzelgewicht pro Palette darf nicht größer als 700 kg sein.'
        }
      }
    },
    destinations: {
      totalWeight: {
        max: 'Bitte prüfen Sie das Gesamtgewicht und die Anzahl der Gebinde. Das Einzelgewicht pro Palette darf nicht größer als 700 kg sein.'
      }
    }
  }
};

const translationsEN = {
  title: 'Pallets per Routing Zone or Routing Region',
  tooltip: 'Please select one of the routing zones in order to enter the amount of pallets for each routing zone and region',
  chooseZones: 'Select Routing Zones',
  totalWeightLabel: 'Total Gross Weight in kg',
  stackableLable: 'Stackable',
  error: {
    content: {
      destinations: {
        totalWeight: {
          max: 'Please check the total weight and number of containers. The individual weight per pallet must not be greater than 700 kg.'
        }
      }
    },
    destinations: {
      totalWeight: {
        max: 'Please check the total weight and number of containers. The individual weight per pallet must not be greater than 700 kg.'
      }
    }
  }
};

i18n.addResourceBundle('de', 'destinationSection', translationsDE);
i18n.addResourceBundle('en', 'destinationSection', translationsEN);

export const DestinationSection = <T extends FieldValues>(props: DestinationSectionProps<T>): ReactElement => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const { t } = useTranslation('destinationSection');
  const { trigger } = useOrderTransferForm();

  return (
    <OrderStepSection headline={t`title`}>
      <DHLFormGroup>
        {t`chooseZones`}
        <span style={{ display: 'inline-block', marginLeft: '4px' }}>
          <DHLTooltipIcon tooltip="Tooltip" placement="right" name="destinationsTooltipIcon" render />
          <DHLTooltip
            testid="destinationTooltip"
            target="destinationsTooltipIcon"
            tooltipOpen={isTooltipOpen}
            toggle={() => setTooltipOpen((o) => !o)}
            placement="right"
          >
            {t`tooltip`}
          </DHLTooltip>
        </span>
      </DHLFormGroup>
      <DHLFormGroup>
        <AMDHLDestinationInput<DestinationSectionSchema>
          name={`${props.name}.matrix`}
          rules={{
            deps: ['packaging.quantity']
          }}
          disabled={props.disabled}
          showSLN={props.showSLN}
          productGroup={props.productGroup}
          showOnlyDEZone={props.showOnlyDEZone}
        />
      </DHLFormGroup>
      {!props.showOnlyRequired && (
        <FormRow mode="two-col" className={classes.centerRow}>
          <TextInput
            name={`${props.name}.totalWeight`}
            label={t('totalWeightLabel')}
            type="number"
            disabled={props.disabled}
            error={({ field, fieldState }) => {
              const errorMessage = fieldState.error?.message;
              return errorMessage && t(`error.${field.name}.${errorMessage}`);
            }}
            onChange={(e) => {
              trigger(`${props.name}.totalWeight` as any);
            }}
          />
          <Controller
            name={`${props.name}.stackable`}
            render={({ field: { ref, ...field } }) => (
              <DHLCheckbox label={t`stackableLable`} {...field} onChange={() => field.onChange(!field.value)} disabled={props.disabled} />
            )}
          />
        </FormRow>
      )}
    </OrderStepSection>
  );
};
