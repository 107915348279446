// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FooterSection_footerList__Odhib {
  list-style-type: none;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
}
.FooterSection_grid__jz7ej {
  display: flex;
  flex-wrap: wrap;
}
.FooterSection_col_6__KP8jv {
  width: 50%;
  flex-grow: 1;
}
.FooterSection_text_left__1RrDF {
  text-align: left;
}
.FooterSection_text_right__x4KER {
  text-align: right;
}
.FooterSection_footerList__Odhib li button {
  font-size: 14px;
  min-height: 36px;
}
.FooterSection_footerList__Odhib li button span::before {
  color: #d40511;
  font-size: 16px;
}
.FooterSection_footerList__Odhib li button span {
  padding-right: 8px;
}
.FooterSection_footerList__Odhib li {
  padding-left: 10px;
  padding-bottom: 10px;
}
.FooterSection_flexButton__imd1P button {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}
`, "",{"version":3,"sources":["webpack://./src/order/orderView/components/FooterSection/FooterSection.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,oBAAoB;EACpB,eAAe;EACf,yBAAyB;EACzB,SAAS;EACT,UAAU;AACZ;AACA;EACE,aAAa;EACb,eAAe;AACjB;AACA;EACE,UAAU;EACV,YAAY;AACd;AACA;EACE,gBAAgB;AAClB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,oBAAoB;AACtB;AACA;EACE,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,2BAA2B;AAC7B","sourcesContent":[".footerList {\n  list-style-type: none;\n  display: inline-flex;\n  flex-wrap: wrap;\n  justify-content: flex-end;\n  margin: 0;\n  padding: 0;\n}\n.grid {\n  display: flex;\n  flex-wrap: wrap;\n}\n.col_6 {\n  width: 50%;\n  flex-grow: 1;\n}\n.text_left {\n  text-align: left;\n}\n.text_right {\n  text-align: right;\n}\n.footerList li button {\n  font-size: 14px;\n  min-height: 36px;\n}\n.footerList li button span::before {\n  color: #d40511;\n  font-size: 16px;\n}\n.footerList li button span {\n  padding-right: 8px;\n}\n.footerList li {\n  padding-left: 10px;\n  padding-bottom: 10px;\n}\n.flexButton button {\n  display: flex;\n  flex-wrap: nowrap;\n  align-items: center;\n  justify-content: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerList": `FooterSection_footerList__Odhib`,
	"grid": `FooterSection_grid__jz7ej`,
	"col_6": `FooterSection_col_6__KP8jv`,
	"text_left": `FooterSection_text_left__1RrDF`,
	"text_right": `FooterSection_text_right__x4KER`,
	"flexButton": `FooterSection_flexButton__imd1P`
};
export default ___CSS_LOADER_EXPORT___;
