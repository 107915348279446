import { OrderReplyTDO, OrderTreeItem } from '../../../services/OrderService';
import { Paginator } from 'common/components/Table/dtos/Paginator';
import { ColumnConfig } from 'common/components/Table/dtos/ColumnConfig';
import { TableLoadingState } from 'common/components/Table/dtos/LoadingState';
import { getCurrentSlice, moveColumnLeft, moveColumnRight, rowContainsFilter, sortRows } from '../utils/table';
import { Filter } from 'common/components/Table/dtos/Filter';
import { Sort } from 'common/components/Table/dtos/Sort';
import { OrderSearchType } from '../../search/dtos/OrderSearchType';

export interface TableReducerState {
  columns: ColumnConfig[];
  currentSlice: OrderTreeItem<OrderReplyTDO>[];
  filter: Filter;
  filteredRows: OrderTreeItem<OrderReplyTDO>[];
  loadingState: TableLoadingState;
  paginator: Paginator;
  rows: OrderTreeItem<OrderReplyTDO>[];
  sort: Sort;
}

export enum TableReducerActions {
  SET_FILTER = 'setFilter',
  RESET_FILTER_AND_SORTING = 'resetFilterAndSorting',
  MOVE_COLUMN = 'moveColumn',
  SET_COLUMNS = 'setColumns',
  SET_LOADING_STATE = 'setLoadingState',
  SET_ROWS = 'setRows',
  SET_PAGINATOR_PAGE = 'setPaginatorPage',
  SET_PAGINATOR_PAGE_SIZE = 'setPaginatorPageSize',
  SORT_ROWS = 'sortRows'
}

export type Action =
  | { type: TableReducerActions.SET_FILTER; data: { name: string | null; value: string | null; type?: OrderSearchType } }
  | { type: TableReducerActions.RESET_FILTER_AND_SORTING }
  | { type: TableReducerActions.MOVE_COLUMN; data: { name: string; direction: 'left' | 'right' } }
  | { type: TableReducerActions.SET_COLUMNS; data: ColumnConfig[] }
  | { type: TableReducerActions.SET_LOADING_STATE; data: TableLoadingState }
  | { type: TableReducerActions.SET_PAGINATOR_PAGE; data: number }
  | { type: TableReducerActions.SET_PAGINATOR_PAGE_SIZE; data: number }
  | { type: TableReducerActions.SET_ROWS; data: OrderTreeItem<OrderReplyTDO>[] }
  | { type: TableReducerActions.SORT_ROWS; data: Sort };

export const defaultReducerState: TableReducerState = {
  columns: [],
  currentSlice: [],
  filter: {
    name: null,
    value: null,
    count: undefined,
    type: undefined
  },
  filteredRows: [],
  loadingState: TableLoadingState.NOT_LOADED,
  paginator: {
    active: false,
    defaultValue: 0,
    pageIndex: 1,
    pageSize: 10,
    pageSizeOptions: [
      {
        key: 0,
        label: '10',
        selected: true
      }
    ],
    totalItems: 0
  },
  rows: [],
  sort: {
    name: null,
    order: null,
    type: null,
    searchType: undefined
  }
};

export function tableReducer(state: TableReducerState, action: Action): TableReducerState {
  switch (action.type) {
    case TableReducerActions.SET_FILTER: {
      const property = action.data.name;
      const value = action.data.value;
      let filteredRows = [...state.rows];
      if (property && value) {
        filteredRows = filteredRows.filter((r) => rowContainsFilter(property, value, r));
      }
      const currentSlice = getCurrentSlice(filteredRows, 1, state.paginator.pageSize);

      return {
        ...state,
        currentSlice,
        filter: {
          ...action.data,
          count: filteredRows.length
        },
        filteredRows,
        paginator: {
          ...state.paginator,
          pageIndex: 1
        }
      };
    }
    case TableReducerActions.RESET_FILTER_AND_SORTING: {
      const currentSlice = getCurrentSlice(state.rows, 1, state.paginator.pageSize);

      return {
        ...state,
        filter: {
          ...defaultReducerState.filter,
          count: undefined
        },
        filteredRows: [...state.rows],
        sort: {
          ...defaultReducerState.sort
        },
        paginator: {
          ...state.paginator,
          pageIndex: 1
        },
        currentSlice
      };
    }
    case TableReducerActions.MOVE_COLUMN: {
      let columns = [...state.columns];
      if (action.data.direction === 'left') {
        columns = moveColumnLeft(columns, action.data.name);
      } else if (action.data.direction === 'right') {
        columns = moveColumnRight(columns, action.data.name);
      }

      return {
        ...state,
        columns
      };
    }
    case TableReducerActions.SET_COLUMNS: {
      return {
        ...state,
        columns: action.data
      };
    }
    case TableReducerActions.SET_LOADING_STATE: {
      return {
        ...state,
        loadingState: action.data
      };
    }
    case TableReducerActions.SORT_ROWS: {
      const rows = [...state.filteredRows];
      const sortedRows = sortRows(action.data.name, rows, action.data.order);
      const currentSlice = getCurrentSlice(sortedRows, state.paginator.pageIndex, state.paginator.pageSize);
      return {
        ...state,
        sort: {
          ...action.data
        },
        filteredRows: sortedRows,
        currentSlice
      };
    }
    case TableReducerActions.SET_PAGINATOR_PAGE: {
      const currentSlice = getCurrentSlice(state.filteredRows, action.data, state.paginator.pageSize);
      return {
        ...state,
        currentSlice,
        paginator: {
          ...state.paginator,
          pageIndex: action.data
        }
      };
    }
    case TableReducerActions.SET_PAGINATOR_PAGE_SIZE: {
      let currentSlice = state.filteredRows;

      if (action.data > 0) {
        currentSlice = getCurrentSlice(state.filteredRows, 1, action.data);
      }

      return {
        ...state,
        currentSlice,
        paginator: {
          ...state.paginator,
          pageIndex: 1,
          pageSize: action.data
        }
      };
    }
    case TableReducerActions.SET_ROWS: {
      const currentSlice = getCurrentSlice(action.data, state.paginator.pageIndex, state.paginator.pageSize);
      return {
        ...state,
        currentSlice,
        rows: action.data,
        filteredRows: action.data,
        paginator: {
          ...defaultReducerState.paginator
        },
        filter: {
          ...defaultReducerState.filter,
          count: undefined
        },
        sort: {
          ...defaultReducerState.sort
        }
      };
    }
    default:
      return defaultReducerState;
  }
}
