import React, { useState, VFC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { useAlerts } from 'common/AlertContext';
import { Button } from 'common/components/Button';
import { useDependentProducts } from 'order/common/hooks/useDependentProducts';
import { changeDialogPostOrder, changePostaktuellOrder, createDialogPostOrder } from 'order/common/services/OrderService';
import { useOrderStep } from 'order/common/services/OrderStepProvider';
import { useSearchContext } from 'order/common/context/search/SearchContext';
import { AppPaths } from 'Main';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { UserRights } from 'common/dtos/userRights';
import { OrderMode } from 'order/common/dtos/OrderMode';
import orderClasses from 'common/styles/order.module.css';
import i18n, { useTranslation } from 'i18n';
import { AlertTypes } from 'order/common/components/Alerts/dtos/AlertTypes';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { BackToSearchButton } from '../../../../common/components/BackToSearch/BackToSearchButton';
import { ProductGroup } from 'order/common/dtos/ProductGroup';
import { DialogMarketingOverview } from '../../components/Overview/DialogMarketingOverview';
import { DialogMarketingOrderCreate, translatePostaktuell, translateDialogPost } from '../../schema/dialogMarketingSchema';
import { MyOrder } from 'order/orderSearch/components/MyOrders/MyOrders';

export const DialogMarketingStep4: VFC = observer(() => {
  const { t } = useTranslation('orderCreate');
  const { watch, handleSubmit } = useFormContext<DialogMarketingOrderCreate>();
  const { previousStep, nextStep } = useOrderStep();
  const { reset: resetSearchContext, triggerAlert, updateMyOrders, myOrders } = useSearchContext();
  const history = useHistory();
  const { addAlert, clear: deleteAlerts } = useAlerts();
  const { hasPermission } = useAuthContext();
  const values = watch();
  const isPostaktuell = values.productGroup === ProductGroup.POSTAKTUELL;
  const dependentProductsReturn = useDependentProducts(
    values.productGroup,
    values.details?.date,
    !isPostaktuell ? values.dialogpostBaseProduct : undefined
  );
  const { orderMode, reset: resetOrderContext, order, orderCategoryProductKey } = useOrderContext();
  const { isDirty, setDirty } = useOrderContext();
  const [activeSpinner, setActiveSpinner] = useState(false);

  return (
    <>
      <main className={orderClasses.formContent}>
        <DialogMarketingOverview value={values} orderPhase={orderMode} />
      </main>
      <footer className={classNames('d-flex', 'justify-content-between')}>
        <div>
          <Button
            style={{ marginRight: '10px' }}
            variant="default"
            onClick={() => previousStep()}
            type="button"
            label={t('buttons.back')}
            dataTestId="btnBack"
          />
          <BackToSearchButton isDirty={isDirty} />
        </div>
        <Button
          variant={activeSpinner ? 'default' : 'primary'}
          disabled={!hasPermission(UserRights.OrderEdit) || activeSpinner}
          dataTestId="btnSubmit"
          onClick={async () => {
            await handleSubmit(async (values) => {
              try {
                deleteAlerts();
                // const dp = translateDialogPost(values, dependentProductsReturn);
                let dpResponse: any;
                if (isPostaktuell) {
                  if (orderMode === OrderMode.CHANGE) {
                    setActiveSpinner(true);
                    const dp = translatePostaktuell(values, dependentProductsReturn, true);
                    const { data } = await changePostaktuellOrder(dp);
                    dpResponse = data;
                  } else {
                    return;
                  }
                } else {
                  if (orderMode === OrderMode.CHANGE) {
                    setActiveSpinner(true);
                    const dp = translateDialogPost(values, dependentProductsReturn, true);
                    const { data } = await changeDialogPostOrder(dp);
                    dpResponse = data;
                  } else {
                    setActiveSpinner(true);
                    const dp = translateDialogPost(values, dependentProductsReturn, false);
                    const { data } = await createDialogPostOrder(dp);
                    dpResponse = data;
                  }
                }

                // const { data: dpResponse } = await createDialogPostOrder(dp);
                if (dpResponse?.resultStatus !== 'ERROR') {
                  setActiveSpinner(false);
                  resetSearchContext();

                  // For persisting my orders
                  const myOrderData: MyOrder = {
                    id: dpResponse?.orderId,
                    myOrderNumbers: [dpResponse?.orderId],
                    orderCategory: values?.orderCategory,
                    orderCategoryProductKey: order?.orderCategoryProductKey || orderCategoryProductKey,
                    orderLabel: values?.orderLabel,
                    creationDate: new Date()?.getTime(),
                    sortingDate: new Date()?.getTime()
                  };
                  const found = myOrders
                    ?.map((myOrder) => myOrder?.myOrderNumbers)
                    ?.filter((myOrderArr) => myOrderArr?.length === 1)
                    ?.find((myOrderNums) => (dpResponse?.orderId ? myOrderNums?.includes(dpResponse.orderId) : undefined));
                  if (!found?.length) {
                    updateMyOrders(myOrderData, 'ADD');
                  } else {
                    updateMyOrders(myOrderData, 'UPDATE');
                  }

                  resetOrderContext();
                  setDirty(false);
                  triggerAlert(dpResponse);
                  history.push(AppPaths.orderSearchPath);
                } else {
                  setActiveSpinner(false);
                  dpResponse?.amErrors?.map((error: { code: string; errorCategory: string; errorDescription: string }) => {
                    addAlert({
                      type: AlertTypes.Error,
                      title: `${error.errorCategory} ${error.code}!`,
                      description: error.errorDescription
                    });
                  });
                }
              } catch (e: any) {
                console.error(e);
                setActiveSpinner(false);
                addAlert({
                  type: AlertTypes.Error,
                  title: orderMode === OrderMode.CHANGE ? i18n.t('responseErrors.change.title') : i18n.t('responseErrors.create.title'),
                  description:
                    (orderMode === OrderMode.CHANGE ? i18n.t('responseErrors.change.description') : i18n.t('responseErrors.create.description')) +
                    (e?.response?.data?.status ? ` (${e?.response?.data?.status})` : '')
                });
              } finally {
                nextStep();
              }
            }, console.error)();
          }}
          type="button"
          label={orderMode === OrderMode.CHANGE ? t('buttons.createOrderConfirm') : t('buttons.submitOrder')}
          activeSpinner={activeSpinner}
          style={{ minWidth: '275px' }}
        />
      </footer>
    </>
  );
});
