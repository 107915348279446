import { LetterTypes } from '../dtos/LetterTypes';

export const determineLetterTypeByFormat = (format: string): LetterTypes | string => {
  let letterType: string = '';
  switch (format) {
    case 'S':
      letterType = LetterTypes.Standard;
      break;
    case 'K':
      letterType = LetterTypes.Compact;
      break;
    case 'G':
      letterType = LetterTypes.Large;
      break;
    case 'M':
      letterType = LetterTypes.Maxi;
      break;
    case 'P':
      letterType = LetterTypes.Postcard;
      break;
    default:
      break;
  }
  return letterType;
};
