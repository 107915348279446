import React, { useEffect, useRef, useState, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { CollectedQuantity, QtyAllocationRange } from 'order/common/context/order/dtos/Order';
import { qtySumCalculator } from '../../context/order/utils/qtySumCalculator';
import i18n from 'i18n';
import { useAuthContext } from 'common/context/auth/AuthContext';
import { IdAssignmentModal } from './IdAssignmentModal';
import { OrderReference } from 'order/common/context/order/dtos/OrderReference';
import { useOrderContext } from 'order/common/context/order/OrderContext';
import { OrderMode } from 'order/common/dtos/OrderMode';
import { deepClone } from 'common/utils/deepClone';
import { Occurrence } from 'common/dtos/Occurrence';

import styles from './qty-allocation.module.css';

import transDE from './i18n/translate.de.json';
import transEN from './i18n/translate.en.json';
import { allocationConvertion } from './helper/allocationConvertion';
import { NewQtyAllocationRow } from './NewQtyAllocationRow';

export interface QtyAllocationData {
  orderNumber?: string;
}

export const NewQtyAllocator: VFC<QtyAllocationData> = ({ orderNumber }) => {
  const language = useAuthContext().user.language;
  const frankingIdPrefix = useRef<string | null>(null);
  const machineCode = useRef<string | null>(null);
  const postageFrankingIdPrefix = useRef<string | null>(null);
  const [total, setTotal] = useState(0);
  const totalError = useRef(false);
  const [_, setUpdated] = useState(0);
  const selectedProductNumber = useRef('0');
  const selectedProductFormat = useRef('0');
  const orderReference = useRef<OrderReference | null>(null);
  const selectedAllocQty = useRef(0);
  const initialTotalQty = useRef(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [frankingIdVisible, setFrankingIdVisible] = useState<boolean>(false);
  const { t } = useTranslation('shipmentAssignment');
  const { orderReferences, changeOrderReference, orderMode, addError, deleteError, setAllocatedDiscounts } = useOrderContext();
  i18n.addResourceBundle('de', 'shipmentAssignment', transDE);
  i18n.addResourceBundle('en', 'shipmentAssignment', transEN);

  const readOnly = orderMode === OrderMode.VIEW;

  useEffect(() => {
    const reference = orderReferences?.find((o) => o.orderId === orderNumber);
    if (reference) {
      orderReference.current = { ...reference };
      frankingIdPrefix.current = reference.frankingIdPrefix || null;
      machineCode.current = reference.machineCode || null;
      postageFrankingIdPrefix.current = reference.postageFrankingIdPrefix || null;
      const calculatedSum = qtySumCalculator(reference?.qtyAllocation || [], 'quantity') as number;
      initialTotalQty.current = calculatedSum;
      setTotal(calculatedSum);
      setUpdated(new Date().getTime());
    }
  }, [orderNumber, orderReferences]);

  const handleAllocOnChange = (productNumber?: string, qty?: number) => {
    if (orderReference.current) {
      const newOrderReference = deepClone(orderReference.current);
      if (newOrderReference?.qtyAllocation.find((q) => q.productNumber === productNumber)?.multipleContainsIds) {
        const allocationsForSelectedProduct = newOrderReference?.qtyAllocation.find((q) => q.productNumber === productNumber);
        if (!allocationsForSelectedProduct) return;

        const qtyChange = (qty || 0) - (allocationsForSelectedProduct.originalAssignedQty || 0);
        const withIdsWithFlex = allocationsForSelectedProduct.collectedQuantities?.find((q) => q.containsIds === true && q.flexOption === true);
        const withIdsNoFlex = allocationsForSelectedProduct.collectedQuantities?.find((q) => q.containsIds === true && q.flexOption === false);
        if (!withIdsWithFlex || !withIdsNoFlex) return;
        const noIdsNoFlex = allocationsForSelectedProduct.collectedQuantities?.find((q) => q.containsIds === false && q.flexOption === false) ?? {
          ...withIdsWithFlex,
          containsIds: false,
          flexOption: false,
          includeTotalQty: false,
          quantity: 0,
          initialQty: 0,
          ranges: null,
          totalWeight: null,
          partialServiceItem: null,
          idItem: null,
          refShipmentIdZA: null
        };

        const reduceQtyReturnRest = (allocationObj: any, qtyToChange: number) => {
          const updatedQty = allocationObj.initialQty + (qtyToChange > 0 ? 0 : qtyToChange);
          allocationObj.quantity = updatedQty < 0 ? 0 : updatedQty;
          return updatedQty;
        };

        if (qtyChange <= 0) {
          [noIdsNoFlex, withIdsWithFlex, withIdsNoFlex].reduce((qtyToChange, allocationObj) => {
            if (!allocationObj) return qtyToChange;
            return reduceQtyReturnRest(allocationObj, qtyToChange);
          }, qtyChange);
        } else {
          withIdsWithFlex.quantity = withIdsWithFlex.initialQty || 0;
          withIdsNoFlex.quantity = withIdsNoFlex.initialQty || 0;
          noIdsNoFlex.quantity = (noIdsNoFlex.initialQty || 0) + qtyChange;
        }

        allocationsForSelectedProduct.collectedQuantities = [withIdsWithFlex, withIdsNoFlex, noIdsNoFlex] as unknown as CollectedQuantity[];
        allocationsForSelectedProduct.quantity = qty || 0;
        allocationsForSelectedProduct.fromOriginal = false;
        allocationsForSelectedProduct.combinedCounter = 3;
        allocationsForSelectedProduct.includeTotalQty = (qty || 0) === (allocationsForSelectedProduct.initialQty || 0);
      }

      newOrderReference?.qtyAllocation?.forEach((q) => {
        if (q.productNumber === productNumber) {
          q.quantity = qty as any;
          q.fromOriginal = false;
          q.containsIds = (q.idsQty && q.idsQty > 0) || qty === q.initialQty;
          q.includeTotalQty = (qty || 0) === (q.initialQty || 0);
          if (newOrderReference?.new) {
            q.flexOption = newOrderReference?.flexOption !== Occurrence.NONE && q.containsIds;
            q.idsQty = qty === q.initialQty ? undefined : q.idsQty;
            q.ranges = qty === q.initialQty ? undefined : q.ranges;
          }
        }
      });

      const totalQtyAllocations = qtySumCalculator(newOrderReference.qtyAllocation || [], 'quantity') as number;
      newOrderReference.includeTotalQty =
        totalQtyAllocations === newOrderReference.initialTotalQty ? Occurrence.ALL : totalQtyAllocations > 0 ? Occurrence.SOME : Occurrence.NONE;
      newOrderReference.allocatedQty = totalQtyAllocations;

      orderReference.current = newOrderReference;
      changeOrderReference(newOrderReference.orderId || '', newOrderReference);

      const newData = allocationConvertion(orderReference.current);
      if (newData) setAllocatedDiscounts(newData, 'UPDATE');
      if (totalQtyAllocations <= 0) {
        totalError.current = true;
        addError(`${orderNumber}-totalError`, { orderNumber, format: '' });
      } else {
        totalError.current = false;
        deleteError(`${orderNumber}-totalError`);
      }
      setTotal(totalQtyAllocations);
    }
  };
  const handleOnIdAssignmentClick = (productNumber: string, format: string) => {
    const allocQty = orderReference.current?.qtyAllocation?.find((a) => a.productNumber === productNumber);
    selectedAllocQty.current = allocQty?.quantity || 0;
    selectedProductNumber.current = productNumber;
    selectedProductFormat.current = format;
    setDisplayModal(true);
  };

  const handleOnSubmit = (productNumber: string, ranges: QtyAllocationRange[], totalRangesQty: number) => {
    if (orderReference.current) {
      const newOrderReference = deepClone(orderReference.current);
      newOrderReference.qtyAllocation?.forEach((q) => {
        if (q.productNumber === productNumber) {
          q.containsIds = totalRangesQty > 0 || q.quantity === q.initialQty;
          q.ranges = ranges;
          q.flexOption = newOrderReference.flexOption !== Occurrence.NONE && totalRangesQty > 0;
        }
      });
      const countAllContainsIds = newOrderReference.qtyAllocation.filter((q) => q.containsIds).length;
      const countAllNotContainsIds = newOrderReference.qtyAllocation.filter((q) => !q.containsIds).length;
      newOrderReference.idsQty = totalRangesQty;
      newOrderReference.containsIds =
        !countAllNotContainsIds && countAllContainsIds
          ? Occurrence.ALL
          : countAllNotContainsIds && countAllContainsIds
          ? Occurrence.SOME
          : Occurrence.NONE;

      orderReference.current = newOrderReference;
      changeOrderReference(newOrderReference.orderId || '', newOrderReference);

      const newData = allocationConvertion(orderReference.current);
      if (newData) setAllocatedDiscounts(newData, 'UPDATE');
    }
    setDisplayModal(false);
  };
  const handleModalCancel = () => setDisplayModal(false);
  const handleQtyAllocationError = (format: string, error: boolean) => {
    if (error) {
      addError(orderNumber || '', { orderNumber, format });
    } else {
      deleteError(orderNumber || '');
    }
  };

  return (
    <>
      <div className={styles.flexbox}>
        <div>
          <div className={styles.containerTwo}>
            <table className={styles.QATable}>
              <thead>
                <tr className={styles.tableHeadRow}>
                  <td rowSpan={2} className={classNames(styles.alignLeft, styles.borderLeft)}>
                    {t('format')}
                  </td>
                  <td rowSpan={2}>{t('deliveryOption')}</td>
                  <td rowSpan={2}>
                    <p>{`${t('shipmentQuantityColumns.shipmentQuantity')}`} </p>
                    <p>{`${t('shipmentQuantityColumns.ofOrder')}`} </p>
                  </td>
                  <td rowSpan={2} className={classNames(styles.borderRight)}>
                    <p>{`${t('shipmentQuantityColumns.shipmentQuantity')}`} </p>
                    <p>{`${t('shipmentQuantityColumns.assigned')}`} </p>
                  </td>

                  {/* eslint-disable */}
                  <td
                    rowSpan={2}
                    className={classNames(styles.bgWhite, styles.width35, styles.borderRight, styles.iconWidth24)}
                    onClick={() => setFrankingIdVisible((prevState) => !prevState)}
                    style={{ cursor: 'pointer', borderTop: 'none', verticalAlign: 'top' }}
                  >
                    {frankingIdVisible ? (
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <polygon fill="#D40511" fillRule="evenodd" points="8 3 10.345 3 16 12 10.345 21 8 21 14 12" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <polygon fill="#D40511" fillRule="evenodd" points="10.345 12 16 3 13.655 3 8 12 13.655 21 16 21" />
                      </svg>
                    )}
                  </td>
                  {frankingIdVisible && (
                    <>
                      <td className={classNames(styles.alignLeft, styles.borderRight, styles.borderBotton)} colSpan={2}>
                        <div className={styles.flex} style={{ height: '100%', alignItems: 'center' }}>
                          <div className={classNames(styles.icon, styles.grey)}>
                            <span className={styles.iconWrapper}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <polygon
                                  fill="#878787"
                                  fillRule="evenodd"
                                  points="23 2.185 21.806 1 11.915 10.815 2.364 1.169 1.171 2.523 10.721 12 1 21.815 2.194 23 11.915 13.185 21.636 22.831 22.829 21.646 13.279 12 23 2.185"
                                />
                              </svg>
                            </span>
                          </div>
                          {t('withoutFranking')}
                        </div>
                      </td>
                      <td className={classNames(styles.borderBotton, styles.borderRight)} colSpan={2}>
                        <div className={styles.flex} style={{ height: '100%', alignItems: 'center' }}>
                          <div className={classNames(styles.icon, styles.green)}>
                            <span className={styles.iconWrapper}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <polygon fill="#878787" fillRule="evenodd" points="24 5.2 11.2 18 8 21.2 4.8 18 0 13.2 3.2 10 8 14.8 20.8 2 24 5.2" />
                              </svg>
                            </span>
                          </div>
                          {t('withFranking')}
                        </div>
                      </td>
                    </>
                  )}
                  <td rowSpan={2} className={styles.whitesmokeBGColor} style={{ borderTop: 'none' }}></td>
                </tr>
                {frankingIdVisible && (
                  <tr>
                    <td className={classNames(styles.alignRight, styles.borderRight)} colSpan={2}>
                      <p>
                        Basis <br />
                        (E+1 bis 2)
                      </p>
                    </td>
                    <td className={styles.alignRight}>
                      <p>
                        ID <br />
                        (E+1 bis 2)
                      </p>
                    </td>
                    <td className={classNames(styles.alignRight, styles.borderRight)}>
                      <p>
                        Schnell <br />
                        (E+1)
                      </p>
                    </td>
                  </tr>
                )}
              </thead>
              <tbody>
                {orderReference.current !== undefined || total
                  ? orderReference.current?.qtyAllocation?.map((data, key) => {
                      const keyId = `${key}-${data.productNumber}`;
                      return (
                        <NewQtyAllocationRow
                          key={keyId}
                          data={data}
                          orderReference={orderReference.current}
                          language={language}
                          id={key.toString()}
                          onChange={handleAllocOnChange}
                          onError={handleQtyAllocationError}
                          onIdAssignmentClick={handleOnIdAssignmentClick}
                          readOnly={readOnly}
                          disabled={orderReference.current?.pendingIds}
                          orderMode={orderMode}
                          frankingIdVisible={frankingIdVisible}
                          setFrankingIdVisible={setFrankingIdVisible}
                        />
                      );
                    })
                  : ''}
              </tbody>
              {orderReference.current?.qtyAllocation && !readOnly && (
                <tfoot>
                  <tr>
                    <td className="p-0 text-left">
                      <p>{t('totalQtyAssigned')}</p>
                    </td>
                    <td colSpan={3}>
                      <p data-testid={'qty-allocator-total-sum'} className="pr-2">
                        {total.toLocaleString(language)}
                      </p>
                    </td>
                  </tr>
                </tfoot>
              )}
            </table>
            {!readOnly && (
              <>
                {orderReference.current?.pendingIds && orderMode === OrderMode.CHANGE && (
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div className={styles.fontNormal} data-testid={'qty-allocator-total-sum'}>
                            {t('pendingIds')}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
                {totalError.current && (
                  <table>
                    <tbody>
                      <tr className={styles.whitesmokeBGColor}>
                        <td>
                          <div className={styles.errorMessage} data-testid={'qty-allocator-total-error'}>
                            {t('totalError')}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {displayModal && !readOnly ? (
        <IdAssignmentModal
          orderId={orderNumber || ''}
          productNumber={selectedProductNumber.current}
          productFormat={selectedProductFormat.current}
          allocQty={selectedAllocQty.current}
          allocatedValue={0}
          onSubmit={handleOnSubmit}
          onCancel={handleModalCancel}
          machineCode={machineCode.current || undefined}
          frankingIdPrefix={frankingIdPrefix.current || undefined}
          postageFrankingIdPrefix={postageFrankingIdPrefix.current || undefined}
          meta={{ language }}
        />
      ) : null}
    </>
  );
};
